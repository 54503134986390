import { CompanyProvider } from './Company.context'

import * as S from './Company.styles'
import { CompanyCreate } from './CompanyCreate'

export const CompanyCreateWrapper = () => {
  return (
    <CompanyProvider>
      <S.Container>
        <CompanyCreate />
      </S.Container>
    </CompanyProvider>
  )
}
