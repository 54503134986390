import styled, { css } from 'styled-components'

import { devices } from 'layout/devices'
import { appearFromRight } from 'layout/animations'

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 360px;

  h2 {
    margin-bottom: 42px;
    text-align: center;
  }

  p {
    color: #646e7d;
    letter-spacing: 0.16px;
  }

  p + P {
    margin-top: 24px;
  }

  button {
    margin-top: 44px;
  }
`

export const Wrapper = styled.main``
export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  width: 100%;

  @media ${devices.large} {
    grid-template-columns: 1fr 1fr;
  }
`

export const ContainerLeft = styled.div`
  display: none;

  @media ${devices.medium} {
    align-items: center;
    display: grid;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;
    img {
      justify-self: center;
      position: relative;
    }
  }
`

export const ContainerRight = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    background-color: ${theme.colors.white};
    justify-content: center;
    padding: 4rem 0;
    position: relative;
    height: 100vh;
    width: 100%;
  `}
`
export const ContentRight = styled.div`
  display: flex;
  align-items: center;
  animation: ${appearFromRight} 0.5s;
  flex-direction: column;
  justify-self: center;
  justify-content: center;
  max-width: 30rem;
  padding: 2.5rem;
  position: relative;
  width: 100%;
`
