import Slider, { Settings } from 'react-slick'

import { assetsPaths } from 'assets'
import * as S from './ImageSlider.styles'

export const ImageSlider = () => {
  const settings: Settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <S.SliderContainer>
      <Slider {...settings}>
        <S.SliderItem bgImage={assetsPaths.images.cooking}>
          <S.SliderHolder>
            <S.ContentWrapper>
              <S.IconContent>
                <S.SliderIcon src={assetsPaths.icons.store} />
                <S.TextStyled>investir no seu negócio</S.TextStyled>
              </S.IconContent>
              <S.TitleStyled>
                você pode mais com crédito inteligente
              </S.TitleStyled>
            </S.ContentWrapper>
          </S.SliderHolder>
        </S.SliderItem>

        <S.SliderItem bgImage={assetsPaths.images.happyCouple}>
          <S.SliderHolder>
            <S.ContentWrapper>
              <S.IconContent>
                <S.SliderIcon src={assetsPaths.icons.house} />
                <S.TextStyled>comprar a casa própria</S.TextStyled>
              </S.IconContent>
              <S.TitleStyled>
                você pode mais com crédito inteligente
              </S.TitleStyled>
            </S.ContentWrapper>
          </S.SliderHolder>
        </S.SliderItem>

        <S.SliderItem bgImage={assetsPaths.images.painting}>
          <S.SliderHolder>
            <S.ContentWrapper>
              <S.IconContent>
                <S.SliderIcon src={assetsPaths.icons.brush} />
                <S.TextStyled>fazer a sonhada reforma</S.TextStyled>
              </S.IconContent>
              <S.TitleStyled>
                você pode mais com crédito inteligente
              </S.TitleStyled>
            </S.ContentWrapper>
          </S.SliderHolder>
        </S.SliderItem>
      </Slider>
    </S.SliderContainer>
  )
}
