import { RestTypes } from 'components/typings/utils/restTypes'
import * as S from './StepsLine.styles'

export type StepsLineProps = {
  lines: number
  linesBehind: number
} & RestTypes

export const StepsLine = ({ lines, linesBehind, ...props }: StepsLineProps) => {
  return (
    <S.StepContainer {...props}>
      {Array.from({ length: lines }, (_, index) => (
        <S.StepLine key={index} />
      ))}
      {Array.from({ length: linesBehind }, (_, index) => (
        <S.StepLineBehind key={index} />
      ))}
    </S.StepContainer>
  )
}
