import { useCallback, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import Assets from 'assets'
import { HeaderActions } from 'components/HeaderActions'
import { ListTable } from 'components/ListTable'
import { ToggleSwitcher } from 'components/ToggleSwitcher'
import { useRouter } from 'hooks/useRouter'
import { useLoading } from 'providers/LoadingProvider'
import { ROUTES_NAME } from 'routes'

import * as S from './Reason.styles'
import { ReasonEditRouteProps } from './ReasonEdit'
import { ReasonDTO } from 'services/remote/v1/Reason/ReasonServiceTypes'
import CompanyService from 'services/remote/v1/Company/CompanyService'
import { useAppData } from 'providers/AppDataProvider'
import { format } from 'date-fns'
import { formatZonedTimeToUtc } from 'utils/formatters/fomatZonedTimeToUtc'

import { getResourcePermissions } from 'utils/getResourcePermissions'
import { PermissionsDTO } from 'models'
import { TooltipText } from 'components/TooltipText'

interface RenderItemProps {
  item: ReasonDTO
  canEdit?: boolean
}

export const Reason = () => {
  const { isLoading, setLoading } = useLoading()
  const { history } = useRouter()
  const { selectedCompanyUuid, data: appData } = useAppData()

  const [tableListData, setTableListData] = useState<ReasonDTO[]>([])
  const [permissions, setPermissions] = useState<PermissionsDTO>(
    {} as PermissionsDTO
  )

  const handleEditButton = useCallback(
    (unit: ReasonDTO) => {
      const state: ReasonEditRouteProps = {
        routeItems: { ...unit }
      }
      history.push({
        pathname: ROUTES_NAME.REASON_EDIT,
        state
      })
    },
    [history]
  )

  const handleCreateNewReason = () => {
    history.push(ROUTES_NAME.REASON_CREATE)
  }

  const renderItem = useCallback(
    ({ item, canEdit }: RenderItemProps) => {
      const formattedDate = format(
        new Date(formatZonedTimeToUtc(item.created_at) || new Date()),
        'dd/MM/yyyy'
      )
      return (
        <>
          <span>
            <TooltipText text={item?.name}>
              {item?.name || <Skeleton width={200} />}
            </TooltipText>
          </span>
          <span>
            <TooltipText text={formattedDate}>
              {item?.created_at ? formattedDate : <Skeleton width={100} />}
            </TooltipText>
          </span>
          <span>
            {isLoading ? (
              <Skeleton width={100} />
            ) : (
              <TooltipText text={item?.created_by?.name ?? '-'}>
                {item?.created_by?.name ?? '-'}
              </TooltipText>
            )}
          </span>
          {!item.is_default ? (
            <ToggleSwitcher
              disabled
              isActive={isLoading ? false : item?.is_active}
              toggleActive={() => null}
            />
          ) : (
            <span />
          )}
          {canEdit && !item.is_default ? (
            <S.Icon onClick={() => handleEditButton(item)}>
              <Assets
                assetProps={{ key: 'lineEditV2', type: 'icon' }}
                alt="editar"
              />
            </S.Icon>
          ) : (
            <></>
          )}
        </>
      )
    },
    [handleEditButton, isLoading]
  )

  const getAllReasons = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await CompanyService.getAllReasonsInCompanyByUuid(
        selectedCompanyUuid || '',
        true
      )
      setTableListData(data.data)
    } catch (err) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }, [selectedCompanyUuid, setLoading])

  useEffect(() => {
    const _permissions = getResourcePermissions(appData, 'CAD', 'CAD_MOT')

    if (_permissions) setPermissions(_permissions)
  }, [appData])

  useEffect(() => {
    getAllReasons()
  }, [getAllReasons])

  return (
    <S.Container>
      <HeaderActions
        title="motivos de recusa"
        buttonTitle="+ novo motivo"
        buttonAction={handleCreateNewReason}
        hasReturnButton={false}
        renderCustomRightCorner={!permissions.write ? <></> : null}
      />

      <ListTable<ReasonDTO>
        headerTitle="Todos os motivos"
        tableStructure={{
          header:
            '1fr minmax(100px, 150px) minmax(150px, 250px) minmax(60px, 60px) minmax(60px, 60px)',
          body: '1fr minmax(100px, 150px) minmax(150px, 250px) minmax(60px, 60px) minmax(60px, 60px)',
          columns: ['motivo', 'criado em', 'criado por', 'ativo', 'editar']
        }}
        data={isLoading ? Array(9).fill({}) : tableListData}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        canEdit={permissions.write || permissions.exclude}
      />
    </S.Container>
  )
}
