import styled, { css } from 'styled-components'

import { CollapsedButtonProps } from './CollapsedButton'

export const Container = styled.div<CollapsedButtonProps>`
  ${({ theme, isCollapsed }) => css`
    align-items: center;
    background: ${theme.colors.greyborders};
    border: none;
    border-radius: 50%;
    display: flex;
    height: 34px;
    justify-content: center;
    width: 34px;
    right: -15px;
    top: 3.6rem;
    position: absolute;
    z-index: 5;

    &:hover {
      cursor: pointer;
    }

    img {
      ${!isCollapsed ? 'transform: scaleX(1)' : 'transform: scaleX(-1)'};
      width: 8px;
      transition: all 0.2s ease-in;
    }
  `}
`
