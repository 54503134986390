import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'
import {
  CreateOrUpdateProductResponse,
  DeleteProductBydUuidResponse,
  ProductReqBody
} from './ProductServiceTypes'

class ProductService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Create product
   * @returns CreateOrUpdateProductResponse
   */
  public createProduct(uuid: string, product: Partial<ProductReqBody>) {
    return this.instance.post<CreateOrUpdateProductResponse>(
      `companies/${uuid}/products`,
      product
    )
  }

  /**
   * update product by UUID
   * @returns CreateOrUpdateProductResponse
   */
  public updateProductByUuid(uuid: string, product: Partial<ProductReqBody>) {
    return this.instance.put<CreateOrUpdateProductResponse>(
      `products/${uuid}`,
      product
    )
  }

  /**
   * delete product by UUID
   * @returns DeleteProductBydUuidResponse
   */
  public deleteProductByUuid(uuid: string) {
    return this.instance.delete<DeleteProductBydUuidResponse>(
      `products/${uuid}`
    )
  }
}

export default new ProductService()
