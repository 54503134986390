/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { PartnersTheme } from 'layout/theme'
import styled, { css } from 'styled-components'

interface StyleProps {
  color: keyof typeof PartnersTheme.colors
  customColumn?: boolean
}

export const Box = styled.div<StyleProps>`
  ${({ theme, color, customColumn }) => css`
    align-items: center;
    background: ${theme.colors.white};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    min-width: 8.75rem;
    position: relative;
    width: 100%;

    ${color && `border: 1px solid ${color};  `};}

    ${
      !!customColumn &&
      ` grid-column-start: 1;
      grid-column-end: 3;
    `
    };
  `}
`

export const Head = styled.div<StyleProps>`
  ${({ color, theme }) => css`
    display: flex;
    align-items: center;
    background: ${theme.colors[color!]}10;
    border-radius: 12px 12px 0 0;
    justify-content: center;
    padding: 0.5rem;
    text-align: center;
    width: 100%;
   
    ${color && `background: ${color}10;`};}
  `}
`

export const Body = styled.div`
  ${() => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.25rem 0;
    text-align: center;
    width: 100%;
  `}
`

interface StyleTextProps {
  color: string
  size: string
}

export const Text = styled.p<StyleTextProps>`
  ${({ theme, color, size }) => css`
    color: ${color};
    font-size: ${size};
    color: ${color};
    font-weight: ${theme.font.weight['weight-400']};
  `}
`
