import { ToastProps } from 'react-toastify'

import * as S from './ToastContainer.styles'

interface AllToastProps extends ToastProps {
  title: string
  message: string
}

export const ToastContainer = ({ message, title }: AllToastProps) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <p>{message}</p>
    </S.Container>
  )
}
