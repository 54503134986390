import styled, { css } from 'styled-components'

import { appearFromRight } from 'layout/animations'

interface GridProps {
  gridTemplate: string
}

interface GridHeaderProps {
  isEditColumn?: boolean
  filter?: boolean
}

export const Container = styled.div`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4.75px;

  animation: ${appearFromRight} 0.3s ease-in;
`

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`

export const TableHeader = styled.div<GridProps>`
  display: inline-grid;
  width: 100%;
  min-width: 800px;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  border-bottom: 1px solid #dadada;
  padding: 27px 31px 12px 31px;
`

export const TableRow = styled.div<GridProps>`
  display: inline-grid;
  width: 100%;
  border-bottom: 1px solid #dadada;
  padding: 27px 31px 12px 31px;
  align-items: center;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  min-width: 800px;
  font-size: 14px;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1rem;
  }

  &:last-child {
    border-bottom: 0;
  }

  .recurse-column__title {
    align-self: center;
  }
`

export const GridHeader = styled.div<GridHeaderProps>`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #646e7d;

  margin-left: ${({ isEditColumn }) => (isEditColumn ? 'auto' : 0)};
  ${(props) =>
    props.filter &&
    css`
      display: flex;
      justify-content: flex-end;
    `}
`

export const EmptyProducts = styled.div`
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
  text-align: center;
`
