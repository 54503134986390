import Assets from 'assets'
import { Text } from 'components/Text'
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import * as S from './DropzoneUpload.styles'

export type FileProps = {
  preview: string
  base64: string | ArrayBuffer | null
} & File

interface DropzoneUploadProps {
  onChange: (files: FileProps[], error: string | null) => void
  initialValue?: string | ArrayBuffer | null
  maxWidth?: string
  recommendedResolution?: string
  showTip?: boolean
  errorMessage?: string
  acceptAllImageTypes?: boolean
}

export const DropzoneUpload = ({
  onChange,
  initialValue,
  maxWidth,
  recommendedResolution,
  showTip = true,
  errorMessage,
  acceptAllImageTypes = false
}: DropzoneUploadProps) => {
  const [files, setFiles] = useState<FileProps[]>([])
  const [error, setError] = useState<string | null>(null)

  const parseError = (errorType: string) => {
    switch (errorType) {
      case 'file-invalid-type':
        return acceptAllImageTypes
          ? 'Formato de arquivo inválido, são aceitos apenas imagens'
          : 'Formato de arquivo inválido, são aceitos .png ou .svg'

      case 'file-too-large':
        return 'Arquivo muito pesado, deve ser menor que 300kb'

      default:
        return 'Erro no upload do arquivo'
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptAllImageTypes
      ? 'image/png, image/svg+xml, image/jpg, image/jpeg'
      : 'image/png, image/svg+xml',
    multiple: false,
    onDrop: async (acceptedFiles, rejections) => {
      if (rejections && !!rejections.length) {
        setError(parseError(rejections[0].errors[0].code))
        return
      }

      const base64 = await blobToBase64(acceptedFiles[0])

      setError(null)

      setFiles(
        acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
            base64
          })
        })
      )
    }
  })

  const blobToBase64 = async (
    blob: Blob
  ): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  useEffect(() => {
    if (files.length) {
      onChange(files, error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, error])

  useEffect(
    () => () => {
      // Avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview))
    },
    [files]
  )

  const handleDeleteLogo = () => {
    setFiles([])
    onChange([], null)
  }

  return (
    <S.Container maxWidth={maxWidth}>
      <S.Dropzone {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />

        <Assets
          alt="selecionar logo"
          assetProps={{ type: 'icon', key: 'pictureIcon' }}
        />
        <p>
          arraste e solte ou <span>selecione</span>
        </p>

        {(!!files.length || initialValue) && (
          <S.UploadPreview>
            <img src={files[0]?.preview || (initialValue as string)} />
          </S.UploadPreview>
        )}
      </S.Dropzone>

      {(!!files.length || initialValue) && (
        <S.UploadPreviewFileName>
          <Text color="text">{files[0]?.name || ''} </Text>

          <S.RemoveIcon onClick={handleDeleteLogo}>
            <Text color="error" weight="weight-600">
              (remover)
            </Text>
          </S.RemoveIcon>
        </S.UploadPreviewFileName>
      )}

      {(error || errorMessage) && (
        <S.ErrorMessage>{error || errorMessage}</S.ErrorMessage>
      )}

      <S.UploadRules>
        <span>
          {acceptAllImageTypes
            ? 'aceitamos os formatos .png, .svg, .jpg, .jpeg'
            : 'aceitamos os formatos .png, .svg'}
        </span>
        <span>tamanho máx. 300kb</span>
        {showTip && (
          <span>
            Dica: Utilize imagens com fundo transparente e aplicação de marca
            preferencialmente horizontal
          </span>
        )}

        {recommendedResolution && (
          <span>{`dimensões recomendadas da imagem: ${recommendedResolution}`}</span>
        )}
      </S.UploadRules>
    </S.Container>
  )
}
