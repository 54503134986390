import styled from 'styled-components'

export const Box = styled.div`
  align-items: center;
  display: flex;
  height: fit-content;
  justify-content: space-between;
  gap: 0.5rem;
  width: fit-content;
`
