import { useRef, useEffect } from 'react'

import { useRouter } from 'hooks/useRouter'
import { useModal } from 'providers/ModalProvider'
import useForm from 'hooks/useForm'
import { useLoading } from 'hooks/useLoading'

import { Button } from 'components/Button'
import { Input } from 'components/Input'

import { Form } from 'components/Form'
import { Text } from 'components/Text'
import { StepsWithIconReturn } from 'templates/StepsWithIconReturn'
import { CommonModal } from 'templates/Modals/CommonModal'

import AuthenticationService from 'services/remote/v1/Authorizer/AuthenticationService'
import { ROUTES_NAME } from 'routes'

type RouteStateType = {
  uuidLogin: string
  pinUuid: string
  flowType: 'firstAccess' | 'forgetPassword'
}

interface FormValues {
  password: string
  confirmPassword: string
}

export const FirstAccessStep2 = () => {
  const inputRef = useRef<HTMLInputElement>(null)

  const { history, location } = useRouter<RouteStateType>()
  const { dispatch } = useModal()
  const { setLoading } = useLoading()

  const isFirstAccess = location.state.flowType === 'firstAccess'

  useEffect(() => {
    if (!location.state) {
      history.replace(ROUTES_NAME.LOGIN)
    }
  }, [location, history])

  const handleGoBack = () => {
    history.goBack()
  }

  const handleSubmitForm = async (data: FormValues) => {
    try {
      setLoading(true)
      const { pinUuid, uuidLogin } = location.state
      await AuthenticationService.updateFirstAccessPassword(
        uuidLogin,
        pinUuid,
        data.password
      )

      dispatch({
        action: 'OPEN_MODAL',
        component: (
          <CommonModal
            title={isFirstAccess ? 'conta criada' : 'senha redefinida'}
            description={
              isFirstAccess
                ? 'Sucesso! Sua conta foi criada e agora você pode usá-la para fazer login'
                : 'Sucesso! A sua senha foi redifinida, agora você pode usá-la para fazer login'
            }
            primaryButtonTitle="ir para o login"
            primaryButtonProps={{
              fontSize: '1rem',
              backgroundColor: 'noverde'
            }}
            primaryButtonAction={() => {
              dispatch({ action: 'CLOSE_MODAL' })
              history.push(ROUTES_NAME.LOGIN)
            }}
          />
        )
      })
    } catch (err) {
      dispatch({
        action: 'OPEN_MODAL',
        component: (
          <CommonModal
            title="Oops! Algo deu errado"
            description={
              err?.response?.data?.error?.messages_client[0] || err.message
            }
            primaryButtonTitle="voltar"
            primaryButtonProps={{
              fontSize: '1rem',
              backgroundColor: 'noverde'
            }}
            primaryButtonAction={() => {
              dispatch({ action: 'CLOSE_MODAL' })
            }}
          />
        )
      })
    } finally {
      setLoading(false)
    }
  }

  const { data, errors, handleChange, handleSubmit } = useForm<FormValues>({
    onSubmit: () => handleSubmitForm(data),
    validations: {
      password: {
        required: {
          value: true,
          message: 'Informe a nova senha'
        },
        custom: {
          message: 'Sua senha precisa possuir no mínimo 6 caracteres',
          isValid: (currValue) => currValue.length >= 6
        },
        pattern: {
          message:
            'A senha deve conter deve conter ao menos uma letra maiúscula, símbolo e numero',
          value:
            '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$'
        }
      },
      confirmPassword: {
        required: {
          value: true,
          message: 'Confirme a nova senha'
        },
        custom: {
          message: 'As senhas informadas precisam ser iguais',
          isValid: (currValue) => inputRef.current?.value === currValue
        }
      }
    }
  })

  return (
    <StepsWithIconReturn
      stepLines={2}
      stepLinesBehind={0}
      title={
        <>
          estamos <br />
          <strong>
            felizes em <br /> te conhecer
          </strong>
        </>
      }
      stepTitle={
        <>
          {isFirstAccess ? 'crie' : 'redefina'} <strong>sua senha</strong>
        </>
      }
      subtitle="muito prazer"
      onClickReturnIcon={handleGoBack}
    >
      <Text
        size="1.2rem"
        color="text"
        _spacing={{
          marginBottom: '2rem'
        }}
      >
        defina uma {!isFirstAccess && 'nova '}senha para sua conta
      </Text>

      <Form
        onSubmit={handleSubmit}
        _flex={{
          alignItems: 'center',
          direction: 'column',
          justifyContent: 'center'
        }}
      >
        <Input
          ref={inputRef}
          name="password"
          message={errors.password}
          onChange={handleChange('password')}
          animatedLabel="senha"
          placeholder=" "
          isRequired
          isSecuryText
          contentProps={{
            marginBottom: '30px'
          }}
        />
        <Input
          name="confirmPassword"
          message={errors.confirmPassword}
          animatedLabel="confirmar senha"
          onChange={handleChange('confirmPassword')}
          placeholder=" "
          isRequired
          isSecuryText
          contentProps={{
            marginBottom: '60px'
          }}
        />

        <Button
          disabled={
            !location.state || !data?.password || !data?.confirmPassword
          }
        >
          {isFirstAccess ? 'criar conta' : 'redefinir senha'}
        </Button>
      </Form>
    </StepsWithIconReturn>
  )
}
