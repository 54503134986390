import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'
import {
  GetLastProcessingTypes,
  UploadFileServiceTypes,
  SendUploadedFileResponse
} from './UploadFileServiceTypes'

class UploadFileService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Get all categories
   * @returns GetAllCategoriesResponse
   */
  public sendUploadedFile(body: UploadFileServiceTypes) {
    return this.instance.post<SendUploadedFileResponse>('imports', { ...body })
  }

  public getLastProcessing(type: string) {
    return this.instance.get<GetLastProcessingTypes>(
      `/imports/last?type=${type}`
    )
  }
}

export default new UploadFileService()
