import styled, { css } from 'styled-components'

interface EditButtonProps {
  disabled?: boolean
}

export const Container = styled.div`
  display: grid;
  height: fit-content;
  padding: 2rem 2.5rem;
  width: 100%;
`

export const EditButton = styled.div<EditButtonProps>`
  margin-left: auto;
  cursor: pointer;
  margin-right: 5px;
  ${({ disabled = false }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `};
`

export const ListHeader = styled.span`
  display: block;
  margin-left: 44px;
  margin-bottom: 28px;
`
