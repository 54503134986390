import styled, { css } from 'styled-components'
import { SimulationSheetProps } from '.'

type Props = {
  fullHeight?: boolean
} & SimulationSheetProps

export const Box = styled.div<Pick<Props, 'fullHeight' | 'status'>>`
  ${({ theme, fullHeight, status }) => css`
    align-items: center;
    background: ${theme.colors.white};
    border: 1px solid
      ${status === 'approved' ? theme.colors.noverde : theme.colors.sunset}30;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    max-width: 30%;
    position: relative;
    width: 100%;
    ${fullHeight ? 'height: 229px' : ''};
  `}
`

export const Head = styled.div<Pick<Props, 'status'>>`
  ${({ theme, status }) => css`
    display: flex;
    align-items: center;
    background: ${status === 'approved'
      ? theme.colors.noverde
      : theme.colors.sunset}30;
    border-radius: 12px 12px 0 0;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    width: 100%;
  `}
`

export const Body = styled.div`
  ${() => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    width: 100%;
  `}
`
