import { Text } from 'components/Text'
import { renderRestTypes } from 'components/typings/utils/renderRestTypes'
import styled, { css } from 'styled-components'
import { PinProps } from '.'

export const Box = styled.div<Partial<PinProps>>`
  ${({ _flex, _layout, _spacing, _sizing, _positioning }) => css`
    display: grid;
    row-gap: 1rem;
    width: fit-content;

    ${renderRestTypes({
      _flex: _flex,
      _layout: _layout,
      _positioning: _positioning,
      _sizing: _sizing,
      _spacing: _spacing
    })}
  `}
`
export const BoxTitle = styled(Text)`
  display: inline-block;
`
export const BoxesPins = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1.125rem;
`
export const BoxPin = styled.input`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.greyborders};
    border-radius: 6px;
    font-size: 4rem;
    font-weight: ${theme.font.weight['weight-500']};
    height: 6.4rem;
    max-width: 4.5rem;
    text-align: center;
    transition: ${theme.transition.fast};
    width: 100%;
    outline: none;

    &:focus {
      border: 1px solid ${theme.colors.noverde};
    }
  `}
`
