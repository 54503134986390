/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import styled, { css } from 'styled-components'
import { InputListProps } from 'components/InputList'
import { Text } from 'components/Text'
import { spin } from 'layout/animations'

interface CompletionItemProps {
  disabled?: boolean
  selected?: boolean
}

interface AnimatedIconWrapper {
  isOpened: boolean
}

export const AutoCompleteRoot = styled.div`
  width: 100%;
  position: relative;
`

export const CompletionList = styled.ul`
  ${({ theme }) => css`
    ::-webkit-scrollbar {
      background-color: ${theme.colors.white};
      width: 6px;
      border-radius: 0px 8px 8px 0px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.greyborders};
      border-radius: 20px;
    }
    display: flex;
    flex-direction: column;
    max-height: 14rem;
    position: absolute;
    overflow-y: auto;
    width: 100%;
    z-index: 2;
    border-radius: 8px;
    border: 1px solid ${theme.colors.greyborders};
    box-shadow: 0 0 0.75rem #e8e8e8;
  `}
`

export const CompletionItem = styled.div<CompletionItemProps>`
  ${({ theme, disabled, selected }) => css`
    align-items: center;
    background: ${disabled ? theme.colors.placeholders : theme.colors.white};
    color: ${disabled ? theme.colors.white : theme.colors.text};
    border-bottom: 1px solid ${theme.colors.greyborders};
    display: flex;
    font-size: 14px;
    padding: 1rem 1.2rem;
    width: 100%;

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      cursor: ${disabled ? 'default' : 'pointer'};
      filter: ${disabled ? 'brightness(1)' : 'brightness(0.9)'};
    }

    filter: ${selected ? 'brightness(0.9)' : 'brightness(1)'};
  `}
`

export const TagBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
  width: 100%;
`

export const TagItem = styled(Text)<Partial<InputListProps>>`
  ${({ theme, tagOptions, disabledTag }) => css`
    align-items: center;
    background: ${theme.colors[tagOptions?.backgroundColor!]};
    border: 1px solid ${theme.colors[tagOptions?.color!]};
    border-radius: 4px;
    color: ${theme.colors[tagOptions?.color!]} !important;
    display: flex;
    gap: 0.2rem;
    justify-content: center;
    min-height: 1.5rem;
    padding: 0.2rem 0.25rem;

    &:hover {
      cursor: ${!disabledTag ? 'pointer' : 'default'};
    }

    svg {
      &:hover {
        cursor: pointer;
      }
    }
  `}
`

export const AnimatedIconWrapper = styled.div<AnimatedIconWrapper>`
  transform: rotate(${({ isOpened }) => (isOpened ? '-180deg' : '0')});
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
`

export const Spinner = styled.div`
  ${({ theme }) => css`
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-left-color: ${theme.colors.noverde};
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: ${spin} 1s linear infinite;
  `}
`
