import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'
import {
  DeleteProfileByUuidResponse,
  ProfileDTO,
  UpdateProfileByUuidResponse
} from './ProfileServiceTypes'

class ProfileService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Update profile by uuid
   * @param uuid string
   * @param profile ProfileDTO
   * @returns UpdateProfileByUuidResponse
   */
  public updateProfileByUuid(uuid: string, profile: ProfileDTO) {
    const payload: ProfileDTO = {
      is_active: profile.is_active,
      name: profile.name,
      resources: profile.resources,
      parent: profile.parent,
      ...(profile.standard && { standard: profile.standard }),
      ...(profile.uuid && { uuid: profile.uuid }),
      ...(profile.id && { id: profile.id })
    }
    return this.instance.put<UpdateProfileByUuidResponse>(`/profiles/${uuid}`, {
      ...payload
    })
  }

  /**
   * Delete profile by uuid
   * @param uuid string
   * @returns DeleteProfileByUuidResponse
   */
  public deleteProfileByUuid(uuid: string) {
    return this.instance.delete<DeleteProfileByUuidResponse>(
      `/profiles/${uuid}`
    )
  }
}

export default new ProfileService()
