import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'

// import { GetAddressDataResponse } from './CepServiceTypes'

class LandingPageService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Upload company Landing Page images
   */
  public uploadLandingPageImages = (
    uuidCompany: string,
    formData: FormData
  ) => {
    const headers = {
      'Content-type': 'multipart/form-data'
    }

    return this.instance.post(
      `/companies/${uuidCompany}/images/upload`,
      formData,
      { headers }
    )
  }
}

export default new LandingPageService()
