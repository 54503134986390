import { RestTypes } from 'components/typings/utils/restTypes'
import { PartnersTheme } from 'layout/theme'
import React from 'react'
import { forwardRef, AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react'
import { Content, ContentProps } from './Button.styles'
import { ButtonVariants } from './Button.types'

type ButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLButtonElement>

export type ButtonProps = {
  variant?: ButtonVariants
  loadingIcon?: JSX.Element
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
  loading?: boolean
  disabled?: boolean
  height?: string
  width?: string
  marginBottom?: string
  as?: React.ElementType
  color?: keyof typeof PartnersTheme.colors
  backgroundColor?: keyof typeof PartnersTheme.colors
  fontSize?: string
  withoutUnderline?: boolean
} & ButtonTypes &
  RestTypes

const ButtonBase: React.ForwardRefRenderFunction<ContentProps, ButtonProps> = (
  {
    as,
    children,
    variant = 'primary',
    disabled = false,
    height,
    leftIcon,
    loading = false,
    marginBottom,
    rightIcon,
    width,
    loadingIcon,
    color,
    backgroundColor,
    fontSize,
    withoutUnderline,
    ...props
  },
  ref
) => (
  <Content
    as={as}
    variant={variant}
    disabled={loading || disabled}
    height={height}
    marginBottom={marginBottom}
    ref={ref}
    width={width}
    leftIcon={leftIcon}
    rightIcon={rightIcon}
    color={color}
    fontSize={fontSize}
    backgroundColor={backgroundColor}
    withoutUnderline={withoutUnderline}
    {...props}
  >
    {!!leftIcon && !loading && leftIcon}
    {!!loading && loadingIcon}
    {!!children && !loading && <span>{children}</span>}
    {!!rightIcon && !loading && rightIcon}
  </Content>
)

export const Button = forwardRef(ButtonBase)
