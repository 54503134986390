import { appearFromRight } from 'layout/animations'
import styled from 'styled-components'

export const Step = styled.div`
  animation: ${appearFromRight} 0.5s;
  display: flex;
  flex-direction: column;
  justify-self: center;
  padding: 2.5rem;
  max-width: 35rem;
  width: 100%;
`

export const Icon = styled.div`
  img {
    cursor: pointer;
  }
`
