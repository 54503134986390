import styled, { css } from 'styled-components'

interface TooltipBoxProps {
  width: number
  height: number
  posX: number
  posY: number
  direction: 'up' | 'down' | 'left' | 'right'
}

export const Content = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const TextBox = styled.div<TooltipBoxProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 2;

  position: absolute;

  visibility: hidden;
  color: transparent;
  background-color: transparent;
  padding: 5px 5px;
  border-radius: 4px;
  font-size: 0.8rem;

  ${({ direction, posY, posX }) =>
    direction === 'down' &&
    css`
      top: ${posY}px;
      left: ${posX}px;
      margin-top: 30px;
    `}

  ${({ direction, posY, posX }) =>
    direction === 'up' &&
    css`
      top: ${posY}px;
      left: ${posX}px;
      margin-top: -40px;
    `}

  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;

  &:hover {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 5px 5px;
    ${({ direction }) =>
      direction === 'right' &&
      css`
        padding: 5px 5px;
      `}

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
`
export const Container = styled.div`
  width: 30px;
  & ${Content}:hover + ${TextBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: fit-content;
    padding: 5px 5px;

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
`
