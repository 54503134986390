import styled, { css } from 'styled-components'

interface DropdownContainerProps {
  secondaryDropdown?: boolean
}

interface ListOptionProps {
  isSelected?: boolean
}

interface ArrowContainerProps {
  isOpened?: boolean
}

export const Container = styled.div`
  position: relative;
`

export const DropdownButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;

  span {
    display: block;
    color: #646e7d;
    font-weight: 700;
    font-size: 15px;
    line-height: 38px;
    margin-left: 10px;
  }
`

export const DropdownContainer = styled.div<DropdownContainerProps>`
  position: absolute;
  top: calc(100%);
  padding: 20px 0;
  left: 0;
  min-width: 300px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 4.696px 11.74px rgba(140, 140, 140, 0.15);
  z-index: 998;

  ${({ secondaryDropdown }) =>
    secondaryDropdown &&
    css`
      left: calc(100% - 14px);
      top: -25px;
      z-index: 999;
    `}
`

export const ListOption = styled.div<ListOptionProps>`
  width: 100%;
  height: 40px;
  transition: all 0.2s ease-in;
  cursor: pointer;
  padding: 0 27px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  span {
    font-weight: 600;
    font-size: 15px;
    color: #646e7d;
  }

  &:hover {
    background-color: #f4f7fd;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: #f4f7fd;
    `}
`
export const OptionNotFound = styled.div`
  padding: 20px 27px 0;
  color: #646e7d;
  font-size: 12px;
`

export const ArrowContainer = styled.div<ArrowContainerProps>`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #c5c7cd;
  border-radius: 11px;

  img {
    transition: all 0.1s ease-in;
    transform: rotate(${({ isOpened }) => (isOpened ? '180deg' : '0')});
  }
`
