import { useRef } from 'react'

import Assets from 'assets'
import { Button } from 'components/Button'
import { Form } from 'components/Form'
import { ImageSlider } from 'components/ImageSilder'
import { Input } from 'components/Input'
import { Terms } from 'components/Terms'
import { CommonModal } from 'templates/Modals/CommonModal'

import useForm from 'hooks/useForm'
import useMediaQuery from 'hooks/useMediaQuery'
import { useModal } from 'providers/ModalProvider'
import { useRouter } from 'hooks/useRouter'
import { useLoading } from 'hooks/useLoading'

import AuthenticationService from 'services/remote/v1/Authorizer/AuthenticationService'

import * as S from './SignIn.styles'
import { ROUTES_NAME } from 'routes'
import { PartnersTheme } from 'layout/theme'
import { PartnersThemeProvider } from 'providers/theme'

type FormSignInValues = {
  email: string
}

export const SignIn = () => {
  const { dispatch } = useModal()
  const { hidelg } = useMediaQuery()
  const { history } = useRouter()
  const { setLoading } = useLoading()

  const inputRef = useRef<HTMLInputElement>(null)

  const handleValidatedAuthFlow = (uuidLogin: string, email: string) => {
    history.push({
      pathname: ROUTES_NAME.LOGIN_PASSWORD,
      state: {
        uuidLogin,
        email
      }
    })
  }

  const handlePendingAuthFlow = async (uuidLogin: string, email: string) => {
    try {
      const { data } = await AuthenticationService.generatePin(uuidLogin)

      history.push({
        pathname: ROUTES_NAME.FIRST_ACCESS,
        state: {
          pinUuid: data?.data?.uuid_pin,
          email,
          uuidLogin: uuidLogin,
          flowType: 'firstAccess'
        }
      })
    } catch (err: any) {
      if (
        err?.response.status === 401 &&
        err?.response?.data?.data?.expiration_time
      ) {
        history.push({
          pathname: ROUTES_NAME.FIRST_ACCESS,
          state: {
            pinUuid: '',
            email,
            uuidLogin: uuidLogin,
            flowType: 'pinBlocked'
          }
        })
      }
    }
  }

  const handleBlockedAuthFlow = () => {
    dispatch({
      action: 'OPEN_MODAL',
      component: (
        <CommonModal
          title="Conta bloqueada"
          description=" Entre em contato com o administrador da conta para desbloquear sua
          conta"
          primaryButtonTitle="voltar"
          primaryButtonProps={{
            fontSize: '1rem',
            backgroundColor: 'noverde'
          }}
          primaryButtonAction={() => {
            dispatch({ action: 'CLOSE_MODAL' })
            inputRef.current?.focus()
          }}
        />
      )
    })
  }

  const handleOnSubmit = async ({ email }: { email: string }) => {
    try {
      setIsSubmitting(true)
      setLoading(true)

      console.log('Verifying email')
      const response = await AuthenticationService.verifyEmail(email)

      if (!response.data?.data) {
        throw new Error(response.data.error?.messages_client[0])
      }

      const { uuid_login: uuidLogin } = response.data.data

      switch (response.data.data.status) {
        case 'validated':
          handleValidatedAuthFlow(uuidLogin, email)
          break

        case 'pending':
          await handlePendingAuthFlow(uuidLogin, email)
          break

        case 'blocked':
          handleBlockedAuthFlow()
          break

        default:
          break
      }
    } catch (err: any) {
      const statusCode = err.request.status

      const renderTitle = () => {
        switch (statusCode) {
          case 404:
            return 'email não encontrado'
          default:
            return 'Oops! Algo deu errado'
        }
      }

      const renderDescription = () => {
        switch (statusCode) {
          case 404:
            return (
              <>
                <p>
                  parece que o email informado não está cadastrado em nossa
                  plataforma.
                </p>
                <p>
                  Entre em contato com o administrador da conta para confirmar
                  seu cadastro
                </p>
              </>
            )
          default:
            return err?.response?.data?.error?.messages_client[0] || err.message
        }
      }

      dispatch({
        action: 'OPEN_MODAL',
        component: (
          <CommonModal
            maxWidth={[statusCode].includes(404)}
            title={renderTitle()}
            description={renderDescription()}
            primaryButtonTitle="voltar"
            primaryButtonProps={{
              fontSize: '1rem',
              backgroundColor: 'noverde'
            }}
            primaryButtonAction={() => {
              dispatch({ action: 'CLOSE_MODAL' })
              inputRef.current?.focus()
            }}
          />
        )
      })
    } finally {
      setLoading(false)
    }
  }

  const { errors, handleChange, handleSubmit, setIsSubmitting, data } =
    useForm<FormSignInValues>({
      onSubmit: () => handleOnSubmit(data),
      validations: {
        email: {
          required: {
            message: 'não esqueça seu e-mail',
            value: true
          }
        }
      }
    })

  return (
    <S.Wrapper>
      <S.Container>
        {hidelg && (
          <S.ContainerLeft>
            <ImageSlider />
          </S.ContainerLeft>
        )}

        <S.ContainerRight>
          <S.ContentRight>
            <Assets
              alt="logo noverde business"
              assetProps={{
                key: 'dotzLogo',
                type: 'image'
              }}
              _positioning={{
                position: 'absolute',
                top: '-25vh'
              }}
              _spacing={{
                marginBottom: '50%'
              }}
              _sizing={{
                width: '25rem'
              }}
            />
            <Form
              _flex={{
                alignItems: 'center',
                direction: 'column',
                justifyContent: 'center'
              }}
              _sizing={{
                width: '100%'
              }}
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Input
                ref={inputRef}
                onChange={handleChange('email')}
                message={errors.email}
                name="email"
                animatedLabel="e-mail"
                isRequired
                type="email"
                animatedLabelProps={{
                  background: '#fff',
                  color: '#000'
                }}
                _spacing={{
                  marginBottom: '2.2rem'
                }}
                _sizing={{
                  width: '100%'
                }}
              />

              <Button
                type="submit"
                _spacing={{
                  marginBottom: '2rem'
                }}
                backgroundColor="dotzOrange"
              >
                Entrar
              </Button>
            </Form>
          </S.ContentRight>
        </S.ContainerRight>
      </S.Container>
      <Terms />
    </S.Wrapper>
  )
}
