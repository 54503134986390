import { useState, useRef, useMemo, useCallback } from 'react'

import Assets from 'assets'
import { RestTypes } from 'components/typings/utils/restTypes'

import * as S from './InputSelect.styles'

export type InputSelectProps = {
  selectedItem: SelectItemType | null
  name: string
  label?: string
  message?: string
  data: SelectItemType[]
  onChange: (value: SelectItemType) => void
  isDisabled?: boolean
  isAutoComplete?: boolean
  termText?: string
  onTermTextChange?: (value: string) => void
  loading?: boolean
  hasDismissButton?: boolean
} & RestTypes

export type SelectItemType = {
  id: string
  value: string
}

export const InputSelect = ({
  name,
  label,
  message,
  data,
  selectedItem,
  onChange,
  isDisabled,
  isAutoComplete,
  termText,
  onTermTextChange,
  loading,
  hasDismissButton
}: InputSelectProps) => {
  const [isOpened, setIsOpened] = useState(false)
  const [word, setWord] = useState('')

  const inputRef = useRef<HTMLInputElement>(null)

  const handleSelectItem = useCallback(
    (item: SelectItemType | null) => {
      onChange(item as SelectItemType)
      setIsOpened(false)
    },
    [onChange]
  )

  const handleOnBlur = () => {
    setTimeout(() => {
      if (!isOpened) {
        return
      }
      setIsOpened(false)
    }, 200)
  }

  const handleOnMouseLeave = useCallback(() => {
    setIsOpened(false)
    inputRef.current?.blur()
  }, [])

  const renderRightIcon = useMemo(() => {
    if (loading) return <S.Spinner />
    if (hasDismissButton && selectedItem) {
      return (
        <Assets
          onClick={() => handleSelectItem(null)}
          alt="remove item"
          assetProps={{
            key: 'closePreview',
            type: 'icon'
          }}
          _spacing={{
            padding: '4px'
          }}
        />
      )
    }

    if (isDisabled && !hasDismissButton) return <></>

    return (
      <S.AnimatedIconWrapper
        onClick={() => setIsOpened(!isOpened)}
        isOpened={isOpened}
      >
        <Assets
          alt="arrow"
          assetProps={{
            key: 'filledArrowPolygonBottom',
            type: 'icon'
          }}
          _spacing={{
            padding: '4px'
          }}
        />
      </S.AnimatedIconWrapper>
    )
  }, [
    loading,
    hasDismissButton,
    isOpened,
    handleSelectItem,
    selectedItem,
    isDisabled
  ])

  return (
    <S.Container
      onClick={() => !isOpened ?? setIsOpened(true)}
      onMouseLeave={handleOnMouseLeave}
    >
      <S.Inputt
        disabled={isDisabled}
        ref={inputRef}
        name={name}
        hideCursor={!onTermTextChange}
        message={message}
        animatedLabel={label}
        onChange={(e) => {
          onTermTextChange && onTermTextChange(e.target.value)
          setWord(e.target.value)
        }}
        onFocus={() => {
          onTermTextChange && onTermTextChange(selectedItem?.value || '')
          setIsOpened(true)
        }}
        onBlur={handleOnBlur}
        defaultValue={selectedItem?.value}
        value={isOpened ? termText : selectedItem?.value || ''}
        autoComplete="off"
        readOnly={!isAutoComplete}
        iconRight={renderRightIcon}
      />
      {isOpened && !isDisabled && (
        <S.SelectList>
          {data
            .filter((item) =>
              item.value.toLowerCase().includes(word.toLowerCase())
            )
            .map((eachItem, index) => (
              <S.SelectItem
                isSelected={eachItem.id === selectedItem?.id}
                onClick={() => {
                  handleSelectItem(eachItem)
                }}
                key={`${eachItem}-${index}`}
              >
                {eachItem.value}
              </S.SelectItem>
            ))}
        </S.SelectList>
      )}
    </S.Container>
  )
}
