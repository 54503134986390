import { useCallback, useMemo } from 'react'

import { useRouter } from './useRouter'

export function useLocationState() {
  const { location, history } = useRouter()

  const justClean = useCallback(() => {
    return window.history.replaceState({}, '')
  }, [])

  const clearLocationStateAndReturn = useCallback(
    (route: string) => {
      justClean()
      history.push(route)
    },
    [history, justClean]
  )

  const verifyLocationState = useCallback(
    (route: string) => {
      if (!location.state) {
        history.push(route)
      }
    },
    [history, location.state]
  )

  return useMemo(() => {
    return {
      clearLocationStateAndReturn,
      justClean,
      verifyLocationState
    }
  }, [clearLocationStateAndReturn, justClean, verifyLocationState])
}
