import Assets from 'assets'
import { StepsLine } from 'components/StepsLine'
import { Text } from 'components/Text'
import { ReactNode } from 'react'
import { WithTextAndLogo } from 'templates/WithTextAndLogo'

import * as S from './StepsWithIconReturn.styles'

interface StepsWithIconReturnProps {
  children?: ReactNode
  notStep?: boolean
  stepLines?: number
  stepLinesBehind?: number
  stepTitle?: string | ReactNode
  title?: string | ReactNode
  subtitle?: string
  withLogo?: boolean
  onClickReturnIcon?: () => void
}

export const StepsWithIconReturn = ({
  children,
  notStep = true,
  stepLines,
  stepLinesBehind,
  stepTitle,
  subtitle,
  title,
  withLogo,
  onClickReturnIcon
}: StepsWithIconReturnProps) => {
  return (
    <WithTextAndLogo title={title} subtitle={subtitle} withLogo={withLogo}>
      <S.Step>
        <S.Icon onClick={onClickReturnIcon}>
          <Assets
            alt="icone de voltar"
            assetProps={{
              key: 'arrowLeft',
              type: 'icon'
            }}
            _spacing={{
              marginBottom: '1.5rem'
            }}
          />
        </S.Icon>
        {notStep && (
          <StepsLine
            lines={stepLines || 0}
            linesBehind={stepLinesBehind || 0}
            _sizing={{
              height: '20px'
            }}
            _spacing={{
              marginBottom: '1rem'
            }}
          />
        )}
        <Text
          size="2.2rem"
          _spacing={{
            marginBottom: '1rem'
          }}
        >
          {stepTitle}
        </Text>
        {children}
      </S.Step>
    </WithTextAndLogo>
  )
}
