/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Button } from 'components/Button'
import { Form } from 'components/Form'
import { Pin } from 'components/Pin'
import { Text } from 'components/Text'
import { useCountdown } from 'hooks/useCountdown'
import useForm from 'hooks/useForm'
import { useRouter } from 'hooks/useRouter'
import { StepsWithIconReturn } from 'templates/StepsWithIconReturn'
import { useLoading } from 'providers/LoadingProvider'
import { useModal } from 'providers/ModalProvider'
import AuthenticationService from 'services/remote/v1/Authorizer/AuthenticationService'
import { CommonModal } from 'templates/Modals/CommonModal'
import { ResetPasswordStep2RouteProps } from './ResetPasswordStep2'
import { ROUTES_NAME } from 'routes'
import { getModalStatusError } from 'pages/FirstAccess/FirstAccessStep1'
import { Callout } from 'components/Callout'
import { formatZonedTimeToUtc } from 'utils/formatters/fomatZonedTimeToUtc'

export interface ResetPasswordStep1RouteProps {
  email: string
  uuidLogin: string
  pinUuid: string
  flowType: 'pinBlocked' | 'pinSent'
}

const PIN_LENGTH = 4

export const ResetPasswordStep1 = () => {
  const { setTime, setIsActiveTime, timeValue, isActiveTime } = useCountdown()
  const { dispatch } = useModal()
  const { history, location } = useRouter()
  const { state } = useLocation<ResetPasswordStep1RouteProps>()
  const [pin, setPin] = useState<Array<number | undefined>>(
    new Array(PIN_LENGTH)
  )
  const [pinUuid, setPinUuid] = useState('')
  const { handleSubmit } = useForm({
    onSubmit: () => {
      handleSubmitPin()
    }
  })
  const { setLoading } = useLoading()
  const [showCallout, setShowCallout] = useState(false)

  useEffect(() => {
    const isBlocked = state?.flowType === 'pinBlocked'
    setShowCallout(isBlocked)

    if (!isBlocked) {
      setIsActiveTime(true)
      setPinUuid(state?.pinUuid)
      setTime(60)
    }
  }, [state])

  async function handleSubmitPin() {
    const { uuidLogin } = state

    try {
      setLoading(true)
      if (pinUuid) {
        await AuthenticationService.validatePin(
          uuidLogin,
          pinUuid,
          pin.join('')
        )
        const state: ResetPasswordStep2RouteProps = {
          pinUuid,
          uuidLogin
        }
        history.push({
          pathname: '/reset-password/step-2',
          state
        })
      }
    } catch (err: any) {
      const errorMessage = getModalStatusError(err?.response.status)

      if (
        err?.response.status === 401 &&
        err?.response?.data?.data?.expiration_time
      ) {
        setShowCallout(true)
      } else {
        setLoading(false)
        dispatch({
          action: 'OPEN_MODAL',
          component: (
            <CommonModal
              maxWidth
              title={errorMessage?.title || 'Ops, algo deu errado'}
              description={
                errorMessage?.body ||
                err?.response?.data?.error?.messages_client[0] ||
                err.message
              }
              primaryButtonTitle="voltar"
              primaryButtonProps={{
                fontSize: '1rem',
                backgroundColor: 'noverde'
              }}
              primaryButtonAction={() => {
                dispatch({ action: 'CLOSE_MODAL' })
              }}
            />
          )
        })
      }
    } finally {
      setLoading(false)
    }
  }

  async function handleResendCode() {
    try {
      setLoading(true)
      const { data } = await AuthenticationService.generatePin(state.uuidLogin)
      setPinUuid(data.data?.uuid_pin || '')
      setTime(60)
      setIsActiveTime(true)
    } catch (err: any) {
      setLoading(false)
      dispatch({
        action: 'OPEN_MODAL',
        component: (
          <CommonModal
            title="Oops! Algo deu errado"
            description={
              err?.response?.data?.error?.messages_client[0] || err.message
            }
            primaryButtonTitle="voltar"
            primaryButtonProps={{
              fontSize: '1rem',
              backgroundColor: 'noverde'
            }}
            primaryButtonAction={() => {
              dispatch({ action: 'CLOSE_MODAL' })
            }}
          />
        )
      })
    } finally {
      setLoading(false)
    }
  }

  const onPinChanged = (pinEntry: number | undefined, index: number) => {
    const newPin = [...pin]
    newPin[index] = pinEntry
    setPin(newPin)
  }

  useEffect(() => {
    if (!location.state) {
      history.replace(ROUTES_NAME.LOGIN)
    }
  }, [location, history])

  return (
    <StepsWithIconReturn
      title={
        <>
          vamos <br />
          <strong>
            redefinir <br /> sua senha
          </strong>
        </>
      }
      stepLines={1}
      stepLinesBehind={1}
      stepTitle={
        <>
          <strong>enviamos um código</strong> <br />
          para seu email
        </>
      }
      onClickReturnIcon={() => history.goBack()}
    >
      <Text
        color="galaxy"
        type="subheading-1"
        _spacing={{
          marginBottom: '1.5rem'
        }}
      >
        {state?.email}
      </Text>
      <Form
        _flex={{
          alignItems: 'center',
          direction: 'column',
          justifyContent: 'center'
        }}
        onSubmit={handleSubmit}
      >
        <Pin
          onPinChanged={onPinChanged}
          pin={pin}
          pinLength={PIN_LENGTH}
          _layout={{
            alignSelf: 'center'
          }}
          _spacing={{
            margin: '1rem 0'
          }}
        />
        {isActiveTime && !showCallout && pinUuid ? (
          <Text
            _spacing={{
              marginBottom: '1.2rem'
            }}
            weight="weight-600"
            color="placeholders"
          >
            {`reenviar código (${timeValue})`}
          </Text>
        ) : (
          <Button
            type="button"
            variant="textPrimary"
            _layout={{
              alignSelf: 'center'
            }}
            _spacing={{
              padding: '2rem',
              margin: '1.5rem 0'
            }}
            disabled={showCallout || !pinUuid}
            onClick={handleResendCode}
          >
            reenviar código
          </Button>
        )}
        <Button
          disabled={
            pin.filter((_pin) => _pin !== undefined).length !== PIN_LENGTH ||
            showCallout ||
            !pinUuid
          }
        >
          confirmar
        </Button>

        {(showCallout || !pinUuid) && (
          <Callout
            type="alert"
            title="Excesso de tentativas falhas"
            _spacing={{
              marginTop: '1rem'
            }}
          >
            Você excedeu o número de tentativas. Tente novamente mais tarde.
          </Callout>
        )}
      </Form>
    </StepsWithIconReturn>
  )
}
