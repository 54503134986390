import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'

import {
  CreateAttendanceResponse,
  AttendanceHistoryResponse
} from './AttendanceTypes'

class AttendanceService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Create a new attendance
   */
  public createAttendanceToCompany = (
    uuidCompany: string,
    uuidUnit: string
  ) => {
    return this.instance.post<CreateAttendanceResponse>(
      `/companies/${uuidCompany}/units/${uuidUnit}/attendances`
    )
  }

  /**
   * Associate a lead to an attendance
   */
  public associateLeadToAttendance = (
    uuidCompany: string,
    uuidUnit: string,
    attendanceUuid: string,
    leadUuid: string
  ) => {
    const reqBody = {
      lead_uuid: leadUuid
    }

    return this.instance.patch(
      `/companies/${uuidCompany}/units/${uuidUnit}/attendances/${attendanceUuid}`,
      reqBody
    )
  }

  /**
   * Get lead's attendance history
   */
  public getLeadAttendanceHistory = (uuidUnit: string, uuidLead: string) => {
    return this.instance.get<AttendanceHistoryResponse>(
      `/units/${uuidUnit}/leads/${uuidLead}/attendances`
    )
  }

  /**
   * send Attendance Status
   * cancelled - true - simulate again
   * cancelled - false - end
   */
  public sendAttendanceStatus = (
    status: boolean,
    motiveId: string,
    simulationUuid: string
  ) => {
    return this.instance.patch(`/simulations/${simulationUuid}/status`, {
      reason: {
        id: motiveId
      },
      cancelled: status
    })
  }

  public sendAttendanceStatusFinish = (
    status: boolean,
    motiveId: string,
    uuidAttendance: string,
    uuidUnit: string
  ) => {
    return this.instance.patch(
      `/units/${uuidUnit}/attendances/${uuidAttendance}/finish`,
      {
        reason: {
          id: motiveId
        },
        cancelled: status
      }
    )
  }
}

export default new AttendanceService()
