import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'

import { GetOffersDataResponse } from './BNPLServiceTypes'

class BNPLService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Check if document has any BNPL offers
   */
  public getOffersByDocument = (document: string) => {
    return this.instance.get<GetOffersDataResponse>(
      `/bnpl-offers?document=${document}`
    )
  }
}

export default new BNPLService()
