import { FormMyAccount } from 'components/FormMyAccount'
import { HeaderActions } from 'components/HeaderActions'

import * as S from './UserMyAccount.styles'

export const UserMyAccount = () => {
  return (
    <S.Container>
      <HeaderActions
        title="minha conta"
        hasReturnButton={false}
        renderCustomRightCorner={<></>}
      />
      <FormMyAccount />
    </S.Container>
  )
}
