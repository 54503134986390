import { appearFromRight } from 'layout/animations'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 1.5rem 2.5rem;
  width: 100%;
`

export const Content = styled.div`
  ${() => css`
    animation: ${appearFromRight} 0.3s ease-in;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-auto-flow: column;
    gap: 1.5rem;
    height: fit-content;
    margin-top: 2rem;
    padding: 2rem 3rem;
    width: 100%;
  `}
`
