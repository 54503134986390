import { PermissionsDTO } from 'models'
import { AccessDTO } from 'services/remote/v1/Portal/PortalService.types'

export const getResourcePermissions = (
  data: AccessDTO | null,
  module: string,
  resource: string
): PermissionsDTO | undefined => {
  const permissions = data?.modules
    .find((eachModule) => eachModule.abbreviation === module)
    ?.resources.find(
      (eachResource) => eachResource.abbreviation === resource
    )?.permissions

  return permissions
}
