/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { states } from 'utils/enums/states'
import useForm from 'hooks/useForm'
import { useRouter } from 'hooks/useRouter'
import { useLoading } from 'providers/LoadingProvider'
import { useAppData } from 'hooks/useAppData'
import { useToast } from 'hooks/useToast'
import { useModal } from 'providers/ModalProvider'

import { Button } from 'components/Button'
import { AutoComplete } from 'components/Autocomplete'
import { Form } from 'components/Form/Form'
import { Input } from 'components/Input'
import { Text } from 'components/Text'
import { phoneMask, cepMask } from 'utils/formatters/masks'
import { unmask } from 'utils/formatters/unmask'
import { CommonModal } from 'templates/Modals/CommonModal'
import { ToggleSwitcher } from 'components/ToggleSwitcher'
import { SelectItemType } from 'components/InputSelect'
import { UnitEditRouteProps } from 'pages/Unit/UnitEdit'
import { ROUTES_NAME } from 'routes'

import CompanyService from 'services/remote/v1/Company/CompanyService'
import UnitService from 'services/remote/v1/Unit/UnitService'
import {
  UnityDTO,
  UpdateUnitReqBody
} from 'services/remote/v1/Unit/UnitServiceTypes'
import CEPService from 'services/remote/v1/Cep/CepService'

import * as S from './FormUnit.styles'
import { PaymentDataDTO } from 'pages/Unit/UnitCreate'
import { InputRadio } from 'components/InputRadio'

interface FormValues {
  unitName: string
  razaoSocial: string
  conta: string
  contaDigito: string
  agencia: string
  agenciaDigito: string
  tipoConta: string
  cnpj: string
  phone_number: string
  zipcode: string
  street: string
  state: string
  neighborhood: string
  city: string
  complement: string
  buildingNumber: string
}

interface FormUnitProps {
  edit: boolean
  editData?: UnityDTO
  banksList: SelectItemType[]
  paymentData?: PaymentDataDTO | null
  hideDelete?: boolean
  hideUpdate?: boolean
}

interface CepValidator {
  street: boolean
  state: boolean
  neighborhood: boolean
  city: boolean
  complement: boolean
  buildingNumber: boolean
}

const validateCNPJ = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const FormUnit = ({
  edit = false,
  editData,
  banksList,
  paymentData,
  hideDelete = false,
  hideUpdate = false
}: FormUnitProps) => {
  const [bankSelected, setBankSelected] = useState<SelectItemType | null>(null)
  const [toggleActive, setToggleActive] = useState<boolean>(
    editData?.is_active !== undefined ? editData?.is_active : true
  )
  const [cepLoading, setCepLoading] = useState(false)
  const [bankError, setBankError] = useState('')
  const [bankTerm, setBankTerm] = useState('')
  const [stateOption, setStateOption] = useState('')
  const [stateSelected, setStateSelected] = useState<
    { id: string; value: string } | undefined
  >({ id: '', value: '' })
  const [firstLoad, setFirstLoad] = useState(true)
  const [cepDisableFields, setCepDisableFields] = useState<CepValidator>({
    street: !editData,
    state: true,
    neighborhood: !editData,
    city: true,
    complement: !editData,
    buildingNumber: !editData
  })

  const { history, location } = useRouter<UnitEditRouteProps>()
  const { setLoading } = useLoading()
  const { selectedCompanyUuid, updateFirstAccessData } = useAppData()
  const { showToast } = useToast()
  const { dispatch } = useModal()

  const { data, errors, handleChange, handleSubmit, updateFormData } =
    useForm<FormValues>({
      initialValues: {
        unitName: editData?.name || '',
        razaoSocial: editData?.corporate_name || '',
        cnpj: validateCNPJ(editData?.cnpj || ''),
        agencia: editData?.agency || paymentData?.agency || '',
        agenciaDigito: editData?.agency_digit || paymentData?.digitAgency || '',
        conta: editData?.account || paymentData?.account || '',
        contaDigito: editData?.account_digit || paymentData?.digitAccount || '',
        tipoConta:
          editData?.account_kind || paymentData?.typeAccount || 'checking',
        phone_number: phoneMask(editData?.phone_number || ''),
        zipcode: editData?.address.zipcode || '',
        buildingNumber: editData?.address.building_number || '',
        complement: editData?.address.complement || '',
        street: editData?.address.street || '',
        state: editData?.address.state || '',
        city: editData?.address.city || '',
        neighborhood: editData?.address.neighborhood || ''
      },
      validations: {
        unitName: {
          required: {
            value: true,
            message: 'Você precisa informar uma unidade'
          }
        },
        razaoSocial: {
          required: {
            message: 'Não esqueça a razão social',
            value: true
          }
        },
        cnpj: {
          required: {
            message: 'Não esqueça o CNPJ',
            value: true
          },
          custom: {
            message: 'Digite um CNPJ válido',
            isValid: (currValue) => currValue.length === 18
          }
        },
        agencia: {
          required: {
            message: 'Não esqueça a agência',
            value: true
          },
          custom: {
            message:
              'O campo agência pode ter no mínimo 3 dígitos e máximo 5 dígitos',
            isValid: (currValue) =>
              3 <= currValue.length && currValue.length <= 5
          }
        },
        conta: {
          required: {
            message: 'Não esqueça a conta bancária',
            value: true
          },
          custom: {
            message: 'Informe uma conta válida',
            isValid: (currValue) =>
              3 <= currValue.length && currValue.length <= 12
          }
        },
        contaDigito: {
          required: {
            message: 'Não esqueça o dígito da conta',
            value: true
          }
        },
        buildingNumber: {
          required: {
            message: 'Não esqueça o número do seu endereço',
            value: true
          }
        },
        city: {
          required: {
            message: 'Não esqueça a cidade',
            value: true
          }
        },
        neighborhood: {
          required: {
            message: 'Não esqueça o bairro',
            value: true
          }
        },
        street: {
          required: {
            message: 'Não esqueça o endereço',
            value: true
          }
        },
        zipcode: {
          required: {
            message: 'Não esqueça o CEP',
            value: true
          }
        },
        phone_number: {
          required: {
            message: 'Não esqueça o telefone',
            value: true
          }
        },
        state: {
          required: {
            message: 'Não esqueça o estado',
            value: true
          }
        }
      },
      onSubmit: async () => {
        const reqBody: UpdateUnitReqBody = {
          is_active: toggleActive,
          name: data.unitName,
          corporate_name: data.razaoSocial,
          account: data.conta,
          account_digit: data.contaDigito,
          account_kind: data.tipoConta,
          agency: data.agencia,
          agency_digit: data.agenciaDigito,
          cnpj: data.cnpj,
          bank: bankSelected?.id || '',
          building_number: data.buildingNumber,
          city: data.city,
          complement: data.complement,
          phone_number: data.phone_number,
          zipcode: data.zipcode,
          neighborhood: data.neighborhood,
          street: data.street,
          state: data.state
        }

        if (bankError) return

        if (edit) {
          await handleUpdateUnit(reqBody)
        } else {
          await handleCreateUnit(reqBody)
        }
      }
    })

  useEffect(() => {
    if (editData) {
      const selectedBank = banksList.find(
        (eachBank) => eachBank.id === editData.bank
      )

      if (selectedBank) {
        setBankSelected(selectedBank)
      }
    }

    if (paymentData) {
      const selectedBank = banksList.find(
        (eachBank) => eachBank.id === paymentData.bank
      )

      if (selectedBank) {
        setBankSelected(selectedBank)
      }
    }
  }, [editData, banksList, paymentData])

  const handleGoBack = async (callback?: () => void) => {
    await updateFirstAccessData()

    if (callback) callback()
    history.goBack()
  }

  const handleFillAddress = async () => {
    try {
      setCepLoading(true)

      if (editData && firstLoad) {
        updateFormData({
          ...data,
          street: editData.address.street,
          neighborhood: editData.address.neighborhood,
          city: editData.address.city,
          state: editData.address.state
        })
        setStateOption(editData.address.state || '')
        setFirstLoad(false)
        setCepLoading(false)
        return
      }

      const unmaskedZipCode = unmask(data.zipcode)

      const response = await CEPService.getAddressInfoByCep(unmaskedZipCode)

      const { city, district, state, street } = response.data.data

      const stateResult =
        editData && firstLoad && state === null
          ? states.find(
              (stateOpts) =>
                stateOpts.value.localeCompare(editData.address.state) === 0
            )
          : states.find(
              (stateOpts) => stateOpts.value.localeCompare(state) === 0
            )
      setStateSelected(stateResult)
      setStateOption(stateResult?.value || '')
      updateFormData({
        ...data,
        street: street || '',
        neighborhood: district || '',
        city: city || '',
        state: stateResult?.value || '',
        buildingNumber: '',
        complement: street ? data.complement : ''
      })
      setCepDisableFields({
        street: false,
        complement: false,
        buildingNumber: false,
        neighborhood: false,
        state: !!stateResult?.value,
        city: !!city
      })
    } catch (err: any) {
      updateFormData({
        ...data,
        street: '',
        neighborhood: '',
        city: '',
        state: '',
        buildingNumber: '',
        complement: ''
      })
      setCepDisableFields({
        street: false,
        complement: false,
        buildingNumber: false,
        neighborhood: false,
        city: false,
        state: false
      })
      setStateSelected({ id: '', value: '' })
      setStateOption('')
    } finally {
      setCepLoading(false)
    }
  }

  useEffect(() => {
    if (unmask(data.zipcode).length === 8) {
      handleFillAddress()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.zipcode, data.zipcode.length])

  const handleCreateUnit = async (reqBody: UpdateUnitReqBody) => {
    try {
      setLoading(true)

      if (!bankSelected || bankError) {
        setLoading(false)
        return
      }

      await CompanyService.createUnityToCompanyByUuid(
        selectedCompanyUuid || '',
        reqBody
      )

      await handleGoBack(() => {
        showToast({
          title: 'Unidade criada',
          message: 'Sua nova unidade foi criada com sucesso',
          type: 'success'
        })
      })
    } catch (err: any) {
      showToast({
        title: 'Oops! Algo deu errado',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateUnit = async (reqBody: UpdateUnitReqBody) => {
    try {
      setLoading(true)

      await UnitService.updateUnitByUuid(editData?.uuid || '', reqBody)

      await handleGoBack(() => {
        showToast({
          title: 'Unidade salva',
          message: 'Sua unidade foi alterada com sucesso',
          type: 'success'
        })
      })
    } catch (err: any) {
      showToast({
        title: 'Oops! Algo deu errado',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteUnit = async () => {
    try {
      dispatch({ action: 'CLOSE_MODAL' })
      setLoading(true)
      await UnitService.deleteUnitByUuid(editData?.uuid || '')

      showToast({
        title: 'Unidade removida',
        message: 'Sua unidade foi removida com sucesso',
        type: 'success'
      })

      history.goBack()
    } catch (err: any) {
      showToast({
        title: 'Oops! Algo deu errado',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const openDeleteModal = () => {
    dispatch({
      action: 'OPEN_MODAL',
      component: (
        <CommonModal
          title="excluir unidade"
          description="Tem certeza que deseja excluir essa unidade? 
           Uma vez excluída, você não poderá acessá-la novamente e todos os membros perderão acesso."
          primaryButtonTitle="Excluir unidade"
          primaryButtonAction={() => handleDeleteUnit()}
          primaryButtonProps={{
            variant: 'text',
            color: 'error'
          }}
          secondaryButtonTitle="cancelar"
          secondaryButtonProps={{
            backgroundColor: 'blue800',
            color: 'white'
          }}
          secondaryButtonAction={() => dispatch({ action: 'CLOSE_MODAL' })}
        />
      )
    })
  }

  function handleToggleSwitcher() {
    setToggleActive(!toggleActive)
  }

  useEffect(() => {
    if (!location.state?.editData && edit) {
      history.push(ROUTES_NAME.UNITS)
    }
    return () => {
      setLoading(false)
    }
  }, [edit, history, location.state?.editData, setLoading])

  const maxDigit = (value: string) => {
    return value.charAt(0)
  }

  return (
    <Form
      _flex={{
        direction: 'column',
        gap: '1rem'
      }}
      onSubmit={(e) => {
        if (!bankSelected) {
          setBankError('Não esqueça o banco')
        }

        handleSubmit(e)
      }}
    >
      <S.Box>
        <Text
          type="subheading-1"
          _spacing={{
            marginBottom: '1.5rem'
          }}
        >
          Sobre a unidade
        </Text>

        <S.InputGroup bankTemplate="0.45fr 0.45fr 0.1fr">
          <Input
            name="unitName"
            animatedLabel="nome da unidade"
            isRequired
            message={errors.unitName}
            onChange={handleChange('unitName')}
            value={data.unitName || ''}
          />
          <Input
            name="razaoSocial"
            animatedLabel="razão social"
            isRequired
            message={errors.razaoSocial}
            onChange={handleChange('razaoSocial')}
            value={data.razaoSocial || ''}
          />

          <S.SwitchGroup>
            <label htmlFor="">ativo</label>
            <ToggleSwitcher
              isActive={toggleActive}
              toggleActive={handleToggleSwitcher}
            />
          </S.SwitchGroup>

          <Input
            name="CNPJ"
            animatedLabel="CNPJ"
            isRequired
            value={data.cnpj}
            onChange={handleChange('cnpj', validateCNPJ)}
            message={errors.cnpj}
          />

          <Input
            name="phone_number"
            animatedLabel="telefone contato *"
            message={errors.phone_number}
            value={data.phone_number}
            onChange={handleChange('phone_number', phoneMask)}
          />
        </S.InputGroup>

        <section>
          <Text
            type="subheading-1"
            color="galaxy"
            _spacing={{ margin: '10px 0 20px 0' }}
          >
            Endereço do estabelecimento
          </Text>

          <S.InputGroupRow customGridTemplate="1fr 1fr 1fr 0.8fr">
            <Input
              name="zipcode"
              animatedLabel="CEP *"
              message={errors.zipcode}
              value={data.zipcode}
              onChange={handleChange('zipcode', cepMask)}
              loading={cepLoading}
            />

            <Input
              name="street"
              disabled={cepDisableFields.street}
              animatedLabel="Rua/Av. *"
              message={errors.street}
              value={data.street}
              onChange={handleChange('street')}
            />

            <Input
              name="buildingNumber"
              animatedLabel="número *"
              disabled={cepDisableFields.buildingNumber}
              message={errors.buildingNumber}
              value={data.buildingNumber}
              onChange={handleChange('buildingNumber')}
            />

            <Input
              name="complement"
              animatedLabel="complemento"
              disabled={cepDisableFields.complement}
              value={data.complement}
              onChange={handleChange('complement')}
            />
          </S.InputGroupRow>

          <S.InputGroupRow customGridTemplate="1fr 1fr 1fr 0.8fr">
            <Input
              name="neighborhood"
              animatedLabel="bairro *"
              disabled={cepDisableFields.neighborhood}
              message={errors.neighborhood}
              value={data.neighborhood}
              onChange={handleChange('neighborhood')}
            />

            <Input
              name="city"
              animatedLabel="cidade *"
              message={errors.city}
              value={data.city}
              onChange={handleChange('city')}
              disabled={cepDisableFields.city}
            />

            <AutoComplete
              disabled={cepDisableFields.state}
              options={states}
              selectedOption={stateSelected}
              inputValue={stateOption}
              errorMessage={errors.state}
              onInputChange={(value: string) => setStateOption(value)}
              onChange={(value) => {
                setStateSelected(value ? value : { id: '', value: '' })
                setStateOption(String(value?.value))
                updateFormData({
                  ...data,
                  state: String(value?.value || '')
                })
              }}
              renderInput={(params) => (
                <Input
                  name="state"
                  placeholder="selecione uma opção"
                  animatedLabel="estado *"
                  data-lpignore
                  {...params}
                />
              )}
            />
          </S.InputGroupRow>

          <Text
            type="subheading-1"
            color="galaxy"
            _spacing={{
              marginBottom: '1.5rem'
            }}
          >
            Conta bancária para desembolso
          </Text>
          <S.InputGroup bankTemplate="1fr 1fr">
            <AutoComplete
              async
              errorMessage={bankError}
              options={banksList}
              selectedOption={bankSelected || null}
              isLoading={banksList.length === 0}
              inputValue={bankTerm}
              onInputChange={setBankTerm}
              onChange={(value) => {
                setBankSelected(value)
                setBankError('')
              }}
              renderInput={(params) => (
                <Input
                  name="banco"
                  placeholder="escolha um banco..."
                  animatedLabel="Banco *"
                  {...params}
                />
              )}
              disabled={editData?.company_disbursement || !!paymentData}
            />

            <S.BankDetailGroup paddingLeft="2rem" gap="3rem">
              <InputRadio
                label="Conta corrente"
                labelFor="corrente"
                id="corrente"
                name="nome"
                value="checking"
                onChange={handleChange('tipoConta')}
                defaultChecked
                disabled={editData?.company_disbursement || !!paymentData}
              />
              <InputRadio
                disabled={editData?.company_disbursement || !!paymentData}
                label="Conta poupança"
                labelFor="poupanca"
                id="poupanca"
                name="nome"
                value="savings"
                onChange={handleChange('tipoConta')}
              />
            </S.BankDetailGroup>
          </S.InputGroup>
          <S.InputGroup bankTemplate="1fr 1fr">
            <S.InputGroup bankTemplate="1fr 0.5fr">
              <Input
                disabled={editData?.company_disbursement || !!paymentData}
                name="agencia"
                animatedLabel="Agência"
                isRequired
                value={data.agencia}
                onChange={handleChange('agencia')}
                message={errors.agencia}
              />

              <Input
                disabled={editData?.company_disbursement || !!paymentData}
                name="digito-agência"
                animatedLabel="Dígito"
                value={data.agenciaDigito}
                onChange={handleChange('agenciaDigito', maxDigit)}
              />
            </S.InputGroup>
            <S.InputGroup bankTemplate="0.5fr 0.5fr" paddingLeft="2rem">
              <Input
                disabled={editData?.company_disbursement || !!paymentData}
                message={errors.conta}
                name="conta"
                animatedLabel="Conta"
                isRequired
                value={data.conta}
                onChange={handleChange('conta')}
              />

              <Input
                name="digito-conta"
                animatedLabel="Dígito"
                isRequired
                value={data.contaDigito}
                onChange={handleChange('contaDigito', maxDigit)}
                message={errors.contaDigito}
                disabled={editData?.company_disbursement || !!paymentData}
              />
            </S.InputGroup>
          </S.InputGroup>
        </section>
      </S.Box>
      <S.ButtonGroup>
        {!hideUpdate && (
          <Button
            _sizing={{
              width: '12rem'
            }}
          >
            {edit ? 'Atualizar' : 'Salvar'}
          </Button>
        )}
        {edit && !hideDelete && (
          <Button
            backgroundColor="error"
            color="white"
            type="button"
            onClick={openDeleteModal}
            _sizing={{
              width: '12rem'
            }}
          >
            Excluir
          </Button>
        )}
      </S.ButtonGroup>
    </Form>
  )
}
