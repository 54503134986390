import { renderRestTypes } from 'components/typings/utils/renderRestTypes'
import styled, { css } from 'styled-components'

import { ImageProps } from '.'
export const Img = styled.img<ImageProps>`
  ${({ objectFit, _flex, _layout, _spacing, _sizing, _positioning }) => css`
    ${!!objectFit && `object-fit: ${objectFit}`};

    ${renderRestTypes({
      _flex: _flex,
      _layout: _layout,
      _positioning: _positioning,
      _sizing: _sizing,
      _spacing: _spacing
    })}
  `}
`
