import { Key, ReactElement } from 'react'
import * as S from './ListTable.styles'
import { Text } from 'components/Text'

type RenderItemProps<T> = {
  item: T
  index: number
  canEdit?: boolean
}

type TableProps<T> = {
  headerTitle?: string
  data: T[]
  keyExtractor: (data: T) => Key | null | undefined
  tableStructure: {
    header: string
    body: string
    columns: any[]
  }
  renderItem: (data: RenderItemProps<T>) => JSX.Element
  listHeaderComponent?: () => JSX.Element
  listFooterComponent?: () => JSX.Element
  lessPadding?: boolean
  canEdit?: boolean
  disableEllipsis?: boolean
  fitContent?: boolean
  pX?: [string, string] | []
}

export const ListTable: <T>(props: TableProps<T>) => React.ReactElement = ({
  tableStructure,
  renderItem,
  keyExtractor,
  data,
  listHeaderComponent,
  listFooterComponent,
  headerTitle,
  lessPadding = false,
  canEdit = true,
  disableEllipsis = false,
  fitContent = false,
  pX = []
}) => {
  const renderGridHeader = tableStructure.columns.map((eachColumn: any) => (
    <S.GridHeader
      key={eachColumn.toString()}
      isEditColumn={eachColumn === 'editar'}
      isLPColumn={eachColumn === 'site'}
    >
      {eachColumn}
    </S.GridHeader>
  ))

  const renderGridRow = data.map((eachLine, index) => (
    <S.TableRow
      key={keyExtractor(eachLine)}
      disableEllipsis={disableEllipsis}
      fitContent={fitContent}
      pX={pX}
      gridTemplate={tableStructure.body}
    >
      {renderItem && renderItem({ item: eachLine, index, canEdit })}
    </S.TableRow>
  ))

  return (
    <S.Container lessPadding={lessPadding}>
      {listHeaderComponent && listHeaderComponent()}

      <S.TableWrapper>
        {headerTitle && (
          <Text
            type="subheading-1"
            _spacing={{
              margin: '0.5rem  2.5rem 1.5rem'
            }}
          >
            {headerTitle}
          </Text>
        )}
        <S.TableHeader
          fitContent={fitContent}
          gridTemplate={tableStructure.header}
          pX={pX}
        >
          {renderGridHeader}
        </S.TableHeader>

        {renderGridRow}
      </S.TableWrapper>

      {listFooterComponent && listFooterComponent()}
    </S.Container>
  )
}
