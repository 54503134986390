import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'

import { BankListResponse } from './BankServiceTypes'

class UnityService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Get banks list
   * @returns void
   */
  public getBanksList() {
    return this.instance.get<BankListResponse>('/banks')
  }
}

export default new UnityService()
