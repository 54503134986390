import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'
import { ListProfessionsResponse } from './ProfessionTypes'

class ProfessionService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Get lead's data
   */
  public listProfessionsByTerm = (term: string) => {
    return this.instance.get<ListProfessionsResponse>(
      `/professions?term=${term}`
    )
  }
}

export default new ProfessionService()
