import Assets from 'assets'
import { Text } from 'components/Text'
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import * as S from './DropzoneUploadCSV.styles'

export type FileProps = {
  preview: string
  base64: string | ArrayBuffer | null
} & File

interface DropzoneUploadProps {
  onChange: (files: FileProps[], error: string | null) => void
  initialValue?: string | ArrayBuffer | null
  maxWidth?: string
  recommendedResolution?: string
  showTip?: boolean
  errorMessage?: string
  acceptedFiles: string[]
}

export const DropzoneUploadCSV = ({
  onChange,
  initialValue,
  maxWidth,
  errorMessage,
  acceptedFiles
}: DropzoneUploadProps) => {
  const [files, setFiles] = useState<FileProps[]>([])
  const [error, setError] = useState<string | null>(null)

  const parseError = (errorType: string) => {
    switch (errorType) {
      case 'file-invalid-type':
        return `Formato de arquivo inválido, são aceitos apenas ${acceptedFiles}`

      case 'file-too-large':
        return 'Arquivo muito grande'

      default:
        return 'Erro no upload do arquivo'
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFiles
      .map((filetypes) => filetypes.split('=')[1].split(','))
      .flat(),
    multiple: false,
    onDrop: async (acceptedFiles, rejections) => {
      if (rejections && !!rejections.length) {
        setError(parseError(rejections[0].errors[0].code))
        return
      }

      const base64 = await blobToBase64(acceptedFiles[0])

      setError(null)

      setFiles(
        acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
            base64
          })
        })
      )
    }
  })

  const blobToBase64 = async (
    blob: Blob
  ): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  useEffect(() => {
    if (files.length) {
      onChange(files, error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, error])

  useEffect(
    () => () => {
      // Avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview))
    },
    [files]
  )

  const handleDeleteLogo = () => {
    setFiles([])
    onChange([], null)
  }

  useEffect(() => {
    if (!initialValue) setFiles([])
  }, [initialValue])

  return (
    <S.Container maxWidth={maxWidth}>
      <S.Dropzone {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />

        <Assets
          alt="selecionar logo"
          assetProps={{ type: 'icon', key: 'fileUpload' }}
        />
        <p>
          arraste e solte ou <span>selecione</span>
        </p>
      </S.Dropzone>

      {(!!files.length || initialValue) && (
        <S.UploadPreviewFileName>
          <Text color="text" weight="weight-600">
            {files[0]?.name || ''}{' '}
          </Text>

          {files[0]?.name && (
            <S.RemoveIcon onClick={handleDeleteLogo}>
              <Text color="error" weight="weight-600">
                (remover)
              </Text>
            </S.RemoveIcon>
          )}
        </S.UploadPreviewFileName>
      )}

      {(error || errorMessage) && (
        <S.ErrorMessage>{error || errorMessage}</S.ErrorMessage>
      )}
    </S.Container>
  )
}
