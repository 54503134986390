/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useContext,
  createContext,
  ReactNode,
  useState,
  useEffect,
  useCallback
} from 'react'

import {
  useLocalStorage,
  STORAGE_KEYS,
  getStorageValue
} from 'hooks/useLocalStorage'
import { useToast } from 'hooks/useToast'

import PortalService from 'services/remote/v1/Portal/PortalService'
import { AccessDTO } from 'services/remote/v1/Portal/PortalService.types'
import { ModuleDTO } from 'models'
import { sleep } from 'utils/sleep'
import { clearLocalStorage } from 'hooks/useLocalStorage'

export interface SelectedUnitDTO {
  companyUuid: string
  unitUuid: string
}

interface AppDataI {
  data: AccessDTO | null
  setData: (data: AccessDTO) => void
  clearAppData: () => void
  selectedCompanyUuid: string | null
  updateSelectedCompanyUuid: (uuid: string) => void
  authToken: string | null
  updateAuthToken: (token: string) => void
  updateFirstAccessData: () => Promise<void>
  selectedUnit: SelectedUnitDTO
  updateSelectedUnit: (companyUuid: string, unitUuid: string) => void
  protectedRoutes: string[]
}

interface AppDataProviderProps {
  children?: ReactNode
}

const AppDataContext = createContext({} as AppDataI)

export const AppDataProvider = ({ children }: AppDataProviderProps) => {
  const [appData, setAppData] = useState<AccessDTO | null>(null)
  const [protectedRoutes, setProtectedRoutes] = useState<string[]>([])
  const { showToast } = useToast()
  const [authToken, setAuthToken] = useState<string | null>(
    getStorageValue(STORAGE_KEYS.ACCESS_TOKEN)
  )
  const [selectedCompanyUuid, setSelectedCompany] = useState<string | null>(
    getStorageValue(STORAGE_KEYS.SELECTED_COMPANY_UUID)
  )

  const [selectedUnit, setSelectedUnit] = useState<SelectedUnitDTO>({
    companyUuid: '',
    unitUuid: ''
  })

  const [companyUuidStored, setCompanyInStorage] = useLocalStorage<string>(
    STORAGE_KEYS.SELECTED_COMPANY_UUID,
    ''
  )
  const [, setAccessToken] = useLocalStorage<null | string>(
    STORAGE_KEYS.ACCESS_TOKEN,
    null
  )

  const updateSelectedUnit = useCallback(
    (companyUuid: string, unitUuid: string) => {
      setSelectedUnit({ companyUuid, unitUuid })
    },
    []
  )

  const saveAllRoutePaths = (modules?: ModuleDTO[]) => {
    if (!modules) return []

    const allowedRoutes = modules.map((eachModule) => {
      const routesList = eachModule.resources.reduce<string[]>(
        (accumulator, currValue) => [...accumulator, currValue.link],
        []
      )

      return {
        routesMapping: routesList
      }
    })

    const routesList = allowedRoutes.reduce<string[]>(
      (accumulator, currValue) => [...accumulator, ...currValue.routesMapping],
      []
    )

    setProtectedRoutes(routesList)
  }

  const updateAuthToken = (token: string) => {
    setAccessToken(token)
    setAuthToken(token)
  }

  const setData = (allData: AccessDTO) => {
    const companyUuid = companyUuidStored || allData.companies[0]?.uuid || ''
    setSelectedCompany(companyUuid)
  }

  const updateSelectedCompanyUuid = useCallback(
    (uuid: string) => {
      setCompanyInStorage(uuid)
      setSelectedCompany(uuid)
    },
    [setCompanyInStorage]
  )

  const updateFirstAccessData = async () => {
    try {
      const response = await PortalService.getAccessData()

      const { data } = response.data

      if (
        !data?.companies.length ||
        !data.modules.length ||
        !data.profiles.length
      ) {
        showToast({
          title: 'Acesso negado',
          message:
            'Parece que você não possui permissões para acessar essa página. Contate seu administrador para mais informações',
          type: 'warning'
        })

        await sleep(1000)

        clearLocalStorage()
        window.location.href = '/'
        return
      }

      saveAllRoutePaths(response.data.data?.modules)
      const filteredCompanies = response.data.data?.companies.filter(
        (company) => company.units.length !== 0
      )

      const result = Object.assign(
        { ...response.data.data },
        { companies: filteredCompanies }
      )

      setAppData(result as AccessDTO)
    } catch (err: any) {
      if (err?.response?.status) {
        const statusCode = Number(err?.response?.status)

        if ([403, 501, 503].includes(statusCode)) {
          showToast({
            title: '',
            message: 'Não foi possível acessar o sistema',
            type: 'error'
          })
          localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN)
          return window.location.replace('/login')
        }
      }
      if (err?.isAxiosError) {
        showToast({
          title: '',
          message:
            'Estamos passando por instabilidades. Tente novamente mais tarde.',
          type: 'info'
        })
        setTimeout(() => {
          localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN)
          return window.location.replace('/login')
        }, 5000)
      }
    }
  }

  useEffect(() => {
    if (companyUuidStored) {
      setSelectedCompany(companyUuidStored)
    }
  }, [companyUuidStored])

  const clearAppData = () => {
    setAppData(null)
    setAuthToken(null)
    setAccessToken(null)
    setSelectedCompany(null)
  }

  return (
    <AppDataContext.Provider
      value={{
        selectedUnit,
        protectedRoutes,
        updateSelectedUnit,
        updateFirstAccessData,
        data: appData,
        setData,
        clearAppData,
        selectedCompanyUuid,
        updateSelectedCompanyUuid,
        authToken,
        updateAuthToken
      }}
    >
      {children}
    </AppDataContext.Provider>
  )
}

export const useAppData = (): AppDataI => {
  const context = useContext(AppDataContext)

  return context
}
