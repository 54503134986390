import {
  useContext,
  createContext,
  useState,
  ReactNode,
  useCallback
} from 'react'

interface LoadingData {
  isLoading: boolean
  setLoading: (value: boolean) => void
}

interface LoadingProviderProps {
  children?: ReactNode
}

const LoadingContext = createContext({} as LoadingData)

export const LoadingProvider = ({ children }: LoadingProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const toggleLoading = useCallback((value: boolean) => {
    setIsLoading(value)
  }, [])

  return (
    <LoadingContext.Provider value={{ isLoading, setLoading: toggleLoading }}>
      {children}
    </LoadingContext.Provider>
  )
}

export const useLoading = (): LoadingData => {
  const context = useContext(LoadingContext)

  return context
}
