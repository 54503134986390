/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { useAppData } from 'hooks/useAppData'
import { useLoading } from 'hooks/useLoading'
import { useToast } from 'hooks/useToast'

import { UserMyAccount } from 'pages/UserMyAccount'
import { UserUpdatePassword } from 'pages/UserUpdatePassword'

import { WithFixedSidebar } from 'templates/WithFixedSidebar'

import { ROUTES_NAME } from './routesName'
import { Reason } from 'pages/Reason'
import { ReasonCreate } from 'pages/Reason/ReasonCreate'
import { ReasonEdit } from 'pages/Reason/ReasonEdit'

import { Dashboard } from 'pages/Dashboard'

import { RouteGateway } from './RouteGateway'
import { getAllProtectedRoutes, getRoutesPathList } from './ProtectedRoutesList'
import { ClosingReport } from 'pages/ClosingReport'
import { UploadFiles } from 'pages/UploadFiles'
import { BNPLOffers } from 'pages/BNPLOffers'

export const PrivateRoutes = () => {
  const [redirectRoute, setRedirectRoute] = useState('')
  const { protectedRoutes, updateFirstAccessData } = useAppData()
  const { setLoading } = useLoading()
  const { showToast } = useToast()

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true)
        await updateFirstAccessData()
      } catch (err: any) {
        showToast({
          message:
            err?.response?.data?.error?.messages_client[0] || err.message,
          title: '',
          type: 'error'
        })
      } finally {
        setLoading(false)
      }
    }

    void init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!protectedRoutes) return

    if (protectedRoutes.includes('/dashboard')) {
      setRedirectRoute('/dashboard')
      return
    }

    setRedirectRoute(protectedRoutes[0])
  }, [protectedRoutes])

  return (
    <WithFixedSidebar>
      <Switch>
        {getAllProtectedRoutes().map((eachRoute) => (
          <RouteGateway
            permissions={getRoutesPathList(protectedRoutes)}
            exact
            key={eachRoute.path}
            path={eachRoute.path}
          >
            {eachRoute.component}
          </RouteGateway>
        ))}
        <Route
          exact
          path={ROUTES_NAME.USER_MY_ACCOUNT}
          component={UserMyAccount}
        />
        <Route
          exact
          path={ROUTES_NAME.USER_UPDATE_PASSWORD}
          component={UserUpdatePassword}
        />
        <Route exact path={ROUTES_NAME.REASON} component={Reason} />
        <Route
          exact
          path={ROUTES_NAME.REASON_CREATE}
          component={ReasonCreate}
        />
        <Route exact path={ROUTES_NAME.REASON_EDIT} component={ReasonEdit} />
        <Route exact path={ROUTES_NAME.DASHBOARD} component={Dashboard} />
        <Route exact path={ROUTES_NAME.REPORTS} component={ClosingReport} />
        <Route exact path={ROUTES_NAME.UPLOAD} component={UploadFiles} />
        <Route exact path={ROUTES_NAME.BNPL_OFFERS} component={BNPLOffers} />

        <Route path="*">
          <Redirect to={redirectRoute} />
        </Route>
      </Switch>
    </WithFixedSidebar>
  )
}
