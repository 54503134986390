import { parseISO, addHours } from 'date-fns'

export const formatZonedTimeToUtc = (date: string, removeUTC?: boolean) => {
  if (!date) return null

  const offset = new Date().getTimezoneOffset() / 60
  const currentTimeZone = removeUTC ? offset * -1 : offset

  const utcDate = addHours(parseISO(date), currentTimeZone)

  return utcDate
}
