/* eslint-disable @typescript-eslint/no-explicit-any */
const styles = {
  success: `color:'#fff';  font-size: 12px; padding: 2px;`,
  error: `color:'#fff';  font-size: 12px; padding: 2px;`,
  debug: `color:'#fff';  font-size: 12px; padding: 2px;`,
  warn: `color:'#fff';  font-size: 12px; padding: 2px;`,
  info: `color:'#fff';  font-size: 12px; padding: 2px;`
}

const isDev = process.env.NODE_ENV === 'development'

const log = {
  warn: (message: string | any, title?: string) =>
    isDev
      ? console.log(`%c🔔[WARN] %o ${title || ''} `, `${styles.warn}`, message)
      : null,
  success: (message: any, title?: string) =>
    isDev
      ? console.log(
          `%c✅[SUCCESS] %o ${title || ''} `,
          `${styles.success}`,
          message
        )
      : null,
  error: (message: any, title?: string) =>
    isDev
      ? console.error(
          `%c❌[ERROR] %o ${title || ''}`,
          `${styles.error}`,
          message
        )
      : null,
  debug: (message: any, title?: string) =>
    isDev
      ? console.log(`%c🐛[DEBUG] %o ${title || ''}`, `${styles.debug}`, message)
      : null,
  info: (message: any, title = '') =>
    isDev
      ? console.log(`%c⭐️[INFO] %o ${title || ''}`, `${styles.info}`, message)
      : null
}

export default log
