import styled, { css } from 'styled-components'

import { appearFromRight } from 'layout/animations'
import { devices } from 'layout/devices'

interface GridProps {
  gridTemplate: string
  fitContent: boolean
  pX: [string, string] | []
}

interface TableRowProps {
  disableEllipsis: boolean
}

interface GridHeaderProps {
  isEditColumn?: boolean
  isLPColumn?: boolean
}

interface ContainerProps {
  lessPadding?: boolean
}

export const Container = styled.div<ContainerProps>`
  margin-top: 30px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4.75px;
  padding: 26px 0 7px;

  animation: ${appearFromRight} 0.3s ease-in;

  @media ${devices.large} {
    padding: 32px 0 7px;
  }

  ${({ lessPadding }) =>
    lessPadding &&
    css`
      padding-top: 20px;
    `}
`

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`

export const TableHeader = styled.div<GridProps>`
  display: inline-grid;
  width: ${({ fitContent }) => (fitContent ? 'fit-content' : '100%')};
  padding: 0 ${({ pX }) => (pX.length > 0 ? pX[0] : '26px')};
  min-width: 800px;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  gap: 1rem;
  @media ${devices.large} {
    padding: 0 ${({ pX }) => (pX.length > 0 ? pX[1] : '46px')};
  }
`

export const TableRow = styled.div<GridProps & TableRowProps>`
  display: inline-grid;
  width: ${({ fitContent }) => (fitContent ? 'fit-content' : '100%')};
  border-bottom: 1px solid #dadada;
  padding: 25px ${({ pX }) => (pX.length > 0 ? pX[0] : '26px')};
  align-items: center;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  gap: 1rem;
  min-width: 800px;

  @media ${devices.large} {
    padding: 25px ${({ pX }) => (pX.length > 0 ? pX[1] : '46px')};
  }

  &:last-child {
    border-bottom: 0;
  }

  .recurse-column__title {
    align-self: center;
  }

  span {
    white-space: nowrap;
    ${({ disableEllipsis }) => (disableEllipsis ? '' : 'overflow:hidden;')}
    text-overflow: ellipsis;
    max-width: 100%;
  }
`

export const GridHeader = styled.div<GridHeaderProps>`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #646e7d;

  margin-left: 0;

  ${({ isEditColumn }) =>
    isEditColumn &&
    css`
      margin-left: auto;
    `}

  ${({ isLPColumn }) =>
    isLPColumn &&
    css`
      margin-left: 2rem;
    `}
`

export const EditButton = styled.div`
  margin-left: auto;
  cursor: pointer;
  margin-right: 5px;
`

export const PermissionsWrapper = styled.div``
