import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  ChangeEvent,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from 'react'

import { Colors } from 'layout/theme'

import { RenderCompletionList, renderTags } from './AutoComplete.utils'
import * as S from './Autocomplete.styles'
import { TagType } from 'components/InputList'
import { SelectItemType } from 'components/InputSelect'
import Assets from 'assets'

interface InputProps {
  name?: string
  renderInput: (params: Record<string, unknown>) => ReactNode
  options: TagType[]
  inputValue: string
  onInputChange: (value: string) => void
  tagOptions?: TagOptionsDTO
  multiple?: boolean
  tags?: TagType[]
  onAddTag?: (tag: TagType) => void
  onRemoveTag?: (tag: TagType) => void
  errorMessage?: string | null
  isLoading?: boolean
  onChange?: (selectedOption: SelectItemType | null) => void
  async?: boolean
  selectedOption?: SelectItemType | null
  disabled?: boolean
}

interface TagOptionsDTO {
  color: Colors
  backgroundColor: Colors
}

export interface RenderTagsProps {
  tagsList?: TagType[]
  filteredOptions?: TagType[]
  options: TagOptionsDTO
  onRemoveTag?: (tag: TagType) => void
}

const defaultTagStyle = {
  color: 'blue400' as Colors,
  backgroundColor: 'blue10' as Colors
}

const AutoCompleteBase: ForwardRefRenderFunction<HTMLDivElement, InputProps> = (
  {
    renderInput,
    options,
    inputValue,
    onInputChange,
    tagOptions = defaultTagStyle,
    multiple,
    tags,
    onAddTag,
    onRemoveTag,
    errorMessage,
    isLoading,
    onChange,
    async,
    selectedOption,
    disabled = false,
    ...rest
  },
  ref
) => {
  const [isFocused, setIsFocused] = useState(false)
  const [selectedItem, setSelectedItem] = useState<null | number>(null)

  const inputRef = useRef<HTMLInputElement>(null)

  const filteredOptions = useMemo(() => {
    return options.filter(
      (eachOption) =>
        eachOption.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    )
  }, [inputValue, options])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCustomBlur = () => {
    onInputChange('')
    setIsFocused(false)
  }

  useEffect(() => {
    setSelectedItem((currState) => {
      if (currState === null) {
        return 0
      }

      if (currState > filteredOptions.length - 1) {
        return 0
      }

      return currState
    })
  }, [renderInput, filteredOptions])

  const handleMouseLeave = () => {
    inputRef.current?.blur()
    handleCustomBlur()
    if (multiple) setSelectedItem(null)
  }

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowDown':
          setSelectedItem((currValue) => {
            if (currValue !== null) {
              if (currValue >= filteredOptions.length - 1) return currValue

              return (currValue += 1)
            }

            return 0
          })
          break
        case 'ArrowUp':
          setSelectedItem((currValue) =>
            currValue === null || currValue <= 0 ? 0 : (currValue -= 1)
          )
          break
        case 'Escape':
          inputRef.current?.blur()
          handleCustomBlur()
          setSelectedItem(null)
          break
        case 'Enter':
          if (!multiple && onChange)
            onChange(filteredOptions[selectedItem || 0])
          if (selectedItem !== null) {
            const tagAlreadyExists = tags?.some(
              (eachTag) => eachTag.value === filteredOptions[selectedItem].value
            )

            if (!tagAlreadyExists) {
              if (onAddTag) onAddTag(filteredOptions[selectedItem])
            }

            inputRef.current?.blur()
            handleCustomBlur()
            setSelectedItem(null)
          }
          break
        default:
          return
      }
    },
    [
      filteredOptions,
      selectedItem,
      tags,
      handleCustomBlur,
      onAddTag,
      multiple,
      onChange
    ]
  )

  useEffect(() => {
    if (isFocused) {
      document.addEventListener('keydown', handleKeyDown)

      return () => {
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [isFocused, handleKeyDown])

  const handleSelectItem = (index: number) => {
    if (!multiple && onChange) {
      onChange(filteredOptions[index])
    } else {
      const tagAlreadyExists = tags?.some(
        (eachTag) => eachTag.value === filteredOptions[index].value
      )

      if (!tagAlreadyExists) {
        if (onAddTag) onAddTag(filteredOptions[index])
      }
    }

    setIsFocused(false)
    inputRef.current?.blur()
  }

  const renderRightIcon = useMemo(() => {
    if (isLoading) return <S.Spinner />
    if (selectedOption && async) {
      return (
        !disabled && (
          <Assets
            onClick={() => onChange && onChange(null)}
            alt="remove item"
            assetProps={{
              key: 'closePreview',
              type: 'icon'
            }}
            _spacing={{
              padding: '4px'
            }}
          />
        )
      )
    }
  }, [isLoading, onChange, selectedOption, async])

  const inputProps = {
    value: inputValue ? inputValue : selectedOption?.value || '',
    onFocus: () => setIsFocused(true),
    ref: inputRef,
    disabled: (!!selectedOption && async) || disabled,
    iconRight: renderRightIcon,
    message: errorMessage,
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === '') {
        onChange && onChange(null)
      }
      onInputChange(e.target.value)
    }
  }

  const completionListProps = {
    options: filteredOptions,
    selectedItem,
    handleSelectItem,
    inputValue,
    isLoading,
    multiple
  }

  const tagProps = {
    tagsList: tags,
    options: tagOptions,
    onRemoveTag,
    filteredOptions
  }

  return (
    <S.AutoCompleteRoot ref={ref} {...rest} onMouseLeave={handleMouseLeave}>
      {renderInput(inputProps)}
      {isFocused && <RenderCompletionList {...completionListProps} />}
      {multiple && tagProps?.tagsList && renderTags(tagProps)}
    </S.AutoCompleteRoot>
  )
}

export const AutoComplete = forwardRef(AutoCompleteBase)
