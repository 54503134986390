import * as S from './Autocomplete.styles'
import { CloseTagIcon } from 'assets/svgs/close-tag-icon'
import { TagType } from 'components/InputList'
import { RenderTagsProps } from 'components/Autocomplete/Autocomplete'
import { useCallback, useEffect, useRef, useState } from 'react'

interface CompletionListProps {
  options: TagType[]
  selectedItem: number | null
  handleSelectItem: (index: number) => void
  isLoading?: boolean
  multiple?: boolean
}

export const RenderCompletionList = ({
  options,
  selectedItem,
  handleSelectItem,
  isLoading,
  multiple
}: CompletionListProps) => {
  const [totalItem, setTotalItem] = useState(-1)
  const listRef = useRef<HTMLUListElement>(null)

  const init = useCallback(() => {
    if (totalItem > 2) {
      listRef.current?.scroll(0, Number(totalItem * 54))
    } else {
      listRef.current?.scroll(0, 0)
    }
  }, [listRef, totalItem])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowDown':
          if (totalItem < options.length) setTotalItem(totalItem + 1)
          break
        case 'ArrowUp':
          if (totalItem > 0) setTotalItem(totalItem - 1)
          break

        default:
          return
      }
    },
    [totalItem, options.length]
  )

  useEffect(() => {
    init()

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [init, handleKeyDown])

  if (!multiple && !isLoading && options.length === 0) return <></>

  return (
    <S.CompletionList ref={listRef}>
      {isLoading && <S.CompletionItem disabled>Carregando</S.CompletionItem>}
      {options.map((eachOption, index) => (
        <S.CompletionItem
          className="completion-item"
          onClick={() => handleSelectItem(index)}
          key={eachOption.id as string}
          selected={selectedItem === index}
        >
          {eachOption.value}
        </S.CompletionItem>
      ))}

      {options.length === 0 && !isLoading && (
        <S.CompletionItem disabled>Sem resultados</S.CompletionItem>
      )}
    </S.CompletionList>
  )
}

const checkIfTagCanBeRemoved = (
  allTags: TagType[],
  selectedTag: { id: string; value: string }
) => {
  if (allTags.length > 0) {
    return !!allTags.find((tag) => tag.id === selectedTag.id)
  }

  return true
}

export const renderTags = ({
  tagsList,
  options,
  onRemoveTag,
  filteredOptions
}: RenderTagsProps) => {
  return (
    <S.TagBox>
      {tagsList?.map((tag, key) => (
        <div
          key={key}
          onClick={() =>
            onRemoveTag &&
            checkIfTagCanBeRemoved(filteredOptions || [], tag) &&
            onRemoveTag(tag)
          }
        >
          {checkIfTagCanBeRemoved(filteredOptions || [], tag) ? (
            <S.TagItem type="caption-2" tagOptions={options}>
              {tag.value}
              <CloseTagIcon color={options.color} />
            </S.TagItem>
          ) : (
            <S.TagItem type="caption-2" tagOptions={options} disabledTag>
              {tag.value}
            </S.TagItem>
          )}
        </div>
      ))}
    </S.TagBox>
  )
}
