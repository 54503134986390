import * as S from './AttendanceAccordion.styles'
import { Text } from '../Text'

import { useState } from 'react'

import Assets from 'assets'
import { AttendanceOverviewData } from 'components/AttendanceOverview'
import { ListTable } from 'components/ListTable'
import { SimulationDTO } from 'services/remote/v1/Attendance/AttendanceTypes'
import { monetaryMask } from 'utils/formatters/masks'
import { TooltipText } from 'components/TooltipText'

export interface AttendanceAccordionData {
  attendanceDate: string
  status: string
  validity: string
  attendant: string
  unit: string
}

interface AttendanceAccordionProps {
  accordionData: AttendanceAccordionData
  attendanceOverviewData: AttendanceOverviewData
  simulations?: SimulationDTO[]
}

interface RenderItemProps {
  item: SimulationDTO
}

export const AttendanceAccordion = ({
  accordionData = {} as AttendanceAccordionData,
  simulations = []
}: AttendanceAccordionProps) => {
  const [open, setOpen] = useState(false)

  const renderSimulationRow = ({ item }: RenderItemProps) => (
    <>
      <span>
        <TooltipText positionRelative text={item?.reason?.status}>
          {item?.reason?.status || '-'}
        </TooltipText>
      </span>
      <span>
        <TooltipText positionRelative text={item?.reason?.name || '-'}>
          {item?.reason?.name || '-'}
        </TooltipText>
      </span>
      <span>
        <TooltipText
          positionRelative
          text={monetaryMask(String(item.requested_amount * 100))}
        >
          {monetaryMask(String(item.requested_amount * 100))}
        </TooltipText>
      </span>
      <span>
        <TooltipText
          positionRelative
          text={
            item?.granted_amount
              ? monetaryMask(String(item.granted_amount * 100))
              : ''
          }
        >
          {item?.granted_amount
            ? monetaryMask(String(item.granted_amount * 100))
            : '-'}
        </TooltipText>
      </span>
      <span>
        <TooltipText
          positionRelative
          text={
            item?.installment_value
              ? monetaryMask(String(item.installment_value.toFixed(2)))
              : ''
          }
        >
          {item?.installment_value
            ? monetaryMask(String(item.installment_value.toFixed(2)))
            : '-'}
        </TooltipText>
      </span>
      <span>
        <TooltipText
          positionRelative
          text={String(item?.granted_period) || '-'}
        >
          {item?.granted_period || '-'}
        </TooltipText>
      </span>
      <span>
        <TooltipText
          positionRelative
          text={
            item.interest_rate
              ? `${item.interest_rate?.toFixed(2).replace('.', ',')}% mês`
              : '-'
          }
        >
          {item.interest_rate
            ? `${item.interest_rate?.toFixed(2).replace('.', ',')}% mês`
            : '-'}
        </TooltipText>
      </span>
    </>
  )

  return (
    <S.Root>
      <S.Head role="button" onClick={() => setOpen(!open)}>
        <S.Block>
          <S.BlockItem>
            <Text color="text" type="body-1" weight="weight-600">
              data do atendimento
            </Text>
            <Text color="black" type="body-1" weight="weight-400">
              {accordionData.attendanceDate}
            </Text>
          </S.BlockItem>

          <S.BlockItem>
            <Text color="text" type="body-1" weight="weight-600">
              status do atendimento
            </Text>
            <Text color="black" type="body-1" weight="weight-400">
              {accordionData.status}
            </Text>
          </S.BlockItem>

          <S.BlockItem>
            <Text color="text" type="body-1" weight="weight-600">
              atendente
            </Text>
            <Text color="black" type="body-1" weight="weight-400">
              {accordionData.attendant}
            </Text>
          </S.BlockItem>

          <S.BlockItem>
            <Text color="text" type="body-1" weight="weight-600">
              unidade
            </Text>
            <Text color="black" type="body-1" weight="weight-400">
              {accordionData.unit}
            </Text>
          </S.BlockItem>

          {simulations.length ? (
            <Assets
              alt="down"
              assetProps={{ key: 'arrowDown', type: 'icon' }}
            />
          ) : (
            <span />
          )}
        </S.Block>
      </S.Head>
      {!!simulations.length && (
        <S.Body isOpen={open}>
          <ListTable<SimulationDTO>
            data={simulations}
            renderItem={renderSimulationRow}
            keyExtractor={(eachSimulation) => eachSimulation.requested_payday}
            lessPadding
            disableEllipsis
            tableStructure={{
              header: '1fr 1fr 1fr 1fr 1fr minmax(40px, 0.5fr) 0.8fr',
              body: '1fr 1fr 1fr 1fr 1fr minmax(40px, 0.5fr) 0.8fr',
              columns: [
                'status',
                'motivo',
                'solicitado',
                'aprovado',
                'parcela',
                'qtd.',
                'taxa de juros'
              ]
            }}
          />
        </S.Body>
      )}
    </S.Root>
  )
}
