import styled, { css } from 'styled-components'

import { RestTypes } from 'components/typings/utils/restTypes'
import { renderRestTypes } from 'components/typings/utils/renderRestTypes'

interface StyledCheckboxProps {
  isChecked?: boolean
  isDisabled?: boolean
}

type LabelProps = {
  isDisabled?: boolean
} & RestTypes

export const Label = styled.label<LabelProps>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: fit-content;
  align-items: center;

  span {
    margin-bottom: 10px;
  }

  ${({ _flex, _layout, _spacing, _sizing, _positioning }) => css`
    ${renderRestTypes({
      _flex: _flex,
      _layout: _layout,
      _positioning: _positioning,
      _sizing: _sizing,
      _spacing: _spacing
    })}
  `}

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      cursor: default;

      span {
        color: ${theme.colors.placeholders};
      }
    `}
`

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border: 3px solid
    ${({ isChecked, theme }) => (isChecked ? theme.colors.noverde : '#dadada')};
  width: 18px;
  cursor: pointer;
  height: 18px;
  background: ${({ isChecked, theme }) =>
    isChecked ? theme.colors.noverde : '#fff'};
  border-radius: 3px;
  transition: all 150ms;

  img {
    visibility: ${({ isChecked }) => (isChecked ? 'visible' : 'hidden')};
  }

  &:before {
    content: '';
    width: 0.3rem;
    height: 0.55rem;
    border: 0.15rem solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg);
    position: absolute;
    left: calc(50% - 3px);
    top: -1px;
    opacity: ${({ isChecked }) => (isChecked ? '1' : '0')};

    transition: all 0.2s ease-in;
  }

  ${({ isDisabled, isChecked }) =>
    isDisabled &&
    css`
      background-color: #dadada;
      border-color: ${isChecked ? 'rgba(12, 209, 91, 0.3)' : '#dadada'};
      cursor: default;

      &:before {
        opacity: 0;
      }

      svg {
        visibility: ${isChecked ? 'visible' : 'hidden'};
      }
    `}
`
