/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

import { Button } from 'components/Button'
import { useModal } from 'providers/ModalProvider'
import { CustomerInformation } from 'components/CustomerInformation'
import { HeaderActions } from 'components/HeaderActions'
import { SimulationSheet } from 'components/SimulationSheet'
import { Text } from 'components/Text'
import * as S from './AttendanceSimulation.styles'
import { ROUTES_NAME } from 'routes'
import { ProposalState } from 'components/ProposalState'
import { toBrl } from 'utils/formatters/to-brl'
import { useLoading } from 'hooks/useLoading'
import { useToast } from 'hooks/useToast'
import { useInterval } from 'hooks/useInterval'
import { CommonModal } from 'templates/Modals/CommonModal'

import {
  CreditAnalysisDTO,
  GetCreditAnalysisResponse
} from 'services/remote/v1/Simulation/SimulationTypes'
import SimulationService from 'services/remote/v1/Simulation/SimulationService'
import { pluralize } from 'utils/formatters/pluralize'
import { useLocationState } from 'hooks/useLocationState'
import { Modify } from 'utils/types/deepModification'
import { AttendancePreAnalisysRouteProps } from 'pages/AttendancePreAnalisys'

export interface AttendanceSimulationRouteProps {
  loansDenied?: boolean
  simulationData?: ModifiedCreditAnalysisDTO
}

type AttendanceRequestType = boolean

type ModifiedCreditAnalysisDTO = Modify<
  CreditAnalysisDTO,
  {
    lead: {
      unityUuid?: string
      attendanceUuid?: string
      leadUuid?: string
    }
  } & CreditAnalysisDTO
>

type GetLoansAnalysisResponse = Modify<
  GetCreditAnalysisResponse,
  {
    data: {
      simulation: {
        reason: {
          slug: string
        }
        uuid: string
      }
    }
  }
>

export const AttendanceSimulation = () => {
  const history = useHistory()
  const { state } = useLocation<AttendanceSimulationRouteProps>()
  const { setLoading } = useLoading()
  const { showToast } = useToast()
  const { dispatch } = useModal()

  const [loansAnalysisData, setLoansAnalysisData] =
    useState<GetLoansAnalysisResponse>({
      data: { simulation: { reason: { slug: 'waiting_credit_analysis' } } }
    } as GetLoansAnalysisResponse)
  const [pollingError, setPollingError] = useState(false)
  const [pollingAttempts, setPollingAttempts] = useState(0)
  const { verifyLocationState } = useLocationState()

  const simulationData = state?.simulationData

  const reasonSlug = simulationData?.simulation?.reason?.slug

  const MAX_POLLING_ATTEMPTS = 8

  const dispatchPolling =
    !!loansAnalysisData?.data?.simulation.uuid &&
    ['waiting_credit_analysis'].includes(
      loansAnalysisData?.data?.simulation?.reason.slug
    ) &&
    !pollingError &&
    pollingAttempts < MAX_POLLING_ATTEMPTS

  useInterval(
    async () => {
      await getLoansAnalysis()
      setPollingAttempts((currState) => (currState += 1))
    },
    dispatchPolling ? 5000 : null
  )

  const getLoansAnalysis = async () => {
    try {
      setLoading(true)
      const { data } = await SimulationService.getCreditAnalysis(
        simulationData?.simulation.uuid || ''
      )

      setLoansAnalysisData(data as unknown as GetLoansAnalysisResponse)
    } catch (err) {
      console.log(err)
      setPollingError(true)
    }
  }

  const acceptLoans = async () => {
    try {
      setLoading(true)
      const { data } = await SimulationService.updateSimulationStatus(
        simulationData?.simulation.uuid || '',
        {
          cancelled: false
        }
      )

      setLoansAnalysisData(data as unknown as GetLoansAnalysisResponse)
    } catch (err: any) {
      setLoading(false)

      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: 'Oops! Algo deu errado.',
        type: 'error'
      })
    }
  }

  function refuseLoans() {
    history.push(ROUTES_NAME.ATTENDANCE_SIMULATION_DENIED, {
      ...state,
      loansDenied: false
    })
  }

  useEffect(() => {
    if (pollingAttempts === MAX_POLLING_ATTEMPTS || pollingError) {
      setLoading(false)
      setLoansAnalysisData({
        data: { simulation: { reason: { slug: 'waiting_credit_analysis' } } }
      } as GetLoansAnalysisResponse)
      setPollingError(false)
      setPollingAttempts(0)

      dispatch({
        action: 'OPEN_MODAL',
        component: (
          <CommonModal
            title="Oops! Algo deu errado"
            description="Tente novamente mais tarde"
            primaryButtonTitle="voltar"
            primaryButtonProps={{
              fontSize: '1rem',
              backgroundColor: 'noverde'
            }}
            primaryButtonAction={() => {
              dispatch({ action: 'CLOSE_MODAL' })
            }}
          />
        )
      })
    }
  }, [pollingAttempts, dispatch, setLoading, pollingError])

  useEffect(() => {
    if (
      loansAnalysisData?.data?.simulation?.uuid &&
      loansAnalysisData.data?.simulation?.reason.slug !==
        'waiting_credit_analysis'
    ) {
      setLoading(false)

      if (loansAnalysisData.data?.simulation?.reason.slug === 'cancelled') {
        history.push(ROUTES_NAME.ATTENDANCE_SIMULATION_DENIED, {
          ...state,
          loansDenied: true
        })
        return
      }

      history.push(ROUTES_NAME.ATTENDANCE_SIMULATION_APPROVED)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loansAnalysisData?.data.simulation.reason])

  const sheetModifier = {
    denied: [
      {
        title: 'valor solicitado',
        value: toBrl(String(simulationData?.simulation?.requested_amount) || '')
      },
      {
        title: 'quantidade parcelas',
        value:
          simulationData?.simulation?.granted_period ||
          simulationData?.simulation?.period ||
          ''
      },
      {
        title: 'dia pagamento',
        value: simulationData?.simulation?.payday || ''
      }
    ],
    sucess: [
      {
        title: 'limite de crédito disponível',
        value: toBrl(String(simulationData?.simulation?.granted_amount))
      },
      {
        title: `${pluralize(
          'parcela',
          String(simulationData?.simulation?.granted_period)
        )}`,
        value: `${simulationData?.simulation?.granted_period || '0'}`,
        description:
          `Com vencimento todo dia ${simulationData?.simulation?.payday}` || ''
      },
      {
        title: `valor da parcela`,
        value: `${
          toBrl(String(simulationData?.proposal?.installment_value)) || '0.00'
        }`,
        description:
          `1º parcela ${format(
            new Date(simulationData?.proposal?.first_due_date || new Date()),
            'dd MMM yyyy',
            {
              locale: ptBR
            }
          ).toUpperCase()}` || ''
      }
    ]
  }

  const textSimulation = () => {
    switch (reasonSlug) {
      case 'approved':
        return 'Simulação aprovada'
      case 'active_loans':
        return 'Simulação rejeitada'
      case 'denied':
        return 'Simulação rejeitada'
      default:
        return ''
    }
  }

  const renderSheets = () => {
    switch (reasonSlug) {
      case 'denied':
        return sheetModifier.denied.map((sheet, key) => (
          <SimulationSheet
            key={key}
            title={sheet.title}
            value={sheet.value}
            status={reasonSlug}
          />
        ))

      case 'active_loans':
        return sheetModifier.denied.map((sheet, key) => (
          <SimulationSheet
            key={key}
            title={sheet.title}
            value={sheet.value}
            status={reasonSlug}
          />
        ))
      case 'approved':
        return sheetModifier.sucess.map((sheet, key) => (
          <SimulationSheet
            key={key}
            fullHeight={reasonSlug === 'approved'}
            title={sheet.title}
            value={sheet.value}
            status={reasonSlug}
            {...(sheet.description ? { description: sheet.description } : {})}
          />
        ))
      default:
        return <></>
    }
  }

  const goToAttendance = async (status: AttendanceRequestType) => {
    try {
      setLoading(true)

      await SimulationService.finishAttendance(
        state?.simulationData?.lead?.unityUuid || '',
        state?.simulationData?.lead?.attendanceUuid || '',
        {
          cancelled: status,
          ...(reasonSlug === 'denied'
            ? null
            : {
                reason: {
                  id: Number(state?.simulationData?.simulation?.reason?.id || 0)
                }
              })
        }
      )

      history.push(ROUTES_NAME.ATTENDANCE)

      showToast({
        message: 'Atendimento finalizado com sucesso',
        title: 'Atendimento finalizado',
        type: 'success'
      })
    } catch (err: any) {
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: 'Oops! Algo deu errado.',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const goToPreAnalysis = async () => {
    try {
      setLoading(true)

      const newState: AttendancePreAnalisysRouteProps = {
        preAnalisysData: {
          name: state.simulationData?.lead.name || '',
          birthday: state.simulationData?.lead?.birthdate || '',
          document: state.simulationData?.lead?.document || '',
          unityUuid: state.simulationData?.lead?.unityUuid || '',
          attendanceUuid: state.simulationData?.lead?.attendanceUuid || '',
          leadUuid: state.simulationData?.lead?.leadUuid || ''
        },
        ...(state.simulationData
          ? {
              simulationData: {
                simulation: { ...state.simulationData.simulation }
              }
            }
          : {})
      }

      history.push({
        pathname: ROUTES_NAME.ATTENDANCE_PRE_ANALISYS,
        state: newState
      })
    } catch (err: any) {
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: 'Oops! Algo deu errado.',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    verifyLocationState(ROUTES_NAME.ATTENDANCE)
  }, [verifyLocationState])

  return (
    <S.Container>
      <HeaderActions
        title="novo atendimento"
        hasReturnButton={false}
        renderCustomRightCorner={<></>}
      />
      <S.ContentBoxContainer>
        <ProposalState
          text={textSimulation()}
          type={simulationData?.simulation.reason.slug as unknown as undefined}
        />
        {['active_loans', 'denied'].includes(reasonSlug || '') && (
          <S.ReasonNameWrapper>
            <Text color="text" size="0.875rem" weight="weight-400">
              Motivo
            </Text>
            <Text type="subheading-1">
              {simulationData?.simulation.reason.name.replace(/./, (c) =>
                c.toUpperCase()
              )}
            </Text>
          </S.ReasonNameWrapper>
        )}
        <CustomerInformation
          heading="Cliente"
          customerInformationData={{
            nomeCompleto: {
              rotule: simulationData?.lead?.name || ''
            },
            dataNascimento: {
              rotule: simulationData?.lead?.birthdate
            },
            cpf: {
              rotule: simulationData?.lead?.document || ''
            }
          }}
          _spacing={{
            marginBottom: '2rem',
            marginTop: '2rem'
          }}
        />
        <S.SimulationSheetGroup>{renderSheets()}</S.SimulationSheetGroup>

        {reasonSlug === 'approved' && (
          <Text
            tag="span"
            color="text"
            size="14px"
            weight="weight-400"
            _spacing={{
              marginTop: '4rem'
            }}
          >
            taxa de juros:&nbsp;
            {Number(simulationData?.proposal?.interest_rate).toFixed(2)}% ao mês
          </Text>
        )}

        {reasonSlug === 'approved' && (
          <Text tag="p" color="text" size="14px" weight="weight-400">
            IOF: {toBrl(String(simulationData?.proposal?.iof_fee))}
          </Text>
        )}
      </S.ContentBoxContainer>

      <S.ButtonGroup>
        {reasonSlug === 'approved' ? (
          <>
            <Button
              color="white"
              backgroundColor="orange200"
              onClick={refuseLoans}
              _sizing={{
                width: '12rem'
              }}
              _spacing={{
                paddingLeft: 'auto'
              }}
            >
              não contratar
            </Button>
            <Button
              color="white"
              onClick={acceptLoans}
              _sizing={{
                width: '12rem'
              }}
              _spacing={{
                paddingLeft: 'auto'
              }}
            >
              contratar
            </Button>
          </>
        ) : (
          <>
            <Button
              backgroundColor="blue700"
              onClick={goToPreAnalysis}
              _sizing={{
                maxWidth: '28rem'
              }}
            >
              simular novamente
            </Button>

            <Button
              color="white"
              backgroundColor="noverde"
              onClick={() => goToAttendance(true)}
              _sizing={{
                maxWidth: '28rem'
              }}
              _spacing={{
                paddingLeft: 'auto'
              }}
            >
              finalizar atendimento
            </Button>
          </>
        )}
      </S.ButtonGroup>
    </S.Container>
  )
}
