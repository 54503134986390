import styled, { css } from 'styled-components'

export const Root = styled.div`
  ${() => css`
    box-shadow: 0 0 0.75rem #e8e8e8;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.2rem;
    height: fit-content;
    margin-bottom: 1rem;
    width: 100%;
  `}
`

export const Head = styled.p`
  ${() => css`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  `}
`

interface BodyProps {
  isOpen: boolean
}
export const Body = styled.div<BodyProps>`
  ${({ isOpen }) => css`
    ${isOpen && `margin-top: 1rem`};
    display: flex;
    flex-direction: column;
    overflow: ${isOpen ? 'visible' : 'hidden'};
    opacity: ${isOpen ? '1' : '0'};
    height: ${isOpen ? 'fit-content' : '0'};
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
  `}
`

export const Block = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: space-between;
  width: 100%;
`

export const BlockItem = styled.div``
