import { useModal } from 'providers/ModalProvider'
import { CommonModal } from 'templates/Modals/CommonModal'

interface ActionModalProps {
  title: string
  description: string
  submitButtonTitle: string
  submitAction: () => void
}

export const useActionModal = () => {
  const { dispatch } = useModal()

  const actionModal = ({
    title,
    description,
    submitButtonTitle,
    submitAction
  }: ActionModalProps) =>
    dispatch({
      action: 'OPEN_MODAL',
      component: (
        <CommonModal
          title={title}
          description={description}
          primaryButtonTitle={submitButtonTitle}
          primaryButtonAction={() => {
            submitAction()
            dispatch({ action: 'CLOSE_MODAL' })
          }}
          primaryButtonProps={{
            variant: 'text',
            color: 'error'
          }}
          secondaryButtonTitle="cancelar"
          secondaryButtonProps={{
            backgroundColor: 'blue800',
            color: 'white'
          }}
          secondaryButtonAction={() => dispatch({ action: 'CLOSE_MODAL' })}
        />
      )
    })

  return { actionModal }
}
