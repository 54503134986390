/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PartnersTheme } from 'layout/theme'
import styled, { css } from 'styled-components'
import { RadioProps } from '.'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Input = styled.input`
  ${({ theme, disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    width: 1rem;
    height: 1rem;
    border: 1px solid
      ${disabled ? theme.colors.placeholders : theme.colors.galaxy};
    border-radius: 50%;
    background: transparent;
    transition: background ${theme.transition.fast};
    outline: none;
    cursor: pointer;
    &:before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: ${disabled ? theme.colors.placeholders : theme.colors.galaxy};
      transition: opacity ${theme.transition.fast};
      opacity: 0;
      position: absolute;
    }
    &:checked {
      &:before {
        opacity: 1;
      }
    }
  `}
`

export const Label = styled.label<
  Pick<RadioProps, 'labelColor' | 'isDisabled'>
>`
  ${({ isDisabled, labelColor = 'galaxy' }) => css`
    padding-left: 0.4rem;
    color: ${isDisabled
      ? PartnersTheme.colors.placeholders
      : PartnersTheme.colors[labelColor!]};
    line-height: 1;
    cursor: pointer;
  `}
`
