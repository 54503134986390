import styled from 'styled-components'

export const Container = styled.div``

export const Title = styled.h3`
  font-size: 1rem;
`

export const Description = styled.p`
  font-size: 0.8rem;
`
