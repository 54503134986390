/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from 'components/Button'
import { CustomerInformation } from 'components/CustomerInformation'
import { HeaderActions } from 'components/HeaderActions'
import { InputSelect, SelectItemType } from 'components/InputSelect'
import { Text } from 'components/Text'
import useForm from 'hooks/useForm'
import { useLocationState } from 'hooks/useLocationState'
import { useRouter } from 'hooks/useRouter'
import { useToast } from 'hooks/useToast'
import { AttendancePreAnalisysRouteProps } from 'pages/AttendancePreAnalisys'
import { AttendanceSimulationRouteProps } from 'pages/AttendanceSimulation'
import { useAppData } from 'providers/AppDataProvider'
import { useLoading } from 'providers/LoadingProvider'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ROUTES_NAME } from 'routes'
import AttendanceService from 'services/remote/v1/Attendance/AttendanceService'
import CompanyService from 'services/remote/v1/Company/CompanyService'
import * as S from './AttendanceSimulationDenied.styles'

export const AttendanceSimulationDenied = () => {
  const { history } = useRouter()
  const { setLoading } = useLoading()
  const { showToast } = useToast()
  const [reason, setReason] = useState<SelectItemType>({} as SelectItemType)
  const [reasonsData, setReasonsData] = useState<SelectItemType[]>([])
  const { state } = useLocation<AttendanceSimulationRouteProps>()
  const { selectedCompanyUuid } = useAppData()

  const simulationData = state?.simulationData
  const loansDenied = state?.loansDenied

  const { clearLocationStateAndReturn, verifyLocationState, justClean } =
    useLocationState()

  const { handleSubmit } = useForm()

  function handleChangeCategoryFinance(value: SelectItemType) {
    setReason(value)
  }

  const goToPreAnalysis = async (status: boolean) => {
    try {
      setLoading(true)

      await AttendanceService.sendAttendanceStatus(
        status,
        reason.id || '',
        state?.simulationData?.simulation?.uuid || ''
      )

      justClean()

      const newState: AttendancePreAnalisysRouteProps = {
        preAnalisysData: {
          name: state.simulationData?.lead.name || '',
          birthday: state.simulationData?.lead?.birthdate || '',
          document: state.simulationData?.lead?.document || '',
          unityUuid: state.simulationData?.lead?.unityUuid || '',
          attendanceUuid: state.simulationData?.lead?.attendanceUuid || '',
          leadUuid: state.simulationData?.lead?.leadUuid || ''
        },
        ...(state.simulationData
          ? {
              simulationData: {
                simulation: { ...state.simulationData.simulation }
              }
            }
          : {})
      }
      history.push({
        pathname: ROUTES_NAME.ATTENDANCE_PRE_ANALISYS,
        state: newState
      })
      setLoading(false)
    } catch (err: any) {
      setLoading(false)
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: 'Oops! Algo deu errado.',
        type: 'error'
      })
    }
  }

  const finishAttendance = async (status: boolean) => {
    try {
      if (loansDenied) {
        history.push(ROUTES_NAME.ATTENDANCE)
        return
      }

      setLoading(true)

      await AttendanceService.sendAttendanceStatusFinish(
        status,
        reason.id || '',
        String(state.simulationData?.lead?.attendanceUuid),
        String(state.simulationData?.lead?.unityUuid)
      )
      clearLocationStateAndReturn(ROUTES_NAME.ATTENDANCE)
      showToast({
        message: 'Atendimento Finalizado',
        title: '',
        type: 'info'
      })
      setLoading(false)
    } catch (err: any) {
      setLoading(false)
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: 'Oops! Algo deu errado.',
        type: 'error'
      })
    }
  }

  const getAllReasons = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await CompanyService.getAllRefusedReasonsInCompanyByUuid(
        selectedCompanyUuid || ''
      )
      const reasons = data.data.map((reason) => {
        return {
          id: String(reason.id),
          value: reason.name
        } as SelectItemType
      })

      setReasonsData(reasons)
    } catch (err) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }, [selectedCompanyUuid, setLoading])

  useEffect(() => {
    if (state) {
      getAllReasons()
    }
  }, [getAllReasons, state])

  useEffect(() => {
    verifyLocationState(ROUTES_NAME.ATTENDANCE)
  }, [verifyLocationState])

  return (
    <S.Container onSubmit={handleSubmit}>
      <HeaderActions
        title="novo atendimento"
        hasReturnButton={false}
        renderCustomRightCorner={<></>}
      />
      <S.Content>
        <Text weight="weight-600" size="1.5rem">
          proposta recusada
        </Text>
        <CustomerInformation
          heading="Cliente"
          customerInformationData={{
            nomeCompleto: {
              rotule: simulationData?.lead.name || ''
            },
            dataNascimento: {
              rotule: String(
                (!!simulationData?.lead?.birthdate &&
                  simulationData?.lead?.birthdate) ||
                  new Date()
              )
            },
            cpf: {
              rotule: String(simulationData?.lead.document) || ''
            }
          }}
        />
        {!loansDenied && (
          <S.InputSelectContainer>
            <InputSelect
              name="motivoRecusa"
              label="motivo da recusa *"
              data={reasonsData}
              selectedItem={reason}
              message={reason ? '' : 'Selecione uma categoria'}
              onChange={handleChangeCategoryFinance}
            />
          </S.InputSelectContainer>
        )}
      </S.Content>
      <S.ButtonGroup center={loansDenied}>
        {!loansDenied && (
          <Button
            disabled={loansDenied ? false : !reason.id}
            backgroundColor="blue700"
            onClick={() => goToPreAnalysis(true)}
            _sizing={{
              maxWidth: '20rem'
            }}
            _spacing={{
              marginTop: '2rem'
            }}
          >
            simular novamente
          </Button>
        )}

        <Button
          disabled={loansDenied ? false : !reason.id}
          onClick={() => finishAttendance(true)}
          _sizing={{
            maxWidth: '20rem'
          }}
          _spacing={{
            marginTop: '2rem'
          }}
        >
          finalizar atendimento
        </Button>
      </S.ButtonGroup>
    </S.Container>
  )
}
