import { toast, TypeOptions } from 'react-toastify'

import { ToastContainer } from 'components/ToastContainer'
import { useCallback } from 'react'

export interface ShowToastData {
  title: string
  message: string
  type: TypeOptions
}

export const useToast = () => {
  const showToast = useCallback(({ message, title, type }: ShowToastData) => {
    toast(
      ({ closeToast, toastProps }) => (
        <ToastContainer
          delay={5000}
          {...closeToast}
          {...toastProps}
          title={title}
          message={message}
          type={type}
        />
      ),
      {
        type
      }
    )
  }, [])

  return { showToast }
}
