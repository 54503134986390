/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState, useEffect } from 'react'

import { useRouter } from 'hooks/useRouter'
import { useLoading } from 'hooks/useLoading'
import { useAppData } from 'hooks/useAppData'
import { useToast } from 'hooks/useToast'

import { FormUnit } from 'components/FormUnit'
import { HeaderActions } from 'components/HeaderActions'

import BankService from 'services/remote/v1/Bank/BankService'

import * as S from './Unit.styles'
import CompanyService from 'services/remote/v1/Company/CompanyService'
import { ROUTES_NAME } from 'routes'

export interface PaymentDataDTO {
  account: string | undefined
  digitAccount: string | undefined
  agency: string | undefined
  digitAgency: string | undefined
  bank: string | undefined
  typeAccount: string | undefined
}

interface RouteStateType {
  currentUnit: string
}

export const UnitCreate = () => {
  const [banksList, setBanksList] = useState<any[]>([])
  const [paymentData, setPaymentData] = useState<PaymentDataDTO | null>(null)
  const [companyDisbursement, setCompanyDisbursement] =
    useState<boolean | null>(null)

  const { history, location } = useRouter<RouteStateType>()
  const { setLoading } = useLoading()
  const { selectedUnit } = useAppData()
  const { showToast } = useToast()

  const init = useCallback(async () => {
    try {
      setLoading(true)
      const banksResponse = await BankService.getBanksList()
      const companyResponse = await CompanyService.getAllUnitysInCompanyByUuid(
        selectedUnit.companyUuid || ''
      )

      const isCompanyAccount = companyResponse.data.data[0].company_disbursement

      if (isCompanyAccount) {
        const {
          account,
          account_digit,
          agency,
          agency_digit,
          bank,
          account_kind
        } = companyResponse.data.data[0]

        setPaymentData({
          account,
          digitAccount: account_digit,
          agency,
          digitAgency: agency_digit,
          bank,
          typeAccount: account_kind
        })
      }

      setCompanyDisbursement(Boolean(isCompanyAccount))

      const banks = banksResponse.data.data.banks.map((eachBank) => ({
        id: eachBank.code,
        value: `${eachBank.code} - ${eachBank.name}`
      }))

      setBanksList(banks)
    } catch (err: any) {
      showToast({
        title: 'Oops! Algo deu errado',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })

      history.replace(ROUTES_NAME.UNITS)
    } finally {
      setLoading(false)
    }
  }, [selectedUnit, setLoading, showToast, history])

  useEffect(() => {
    init()
  }, [init])

  useEffect(() => {
    if (!location?.state?.currentUnit) {
      history.replace(ROUTES_NAME.UNITS)
      return
    }

    if (location.state.currentUnit !== selectedUnit.unitUuid) {
      history.replace(ROUTES_NAME.UNITS)
    }
  }, [location, selectedUnit, history])

  return (
    <S.Container>
      <HeaderActions
        title="nova unidade"
        hasReturnButton={true}
        returnButtonAction={() => history.goBack()}
        renderCustomRightCorner={<></>}
      />
      {companyDisbursement !== null && (
        <FormUnit
          edit={false}
          banksList={banksList}
          paymentData={paymentData}
        />
      )}
    </S.Container>
  )
}
