import { Input } from 'components/Input'

import * as S from './CustomDatePicker.styles'
import Assets from 'assets'

interface DatePickerInputProps {
  name: string
  handleFocus: () => void
  value: string
}

export const DatePickerInput = ({
  name,
  handleFocus,
  value
}: DatePickerInputProps) => {
  return (
    <S.InputContainer>
      <Input
        name={name}
        animatedLabel="periodo"
        readOnly
        onFocus={handleFocus}
        value={value}
        iconRight={
          <S.IconsWrapper onClick={handleFocus}>
            <Assets
              alt="informative"
              assetProps={{
                key: 'datePicker',
                type: 'icon'
              }}
            />

            <Assets
              alt="informative"
              assetProps={{
                key: 'filledArrowPolygonBottom',
                type: 'icon'
              }}
            />
          </S.IconsWrapper>
        }
      />
    </S.InputContainer>
  )
}
