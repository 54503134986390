import { devices } from 'layout/devices'
import styled, { css } from 'styled-components'

export const Wrapper = styled.main`
  display: grid;
  grid-auto-flow: column;
  height: 100%;
  width: 100%;
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100vh;
  position: relative;

  @media ${devices.medium} {
    grid-template-columns: repeat(2, 50%);
  }
`

export const ContainerLeft = styled.div`
  ${({ theme }) => css`
    display: none;
    @media ${devices.medium} {
      background: ${theme.colors.dotzOrange};
      display: grid;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
      width: 100%;
    }

    img {
      bottom: 2.5rem;
      justify-self: center;
      position: absolute;
      width: 9.5rem;
    }
  `}
`

export const ContentLeft = styled.div`
  display: none;

  @media ${devices.medium} {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    line-height: 1;
    width: 30rem;
  }
`
export const ContainerRight = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
`
