import { Text } from 'components/Text'
import { RestTypes } from 'components/typings/utils/restTypes'
import { formatZonedTimeToUtc } from 'utils/formatters/fomatZonedTimeToUtc'
import { formatDate } from 'utils/formatters/format-date'
import { cpfMask } from 'utils/formatters/masks'
import * as S from './CustomerInformation.styles'

type Informations = {
  rotule?: string
}

export interface CustomerInformationData {
  nomeCompleto?: Informations
  dataNascimento?: Informations
  cpf?: Informations
  statusProposta?: Informations
  atendente?: Informations
  dataAtendimento?: Informations
  validadeProposta?: Informations
  unidade?: Informations
}

export type CustomerInformationProps = {
  heading: string
  customerInformationData: CustomerInformationData
} & RestTypes

export const CustomerInformation = ({
  heading = '',
  customerInformationData = {} as CustomerInformationData,
  ...rest
}: CustomerInformationProps) => (
  <S.Root {...rest}>
    <Text type="subheading-1">{heading}</Text>
    <S.Box>
      {customerInformationData.nomeCompleto && (
        <S.BoxItem>
          <Text color="text" size="0.875rem" weight="weight-400">
            {customerInformationData.nomeCompleto && 'nome completo'}
          </Text>
          <Text color="galaxy" size="1rem" weight="weight-400">
            {customerInformationData.nomeCompleto.rotule}
          </Text>
        </S.BoxItem>
      )}

      {customerInformationData.dataNascimento && (
        <S.BoxItem>
          <Text color="text" size="0.875rem" weight="weight-400">
            {customerInformationData.dataNascimento && 'data de nascimento'}
          </Text>
          <Text color="galaxy" size="1rem" weight="weight-500">
            {formatDate(
              String(
                new Date(
                  formatZonedTimeToUtc(
                    String(customerInformationData.dataNascimento.rotule),
                    false
                  ) || ''
                )
              ),
              'DD/ MM/ AAAA'
            )}
          </Text>
        </S.BoxItem>
      )}

      {customerInformationData.cpf && (
        <S.BoxItem>
          <Text color="text" size="0.875rem" weight="weight-400">
            {customerInformationData.cpf && 'cpf'}
          </Text>
          <Text color="galaxy" size="1rem" weight="weight-500">
            {cpfMask(customerInformationData.cpf.rotule || '')}
          </Text>
        </S.BoxItem>
      )}

      {customerInformationData.statusProposta && (
        <S.BoxItem>
          <Text color="text" size="0.875rem" weight="weight-400">
            {customerInformationData.statusProposta && 'status da proposta'}
          </Text>
          <Text color="galaxy" size="1rem" weight="weight-400">
            {customerInformationData.statusProposta.rotule}
          </Text>
        </S.BoxItem>
      )}

      {customerInformationData.atendente && (
        <S.BoxItem>
          <Text color="text" size="0.875rem" weight="weight-400">
            {customerInformationData.atendente && 'atendente'}
          </Text>
          <Text color="galaxy" size="1rem" weight="weight-500">
            {customerInformationData.atendente.rotule}
          </Text>
        </S.BoxItem>
      )}

      {customerInformationData.dataAtendimento && (
        <S.BoxItem>
          <Text color="text" size="0.875rem" weight="weight-400">
            {customerInformationData.dataAtendimento && 'data do atendimento'}
          </Text>
          <Text color="galaxy" size="1rem" weight="weight-500">
            {formatDate(
              customerInformationData.dataAtendimento.rotule || '',
              'DD/ MM/ AAAA'
            )}
          </Text>
        </S.BoxItem>
      )}

      {customerInformationData.validadeProposta && (
        <S.BoxItem>
          <Text color="text" size="0.875rem" weight="weight-400">
            {customerInformationData.validadeProposta && 'validade da proposta'}
          </Text>
          <Text color="galaxy" size="1rem" weight="weight-400">
            {formatDate(
              customerInformationData.validadeProposta.rotule || '',
              'DD/ MM/ AAAA'
            )}
          </Text>
        </S.BoxItem>
      )}

      {customerInformationData.unidade && (
        <S.BoxItem>
          <Text color="text" size="0.875rem" weight="weight-400">
            {customerInformationData.unidade && 'unidade'}
          </Text>
          <Text color="galaxy" size="1rem" weight="weight-500">
            {customerInformationData.unidade.rotule}
          </Text>
        </S.BoxItem>
      )}
    </S.Box>
  </S.Root>
)
