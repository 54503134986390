import { Switch, Route, Redirect } from 'react-router-dom'

import { ROUTES_NAME } from './routesName'

import { AccessWithPassword } from 'pages/AccessWithPassword'
import { FirstAccessStep1 } from 'pages/FirstAccess/FirstAccessStep1'
import { FirstAccessStep2 } from 'pages/FirstAccess/FirstAccessStep2'
import { ResetPasswordStep1 } from 'pages/ResetPassword/ResetPasswordStep1'
import { ResetPasswordStep2 } from 'pages/ResetPassword/ResetPasswordStep2'
import { SignIn } from 'pages/SignIn'

export const AuthRoutes = () => {
  return (
    <Switch>
      <Route exact path={ROUTES_NAME.LOGIN} component={SignIn} />

      <Route
        exact
        path={ROUTES_NAME.RESET_PASSWORD}
        component={ResetPasswordStep1}
      />

      <Route
        exact
        path={ROUTES_NAME.RESET_PASSWORD_STEP_2}
        component={ResetPasswordStep2}
      />

      <Route
        exact
        path={ROUTES_NAME.FIRST_ACCESS}
        component={FirstAccessStep1}
      />

      <Route
        exact
        path={ROUTES_NAME.FIRST_ACCESS_STEP_2}
        component={FirstAccessStep2}
      />

      <Route
        exact
        path={ROUTES_NAME.LOGIN_PASSWORD}
        component={AccessWithPassword}
      />

      <Route path="*">
        <Redirect to={ROUTES_NAME.LOGIN} />
      </Route>
    </Switch>
  )
}
