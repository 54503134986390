import { RestTypes } from 'components/typings/utils/restTypes'
import { Img } from './Image.styles'

export type ImageProps = {
  src: string
  alt: string
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
  className?: string
} & RestTypes

export const Image = ({ src, alt, className, ...props }: ImageProps) => (
  <Img
    src={src}
    alt={alt}
    {...(className ? { className: className } : {})}
    {...props}
  />
)
