import styled, { css } from 'styled-components'

interface ModuleContainer {
  isSelected: boolean
  resourcesLength: number
}

interface ResourceContainer {
  isSelected: boolean
}

export const Container = styled.nav<{ isCollapsed?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  width: 100%;
`

export const ModuleContainer = styled.div<ModuleContainer>`
  ${({ theme, isSelected, resourcesLength }) => css`
    width: 100%;
    height: 50px;
    color: ${theme.colors.white};
    background-color: ${theme.colors.dotzOrange};
    border-radius: 0.25rem;
    height: ${isSelected ? resourcesLength * 50 + 50 : 50}px;
    overflow: hidden;
    transition: all 0.2s ease-in;
  `}
`

export const ModuleHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 0 32px 0px 66px;
    width: 100%;
    height: 50px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: background 0.2s;

    img {
      margin-right: 15px;
      max-width: 18px;
    }

    &:hover {
      background: ${theme.colors.dotzLightOrange};
    }
  `}
`

export const ModuleResourcesWrapper = styled.ul`
  width: 100%;
  cursor: pointer;
`

export const ResourceItem = styled.li<ResourceContainer>`
  ${({ theme, isSelected }) => css`
    display: flex;
    list-style: none;
    padding: 0 32px 0px 90px;
    height: 50px;
    align-items: center;
    color: ${isSelected ? '#fff' : 'rgba(255, 255, 255, 0.5)'};
    font-size: 14px;
    font-weight: 500;
    transition: background 0.2s;

    img {
      margin-right: 15px;
      max-width: 18px;
    }

    &:hover {
      background: ${theme.colors.dotzLightOrange};
    }
  `}
`

export const CollapsedIcons = styled.div`
  ${({ theme }) => css`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    padding: 1.2rem 0;
    width: 100%;
    background: ${theme.colors.dotzOrange};
    border-radius: 0.25rem;
    transition: background 0.2s;

    &:hover {
      background: ${theme.colors.dotzLightOrange};
    }

    img {
      max-width: 18px;
    }
  `}
`
