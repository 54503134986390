import { HttpClient } from 'services/http-client'
import { endpoints } from 'services/endpoints'

export interface CategoryResponse {
  id: string
  name: string
}

export interface ListCategoryResponse {
  categories: CategoryResponse[]
}

class Category extends HttpClient {
  public constructor() {
    super(endpoints.remote.v2.Zendesk)
  }

  public listCategories = () => {
    return this.instance.get<ListCategoryResponse>(`/categories`)
  }
}

export default new Category()
