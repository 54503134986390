/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState, useEffect } from 'react'

import { useLoading } from 'hooks/useLoading'
import { useRouter } from 'hooks/useRouter'
import { useModal } from 'providers/ModalProvider'
import useForm from 'hooks/useForm'
import { useToast } from 'hooks/useToast'
import { useAppData } from 'hooks/useAppData'
import { ROUTES_NAME } from 'routes'

import { Button } from 'components/Button'
import { Form } from 'components/Form/Form'
import { Text } from 'components/Text'
import { Input } from 'components/Input'
import { ToggleSwitcher } from 'components/ToggleSwitcher'
import { SelectItemType } from 'components/InputSelect'
import { CommonModal } from 'templates/Modals/CommonModal'

import * as S from './FormProduct.styles'
import { ProductDTO } from 'services/remote/v1/Product/ProductServiceTypes'
import ProductService from 'services/remote/v1/Product/ProductService'
import { ProductEditRouteProps } from 'pages/Product/ProductEdit'
import { useLocationState } from 'hooks/useLocationState'
import { AutoComplete } from 'components/Autocomplete'

interface FormValues {
  name: string
  category: SelectItemType | null
}

interface FormProductProps {
  edit: boolean
  routeItems?: ProductDTO
  hideDelete?: boolean
  hideUpdate?: boolean
}

export const FormProduct = ({
  edit = false,
  routeItems,
  hideDelete = false,
  hideUpdate = false
}: FormProductProps) => {
  const { setLoading } = useLoading()
  const { showToast } = useToast()
  const { data: appData, selectedUnit } = useAppData()
  const { history } = useRouter<ProductEditRouteProps>()
  const [toggleActive, setToggleActive] = useState<boolean>(
    routeItems ? routeItems.is_active : true
  )
  const [categories, setCategories] = useState<SelectItemType[]>([])
  const [categoryOption, setCategoryOption] = useState('')
  const [categorySelected, setCategorySelected] = useState<
    SelectItemType | undefined
  >({
    id: String(routeItems?.category.id),
    value: routeItems?.category.name ?? ''
  })
  const selectedItem: SelectItemType = {
    id: String(routeItems?.category.id),
    value: routeItems?.category.name ?? ''
  }

  const { dispatch } = useModal()
  const { clearLocationStateAndReturn } = useLocationState()

  const getAllCategories = useCallback(() => {
    try {
      if (selectedUnit.companyUuid.length === 0) return
      setLoading(true)
      const selectedCompany = appData?.companies.find(
        (eachCompany) => eachCompany.uuid === selectedUnit.companyUuid
      )

      if (!selectedCompany) {
        showToast({
          title: 'Oops! Algo deu errado',
          message: 'Empresa não encontrada',
          type: 'error'
        })
        setLoading(false)
        return
      }

      setCategories(
        selectedCompany.categories.map((category) => {
          return {
            id: String(category.id),
            value: category.name
          }
        })
      )
    } catch (err: any) {
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: '',
        type: 'error'
      })
      history.goBack()
    }
    setLoading(false)
  }, [history, setLoading, showToast, selectedUnit, appData])

  const createOrEditProduct = useCallback(
    async (name: string, category: SelectItemType | null) => {
      try {
        setLoading(true)
        console.log({ categorySelected, categoryOption })
        const productReqBody: Pick<
          ProductDTO,
          'name' | 'is_active' | 'category'
        > = {
          name: name,
          is_active: toggleActive,
          category: {
            id: Number(category?.id)
          }
        }
        if (edit) {
          await ProductService.updateProductByUuid(
            String(routeItems?.uuid),
            productReqBody
          )
          showToast({
            message: 'Seu produto foi atualizado com sucesso',
            title: 'Produto Atualizado',
            type: 'success'
          })
          clearLocationStateAndReturn(ROUTES_NAME.PRODUCT)
        } else {
          await ProductService.createProduct(
            selectedUnit.companyUuid,
            productReqBody
          )
          showToast({
            message: 'Seu produto foi criado com sucesso',
            title: 'Produto Criado',
            type: 'success'
          })
          return history.goBack()
        }
      } catch (err: any) {
        setLoading(false)
        showToast({
          title: '',
          message:
            err?.response?.data?.error?.messages_client[0] || err.message,
          type: 'error'
        })
      }
    },
    [
      setLoading,
      toggleActive,
      routeItems?.uuid,
      edit,
      history,
      showToast,
      selectedUnit.companyUuid,
      clearLocationStateAndReturn
    ]
  )

  useEffect(() => {
    getAllCategories()
  }, [getAllCategories])

  const { data, errors, updateFormData, handleChange, handleSubmit } =
    useForm<FormValues>({
      initialValues: {
        name: routeItems?.name || '',
        category: routeItems?.category ? selectedItem : null
      },
      validations: {
        name: {
          required: {
            value: true,
            message: 'Informe um nome ao produto'
          }
        },
        category: {
          required: {
            value: true,
            message: 'Informe uma categoria'
          }
        }
      },
      onSubmit: async () => {
        createOrEditProduct(data.name, data.category)
      }
    })

  function handleToggleSwitcher() {
    setToggleActive((currState) => !currState)
  }

  const handleRemoveReason = () => {
    dispatch({
      action: 'OPEN_MODAL',
      component: (
        <CommonModal
          title="excluir produto"
          description="Tem certeza que deseja excluir esse produto? Uma vez excluído, você não poderá acessá-lo novamente."
          primaryButtonTitle="excluir produto"
          primaryButtonProps={{ fontSize: '1rem', backgroundColor: 'error' }}
          primaryButtonAction={() => handleDeleteProduct()}
          secondaryButtonTitle="cancelar"
          secondaryButtonProps={{
            variant: 'text',
            color: 'galaxy',
            withoutUnderline: true,
            _spacing: {
              padding: '0'
            }
          }}
          secondaryButtonAction={() => dispatch({ action: 'CLOSE_MODAL' })}
        />
      )
    })
  }

  const handleDeleteProduct = async () => {
    try {
      setLoading(true)
      await ProductService.deleteProductByUuid(String(routeItems?.uuid))
      showToast({
        message: 'Seu produto foi excluído com sucesso',
        title: 'Produto Excluído',
        type: 'success'
      })
      dispatch({ action: 'CLOSE_MODAL' })
      clearLocationStateAndReturn(ROUTES_NAME.PRODUCT)
    } catch (err: any) {
      setLoading(false)
      dispatch({ action: 'CLOSE_MODAL' })
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: '',
        type: 'success'
      })
    }
  }

  return (
    <Form
      _flex={{
        direction: 'column',
        gap: '1rem'
      }}
      onSubmit={handleSubmit}
    >
      <S.Box>
        <Text
          type="subheading-1"
          _spacing={{
            marginBottom: '1.5rem'
          }}
        >
          Sobre o produto
        </Text>

        <S.InputGroup>
          <Input
            name="name"
            animatedLabel="nome do produto"
            isRequired
            message={errors.name}
            onChange={handleChange('name')}
            value={data.name || ''}
          />
          <AutoComplete
            options={categories}
            selectedOption={categorySelected}
            inputValue={categoryOption}
            errorMessage={errors.category}
            onInputChange={(value: string) => setCategoryOption(value)}
            onChange={(value) => {
              setCategorySelected(value ? value : { id: '', value: '' })
              updateFormData({
                ...data,
                category: value
              })
            }}
            isLoading={categories.length === 0}
            renderInput={(params) => (
              <Input
                name="state"
                placeholder="selecione uma categoria"
                animatedLabel="categoria *"
                data-lpignore
                {...params}
              />
            )}
          />
          <S.SwitchGroup>
            <label htmlFor="">ativo</label>
            <ToggleSwitcher
              isActive={toggleActive}
              toggleActive={handleToggleSwitcher}
            />
          </S.SwitchGroup>
        </S.InputGroup>
      </S.Box>
      <S.ButtonGroup>
        {!hideUpdate && (
          <Button
            _sizing={{
              width: '12rem'
            }}
          >
            {edit ? 'Atualizar' : 'Salvar'}
          </Button>
        )}
        {edit && !hideDelete && (
          <Button
            backgroundColor="error"
            color="white"
            type="button"
            onClick={handleRemoveReason}
            _sizing={{
              width: '12rem'
            }}
          >
            Excluir
          </Button>
        )}
      </S.ButtonGroup>
    </Form>
  )
}
