/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'

import { useAppData } from 'hooks/useAppData'
import { useToast } from 'hooks/useToast'

import { Button } from 'components/Button'
import { toBrl } from 'utils/formatters/to-brl'
import { CustomDatePicker } from 'components/CustomDatePicker'
import { EmptyState } from 'components/EmptyState'
import { SelectItemType } from 'components/InputSelect'
import { ListTable } from 'components/ListTable'
import { useLoading } from 'providers/LoadingProvider'
import { Input } from 'components/Input'

import * as S from './FormAttendanceProposalFollowUp.styles'
import SimulationService from 'services/remote/v1/Simulation/SimulationService'
import { FollowUpDTO } from 'models/FollowUpDTO'
import { cpfMask } from 'utils/formatters/masks'
import { formatDate } from 'utils/formatters/format-date'

import { formatZonedTimeToUtc } from 'utils/formatters/fomatZonedTimeToUtc'
import { TooltipText } from 'components/TooltipText'
import { AutoComplete } from 'components/Autocomplete'
import { ExportButton } from 'components/ExportButton'

const statusColors = {
  rejeitada: '#FF6464',
  'pré-aprovada': '#ff9900',
  recusada: '#9B51E0',
  aceita: '#0cd15b',
  desembolsada: '#1890FF'
}

const headers = [
  { label: 'status', key: 'status' },
  { label: 'motivo', key: 'reason' },
  { label: 'data', key: 'date' },
  { label: 'cliente', key: 'name' },
  { label: 'CPF', key: 'document' },
  { label: 'unidade', key: 'unit' },
  { label: 'criado por', key: 'created_by' },
  { label: 'valor solicitado', key: 'requested_amount' },
  { label: 'valor liberado', key: 'granted_amount' },
  { label: 'parcelas', key: 'granted_period' },
  { label: 'juros', key: 'interest_rate' }
]

export const FormAttendanceProposalFollowUp = () => {
  const [isEmptyState, setIsEmptyState] = useState(false)
  const { isLoading, setLoading } = useLoading()
  const { showToast } = useToast()
  const { selectedUnit: currentSelectedUnit, data: appData } = useAppData()
  const [hasPermissionExport, setHasPermissionExport] = useState(false)

  const [dateRange, setDateRange] = useState<(null | Date)[]>([
    new Date(),
    new Date()
  ])
  const [tableListData, setTableListData] = useState<FollowUpDTO[]>([])
  const [unitsList, setUnitsList] = useState<SelectItemType[]>([])
  const [statusList, setStatusList] = useState<SelectItemType[]>([])
  const [statusTerm, setStatusTerm] = useState('')
  const [unitTerm, setUnitTerm] = useState('')

  const [selectedUnit, setSelectedUnit] = useState<SelectItemType>({
    id: '',
    value: ''
  })

  const [selectedPayoutMethod, setPayoutMethod] = useState<SelectItemType>({
    id: '',
    value: ''
  })

  const listAllCompanyUnits = () => {
    const currentCompany = appData?.companies.find(
      (eachCompany) => eachCompany.uuid === currentSelectedUnit.companyUuid
    )

    let units: SelectItemType[] = []

    if (currentCompany) {
      units = currentCompany?.units.map((eachUnit) => ({
        id: String(eachUnit.uuid),
        value: String(eachUnit.name)
      }))
    }

    units.unshift({ id: '', value: 'Todas' })

    setUnitsList(units)
  }

  const getSimulationStatus = async () => {
    try {
      setLoading(true)

      const { data } = await SimulationService.getSimulationsStatus()

      const newData: SelectItemType[] = data?.data?.map((status) => ({
        ...status,
        id: status.name
      }))

      newData.unshift({ id: '', value: 'Todos' })

      setStatusList(newData)
    } catch (err: any) {
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: 'Oops! Algo deu errado.',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const getExportData = () => {
    if (tableListData.length > 0) {
      const exportData = tableListData.map((listData) => ({
        status: listData.status,
        reason: listData.reason.name,
        date: formatDate(
          String(
            new Date(formatZonedTimeToUtc(listData.created_at, true) || '')
          ),
          'DD/MM/AAAA - HH:MM'
        ),
        name: listData.lead.name,
        document: listData.lead.document,
        unit: listData.unit.name,
        created_by: listData.created_by,
        requested_amount: listData.requested_amount
          ? toBrl(listData.requested_amount)
          : '',
        granted_amount: listData.granted_amount
          ? toBrl(listData.granted_amount)
          : '',
        granted_period: listData.granted_period,
        interest_rate: listData.interest_rate
          ? `${listData.interest_rate}%`
          : ''
      }))
      return exportData
    }
  }

  const checkPermission = () => {
    if (appData) {
      const currentModule = appData.modules.find(
        (_module) => _module.abbreviation === 'ATN'
      )

      if (currentModule) {
        const report = currentModule.resources.find(
          (resource) => resource.abbreviation === 'ATEN_PROG'
        )

        setHasPermissionExport(!!report?.permissions?.export)
      }
    }
  }

  useEffect(() => {
    checkPermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData])

  useEffect(() => {
    getSimulationStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentSelectedUnit.unitUuid) {
      handleGetFollowUpList()
      listAllCompanyUnits()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedUnit, setLoading])

  const handleGetFollowUpList = async () => {
    try {
      setLoading(true)
      const beginDate = dateRange[0]
        ? formatDate(dateRange[0].toISOString(), 'AAAA-MM-DD')
        : ''

      const endDate = dateRange[1]
        ? formatDate(dateRange[1].toISOString(), 'AAAA-MM-DD')
        : ''

      const response = await SimulationService.getFollowUpData({
        uuidCompany: currentSelectedUnit?.companyUuid || '',
        unitUuid: selectedUnit?.id || '',
        beginDate: beginDate ? `${beginDate}T00:00:00.000Z` : beginDate,
        endDate: endDate
          ? `${endDate}T23:59:59.999Z`
          : `${beginDate}T23:59:59.999Z`,
        statusUuid: selectedPayoutMethod?.id ? selectedPayoutMethod.value : ''
      })

      setIsEmptyState(response.data.data.length === 0)

      setTableListData(response.data.data)
    } catch (err: any) {
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: 'Oops! Algo deu errado.',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  interface RenderItemProps {
    item: FollowUpDTO
    canEdit?: boolean
  }

  const renderItem = useCallback(
    ({ item }: RenderItemProps) => {
      const formattedDate = item?.created_at
        ? formatDate(
            String(new Date(formatZonedTimeToUtc(item.created_at, true) || '')),
            'DD/MM/AAAA - HH:MM'
          )
        : '-'
      return (
        <>
          {isLoading ? (
            <>
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={200} />
            </>
          ) : (
            <>
              <S.ColorSpan
                customColor={
                  statusColors[
                    item?.reason?.status as keyof typeof statusColors
                  ]
                }
              >
                <TooltipText
                  positionRelative
                  text={item?.reason?.status || '-'}
                >
                  {item?.reason?.status || '-'}
                </TooltipText>
              </S.ColorSpan>
              <span>
                <TooltipText positionRelative text={item?.reason?.name}>
                  {item?.reason?.name || '-'}
                </TooltipText>
              </span>
              <span>
                <TooltipText positionRelative text={formattedDate}>
                  {formattedDate}
                </TooltipText>
              </span>
              <span>
                <TooltipText positionRelative text={item?.lead?.name}>
                  {item?.lead?.name || '-'}
                </TooltipText>
              </span>
              <span>
                <TooltipText
                  positionRelative
                  text={
                    item?.lead?.document ? cpfMask(item?.lead?.document) : '-'
                  }
                >
                  {item?.lead?.document ? cpfMask(item?.lead?.document) : '-'}
                </TooltipText>
              </span>
              <span>
                <TooltipText positionRelative text={item?.unit?.name || '-'}>
                  {item?.unit?.name || '-'}
                </TooltipText>
              </span>
              <span>
                <TooltipText positionRelative text={item?.created_by}>
                  {item?.created_by || '-'}
                </TooltipText>
              </span>
              <span>
                <TooltipText
                  positionRelative
                  text={toBrl(item?.requested_amount) || ''}
                >
                  {toBrl(item?.requested_amount) || '-'}
                </TooltipText>
              </span>
              <span>
                <TooltipText
                  positionRelative
                  text={item?.granted_amount ? toBrl(item?.granted_amount) : ''}
                >
                  {item?.granted_amount ? toBrl(item?.granted_amount) : '-'}
                </TooltipText>
              </span>
              <span>
                <TooltipText
                  positionRelative
                  text={item?.granted_period ? `${item?.granted_period}` : '-'}
                >
                  {item?.granted_period ? `${item?.granted_period}` : '-'}
                </TooltipText>
              </span>
              <span>
                <TooltipText
                  positionRelative
                  text={item?.interest_rate ? `${item?.interest_rate}%` : ''}
                >
                  {item?.interest_rate ? `${item?.interest_rate}%` : '-'}
                </TooltipText>
              </span>
            </>
          )}
        </>
      )
    },
    [isLoading]
  )

  return (
    <>
      <S.FormArea>
        <div>
          <CustomDatePicker dateRange={dateRange} setDateRange={setDateRange} />
        </div>

        <AutoComplete
          options={statusList}
          selectedOption={selectedPayoutMethod}
          inputValue={statusTerm}
          onInputChange={(value: string) => setStatusTerm(value)}
          onChange={(value) =>
            setPayoutMethod(value ? value : { id: '', value: '' })
          }
          renderInput={(params) => (
            <Input
              name="status"
              placeholder="selecione um status..."
              animatedLabel="status *"
              data-lpignore
              {...params}
            />
          )}
        />

        <AutoComplete
          options={unitsList}
          selectedOption={selectedUnit}
          inputValue={unitTerm}
          onInputChange={(value: string) => setUnitTerm(value)}
          onChange={(value) =>
            setSelectedUnit(value ? value : { id: '', value: '' })
          }
          renderInput={(params) => (
            <Input
              name="unidade"
              placeholder="selecione uma unidade..."
              animatedLabel="unidade *"
              data-lpignore
              {...params}
            />
          )}
        />

        <Button
          type="button"
          variant="primaryBlue"
          onClick={handleGetFollowUpList}
        >
          consultar
        </Button>
      </S.FormArea>
      {isEmptyState ? (
        <EmptyState
          description="Não foi retornado nenhum acompanhamento com esta filtragem"
          onClick={() => null}
          showCreateButton={true}
        />
      ) : (
        <S.WrapperTable>
          <ListTable<FollowUpDTO>
            tableStructure={{
              header:
                'minmax(100px, 500px) minmax(280px, 500px)  minmax(150px, 500px) minmax(200px, 500px) minmax(115px, 500px) minmax(100px, 500px) minmax(250px, 500px) minmax(100px, 500px) minmax(100px, 800px) minmax(100px, 800px) minmax(100px, 800px)',
              body: 'minmax(100px, 500px) minmax(280px, 500px) minmax(150px, 500px) minmax(200px, 500px) minmax(115px, 500px) minmax(100px, 500px) minmax(250px, 500px) minmax(100px, 500px) minmax(100px, 800px) minmax(100px, 800px) minmax(100px, 800px)',
              columns: headers.map((header) => header.label)
            }}
            disableEllipsis
            fitContent
            data={isLoading ? Array(10).fill({}) : tableListData}
            pX={['20px', '20px']}
            renderItem={renderItem}
            keyExtractor={(item) => item.uuid}
          />
          {hasPermissionExport && tableListData.length > 0 && (
            <ExportButton
              filename={'TabeladeAcompanhemento.csv'}
              headers={headers}
              data={getExportData() || []}
              useButton
            />
          )}
        </S.WrapperTable>
      )}
    </>
  )
}
