import { appearFromRight } from 'layout/animations'
import { devices } from 'layout/devices'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 1.5rem 2.5rem;
  width: 100%;
`

export const ContentBoxContainer = styled.div`
  margin-top: 30px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4.75px;
  padding: 26px;
  width: 100%;

  animation: ${appearFromRight} 0.3s ease-in;

  @media ${devices.large} {
    padding: 32px 46px 46px;
  }
`

export const AccordionWrapper = styled.div`
  margin-top: 28px;
`

export const Heading = styled.p``

export const TagList = styled.div`
  display: flex;
  margin-top: 18px;
  margin-bottom: 32px;
  flex-wrap: wrap;

  p + p {
    margin-left: 8px;
  }
`
