import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'
import { GetAllCategoriesResponse } from './CategoryServiceTypes'

class CompanyService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Get all categories
   * @returns GetAllCategoriesResponse
   */
  public getAllCategories() {
    return this.instance.get<GetAllCategoriesResponse>(`/categories`)
  }
}

export default new CompanyService()
