import styled, { css } from 'styled-components'

import { renderRestTypes } from 'components/typings/utils/renderRestTypes'

import { RestTypes } from 'components/typings/utils/restTypes'

type IsActiveProp = {
  isActive: boolean
  disabled?: boolean
} & RestTypes

export const Container = styled.div<IsActiveProp>`
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  input[type='checkbox'] {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    width: 36px;
    height: 14px;
    background-color: ${({ isActive }) =>
      isActive ? 'rgba(12, 15, 37, 0.5)' : 'rgba(0, 0, 0, 0.12)'};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    border-radius: 100px;
    padding: 2px 10px;
    transition: background-color 0.4s ease;
  }

  ${({ _flex, _layout, _spacing, _sizing, _positioning }) => css`
    ${renderRestTypes({
      _flex: _flex,
      _layout: _layout,
      _positioning: _positioning,
      _sizing: _sizing,
      _spacing: _spacing
    })}
  `}
`

export const PaintedBall = styled.div<IsActiveProp>`
  background-color: ${({ isActive }) => (isActive ? '#0C0F25' : '#BDBDBD')};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  transition: 0.4s ease left, 0.4s ease transform, background-color 0.4s ease;
  left: 50%;
  z-index: 2;

  transform: translateX(${({ isActive }) => (isActive ? 0 : -100)}%);
`
