import { createGlobalStyle } from 'styled-components'

import { PartnersTheme } from 'layout/theme'

const RADIUS = '1rem'

const datePicker = createGlobalStyle`
  .react-datepicker-wrapper {
    display: inline-flex;

    .react-datepicker__input-container input {
      display: block;
    }
  }

  .react-datepicker {
    width: 22.5rem;
    max-width: 100%;
    border-color: #CFCDD6;
    overflow: hidden;
    border-radius: ${RADIUS};
    filter: drop-shadow(4px 4px 16px rgba(0, 0, 0, 0.16));
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__week {
    padding-bottom: 0.5rem;
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: ${RADIUS};
    
  }

  .react-datepicker__month {
    margin: 1rem;
  }

  .react-datepicker__header {
    border-top-left-radius: ${RADIUS};
    border-bottom-width: 0;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    .react-datepicker__day-names,
    .react-datepicker__week {
      border-bottom: 1px solid #CFCDD6;
      margin-top: 1rem;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      line-height: 1.5;
      font-size: 0.75rem;
      font-family: ${PartnersTheme.font.family.default};
      font-weight: 400;
      color: ${PartnersTheme.colors.galaxy};
    }
  }

  .react-datepicker__month {
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      font-size: 0.75rem;
      font-family: ${PartnersTheme.font.family.default};
      font-weight: 300;
      color: ${PartnersTheme.colors.galaxy};

      :focus {
        outline: none;
      }
    }
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 1rem;
    font-family: ${PartnersTheme.font.family.default};
    font-weight: 400;
    color: ${PartnersTheme.colors.galaxy};
  }

  .react-datepicker__header {
    background-color: ${PartnersTheme.colors.white};
    display: flex;
    flex-direction: column;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 0.125rem;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 50%;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    box-shadow: 0.5px 0.5px 4px rgba(76, 63, 143, 0.25);
    position: relative;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    color: ${PartnersTheme.colors.white} !important;

    ::before {
      content: "";
      width: 0.1875rem;
      height: 0.1875rem;
      border-radius: 50%;
      background-color: transparent;
      position: absolute;
      bottom: 0.1875rem;
      right: 0;
      left: 0;
      margin: 0 auto;
    }
  }

  .react-datepicker__day--in-range {
    color: ${PartnersTheme.colors.white} !important;
    background-color: ${PartnersTheme.colors.noverde} !important;
  }

  .react-datepicker__day--selecting-range{
    background-color: ${PartnersTheme.colors.green400} !important;
    background-color: red !important;
  }

  .react-datepicker__day--selecting-range-start {
    background-color: ${PartnersTheme.colors.green400} !important;
  }

  .react-datepicker__day--in-selecting-range {
    color: ${PartnersTheme.colors.white} !important;
    background-color: rgba(12, 209, 91, 0.5) !important;
  }
  
  /* active date hover */
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background-color: ${PartnersTheme.colors.noverde};
  }

  .react-datepicker__navigation--next {
    right: 1rem;
    border-radius: 50%;
  }

  .react-datepicker__navigation--previous {
    left: 1rem;
  }

  .react-datepicker__navigation {
    width: 1.5rem;
    height: 1.5rem;
    top: 1rem;
    border: 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-origin: content-box;
    padding: 0.3125rem;
    transition: all .2s;
    cursor: pointer;

    :focus {
      outline: none;
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: inherit;
    box-shadow: inherit;
    font-weight: 300 !important;
    color: #0c0f25 !important;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  .react-datepicker__day--keyboard-selected.react-datepicker__day--in-selecting-range {
    color: #ffff !important;
  }

  .react-datepicker__day--keyboard-selected.react-datepicker__day--in-range {
    color: #ffff !important;
  }
`

export default datePicker
