import { useEffect, useState } from 'react'

import { useRouter } from 'hooks/useRouter'
import { useLocationState } from 'hooks/useLocationState'
import { ProductDTO } from 'services/remote/v1/Product/ProductServiceTypes'

import { HeaderActions } from 'components/HeaderActions'
import { FormProduct } from 'components/FormProduct/FormProduct'
import { ROUTES_NAME } from 'routes'

import * as S from './Product.styles'
import { useAppData } from 'hooks/useAppData'
import { PermissionsType } from 'pages/Profile/types'
import { getResourcePermissions } from 'utils/getResourcePermissions'

export interface ProductEditRouteProps {
  routeItems: ProductDTO
}

export const ProductEdit = () => {
  const { history } = useRouter()
  const { location } = useRouter<ProductEditRouteProps>()
  const { verifyLocationState } = useLocationState()
  const { data: appData } = useAppData()

  const [permissions, setPermissions] = useState<PermissionsType>(
    {} as PermissionsType
  )

  useEffect(() => {
    verifyLocationState(ROUTES_NAME.PRODUCT)
  }, [verifyLocationState])

  useEffect(() => {
    const _permissions = getResourcePermissions(appData, 'CAD', 'CAD_PRD')

    if (_permissions) setPermissions(_permissions)
  }, [appData])

  return (
    <S.Container>
      <HeaderActions
        title="editar produto"
        returnButtonAction={() => {
          history.goBack()
        }}
        hasReturnButton={true}
        renderCustomRightCorner={<></>}
      />
      <FormProduct
        edit={true}
        routeItems={location.state?.routeItems}
        hideDelete={!permissions.exclude}
        hideUpdate={!permissions.write}
      />
    </S.Container>
  )
}
