/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRouter } from 'hooks/useRouter'
import { useLoading } from 'hooks/useLoading'
import { useModal } from 'providers/ModalProvider'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState
} from 'react'
import { CommonModal } from 'templates/Modals/CommonModal'
import CompanyService from 'services/remote/v1/Company/CompanyService'
import { UpdateCompanyReqBody } from 'services/remote/v1/Company/CompanyServiceTypes'
import { TagType } from 'components/InputList'
import { useToast } from 'hooks/useToast'
import { useAppData } from 'providers/AppDataProvider'

interface CompanyContextData {
  categoriesRequired: boolean
  tagsCategories: TagType[]
  handleGoBack: () => void
  handleDelete: (companyId: string) => void
  addCategoryTag: (tag: TagType) => void
  removeCategoryTag: (tag: TagType) => void
  validationCategories: (flag: boolean) => void
  handleEditSubmit: (
    uuid: string,
    company: UpdateCompanyReqBody
  ) => Promise<void>
  handleCreateSubmit: (company: UpdateCompanyReqBody) => Promise<void>
  addListOfCategories: (tagList: TagType[]) => void
}
const CompanyContext = createContext({} as CompanyContextData)

interface CompanyProviderProps {
  children: ReactNode
}

export const CompanyProvider = ({ children }: CompanyProviderProps) => {
  const { history } = useRouter()
  const { dispatch } = useModal()
  const { setLoading } = useLoading()
  const { showToast } = useToast()
  const {
    updateSelectedCompanyUuid,
    updateSelectedUnit,
    selectedUnit,
    data: appData,
    updateFirstAccessData
  } = useAppData()

  const [categoriesRequired, setCategoriesRequired] = useState(false)
  const [tagsCategories, setTagsCategories] = useState<TagType[]>([])

  const handleEditSubmit = async (
    uuid: string,
    company: UpdateCompanyReqBody
  ) => {
    try {
      await CompanyService.updateCompanyByUuid(uuid, company)
    } catch (err: any) {
      throw new Error(err)
    }
  }

  const handleCreateSubmit = async (company: UpdateCompanyReqBody) => {
    try {
      await CompanyService.createCompany(company)
    } catch (err) {
      throw new Error(err)
    }
  }

  const addCategoryTag = useCallback(
    (tag: TagType) => {
      const index = tagsCategories.findIndex(
        (eachCategory) => tag.value.toLowerCase() === eachCategory.value
      )

      if (index >= 0) return

      setTagsCategories([
        ...tagsCategories,
        { value: tag.value.toLowerCase(), id: tag.id }
      ])
    },
    [tagsCategories]
  )

  const addListOfCategories = useCallback((list: TagType[]) => {
    setTagsCategories(list)
  }, [])

  const removeCategoryTag = (tag: TagType) => {
    const updatedTags = tagsCategories.filter(
      (tmpTag) => tmpTag.value !== tag.value
    )
    setTagsCategories(updatedTags)
  }

  const validationCategories = (flag: boolean) => {
    setCategoriesRequired(flag)
  }

  const handleGoBack = () => {
    history.goBack()
  }

  const handleDeleteCompany = async (companyId: string) => {
    try {
      dispatch({ action: 'CLOSE_MODAL' })
      setLoading(true)
      await CompanyService.deleteCompanyBydUuid(companyId)

      await updateFirstAccessData()

      if (selectedUnit.companyUuid === companyId) {
        const newSelectedCompany = appData?.companies.find(
          (eachCompany) => eachCompany.uuid !== companyId
        )

        if (newSelectedCompany) {
          updateSelectedUnit(
            newSelectedCompany.uuid,
            newSelectedCompany.units[0].uuid
          )
          updateSelectedCompanyUuid(newSelectedCompany.uuid)
        }
      }

      showToast({
        title: 'Empresa removida',
        message: 'Sua empresa foi removida com sucesso',
        type: 'success'
      })

      history.goBack()
    } catch (err) {
      showToast({
        title: 'Oops! Algo deu errado',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  function handleDelete(companyId: string) {
    dispatch({
      action: 'OPEN_MODAL',
      component: (
        <CommonModal
          title="excluir empresa"
          description="Tem certeza que deseja excluir essa empresa? 
           Uma vez excluída, você não poderá acessá-la novamente e todos os membros perderão acesso."
          primaryButtonTitle="Excluir empresa"
          primaryButtonAction={() => handleDeleteCompany(companyId)}
          primaryButtonProps={{
            variant: 'text',
            color: 'error'
          }}
          secondaryButtonTitle="cancelar"
          secondaryButtonProps={{
            backgroundColor: 'blue800',
            color: 'white'
          }}
          secondaryButtonAction={() => dispatch({ action: 'CLOSE_MODAL' })}
        />
      )
    })
  }

  return (
    <CompanyContext.Provider
      value={{
        categoriesRequired,
        tagsCategories,
        validationCategories,
        addCategoryTag,
        removeCategoryTag,
        handleGoBack,
        handleDelete,
        handleEditSubmit,
        addListOfCategories,
        handleCreateSubmit
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

export const useCompanyContext = (): CompanyContextData => {
  const context = useContext(CompanyContext)
  return context
}
