/* eslint-disable @typescript-eslint/no-explicit-any */
import useForm from 'hooks/useForm'
import faker from 'faker-br'

import BNPLService from 'services/remote/v1/BNPL/BNPLService'

import * as S from './BNPLOffers.styles'
import { HeaderActions } from 'components/HeaderActions'
import { Input } from 'components/Input'
import { Form } from 'components/Form'
import { FillFormButton } from 'components/FillFormButton'

import { cpfMask } from 'utils/formatters/masks'
import { unmask } from 'utils/formatters/unmask'
import { Button } from 'components/Button'
import { validateCpf } from 'utils/validators/validateCpf'
import { useToast } from 'hooks/useToast'

interface FormValues {
  cpf: string
}

export const BNPLOffers = () => {
  const { showToast } = useToast()

  const checkDocumentOffers = async (data: FormValues) => {
    showToast({
      title: 'Oferta Dotz Parcela',
      message: 'Estamos consultando este CPF, aguarde um pouco.',
      type: 'warning'
    })

    try {
      const bnplResponse = await BNPLService.getOffersByDocument(
        unmask(data.cpf)
      )

      const responseData = bnplResponse.data.data

      showToast({
        title: 'Oferta Dotz Parcela',
        message: responseData.offers_found
          ? 'Parabéns! Este cliente possui oferta aprovada. Instrua o cliente a autorizar o limite no App Dotz!'
          : 'No momento, não encontram-se ofertas aprovadas. Use o App Dotz e tenha mais chances de ter benefícios em suas compras!',
        type: responseData.offers_found ? 'success' : 'error'
      })
    } catch (err: any) {
      console.log(err)

      if (err?.response?.status == 403) {
        return showToast({
          message: 'Você não possui permissão para executar a ação',
          title: '',
          type: 'error'
        })
      } else if (err?.response?.status == 500) {
        return showToast({
          message: 'Infelizmente este cliente não possui ofertas disponiveis.',
          title: 'Oferta Dotz Parcela',
          type: 'error'
        })
      }

      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: '',
        type: 'error'
      })
    }
  }

  const { data, errors, handleSubmit, handleChange, updateFormData } =
    useForm<FormValues>({
      initialValues: {
        cpf: ''
      },
      validations: {
        cpf: {
          required: {
            value: true,
            message: 'Este campo é obrigatório'
          },
          custom: {
            message: 'Informe um CPF válido',
            isValid: (value: string) => validateCpf(value)
          }
        }
      },
      onSubmit: () => checkDocumentOffers(data)
    })

  const fillWithMockedData = () => {
    const mockedCpf = faker.br.cpf()
    updateFormData({
      cpf: cpfMask(mockedCpf)
    })
  }

  return (
    <S.Container>
      <HeaderActions
        title="Ofertas Dotz Parcela"
        hasReturnButton={false}
        renderCustomRightCorner={<></>}
      />
      <Form _flex={{ direction: 'column' }} onSubmit={handleSubmit}>
        <S.ContentBoxContainer>
          <S.FormTitle>Sobre o cliente</S.FormTitle>

          <Input
            name="cpf"
            animatedLabel="Insira o CPF do cliente"
            message={errors.cpf}
            value={data.cpf}
            onChange={handleChange('cpf', cpfMask)}
          />
        </S.ContentBoxContainer>

        <S.ButtonGroup>
          <Button
            color="white"
            backgroundColor="dotzOrange"
            onClick={() => null}
            _sizing={{
              width: '12rem'
            }}
            _spacing={{
              paddingLeft: 'auto'
            }}
          >
            Consultar
          </Button>
        </S.ButtonGroup>
      </Form>
      {process.env.REACT_APP_CURRENT_ENV !== 'PROD' && (
        <FillFormButton onClick={fillWithMockedData} />
      )}
    </S.Container>
  )
}
