import styled, { css } from 'styled-components'
import { spin } from 'layout/animations'

import { renderRestTypes } from 'components/typings/utils/renderRestTypes'
import { InputSelectProps } from './InputSelect'
import { Input } from 'components/Input/Input'

interface AnimatedIconWrapper {
  isOpened: boolean
}

interface SelectItemProps {
  isSelected: boolean
}

export const Container = styled.div<Partial<InputSelectProps>>`
  ${({ _flex, _layout, _positioning, _sizing, _spacing }) => css`
    display: flex;
    flex-direction: column;
    position: relative;

    ${renderRestTypes({
      _flex: _flex,
      _layout: _layout,
      _positioning: _positioning,
      _sizing: _sizing,
      _spacing: _spacing
    })}
  `}
`

export const Inputt = styled(Input)`
  ${({ hideCursor }) =>
    hideCursor &&
    css`
      ::-webkit-search-decoration,
      ::-webkit-search-cancel-button,
      ::-webkit-search-results-button,
      ::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }
      cursor: default;
      caret-color: transparent;

      :not(:focus) {
        ::placeholder {
          color: transparent;
        }
      }
    `}
`

export const SelectList = styled.ul`
  position: absolute;
  top: calc(100%);
  left: 0;
  width: 100%;
  z-index: 9999;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 0 0.75rem #e2e2e2;
`

export const SelectItem = styled.li<SelectItemProps>`
  ${({ theme, isSelected }) => css`
    align-items: center;
    background: ${theme.colors.white};
    border-radius: 4px;
    border-bottom: 1px solid #e2e2e2;
    color: ${theme.colors.text};
    display: flex;
    font-size: 14px;
    padding: 1rem 1.2rem;
    width: 100%;

    ${isSelected &&
    css`
      filter: brightness(0.98);
    `}

    &:hover {
      cursor: pointer;
      filter: brightness(0.9);
    }
  `}
`
export const AnimatedIconWrapper = styled.div<AnimatedIconWrapper>`
  transform: rotate(${({ isOpened }) => (isOpened ? '-180deg' : '0')});
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
`

export const Spinner = styled.div`
  ${({ theme }) => css`
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-left-color: ${theme.colors.noverde};
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: ${spin} 1s linear infinite;
  `}
`
