/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useCallback } from 'react'
import Skeleton from 'react-loading-skeleton'

import { useRouter } from 'hooks/useRouter'
import { useLoading } from 'hooks/useLoading'
import { useAppData } from 'hooks/useAppData'
import { useModal } from 'providers/ModalProvider'

import { CommonModal } from 'templates/Modals/CommonModal'

import { HeaderActions } from 'components/HeaderActions'
import { ListTable } from 'components/ListTable'
import { ToggleSwitcher } from 'components/ToggleSwitcher'
import Assets from 'assets'

import { ROUTES_NAME } from 'routes'
import * as S from './Profile.styles'

import { ProfileDTO } from 'services/remote/v1/Profile/ProfileServiceTypes'
import CompanyService from 'services/remote/v1/Company/CompanyService'
import { format } from 'date-fns'
import { formatZonedTimeToUtc } from 'utils/formatters/fomatZonedTimeToUtc'
import { getResourcePermissions } from 'utils/getResourcePermissions'
import { PermissionsDTO } from 'models'
import { TooltipText } from 'components/TooltipText'

type RenderItemProps = {
  item: ProfileDTO
  canEdit?: boolean
}

export const Profile = () => {
  const [profiles, setProfiles] = useState<ProfileDTO[]>([])
  const [permissions, setPermissions] = useState<PermissionsDTO>(
    {} as PermissionsDTO
  )

  const { selectedCompanyUuid, data: appData } = useAppData()

  const { isLoading, setLoading } = useLoading()

  const { history } = useRouter()

  const { dispatch } = useModal()

  const init = useCallback(async () => {
    if (!selectedCompanyUuid) return

    try {
      setLoading(true)
      const { data } = await CompanyService.getAllProfilesInCompanyByUuid(
        selectedCompanyUuid,
        true
      )

      setProfiles(data.data)
    } catch (err: any) {
      dispatch({
        action: 'OPEN_MODAL',
        component: (
          <CommonModal
            title="Oops! Algo deu errado"
            description={
              err?.response?.data?.error?.messages_client[0] || err.message
            }
            primaryButtonTitle="voltar"
            primaryButtonProps={{
              fontSize: '1rem',
              backgroundColor: 'noverde'
            }}
            primaryButtonAction={() => {
              dispatch({ action: 'CLOSE_MODAL' })
            }}
          />
        )
      })
    } finally {
      setLoading(false)
    }
  }, [selectedCompanyUuid, setLoading, dispatch])

  useEffect(() => {
    init()
  }, [init])

  useEffect(() => {
    const _permissions = getResourcePermissions(appData, 'CAD', 'CAD_PER')

    if (_permissions) setPermissions(_permissions)
  }, [appData])

  const handleEditButton = (profile: ProfileDTO) => {
    history.push({
      pathname: ROUTES_NAME.PROFILE_EDIT,
      state: {
        selectedProfile: profile,
        parent: profile.parent
      }
    })
  }

  const handleCreateProfile = () => {
    history.push(ROUTES_NAME.PROFILE_CREATE)
  }

  const renderItem = ({ item, canEdit }: RenderItemProps) => {
    const formattedDate = item?.created_at
      ? format(
          new Date(formatZonedTimeToUtc(item?.created_at) || new Date()),
          'dd/MM/yyyy'
        )
      : ''
    return (
      <>
        <span>
          <TooltipText text={item?.name}>
            {item?.name || <Skeleton width={200} />}
          </TooltipText>
        </span>
        <span>
          <TooltipText text={formattedDate}>
            {item?.created_at ? formattedDate : <Skeleton width={200} />}
          </TooltipText>
        </span>
        <span title={item?.created_by}>
          <TooltipText text={item.created_by || ''}>
            {item?.created_by ? item.created_by : <Skeleton width={200} />}{' '}
          </TooltipText>
        </span>
        {!item.is_default ? (
          <ToggleSwitcher
            disabled
            isActive={isLoading ? false : item.is_active}
            toggleActive={() => null}
          />
        ) : (
          <span />
        )}

        {canEdit && !item.is_default ? (
          <S.EditButton onClick={() => handleEditButton(item)}>
            <Assets
              assetProps={{ key: 'lineEditV2', type: 'icon' }}
              alt="editar"
            />
          </S.EditButton>
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <S.Container>
      <HeaderActions
        title="perfil"
        buttonTitle="+ novo perfil"
        buttonAction={handleCreateProfile}
        hasReturnButton={false}
        renderCustomRightCorner={!permissions.write ? <></> : null}
      />

      <ListTable<ProfileDTO>
        tableStructure={{
          header:
            '1fr minmax(200px, 200px) 1fr minmax(60px, 60px) minmax(60px, 60px)',
          body: '1fr minmax(200px, 200px) 1fr minmax(60px, 60px) minmax(60px, 60px)',
          columns: ['nome', 'criado em', 'criado por', 'ativo', 'editar']
        }}
        data={profiles.length === 0 ? Array(4).fill({}) : profiles}
        renderItem={renderItem}
        keyExtractor={(item) => item.uuid || String(Math.random())}
        canEdit={permissions.write || permissions.exclude}
      />
    </S.Container>
  )
}
