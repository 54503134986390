export const states = [
  { id: '1', value: 'AC' },
  { id: '2', value: 'AL' },
  { id: '3', value: 'AP' },
  { id: '4', value: 'AM' },
  { id: '5', value: 'BA' },
  { id: '6', value: 'CE' },
  { id: '7', value: 'DF' },
  { id: '8', value: 'ES' },
  { id: '9', value: 'GO' },
  { id: '10', value: 'MA' },
  { id: '11', value: 'MT' },
  { id: '12', value: 'MS' },
  { id: '13', value: 'MG' },
  { id: '14', value: 'PA' },
  { id: '15', value: 'PB' },
  { id: '16', value: 'PR' },
  { id: '17', value: 'PE' },
  { id: '18', value: 'PI' },
  { id: '19', value: 'RJ' },
  { id: '20', value: 'RN' },
  { id: '21', value: 'RS' },
  { id: '22', value: 'RO' },
  { id: '23', value: 'RR' },
  { id: '24', value: 'SC' },
  { id: '25', value: 'SP' },
  { id: '26', value: 'SE' },
  { id: '27', value: 'TO' }
]
