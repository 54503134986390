import styled, { css } from 'styled-components'

interface ContainerProps {
  maxWidth?: string
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
`

export const Dropzone = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 120px;
    background-color: #eaf0f4;
    border-radius: 8px;
    border: 2px dashed ${theme.colors.blue500};
    background-clip: padding-box;
    flex-direction: column;

    p {
      color: #828282;
      letter-spacing: 0.16px;
      margin-top: 8px;

      span {
        font-weight: 600;
        color: ${theme.colors.blue500};
      }
    }
  `}
`

export const UploadRules = styled.div`
  margin-top: 24px;
  max-width: 500px;

  span {
    display: block;
    color: #646e7d;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.174103px;
  }
`

export const UploadPreview = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #eaf0f4;
  flex-direction: column;
  cursor: pointer;

  img {
    max-height: 40px;
  }
`

export const UploadPreviewFileName = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin: 16px auto 0;
  gap: 10px;
`

export const ErrorMessage = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-size: 12px;
    margin-top: 5px;
  `}
`

export const RemoveIcon = styled.div`
  cursor: pointer;
`
