/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { ROUTES_NAME } from 'routes'

import { Button } from 'components/Button'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { Text } from 'components/Text'
import { ToggleSwitcher } from 'components/ToggleSwitcher'
import useForm from 'hooks/useForm'
import { useRouter } from 'hooks/useRouter'
import { TagType, useTags } from 'hooks/useTags'
import { useToast } from 'hooks/useToast'
import { useAppData } from 'providers/AppDataProvider'
import { useLoading } from 'providers/LoadingProvider'
import { useModal } from 'providers/ModalProvider'
import CompanyService from 'services/remote/v1/Company/CompanyService'
import UserService from 'services/remote/v1/User/UserService'
import { AutoComplete } from 'components/Autocomplete'
import {
  UpdateUserReqBody,
  UserDTO
} from 'services/remote/v1/User/UserServiceTypes'
import { CommonModal } from 'templates/Modals/CommonModal'
import { sleep } from 'utils/sleep'
import { validateEmail } from 'utils/validators/validateEmail'
import { validateFullName } from 'utils/validators/validateFullName'
import { onlyText } from 'utils/formatters/only-text'

import * as S from './FormUsers.styles'
import { useLocationState } from 'hooks/useLocationState'

interface FormValues {
  name: string
  email: string
}

interface FormUsersProps {
  edit?: boolean
  editData?: UserDTO
  hideDelete?: boolean
  hideUpdate?: boolean
}

export const FormUsers = ({
  editData = {} as UserDTO,
  edit = false,
  hideDelete = false,
  hideUpdate = false
}: FormUsersProps) => {
  const { selectedCompanyUuid } = useAppData()
  const { history } = useRouter()
  const { setLoading } = useLoading()
  const { dispatch } = useModal()
  const { showToast } = useToast()
  const { clearLocationStateAndReturn } = useLocationState()

  // pra preencher a lista nos selects
  const [profilesList, setProfilesList] = useState<TagType[]>([])
  const [unitsList, setUnitsList] = useState<TagType[]>([])
  const [profileInputFilter, setProfileInputFilter] = useState('')
  const [unitInputFilter, setUnitInputFilter] = useState('')

  const [isActive, setIsActive] = useState(
    editData?.is_active !== undefined ? editData?.is_active : true
  )

  // pra manipular o array de tags
  const [tagsFiliasData] = useState<TagType[]>(
    editData?.units?.map((eachUnity) => ({
      id: String(eachUnity.id).toLowerCase(),
      value: eachUnity.name.toLowerCase()
    })) || []
  )
  const [tagsPerfisData] = useState<TagType[]>(
    editData?.profiles?.map((eachProfile) => ({
      id: String(eachProfile.id).toLowerCase(),
      value: eachProfile.name.toLowerCase()
    })) || []
  )

  const {
    addTag: addTagFilial,
    removeTag: removeTagFilial,
    tags: tagsFiliais
  } = useTags({
    tagsProps: tagsFiliasData
  })

  const {
    addTag: addTagPerfil,
    removeTag: removeTagPerfil,
    tags: tagsPerfis
  } = useTags({
    tagsProps: tagsPerfisData
  })

  const [customErrors, setCustomErrors] = useState({
    profiles: false,
    unit: false
  })

  const handleSubmitUpdate = async ({ email, name }: FormValues) => {
    try {
      setLoading(true)
      const updatedUserData: UpdateUserReqBody = {
        email,
        name,
        is_active: isActive,
        units: tagsFiliais.map((eachUnit) => ({
          id: Number(eachUnit.id)
        })),
        profiles: tagsPerfis.map((eachProfile) => ({
          id: Number(eachProfile.id)
        }))
      }

      await UserService.updateProfileByUuid(
        selectedCompanyUuid || '',
        editData.uuid || '',
        updatedUserData
      )
      showToast({
        message: 'Usuário atualizado com sucesso',
        title: 'Tudo certo!',
        type: 'success'
      })
      clearLocationStateAndReturn(ROUTES_NAME.USERS)
    } catch (err: any) {
      setLoading(false)
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: 'Oops! Algo deu errado.',
        type: 'error'
      })
    }
  }

  const handleDeleteUser = async () => {
    try {
      setLoading(true)
      await UserService.deleteProfileByUuid(
        selectedCompanyUuid || '',
        editData.uuid
      )
      await sleep(500)
      dispatch({ action: 'CLOSE_MODAL' })
      showToast({
        message: 'Usuário excluído com sucesso',
        title: 'Tudo certo!',
        type: 'success'
      })
      clearLocationStateAndReturn(ROUTES_NAME.USERS)
    } catch (err: any) {
      setLoading(false)
      dispatch({ action: 'CLOSE_MODAL' })
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: 'Oops! Algo deu errado',
        type: 'error'
      })
    }
  }

  const openModalToDelete = () => {
    dispatch({
      action: 'OPEN_MODAL',
      component: (
        <CommonModal
          title="excluir"
          description={
            <>
              <Text>
                Tem certeza que deseja excluir esse usuário? <br />
                Uma vez excluído, você não poderá acessá-lo novamente.
              </Text>
            </>
          }
          primaryButtonTitle="excluir"
          primaryButtonProps={{ fontSize: '1rem', backgroundColor: 'error' }}
          primaryButtonAction={() => handleDeleteUser()}
          secondaryButtonTitle="cancelar"
          secondaryButtonProps={{
            variant: 'text',
            color: 'galaxy',
            withoutUnderline: true,
            _spacing: {
              padding: '0'
            }
          }}
          secondaryButtonAction={() => dispatch({ action: 'CLOSE_MODAL' })}
        />
      )
    })
  }

  const handleSubmitInvite = async ({ email, name }: FormValues) => {
    try {
      setLoading(true)
      const newUser = {
        name,
        email,
        is_active: isActive,
        profiles: tagsPerfis.map((eachProfile) => ({
          ...eachProfile,
          id: Number(eachProfile.id)
        })),
        units: tagsFiliais.map((eachUnity) => ({
          ...eachUnity,
          id: Number(eachUnity.id)
        }))
      }

      await CompanyService.createUserToCompanyByUuid(
        selectedCompanyUuid || '',
        newUser
      )

      history.goBack()
      showToast({
        message: 'Usuário criado com sucesso',
        title: 'Tudo certo!',
        type: 'success'
      })
    } catch (err: any) {
      setLoading(false)
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: 'Oops! Algo deu errado',
        type: 'error'
      })
    }
  }

  const { handleSubmit, errors, data, handleChange } = useForm<FormValues>({
    initialValues: {
      email: editData.email,
      name: editData.name
    },
    validations: {
      name: {
        custom: {
          message: 'Insira o seu nome e sobrenome',
          isValid: (value: string) => validateFullName(value)
        },
        required: {
          message: 'Não esqueça o nome do perfil',
          value: true
        }
      },
      email: {
        custom: {
          message: 'Formato inválido',
          isValid: (value: string) => validateEmail(value)
        },
        required: {
          message: 'Não esqueça o seu email',
          value: true
        }
      }
    },
    onSubmit: async () => {
      if (!tagsFiliais.length || !tagsPerfis.length) return

      if (edit) {
        await handleSubmitUpdate(data)
        return
      }
      await handleSubmitInvite({
        email: data.email,
        name: data.name
      })
    }
  })

  useEffect(() => {
    const init = async () => {
      if (!selectedCompanyUuid) return

      try {
        setLoading(true)
        const [profilesResponse, unitsResponse] = await Promise.all([
          CompanyService.getAllProfilesInCompanyByUuid(selectedCompanyUuid),
          CompanyService.getAllUnitysInCompanyByUuid(selectedCompanyUuid)
        ])

        const reducedProfileList = profilesResponse.data.data.map(
          (eachProfile) => ({
            id: String(eachProfile.id || ''),
            value: eachProfile.name
          })
        )

        const reducedUnityList = unitsResponse.data.data.map((eachUnity) => ({
          id: String(eachUnity.id || ''),
          value: eachUnity.name || ''
        }))

        setProfilesList(reducedProfileList)
        setUnitsList(reducedUnityList)
      } catch {
        setLoading(false)
        history.goBack()
        showToast({
          message:
            'A tela que você tentou acessar está indisponível no momento.',
          title: 'Indisponível',
          type: 'info'
        })
      } finally {
        setLoading(false)
      }
    }
    init()
  }, [history, selectedCompanyUuid, setLoading, showToast])

  return (
    <Form
      _flex={{
        direction: 'column',
        gap: '1rem'
      }}
      _spacing={{
        marginTop: '38px'
      }}
      onSubmit={(e) => {
        const error = customErrors
        error.profiles = !tagsPerfis.length
        error.unit = !tagsFiliais.length
        setCustomErrors(error)
        handleSubmit(e)
      }}
    >
      <S.ContainerInviteData>
        <Text type="subheading-1">Sobre o convidado</Text>

        <S.ContainerInviteInputs>
          <Input
            isRequired
            name="name"
            message={errors.name}
            animatedLabel="nome e sobrenome"
            value={data?.name}
            onChange={handleChange('name', onlyText)}
          />
          <section>
            <Input
              isRequired
              name="email"
              message={errors.email}
              animatedLabel="email"
              type="email"
              onChange={handleChange('email')}
              value={data?.email}
            />
            <S.SwitcherWrapper>
              <label htmlFor="">ativo</label>
              <ToggleSwitcher
                isActive={isActive}
                toggleActive={() => setIsActive(!isActive)}
              />
            </S.SwitcherWrapper>
          </section>
        </S.ContainerInviteInputs>
        <S.ContainerInviteInputs>
          <AutoComplete
            multiple
            tagOptions={{
              color: 'blue400',
              backgroundColor: 'blue10'
            }}
            options={profilesList}
            inputValue={profileInputFilter}
            tags={tagsPerfis}
            onAddTag={(tag) => {
              addTagPerfil(tag)
              setCustomErrors((prev) => ({ ...prev, profiles: false }))
            }}
            onRemoveTag={removeTagPerfil}
            onInputChange={(value: string) => {
              setProfileInputFilter(value)
            }}
            errorMessage={customErrors.profiles ? 'selecione um perfil' : ''}
            renderInput={(params) => (
              <Input
                name="perfis"
                placeholder="escolher perfis..."
                animatedLabel="perfil(s) *"
                {...params}
              />
            )}
          />

          <AutoComplete
            multiple
            tagOptions={{
              color: 'green400',
              backgroundColor: 'green10'
            }}
            options={unitsList}
            inputValue={unitInputFilter}
            tags={tagsFiliais}
            onAddTag={(tag) => {
              addTagFilial(tag)
              setCustomErrors((prev) => ({ ...prev, unit: false }))
            }}
            onRemoveTag={removeTagFilial}
            onInputChange={(value: string) => {
              setUnitInputFilter(value)
            }}
            errorMessage={customErrors.profiles ? 'selecione uma filial' : ''}
            renderInput={(params) => (
              <Input
                name="filiais"
                placeholder="escolher filiais..."
                animatedLabel="filial(s) *"
                {...params}
              />
            )}
          />
        </S.ContainerInviteInputs>
      </S.ContainerInviteData>
      <S.ButtonGroup>
        {edit && !hideDelete && (
          <Button
            type="button"
            _sizing={{
              width: '14rem'
            }}
            backgroundColor="error"
            onClick={openModalToDelete}
          >
            excluir
          </Button>
        )}
        {!hideUpdate && (
          <Button
            variant="primary"
            _sizing={{
              width: '14rem'
            }}
          >
            {edit ? 'atualizar' : 'salvar'}
          </Button>
        )}
      </S.ButtonGroup>
    </Form>
  )
}
