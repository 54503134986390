/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'

import { Button } from 'components/Button'
import { Form } from 'components/Form/Form'
import { Input } from 'components/Input'
import { Text } from 'components/Text'
import { ToggleSwitcher } from 'components/ToggleSwitcher'
import useForm from 'hooks/useForm'
import { useRouter } from 'hooks/useRouter'
import { useToast } from 'hooks/useToast'
import { useLoading } from 'providers/LoadingProvider'
import { ROUTES_NAME } from 'routes'

import * as S from './FormReason.styles'
import { ReasonDTO } from 'services/remote/v1/Reason/ReasonServiceTypes'
import { UnityDTO } from 'services/remote/v1/Unit/UnitServiceTypes'
import CompanyService from 'services/remote/v1/Company/CompanyService'
import { useAppData } from 'providers/AppDataProvider'
import ReasonService from 'services/remote/v1/Reason/ReasonService'
import { CommonModal } from 'templates/Modals/CommonModal'
import { useModal } from 'providers/ModalProvider'
import { useLocationState } from 'hooks/useLocationState'

interface FormValues {
  reason: string
}

export interface FormReasonRouteProps {
  routeItems: UnityDTO
}

interface FormReasonProps {
  edit?: boolean
  routeItems?: ReasonDTO
  hideDelete?: boolean
  hideUpdate?: boolean
}

export const FormReason = ({
  edit = false,
  routeItems,
  hideDelete = false,
  hideUpdate = false
}: FormReasonProps) => {
  const { history, location } = useRouter<FormReasonRouteProps>()
  const { setLoading } = useLoading()
  const { selectedCompanyUuid } = useAppData()
  const { showToast } = useToast()
  const { dispatch } = useModal()

  const { clearLocationStateAndReturn } = useLocationState()

  const [toggleActive, setToggleActive] = useState(
    routeItems?.is_active === undefined ? true : routeItems?.is_active
  )

  const { data, errors, handleChange, handleSubmit } = useForm<FormValues>({
    initialValues: {
      reason: routeItems?.name || ''
    },
    validations: {
      reason: {
        required: {
          value: true,
          message: 'Você precisa informar um motivo'
        }
      }
    },
    onSubmit: async () => {
      if (edit) {
        handleEditReason()
        return
      }
      handleCreateReason()
    }
  })

  async function handleCreateReason() {
    try {
      setLoading(true)
      await CompanyService.createReasonToCompanyByUuid(
        selectedCompanyUuid || '',
        {
          is_active: toggleActive,
          name: data.reason
        }
      )
      showToast({
        message: 'Seu motivo foi criado com sucesso',
        title: 'Motivo Criado',
        type: 'success'
      })
      clearLocationStateAndReturn(ROUTES_NAME.REASON)
    } catch (err: any) {
      setLoading(false)
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: '',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  async function handleEditReason() {
    try {
      setLoading(true)
      await ReasonService.updateReasonByUuid(routeItems?.uuid || '', {
        is_active: toggleActive,
        name: data.reason
      })
      showToast({
        message: 'Seu motivo foi atualizado com sucesso',
        title: 'Motivo Atualizado',
        type: 'success'
      })
      history.goBack()
    } catch (err: any) {
      setLoading(false)
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: '',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const handleRemoveReason = () => {
    dispatch({
      action: 'OPEN_MODAL',
      component: (
        <CommonModal
          title="excluir motivo"
          description="Tem certeza que deseja excluir esse motivo? Uma vez excluído, você não poderá acessá-lo novamente."
          primaryButtonTitle="excluir motivo"
          primaryButtonProps={{ fontSize: '1rem', backgroundColor: 'error' }}
          primaryButtonAction={() => handleDeleteReason()}
          secondaryButtonTitle="cancelar"
          secondaryButtonProps={{
            variant: 'text',
            color: 'galaxy',
            withoutUnderline: true,
            _spacing: {
              padding: '0'
            }
          }}
          secondaryButtonAction={() => dispatch({ action: 'CLOSE_MODAL' })}
        />
      )
    })
  }

  async function handleDeleteReason() {
    try {
      setLoading(true)
      await ReasonService.deleteReasonByUuid({
        uuid: routeItems?.uuid || ''
      })
      showToast({
        message: 'Seu motivo foi excluído com sucesso',
        title: 'Motivo Excluído',
        type: 'success'
      })
      clearLocationStateAndReturn(ROUTES_NAME.REASON)
    } catch (err: any) {
      setLoading(false)
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: '',
        type: 'error'
      })
    } finally {
      dispatch({ action: 'CLOSE_MODAL' })
      setLoading(false)
    }
  }

  function handleToggleSwitcher() {
    setToggleActive(!toggleActive)
  }

  useEffect(() => {
    if (!location.state?.routeItems && edit) {
      history.push(ROUTES_NAME.UNITS)
    }
    return () => {
      setLoading(false)
    }
  }, [edit, history, location.state?.routeItems, setLoading])

  return (
    <Form
      _flex={{
        direction: 'column',
        gap: '1rem'
      }}
      onSubmit={handleSubmit}
    >
      <S.Box>
        <Text
          type="subheading-1"
          _spacing={{
            marginBottom: '1.5rem'
          }}
        >
          Sobre o motivo
        </Text>

        <S.InputGroup>
          <Input
            name="reason"
            animatedLabel="motivo"
            isRequired
            message={errors.reason}
            onChange={handleChange('reason')}
            value={data.reason || ''}
          />
          <S.SwitchGroup>
            <label htmlFor="">ativo</label>
            <ToggleSwitcher
              isActive={toggleActive}
              toggleActive={handleToggleSwitcher}
            />
          </S.SwitchGroup>
        </S.InputGroup>
      </S.Box>
      <S.ButtonGroup>
        {!hideUpdate && (
          <Button
            _sizing={{
              width: '12rem'
            }}
          >
            {edit ? 'Atualizar' : 'Salvar'}
          </Button>
        )}
        {edit && !hideDelete && (
          <Button
            backgroundColor="error"
            color="white"
            type="button"
            _sizing={{
              width: '12rem'
            }}
            onClick={handleRemoveReason}
          >
            Excluir
          </Button>
        )}
      </S.ButtonGroup>
    </Form>
  )
}
