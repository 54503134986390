import { Profile, ProfileCreate, ProfileEdit } from 'pages/Profile'
import {
  Company,
  CompanyEditWrapper,
  CompanyCreateWrapper
} from 'pages/Company'
import { Unit } from 'pages/Unit'
import { UnitCreate } from 'pages/Unit/UnitCreate'
import { UnitEdit } from 'pages/Unit/UnitEdit'
import { Product } from 'pages/Product'
import { ProductCreate } from 'pages/Product/ProductCreate'
import { ProductEdit } from 'pages/Product/ProductEdit'
import { Attendance } from 'pages/Attendance'
import { AttendanceCustomerInfo } from 'pages/AttendanceCustomerInfo'
import { AttendancePreAnalisys } from 'pages/AttendancePreAnalisys'
import { AttendanceSimulation } from 'pages/AttendanceSimulation'
import { AttendanceSimulationApproved } from 'pages/AttendanceSimulationApproved'
import { AttendanceProposalFollowUp } from 'pages/AttendanceProposalFollowUp'
import { AttendanceSimulationDenied } from 'pages/AttendanceSimulationDenied'
import { AttendanceHistory } from 'pages/AttendanceHistory'
import { Users } from 'pages/Users'
import { UsersEdit } from 'pages/Users/UsersEdit'
import { UsersCreate } from 'pages/Users/UsersCreate'
import { BNPLOffers } from 'pages/BNPLOffers/BNPLOffers'

import { ROUTES_NAME } from './routesName'

type RouteDataType = {
  path: string
  component: JSX.Element
}

export const protectedRoutesList = [
  {
    path: ROUTES_NAME.PROFILE,
    component: <Profile />,
    subroutes: [
      {
        path: ROUTES_NAME.PROFILE_CREATE,
        component: <ProfileCreate />
      },
      {
        path: ROUTES_NAME.PROFILE_EDIT,
        component: <ProfileEdit />
      }
    ]
  },

  {
    path: ROUTES_NAME.USERS,
    component: <Users />,
    subroutes: [
      {
        path: ROUTES_NAME.USERS_EDIT_USER,
        component: <UsersEdit />
      },
      {
        path: ROUTES_NAME.USERS_INVITE_USER,
        component: <UsersCreate />
      }
    ]
  },

  {
    path: ROUTES_NAME.COMPANY,
    component: <Company />,
    subroutes: [
      {
        path: ROUTES_NAME.COMPANY_CREATE,
        component: <CompanyCreateWrapper />
      },
      {
        path: ROUTES_NAME.COMPANY_EDIT,
        component: <CompanyEditWrapper />
      }
    ]
  },

  {
    path: ROUTES_NAME.UNITS,
    component: <Unit />,
    subroutes: [
      {
        path: ROUTES_NAME.UNITS_CREATE,
        component: <UnitCreate />
      },
      {
        path: ROUTES_NAME.UNITS_EDIT,
        component: <UnitEdit />
      }
    ]
  },

  {
    path: ROUTES_NAME.PRODUCT,
    component: <Product />,
    subroutes: [
      {
        path: ROUTES_NAME.PRODUCT_CREATE,
        component: <ProductCreate />
      },
      {
        path: ROUTES_NAME.PRODUCT_EDIT,
        component: <ProductEdit />
      }
    ]
  },

  {
    path: ROUTES_NAME.ATTENDANCE,
    component: <Attendance />,
    subroutes: [
      {
        path: ROUTES_NAME.ATTENDANCE_CUSTOMER_INFORMATION,
        component: <AttendanceCustomerInfo />
      },
      {
        path: ROUTES_NAME.ATTENDANCE_PRE_ANALISYS,
        component: <AttendancePreAnalisys />
      },
      {
        path: ROUTES_NAME.ATTENDANCE_SIMULATION,
        component: <AttendanceSimulation />
      },
      {
        path: ROUTES_NAME.ATTENDANCE_SIMULATION_APPROVED,
        component: <AttendanceSimulationApproved />
      },
      {
        path: ROUTES_NAME.ATTENDANCE_SIMULATION_DENIED,
        component: <AttendanceSimulationDenied />
      },
      {
        path: ROUTES_NAME.ATTENDANCE_HISTORY,
        component: <AttendanceHistory />
      },
      {
        path: ROUTES_NAME.ATTENDANCE_PROPOSAL_FOLLOW_UP,
        component: <AttendanceProposalFollowUp />
      }
    ]
  }
]

export const getAllProtectedRoutes = () =>
  protectedRoutesList.reduce<RouteDataType[]>((accumulator, currValue) => {
    const subRoutes = currValue.subroutes?.map((eachSubRoute) => ({
      path: eachSubRoute.path,
      component: eachSubRoute.component
    }))

    return [
      ...accumulator,
      { path: currValue.path, component: currValue.component },
      ...(subRoutes || [])
    ]
  }, [])

export const getRoutesPathList = (protectedRoutes: string[]) => {
  return protectedRoutesList
    .filter((eachRoute) => protectedRoutes.includes(eachRoute.path))
    .reduce<string[]>((accumulator, currValue) => {
      const subRoutes = currValue.subroutes?.map(
        (eachSubRoute) => eachSubRoute.path
      )

      return [...accumulator, currValue.path, ...(subRoutes || [])]
    }, [])
}
