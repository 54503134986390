import React, { memo } from 'react'
import * as S from './Text.styles'
import { TextTags, TextTypes } from './Text.types'
import { PartnersTheme } from '../../layout/theme'
import { RestTypes } from 'components/typings/utils/restTypes'

type TextCustomProps = {
  marginBottom?: string
  marginTop?: string
}

export type TextProps = {
  color?: keyof typeof PartnersTheme.colors
  children: string | React.ReactNode
  weight?: keyof typeof PartnersTheme.font.weight
  size?: string
  tag?: TextTags
  type?: TextTypes
  custom?: TextCustomProps
} & RestTypes

const TextMemo = ({ children, ...props }: TextProps) => {
  return <S.Text {...props}>{children}</S.Text>
}

export const Text = memo(TextMemo)
