import { PartnersTheme } from 'layout/theme'
import { InputHTMLAttributes } from 'react'
import * as S from './InputRadio.styles'

type RadioValue = string | ReadonlyArray<string> | number

export type RadioProps = {
  onCheck?: (value?: RadioValue) => void
  label?: string
  labelColor?: keyof typeof PartnersTheme.colors
  labelFor?: string
  value?: RadioValue
  isDisabled?: boolean
} & InputHTMLAttributes<HTMLInputElement>

export const InputRadio = ({
  label,
  onCheck,
  labelFor = '',
  value,
  disabled,
  ...props
}: RadioProps) => {
  const onChange = () => {
    !!onCheck && onCheck(value)
  }

  return (
    <S.Wrapper>
      <S.Input
        id={labelFor}
        disabled={disabled}
        type="radio"
        value={value}
        onChange={onChange}
        {...props}
      />
      {label && (
        <S.Label isDisabled={disabled} htmlFor={labelFor}>
          {label}
        </S.Label>
      )}
    </S.Wrapper>
  )
}
