import { useEffect, useState } from 'react'

import { FormReason } from 'components/FormReason'
import { HeaderActions } from 'components/HeaderActions'
import { useLocationState } from 'hooks/useLocationState'
import { useRouter } from 'hooks/useRouter'
import { ROUTES_NAME } from 'routes/routesName'
import { ReasonDTO } from 'services/remote/v1/Reason/ReasonServiceTypes'

import * as S from './Reason.styles'
import { useAppData } from 'hooks/useAppData'
import { PermissionsDTO } from 'models/PermissionsDTO'
import { getResourcePermissions } from 'utils/getResourcePermissions'

export interface ReasonEditRouteProps {
  routeItems: ReasonDTO
}

export const ReasonEdit = () => {
  const { history, location } = useRouter<ReasonEditRouteProps>()
  const { verifyLocationState } = useLocationState()
  const { data: appData } = useAppData()
  const [permissions, setPermissions] = useState<PermissionsDTO>(
    {} as PermissionsDTO
  )

  useEffect(() => {
    verifyLocationState(ROUTES_NAME.REASON)
  }, [verifyLocationState])

  useEffect(() => {
    const _permissions = getResourcePermissions(appData, 'CAD', 'CAD_MOT')

    if (_permissions) setPermissions(_permissions)
  }, [appData])

  return (
    <S.Container>
      <HeaderActions
        title="editar motivo"
        hasReturnButton={true}
        returnButtonAction={() => history.goBack()}
        renderCustomRightCorner={<></>}
      />
      <FormReason
        edit
        routeItems={location.state?.routeItems}
        hideDelete={!permissions.exclude}
        hideUpdate={!permissions.write}
      />
    </S.Container>
  )
}
