import { useCallback, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import { Avatar } from 'components/Avatar'
import { ToggleSwitcher } from 'components/ToggleSwitcher'
import { ListTable } from 'components/ListTable'
import { HeaderActions } from 'components/HeaderActions'
import { useRouter } from 'hooks/useRouter'
import { useAppData } from 'hooks/useAppData'
import { useLoading } from 'hooks/useLoading'
import { UserDTO } from 'services/remote/v1/User/UserServiceTypes'

import Assets from 'assets'
import { ROUTES_NAME } from 'routes'

import * as S from './Users.styles'
import { UsersEditRouteProps } from './UsersEdit'
import UserService from 'services/remote/v1/User/UserService'
import { PermissionsType } from 'pages/Profile/types'
import { getResourcePermissions } from 'utils/getResourcePermissions'
import { TooltipText } from 'components/TooltipText'

type RenderItemProps = {
  item: UserDTO
  index: number
  canEdit?: boolean
}

export const Users = () => {
  const { history } = useRouter()
  const { selectedUnit, data: appData } = useAppData()
  const [permissions, setPermissions] = useState<PermissionsType>(
    {} as PermissionsType
  )
  const { isLoading, setLoading } = useLoading()

  const [usersData, setUsersData] = useState<UserDTO[]>([])

  const init = useCallback(async () => {
    if (!selectedUnit.unitUuid) return
    try {
      setLoading(true)
      const response = await UserService.getAllUsers(selectedUnit.unitUuid)

      setUsersData(response.data.data)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }, [selectedUnit, setLoading])

  useEffect(() => {
    init()
  }, [init])

  function handleInviteButton() {
    history.push(ROUTES_NAME.USERS_INVITE_USER)
  }

  const handleEditButton = (item: UserDTO) => {
    const state: UsersEditRouteProps = { editData: item }
    history.push({
      pathname: ROUTES_NAME.USERS_EDIT_USER,
      state
    })
  }

  const statusMap = {
    validated: 'validado',
    pending: 'pendente',
    blocked: 'bloqueado'
  }

  const renderItem = ({ item, canEdit }: RenderItemProps) => (
    <>
      <S.NameWrapper>
        <Avatar
          title={item.name}
          _sizing={{ height: '44px', width: '44px' }}
          _spacing={{
            margin: '0'
          }}
        />
        <span>
          <TooltipText text={item.name}>
            {item.name || <Skeleton width={150} />}
          </TooltipText>
        </span>
      </S.NameWrapper>

      <span>
        <TooltipText text={item.email}>
          {item.email || <Skeleton width={200} />}
        </TooltipText>
      </span>
      <span>
        <TooltipText text={item.created_by}>
          {item.created_by || <Skeleton width={100} />}
        </TooltipText>
      </span>
      <S.StatusText status={item.status}>
        <TooltipText text={statusMap[item.status as keyof typeof statusMap]}>
          {statusMap[item.status as keyof typeof statusMap] || (
            <Skeleton width={100} />
          )}
        </TooltipText>
      </S.StatusText>

      <ToggleSwitcher isActive={item.is_active} toggleActive={() => null} />

      {canEdit ? (
        <S.EditButton onClick={() => handleEditButton(item)}>
          <Assets
            assetProps={{ key: 'lineEditV2', type: 'icon' }}
            alt="editar"
          />
        </S.EditButton>
      ) : (
        <></>
      )}
    </>
  )

  useEffect(() => {
    const _permissions = getResourcePermissions(appData, 'CAD', 'CAD_EQUIP')

    if (_permissions) setPermissions(_permissions)
  }, [appData])

  return (
    <S.Container>
      <HeaderActions
        title="Usuários"
        buttonTitle="+ convidar"
        buttonAction={handleInviteButton}
        hasReturnButton={false}
        renderCustomRightCorner={!permissions.write ? <></> : null}
      />
      <ListTable<UserDTO>
        tableStructure={{
          header:
            'minmax(200px, 3fr) minmax(200px, 3fr) minmax(200px, 3fr) minmax(60px, 8rem) minmax(60px, 3.75rem) minmax(60px, 3.75rem)',
          body: 'minmax(200px, 3fr) minmax(200px, 3fr) minmax(200px, 3fr) minmax(60px, 8rem) minmax(60px, 3.75rem) minmax(60px, 3.75rem)',
          columns: [
            'nome',
            'email',
            'convidado por',
            'status',
            'ativo',
            'editar'
          ]
        }}
        data={isLoading ? Array(4).fill({}) : usersData || []}
        renderItem={renderItem}
        keyExtractor={(item) => item.id || String(Math.random())}
        canEdit={permissions.write || permissions.exclude}
      />
    </S.Container>
  )
}
