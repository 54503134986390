import React, { ReactNode, useState } from 'react'
import {
  forwardRef,
  InputHTMLAttributes,
  ForwardRefRenderFunction
} from 'react'
import * as S from './Input.styles'
import Assets, { icons } from 'assets'
import { RestTypes } from 'components/typings/utils/restTypes'

export type InputProps = {
  name: string
  label?: string
  labelProps?: S.InputLabelProps
  message?: string
  messageProps?: S.MessageProps
  contentProps?: S.ContentProps
  isRequired?: boolean
  placeholder?: string
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  value?: string | ReadonlyArray<string> | number
  animatedLabel?: string
  animatedLabelProps?: S.AnimatedLabelProps
  isSecuryText?: boolean
  darkMode?: boolean
  listData?: ReactNode
  inputListPlaceholder?: string
  hideCursor?: boolean
  loading?: boolean
  customIcon?: string
  handleCustom?: () => void
  disabledIcon?: boolean
} & InputHTMLAttributes<HTMLInputElement> &
  RestTypes

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    customIcon = '',
    label,
    labelProps,
    message,
    messageProps,
    contentProps,
    isRequired = false,
    placeholder,
    iconLeft,
    iconRight,
    value,
    animatedLabel,
    animatedLabelProps,
    isSecuryText,
    darkMode,
    listData,
    inputListPlaceholder,
    loading,
    onFocus,
    onBlur,
    handleCustom,
    disabledIcon = false,
    ...rest
  },
  ref
) => {
  const [showPassword, setShowPassword] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const handleToggleShowPassword = () => {
    setShowPassword((currState) => !currState)
  }

  const { _flex, _layout, _positioning, _sizing, _spacing } = rest

  return (
    <S.Wrapper>
      <S.Content
        _flex={_flex}
        _layout={_layout}
        _positioning={_positioning}
        _sizing={_sizing}
        _spacing={_spacing}
        {...contentProps}
      >
        {!!label && (
          <S.Label htmlFor={name} {...labelProps}>
            {label}
          </S.Label>
        )}
        <S.InputWrapper isActive={!!(isFocused || value?.toString())}>
          {!!iconLeft && <S.Icon iconLeft={iconLeft}>{iconLeft}</S.Icon>}
          <S.Input
            id={name}
            name={name}
            ref={ref}
            {...(inputListPlaceholder
              ? {
                  placeholder: inputListPlaceholder
                }
              : {
                  placeholder: animatedLabel
                    ? isFocused
                      ? placeholder ?? ' '
                      : ' '
                    : ' '
                })}
            message={message}
            iconLeft={iconLeft}
            iconRight={iconRight}
            onFocus={(e) => {
              setIsFocused(true)
              onFocus && onFocus(e)
            }}
            onBlur={(e) => {
              if (!e.target.value) {
                setIsFocused(false)
              }
              onBlur && onBlur(e)
            }}
            autoComplete="off"
            value={value}
            darkMode={darkMode}
            animatedLabel={animatedLabel}
            {...(isSecuryText && !showPassword ? { type: 'password' } : null)}
            {...rest}
          />
          {!!animatedLabel && (
            <S.AnimatedLabel
              id="animated-label"
              htmlFor={name}
              {...labelProps}
              {...animatedLabelProps}
            >
              {animatedLabel}
              {isRequired ? ' *' : ''}
            </S.AnimatedLabel>
          )}
          {loading && <S.Spinner />}

          {!!iconRight && <S.Icon iconRight={iconRight}>{iconRight}</S.Icon>}
          {isSecuryText && (
            <S.Icon iconRight onClick={handleToggleShowPassword}>
              <Assets
                alt="Password icon"
                assetProps={{
                  type: 'icon',
                  key: showPassword ? 'eyeOpenIcon' : 'eyeIcon'
                }}
              />
            </S.Icon>
          )}

          {!!customIcon && (
            <S.Icon
              iconRight
              onClick={handleCustom}
              disabledIcon={disabledIcon}
            >
              <Assets
                alt="customIcon"
                assetProps={{
                  type: 'icon',
                  key: customIcon as keyof typeof icons
                }}
              />
            </S.Icon>
          )}
        </S.InputWrapper>
        {!!message && <S.Message {...messageProps}>{message}</S.Message>}
      </S.Content>
      {!!listData && listData}
    </S.Wrapper>
  )
}

export const Input = forwardRef(InputBase)
