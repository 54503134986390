/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

import { InputSelect, SelectItemType } from 'components/InputSelect'
import Axios, { AxiosRequestConfig } from 'axios'

import * as S from './FormUploadFiles.styles'

import { Input } from 'components/Input'
import { DropzoneUploadCSV } from 'components/DropzoneUploadCSV'
import { Text } from 'components/Text'
import { Form } from 'components/Form'
import useForm from 'hooks/useForm'
import { Button } from 'components/Button'
import Assets from 'assets'
import { useToast } from 'hooks/useToast'
import { base64ToArrayBuffer } from 'utils/base64-to-array-buffer'

import UploadFileService from 'services/remote/v1/UploadFile/UploadFileService'
import { useLoading } from 'hooks/useLoading'
import { LastProcessingTypes } from 'services/remote/v1/UploadFile/UploadFileServiceTypes'
import { formatDate } from 'utils/formatters/format-date'
import { formatZonedTimeToUtc } from 'utils/formatters/fomatZonedTimeToUtc'
import ImportsService from 'services/remote/v1/Imports/ImportsService'
import { isEmpty } from 'utils/is-empty'

interface FormValues {
  motivo: string
}

type FileType = {
  id: string
  value: string
  reasonRequired: boolean
  supportedFileType: string[]
}

export const FormUploadFiles = () => {
  const [fileOption, setFileOption] = useState<SelectItemType | null>(null)
  const [isReasonRequired, setIsReasonRequired] = useState(false)
  const [uploadFile, setUploadFile] = useState<File>({} as File)
  const [fileTypes, setFileTypes] = useState<FileType[]>([])
  const [selectedFileType, setSelectedFileType] = useState<FileType>(
    {} as FileType
  )
  const [fileOptionError, setFileOptionError] = useState('')
  const [uploadFileError, setUploadFileError] = useState('')
  const { showToast } = useToast()
  const { isLoading, setLoading } = useLoading()
  const [lastProcessing, setLastProcessing] = useState<LastProcessingTypes>()

  const handleOnFileMethodChange = (value: SelectItemType) => {
    setLastProcessing({} as LastProcessingTypes)
    setFileOption(value)
    setSelectedFileType(value as FileType)
    setIsReasonRequired(
      Boolean(fileTypes.find((file) => file.id === value.id)?.reasonRequired)
    )
  }

  const fetchInconsistencies = () => {
    if (!lastProcessing?.inconsistency_url) return

    try {
      const fileName = `Erro_${lastProcessing.filename}`

      const downloadLink = document.createElement('a')
      downloadLink.download = fileName
      downloadLink.href = lastProcessing.inconsistency_url
      downloadLink.click()
    } catch {
      throw new Error('processing_failed')
    }
  }

  const fetchLastProcessing = async () => {
    if (!fileOption?.id) {
      setFileOptionError('Selecione um tipo de arquivo')

      return
    }

    setLoading(true)

    try {
      const { data } = await UploadFileService.getLastProcessing(
        fileOption?.id || ''
      )

      setLastProcessing(data.data)

      setFileOptionError('')

      localStorage.setItem('refresh', String(new Date()))
    } catch (err: any) {
      showToast({
        title: 'Oops! Algo deu errado',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })
    }
    setLoading(false)
  }

  const fetchFileTypes = async () => {
    try {
      setLoading(true)
      const files = await ImportsService.getFileTypes()

      setFileTypes(
        files.data.data.map((file) => ({
          id: file.uuid,
          value: file.name,
          reasonRequired: Boolean(file.reason_required),
          supportedFileType: file.supported_file_types
        }))
      )
    } catch (err: any) {
      showToast({
        title: 'Oops! Algo deu errado',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const { handleSubmit, errors, data, handleChange, updateFormData } =
    useForm<FormValues>({
      validations: {
        motivo: {
          required: {
            message: 'Campo obrigatório',
            value: isReasonRequired
          }
        }
      },

      onSubmit: async () => {
        if (!fileOption?.id) {
          setFileOptionError('Selecione um tipo de arquivo')

          return
        }

        if (isEmpty(uploadFile)) {
          setUploadFileError('O arquivo é obrigatório')

          return
        }

        setLoading(true)

        try {
          const reqBody = {
            import_type_uuid: fileOption?.id || '',
            filename: uploadFile.name,
            reason: data.motivo
          }

          const response = await UploadFileService.sendUploadedFile(reqBody)

          const result = {
            uuid: response.data.data.uuid,
            fields: response.data.data.fields,
            url: response.data.data.url
          }

          const config: AxiosRequestConfig = {
            headers: { 'Content-Type': 'multipart/form-data' }
          }

          const formData = new FormData()

          formData.append('key', result.fields.key)
          formData.append('AWSAccessKeyId', result.fields.AWSAccessKeyId)
          formData.append(
            'x-amz-security-token',
            result.fields['x-amz-security-token']
          )
          formData.append('policy', result.fields.policy)
          formData.append('signature', result.fields.signature)
          formData.append('file', uploadFile)

          await Axios.post(result.url, formData, config)

          setUploadFile({} as File)

          updateFormData({
            ...data,
            motivo: ''
          })

          setUploadFileError('')

          fetchLastProcessing()
        } catch (err: any) {
          showToast({
            title: 'Oops! Algo deu errado',
            message:
              err?.response?.data?.error?.messages_client[0] || err.message,
            type: 'error'
          })
          setLoading(false)
        }
      }
    })

  useEffect(() => {
    fetchFileTypes()
  }, [])

  return (
    <S.Box>
      <S.Content>
        <Text
          type="subheading-1"
          color="galaxy"
          _spacing={{
            marginTop: '38px'
          }}
        >
          Tipo de Arquivo
        </Text>
        <S.WrapperSelect>
          <InputSelect
            name="fileType"
            label="Tipo de importação *"
            data={fileTypes}
            onChange={handleOnFileMethodChange}
            selectedItem={fileOption}
            message={fileOptionError}
          />
        </S.WrapperSelect>
      </S.Content>

      <S.Container>
        <S.InputGroupRow customGridTemplate="1fr 0.1fr 1fr">
          <Form
            _flex={{
              direction: 'column',
              gap: '1rem',
              alignItems: 'center'
            }}
            onSubmit={(e) => {
              handleSubmit(e)

              if (!uploadFile) {
                setUploadFileError('O arquivo é obrigatório')

                return
              }
            }}
          >
            <Text weight="weight-700" size="24px">
              Upload
            </Text>
            <Text
              type="subheading-1"
              color="galaxy"
              _spacing={{ marginBottom: '-1rem' }}
            >
              Arquivo
            </Text>
            <DropzoneUploadCSV
              acceptedFiles={selectedFileType?.supportedFileType || []}
              onChange={(files) => {
                setUploadFile(files[0] as File)
                setLastProcessing({} as LastProcessingTypes)
              }}
              errorMessage={uploadFileError}
              initialValue={uploadFile?.name}
            />

            {isReasonRequired && (
              <Input
                name="motivo"
                animatedLabel="informe um motivo"
                isRequired
                value={data.motivo || ''}
                onChange={handleChange('motivo')}
                message={errors.motivo}
              />
            )}

            <Button
              _sizing={{
                width: '20rem'
              }}
              disabled={isLoading}
            >
              enviar
            </Button>
          </Form>

          <S.Divider />

          <S.Content>
            <Text weight="weight-700" size="24px">
              último processamento
            </Text>
            <S.Wrapper>
              <S.InputGroup bankTemplate="1fr 1fr">
                <Input
                  disabled
                  name="data/hora"
                  animatedLabel="data/hora"
                  value={
                    lastProcessing?.imported_at
                      ? formatDate(
                          String(
                            formatZonedTimeToUtc(
                              lastProcessing?.imported_at || '',
                              true
                            )
                          ),
                          'DD/MM/AAAA - HH:MM'
                        )
                      : ''
                  }
                />

                <Input
                  disabled
                  name="nome do arquivo"
                  animatedLabel="nome do arquivo"
                  value={lastProcessing?.filename || ''}
                />
              </S.InputGroup>
              <S.InputGroup bankTemplate="1.3fr 0.7fr">
                <Input
                  disabled
                  name="importando por"
                  animatedLabel="importando por"
                  value={lastProcessing?.imported_by || ''}
                />

                <Input
                  disabled
                  name="status"
                  animatedLabel="status"
                  value={lastProcessing?.status || ''}
                />
              </S.InputGroup>

              <S.InputGroup bankTemplate="0.8fr 0.8fr 0.8fr">
                <Input
                  disabled
                  name="registros"
                  animatedLabel="registros"
                  value={lastProcessing?.total_records || 0}
                />

                <Input
                  disabled
                  name="importados"
                  animatedLabel="importados"
                  value={lastProcessing?.total_imported || 0}
                />

                <Input
                  disabled
                  name="inconsistências"
                  animatedLabel="inconsistências"
                  value={lastProcessing?.total_inconsistencies || 0}
                  customIcon="download"
                  handleCustom={fetchInconsistencies}
                  disabledIcon={!lastProcessing?.total_inconsistencies}
                />
              </S.InputGroup>

              <S.Stack>
                <Button
                  _sizing={{
                    width: '65px',
                    height: '62px'
                  }}
                  disabled={isLoading}
                  onClick={fetchLastProcessing}
                >
                  <Assets
                    alt="refresh"
                    assetProps={{ type: 'icon', key: 'refresh' }}
                  />
                </Button>

                <Text weight="weight-600">último refresh</Text>
                <Text weight="weight-600">
                  {!!localStorage.getItem('refresh') &&
                    formatDate(
                      localStorage.getItem('refresh') || '',
                      'DD/MM/AAAA - HH:MM'
                    )}
                </Text>
              </S.Stack>
            </S.Wrapper>
          </S.Content>
        </S.InputGroupRow>
      </S.Container>
    </S.Box>
  )
}
