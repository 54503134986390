import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 1.2rem;
  padding: 1.5rem 2.5rem;
  width: 100%;
`

export const ContainerSelects = styled.div`
  display: flex;
  gap: 1.2rem;
  height: fit-content;
  width: 100%;
`

export const AutoCompleteContainer = styled.div`
  width: 17.5rem;
`

export const ContainerCharts = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 1.2rem;
  height: fit-content;
  width: 100%;
`

export const ColumnLeft = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.greyborders};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    height: 100%;
    padding: 1rem;
    width: 100%;
    position: relative;

    &:hover ${WrapperExport} {
      display: flex;
    }
  `}
`

export const RowsRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  position: relative;
  width: 100%;

  > div {
    display: flex;
    gap: 1rem;
    position: relative;
    width: 100%;
  }
`

export const ConversionTime = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;
  width: 100%;
`
export const SheetsList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 1rem;
  height: fit-content;
  width: 100%;
  width: 100%;
`

type ExportButtonProps = {
  hide?: boolean
}

export const WrapperExport = styled.div<ExportButtonProps>`
  ${({ hide }) => css`
    position: absolute;
    right: 15px;
    top: -8px;
    margin: 0;
    display: ${!!hide && 'none'};
  `}
`
