import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'
import { ReasonDTO, UpdateReasonByUuidResponse } from './ReasonServiceTypes'

class ReasonService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Update reason by uuid
   * @param uuid number
   * @param reason Pick<ReasonDTO, 'name' | 'is_active'>
   * @returns UpdateReasonByUuidResponse
   */
  public updateReasonByUuid(
    uuidReason: string,
    reason: Pick<ReasonDTO, 'name' | 'is_active'>
  ) {
    return this.instance.put<UpdateReasonByUuidResponse>(
      `/reasons/${uuidReason}`,
      { ...reason }
    )
  }

  /**
   * Delete reason by uuid
   * @param uuid number
   */
  public deleteReasonByUuid(payload: Pick<ReasonDTO, 'uuid'>) {
    return this.instance.delete(`/reasons/${payload.uuid}`)
  }
}

export default new ReasonService()
