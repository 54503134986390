/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react'

import { useRouter } from 'hooks/useRouter'
import { useLoading } from 'hooks/useLoading'
import useForm from 'hooks/useForm'
import { useAppData } from 'hooks/useAppData'
import { useToast } from 'hooks/useToast'
import { useModal } from 'providers/ModalProvider'
import { CommonModal } from 'templates/Modals/CommonModal'

import * as S from './Profile.styles'
import { HeaderActions } from 'components/HeaderActions'
import { ListTable } from 'components/ListTable'
import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'
import { Input } from 'components/Input'
import { Text } from 'components/Text'
import { Form } from 'components/Form'
import { ToggleSwitcher } from 'components/ToggleSwitcher'
import { Tooltip } from 'components/Tooltip'

import CompanyService from 'services/remote/v1/Company/CompanyService'

import { UpdateListType } from './types'
import { ProfileDTO } from 'services/remote/v1/Profile/ProfileServiceTypes'
import { onlyText } from 'utils/formatters/only-text'
import { ResourceDTO } from 'models'
import { InputSelect, SelectItemType } from 'components/InputSelect'
import ResourceProfileService from 'services/remote/v1/Profile/ResourceProfileService/ResourceProfileService'
import { AutoComplete } from 'components/Autocomplete'

type RouteStateProps = {
  resources: ResourceDTO[]
}

type FormValues = {
  name: string
}

export const ProfileCreate = () => {
  const [customErrors, setCustomErrors] = useState({
    perfilHierarquia: false
  })
  const { history, location } = useRouter<RouteStateProps>()
  const { setLoading } = useLoading()
  const { showToast } = useToast()
  const [profiles, setProfiles] = useState<SelectItemType[]>([
    {
      id: '',
      value: 'carregando opções...'
    } as SelectItemType
  ])
  const [selectedProfile, setSelectedProfile] =
    useState<SelectItemType | null>(null)
  const { dispatch } = useModal()

  const [resourcesData, setResourcesData] = useState<ResourceDTO[]>([])

  const [isProfileActive, setIsProfileActive] = useState(true)

  const { selectedCompanyUuid, selectedUnit } = useAppData()

  const [profileValue, setProfileValue] = useState('')

  const handleSelectedProfile = useCallback((value: SelectItemType) => {
    setSelectedProfile(value)
  }, [])

  const getAllResources = useCallback(async () => {
    if (!selectedCompanyUuid) return

    try {
      setLoading(true)
      const { data } = await ResourceProfileService.getCompanyResources(
        selectedCompanyUuid
      )

      if (data.data.length === 0) {
        history.goBack()
      }

      setResourcesData(data.data)
    } catch (err: any) {
      dispatch({
        action: 'OPEN_MODAL',
        component: (
          <CommonModal
            title="Oops! Algo deu errado"
            description={
              err?.response?.data?.error?.messages_client[0] || err.message
            }
            primaryButtonTitle="voltar"
            primaryButtonProps={{
              fontSize: '1rem',
              backgroundColor: 'noverde'
            }}
            primaryButtonAction={() => {
              dispatch({ action: 'CLOSE_MODAL' })
            }}
          />
        )
      })
    } finally {
      setLoading(false)
    }
  }, [])

  const handleSubmitCreate = async (name: string) => {
    try {
      if (!selectedCompanyUuid) return

      setLoading(true)

      const profileReqBody: ProfileDTO = {
        is_active: isProfileActive,
        name,
        resources: resourcesData,
        parent: {
          id: Number(selectedProfile?.id),
          uuid: selectedUnit.unitUuid,
          name: selectedProfile?.value
        }
      }

      const checkAnyResourceIsChecked = resourcesData
        .map((item) => item.permissions.read)
        .some((item) => item === true)

      if (checkAnyResourceIsChecked) {
        await CompanyService.createProfileToCompanyByUuid(
          selectedCompanyUuid,
          profileReqBody
        )
        showToast({
          message: 'Seu perfil foi criado com sucesso',
          title: 'Perfil Criado',
          type: 'success'
        })

        history.goBack()
      } else {
        showToast({
          message: 'Selecione ao menos um dos recursos listados',
          title: 'Oops! Algo deu errado',
          type: 'error'
        })
      }
    } catch (err: any) {
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: '',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const getAllUsersProfiles = useCallback(async () => {
    if (!selectedCompanyUuid) return

    const { data } = await CompanyService.getAllProfilesInCompanyByUuid(
      selectedCompanyUuid
    )
    const values = data.data.map((values) => {
      return {
        id: String(values.id),
        value: String(values.name)
      }
    })
    setProfiles(values)
  }, [selectedCompanyUuid])

  const { handleSubmit, errors, data, handleChange } = useForm<FormValues>({
    validations: {
      name: {
        required: {
          message: 'Não esqueça o nome do perfil',
          value: true
        },
        pattern: {
          message: 'Apenas letras no nome do perfil',
          value: '^[A-Za-z ]*$'
        }
      }
    },
    onSubmit: async () => await handleSubmitCreate(data.name as string)
  })

  useEffect(() => {
    getAllResources()
  }, [])

  const handleGoBack = () => {
    history.goBack()
  }

  useEffect(() => {
    getAllUsersProfiles()
  }, [getAllUsersProfiles])

  const handleUpdateEditTable = (props: UpdateListType) => {
    if (props.element === 'checkbox') {
      setResourcesData((currState) => {
        let currStateCopy = [...currState]

        if (
          currStateCopy[props.index].abbreviation === 'CAD_PER' &&
          props.permissionKey === 'write'
        ) {
          if (!props.newValue) {
            currStateCopy = currState.map((resource) => ({
              ...resource,
              permissions: {
                ...resource.permissions,
                allow: false
              }
            }))
          } else {
            dispatch({
              action: 'OPEN_MODAL',
              component: (
                <CommonModal
                  title="Atenção"
                  description="Você sinalizou que o perfil que está criando pode criar outros perfis. Marque na coluna permitir os recursos que este perfil pode dar permissões de acesso"
                  primaryButtonTitle="Fechar"
                  primaryButtonAction={() =>
                    dispatch({ action: 'CLOSE_MODAL' })
                  }
                />
              )
            })
          }
        }

        if (props.permissionKey === 'read' && !props.newValue) {
          currStateCopy[props.index].permissions = {
            exclude: false,
            export: false,
            read: false,
            write: false,
            allow: false
          }

          return [...currStateCopy]
        }

        currStateCopy[props.index].permissions[props.permissionKey] =
          props.newValue

        return [...currStateCopy]
      })

      return
    }

    setIsProfileActive(props.newValue)
  }

  const renderListHeader = () => (
    <S.TitleInputWrapper>
      <Text color="galaxy">Perfil</Text>
      <S.InputRowWrapper>
        <Input
          name="name"
          animatedLabel="Nome do perfil *"
          message={errors.name}
          value={data.name || ''}
          onChange={handleChange('name', onlyText)}
        />
        <AutoComplete
          options={profiles}
          selectedOption={selectedProfile}
          inputValue={profileValue}
          onInputChange={(value: string) => setProfileValue(value)}
          onChange={(value) => {
            setCustomErrors((prev) => ({
              ...prev,
              perfilHierarquia: false
            }))
            handleSelectedProfile(value ? value : { id: '', value: '' })
          }}
          renderInput={(params) => (
            <Input
              name="perfilHierarquia"
              placeholder="selecione um perfil de hierarquia..."
              animatedLabel="perfil hierarquia *"
              message={
                customErrors.perfilHierarquia
                  ? 'Informe um perfil de hierarquia'
                  : ''
              }
              data-lpignore
              {...params}
            />
          )}
        />
        <S.SwitchGroup>
          <label htmlFor="">ativo</label>
          <ToggleSwitcher
            isActive={isProfileActive}
            toggleActive={(currValue) => {
              handleUpdateEditTable({
                element: 'switch',
                newValue: currValue
              })
            }}
          />
        </S.SwitchGroup>
      </S.InputRowWrapper>
    </S.TitleInputWrapper>
  )

  const renderItem = ({
    item,
    index
  }: {
    item: ResourceDTO
    index: number
  }) => {
    return (
      <>
        <span className="recurse-column__title">{item.name}</span>
        <Checkbox
          label="Ver"
          isChecked={item.permissions.read}
          toggleChecked={(currValue) =>
            handleUpdateEditTable({
              element: 'checkbox',
              permissionKey: 'read',
              index,
              newValue: currValue
            })
          }
        />
        {item.can_register ? (
          <>
            <Checkbox
              isDisabled={!item.permissions.read}
              label="Criar"
              isChecked={item.permissions.write}
              toggleChecked={(currValue) =>
                handleUpdateEditTable({
                  element: 'checkbox',
                  permissionKey: 'write',
                  index,
                  newValue: currValue
                })
              }
            />
            <Checkbox
              isDisabled={!item.permissions.read}
              label="Excluir"
              isChecked={item.permissions.exclude}
              toggleChecked={(currValue) =>
                handleUpdateEditTable({
                  element: 'checkbox',
                  permissionKey: 'exclude',
                  index,
                  newValue: currValue
                })
              }
            />
          </>
        ) : (
          <>
            <span>-</span>
            <span>-</span>
          </>
        )}

        {item.can_extract ? (
          <Checkbox
            isDisabled={!item.permissions.read}
            label="Exportar"
            isChecked={item.permissions.export}
            toggleChecked={(currValue) =>
              handleUpdateEditTable({
                element: 'checkbox',
                permissionKey: 'export',
                index,
                newValue: currValue
              })
            }
          />
        ) : (
          <span>-</span>
        )}

        <Checkbox
          label="Permitir"
          isDisabled={
            !resourcesData?.find(
              (resource) => resource.abbreviation === 'CAD_PER'
            )?.permissions?.write
          }
          isChecked={item.permissions.allow}
          toggleChecked={(currValue) =>
            handleUpdateEditTable({
              element: 'checkbox',
              permissionKey: 'allow',
              index,
              newValue: currValue
            })
          }
        />
      </>
    )
  }

  return (
    <Form
      onSubmit={(e) => {
        const errors = customErrors
        errors.perfilHierarquia = !selectedProfile

        setCustomErrors(errors)
        handleSubmit(e)
      }}
    >
      <S.Container>
        <HeaderActions
          title="novo perfil"
          hasReturnButton={true}
          returnButtonAction={handleGoBack}
          renderCustomRightCorner={<></>}
        />

        {!!resourcesData.length && (
          <>
            <ListTable<ResourceDTO>
              tableStructure={{
                header: '1fr 2.15fr',
                body: '1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr',
                columns: [
                  'recurso',
                  <S.WrapperTooltip key="tootilp">
                    <p>permissão</p>
                    <Tooltip
                      text={[
                        'Ver - Permite acessar o recurso no menu lateral',
                        'Criar -Permite criar o recurso',
                        'Excluir - Permite excluir o recurso',
                        'Exportar - Permite exportar o recurso',
                        'Permitir - Permite o perfil atribuir o recurso a outro perfil'
                      ]}
                    />
                  </S.WrapperTooltip>
                ]
              }}
              data={resourcesData}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
              listHeaderComponent={renderListHeader}
            />

            <S.FooterButtonsWrapper>
              <Button _sizing={{ maxWidth: '220px' }}>Salvar</Button>
            </S.FooterButtonsWrapper>
          </>
        )}
      </S.Container>
    </Form>
  )
}
