/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react'

import CategoryService from 'services/remote/v1/Category/CategoryService'
import { CompanyDTO } from 'services/remote/v1/Company/CompanyServiceTypes'
import { CategoryDTO } from 'services/remote/v1/Category/CategoryServiceTypes'
import BankService from 'services/remote/v1/Bank/BankService'

import { FormCompany } from 'components/FormCompany'
import { HeaderActions } from 'components/HeaderActions'

import { useCompanyContext } from './Company.context'

import { useRouter } from 'hooks/useRouter'
import { useToast } from 'hooks/useToast'
import { useLoading } from 'hooks/useLoading'
import { useAppData } from 'hooks/useAppData'
import { sleep } from 'utils/sleep'
import { ROUTES_NAME } from 'routes'
import { PermissionsType } from 'pages/Profile/types'
import { getResourcePermissions } from 'utils/getResourcePermissions'

type RouteStateType = {
  selectedCompany?: CompanyDTO
  currentUnit: string
}

export const CompanyEdit = () => {
  const [categoriesList, setCategoriesList] = useState<CategoryDTO[]>([])
  const [banksList, setBanksList] = useState<any[]>([])

  const { showToast } = useToast()
  const { history, location } = useRouter<RouteStateType>()
  const { handleGoBack } = useCompanyContext()
  const { setLoading } = useLoading()
  const { selectedUnit, data: appData } = useAppData()
  const [permissions, setPermissions] = useState<PermissionsType>(
    {} as PermissionsType
  )

  useEffect(() => {
    if (!location?.state?.currentUnit || !location.state.selectedCompany) {
      history.push(ROUTES_NAME.COMPANY)
      return
    }

    if (location.state.currentUnit !== selectedUnit.unitUuid) {
      history.push(ROUTES_NAME.COMPANY)
      return
    }
  }, [location, selectedUnit, history])

  const init = useCallback(async () => {
    try {
      setLoading(true)
      sleep(200)

      const [categoriesResponse, banksResponse] = await Promise.all([
        CategoryService.getAllCategories(),
        BankService.getBanksList()
      ])

      const banks = banksResponse.data.data.banks.map((eachBank) => ({
        id: eachBank.code,
        value: `${eachBank.code} - ${eachBank.name}`
      }))

      setBanksList(banks)
      setCategoriesList(categoriesResponse.data.data)
    } catch (err: any) {
      showToast({
        title: 'Oops! Algo deu errado',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })
      history.replace(ROUTES_NAME.COMPANY)
    } finally {
      setLoading(false)
    }
  }, [history, setLoading, showToast])

  useEffect(() => {
    init()
  }, [init])

  useEffect(() => {
    const _permissions = getResourcePermissions(appData, 'CAD', 'CAD_EMPR')

    if (_permissions) setPermissions(_permissions)
  }, [appData])

  return (
    <>
      <HeaderActions
        title="editar empresa"
        hasReturnButton
        returnButtonAction={handleGoBack}
        renderCustomRightCorner={<></>}
      />
      <FormCompany
        edit
        companyData={location.state.selectedCompany}
        categoriesList={categoriesList}
        banksList={banksList}
        hideDelete={permissions.exclude}
        hideUpdate={!permissions.write}
      />
    </>
  )
}
