import styled, { css } from 'styled-components'

interface TooltipBoxProps {
  width: number
  height: number
  posX: number
  posY: number
  direction: 'up' | 'down' | 'left' | 'right'
}

interface ContainerProps {
  positionRelative: boolean
  width: number
}

export const Content = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
`
export const TextBox = styled.div<TooltipBoxProps>`
  position: absolute;

  visibility: hidden;
  color: transparent;
  background-color: transparent;
  ${({ width }) => (width < 30 ? 'padding: 5px 10px' : 'padding: 5px 5px')};
  border-radius: 4px;
  font-size: 0.8rem;

  ${({ direction, posY, posX }) =>
    direction === 'down' &&
    css`
      top: ${posY}px;
      left: ${posX}px;
      margin-top: 30px;
    `}

  ${({ direction, posY, posX }) =>
    direction === 'up' &&
    css`
      top: ${posY}px;
      left: ${posX}px;
      margin-top: -40px;
    `}

  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;

  &:before {
    content: '';
    width: 0;
    height: 0;

    ${({ direction }) =>
      direction === 'down' &&
      css`
        top: -6px;
        left: calc(50% - 8px);
        transform: rotate(135deg);
        border: 6px solid transparent;
      `}

    ${({ direction, width }) =>
      direction === 'up' &&
      css`
        bottom: -6px;
        ${width < 30 ? 'left: calc(50% - 6px)' : 'left: calc(50% - 8px)'};
        transform: rotate(-45deg);
        border: 6px solid transparent;
      `}
    position: absolute;

    transition: border 0.3s ease-in-out;
  }

  &:hover {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    ${({ width }) => (width < 30 ? 'padding: 5px 10px' : 'padding: 5px 5px')};
    ${({ direction }) =>
      direction === 'right' &&
      css`
        padding: 5px 5px;
      `}

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
`
export const Container = styled.div<ContainerProps>`
  width: 100%;
  ${({ positionRelative }) => (positionRelative ? 'position: relative;' : '')}
  & ${Content}:hover + ${TextBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: fit-content;
    text-align: center;
    ${({ width }) => (width < 30 ? 'padding: 5px 10px' : 'padding: 5px 5px')};

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
`
