import { Text } from 'components/Text'
import { PartnersTheme } from 'layout/theme'
import { WrapperExport } from 'pages/Dashboard/Dashboard.styles'
import styled, { css } from 'styled-components'

export const Box = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.greyborders};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
    padding: 1.5rem 1.2rem;
    width: 100%;
    height: 100%;

    &:hover ${WrapperExport} {
      display: flex;
    }
  `}
`

export const BoxSum = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 140px;
  align-items: center;
`

interface StyleProps {
  color?: keyof typeof PartnersTheme.colors
}

export const RotuleInformation = styled(Text)<StyleProps>`
  ${({ theme, color }) => css`
    color: ${theme.colors[color || 'text']};
    text-align: start;
    height: 48px;
    min-width: 140px;
    background: ${theme.colors.backgrounds};
    border-radius: 8px 8px 0 0;
    padding: 8px;
    text-align: center;
  `}
`

export const ValueInformation = styled(Text)<StyleProps>`
  ${({ theme, color }) => css`
    color: ${theme.colors[color || 'black']};
    text-align: center;
  `}
`

export const BoxMoreInformations = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
  row-gap: 2rem;
  width: 100%;
  margin-top: 0.75rem;
`

export const BoxInformation = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  text-align: center;
  width: fit-content;
`
