import { FormAttendanceProposalFollowUp } from 'components/FormAttendanceProposalFollowUp'
import { HeaderActions } from 'components/HeaderActions'

import * as S from './AttendanceProposalFollowUp.styles'

export const AttendanceProposalFollowUp = () => {
  return (
    <S.Container>
      <HeaderActions
        title="acompanhamento de propostas"
        hasReturnButton={true}
        renderCustomRightCorner={<></>}
      />
      <FormAttendanceProposalFollowUp />
    </S.Container>
  )
}
