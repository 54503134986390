import { clearLocalStorage } from './useLocalStorage'
import { useAppData } from 'hooks/useAppData'

export const useResetApp = () => {
  const { clearAppData } = useAppData()
  const clearApp = () => {
    clearAppData()
    clearLocalStorage()
  }

  return {
    resetStorageAndContexts: clearApp
  }
}
