/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useRef, ChangeEvent, useCallback } from 'react'

import useForm from 'hooks/useForm'
import { useRouter } from 'hooks/useRouter'
import { useAppData } from 'hooks/useAppData'
import { useToast } from 'hooks/useToast'
import { useLoading } from 'hooks/useLoading'

import { Button } from 'components/Button'
import { AutoComplete } from 'components/Autocomplete'
import {
  DropzoneUpload,
  FileProps
} from 'components/DropzoneUpload/DropzoneUpload'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { ToggleSwitcher } from 'components/ToggleSwitcher'
import { Text } from 'components/Text'
import { phoneMask, cepMask } from 'utils/formatters/masks'
import { unmask } from 'utils/formatters/unmask'

import { useCompanyContext } from 'pages/Company/Company.context'

import * as S from './FormCompany.styles'
import { TagType } from 'components/InputList'

import {
  CompanyDTO,
  UpdateCompanyReqBody
} from 'services/remote/v1/Company/CompanyServiceTypes'
import LandingPageService from 'services/remote/v1/LandingPage/LandingPageService'
import Category from 'services/remote/v2/Zendesk/Category'
import { CategoryDTO } from 'services/remote/v1/Category/CategoryServiceTypes'
import CEPService from 'services/remote/v1/Cep/CepService'
import { SelectItemType } from 'components/InputSelect'
import { InputRadio } from 'components/InputRadio'
import { onlyNumbers } from 'utils/formatters/only-numbers'
import { states } from 'utils/enums/states'

const MIN_ACCOUNT_VALUE = 3
const MAX_ACCOUNT_VALUE = 12

type BankSelectDTO = {
  id: string
  value: string
}

interface FormCompanyProps {
  edit: boolean
  companyData?: CompanyDTO
  categoriesList?: CategoryDTO[]
  banksList?: BankSelectDTO[]
  hideDelete?: boolean
  hideUpdate?: boolean
}

interface LandingPageImagesDTO {
  logo: string | ArrayBuffer | null
  primary: string | ArrayBuffer | null
  secondary: string | ArrayBuffer | null
}

interface FormValues {
  nome: string
  razaoSocial: string
  cnpj: string
  agencia: string
  agenciaDigito: string
  conta: string
  contaDigito: string
  tipoConta: string
  mainColor: string
  proposalValue: string
  genreArticle: string
  landingCompanyName: string
  buildingNumber: string
  city: string
  complement: string
  neighborhood: string
  state: string
  street: string
  zipcode: string
  phone_number: string
}

interface CepValidator {
  street: boolean
  state: boolean
  neighborhood: boolean
  city: boolean
  complement: boolean
  buildingNumber: boolean
}

const disbursementOption = [
  { id: '2', value: 'Conta bancária da unidade' },
  { id: '1', value: 'Conta bancária da empresa' }
]

export const FormCompany = ({
  edit,
  companyData,
  categoriesList,
  banksList = [],
  hideDelete,
  hideUpdate
}: FormCompanyProps) => {
  const {
    categoriesRequired,
    tagsCategories,
    addCategoryTag,
    handleDelete,
    removeCategoryTag,
    validationCategories,
    handleEditSubmit,
    addListOfCategories,
    handleCreateSubmit
  } = useCompanyContext()
  const { showToast } = useToast()
  const { setLoading } = useLoading()
  const [avatar, setAvatar] = useState<string | ArrayBuffer | null>(null)
  const [avatarError, setAvatarError] = useState('')
  const [isActive, setIsActive] = useState<boolean>(true)
  const [bankTerm, setBankTerm] = useState('')
  const [payoutMethod, updatePayoutMethod] = useState(
    disbursementOption[companyData?.company_disbursement ? 1 : 0]
  )
  const [cepLoading, setCepLoading] = useState(false)
  const [bankSelected, setBankSelected] = useState<SelectItemType | null>(null)
  const [bankError, setBankError] = useState('')
  const [disbursmentOption, setDisbursmentOption] = useState('')
  const [selectedTab, setSelectedTab] =
    useState<'about' | 'personalization'>('about')
  const [landingPageImages, setLandingPageImages] =
    useState<LandingPageImagesDTO>({
      logo: null,
      primary: null,
      secondary: null
    })

  const { history } = useRouter()
  const { updateFirstAccessData } = useAppData()

  const isPaymentRequired = payoutMethod?.id === '1'

  const colorPickerRef = useRef<HTMLInputElement>(null)

  const [faqCategoriesList, setFaqCategoriesList] = useState<TagType[]>([])
  const [faqCategoriesTags, setFaqCategoriesTags] = useState<TagType[]>([])
  const [faqCategoriesTerm, setFaqCategoriesTerm] = useState('')

  const [cepDisableFields, setCepDisableFields] = useState<CepValidator>({
    street: !companyData,
    state: true,
    neighborhood: !companyData,
    city: true,
    complement: !companyData,
    buildingNumber: !companyData
  })

  const [stateOption, setStateOption] = useState('')
  const [stateSelected, setStateSelected] = useState<
    { id: string; value: string } | undefined
  >({ id: '', value: '' })
  const [firstLoad, setFirstLoad] = useState(true)

  const addFaqCategoriesTag = useCallback(
    (tag: TagType) => {
      const index = faqCategoriesTags.findIndex(
        (eachCategory) => tag.value.toLowerCase() === eachCategory.value
      )

      if (index >= 0) return

      setFaqCategoriesTags([
        ...faqCategoriesTags,
        { value: tag.value.toLowerCase(), id: tag.id }
      ])
    },
    [faqCategoriesTags]
  )

  const removeFaqCategoryTag = (tag: TagType) => {
    const updatedTags = faqCategoriesTags.filter(
      (tmpTag) => tmpTag.value !== tag.value
    )
    setFaqCategoriesTags(updatedTags)
  }

  const handleCompanyCustomizationImages = useCallback(
    (key: string, file: FileProps) => {
      setLandingPageImages((currState) => ({
        ...currState,
        [key]: file
      }))
    },
    []
  )

  const fillFaqCategories = useCallback(async () => {
    try {
      const response = await Category.listCategories()

      setFaqCategoriesList(
        response.data.categories.map((section) => {
          return {
            id: section.id,
            value: section.name.toLocaleLowerCase()
          }
        })
      )
    } catch (err: any) {
      showToast({
        title: 'Oops! Algo deu errado',
        message: err,
        type: 'error'
      })
    }
  }, [showToast])

  useEffect(() => {
    fillFaqCategories()
  }, [fillFaqCategories])

  useEffect(() => {
    if (faqCategoriesList.length === 0) return

    const filteredCategoryTags = companyData?.faq_category_ids.map(
      (categoryId) => {
        return faqCategoriesList.find(
          (item) => String(item.id) === String(categoryId)
        )
      }
    ) as TagType[]

    setFaqCategoriesTags(filteredCategoryTags)
  }, [companyData?.faq_category_ids, faqCategoriesList])

  useEffect(() => {
    if (companyData) {
      setIsActive(companyData?.is_active || true)

      if (companyData.image_trademark) {
        setAvatar(companyData.image_trademark)
      }

      if (companyData.company_disbursement) {
        const selectedBank = banksList.find(
          (eachBank) => eachBank.id === companyData.bank
        )

        setBankSelected(selectedBank || null)
      }

      if (
        companyData.categories.length > 0 &&
        categoriesList &&
        categoriesList?.length > 0
      ) {
        const tags = companyData.categories.map((category) => {
          const categoryIndex = categoriesList.findIndex(
            (eachCategory) => eachCategory.id === category.id
          )

          return {
            value: categoriesList[categoryIndex]?.name.toLowerCase(),
            id: String(category.id)
          }
        })

        addListOfCategories(tags)
      }
    }
  }, [companyData, categoriesList, addListOfCategories, banksList])

  const validateCNPJ = (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  }

  const { handleSubmit, errors, data, handleChange, updateFormData } =
    useForm<FormValues>({
      validations: {
        nome: {
          required: {
            message: 'Não esqueça o nome da empresa',
            value: true
          }
        },
        razaoSocial: {
          required: {
            message: 'Não esqueça a razão social',
            value: true
          }
        },
        cnpj: {
          required: {
            message: 'Não esqueça o CNPJ',
            value: true
          },
          custom: {
            message: 'Digite um CNPJ válido',
            isValid: (currValue) => currValue.length === 18
          }
        },
        agencia: {
          required: {
            message: 'Não esqueça a agência',
            value: isPaymentRequired
          },
          custom: {
            message:
              'O campo agência pode ter no mínimo 3 dígitos máximo 5 dígitos',
            isValid: (currValue) =>
              isPaymentRequired
                ? 3 <= currValue.length && currValue.length <= 5
                : !isPaymentRequired
          }
        },
        conta: {
          required: {
            message: 'Não esqueça a conta bancária',
            value: isPaymentRequired
          },
          custom: {
            message: 'Informe uma conta válida',
            isValid: (currValue) =>
              isPaymentRequired
                ? currValue.length >= MIN_ACCOUNT_VALUE &&
                  currValue.length <= MAX_ACCOUNT_VALUE
                : !isPaymentRequired
          }
        },
        contaDigito: {
          required: {
            message: 'Não esqueça o dígito da conta',
            value: isPaymentRequired
          }
        },
        buildingNumber: {
          required: {
            message: 'Não esqueça o número do seu endereço',
            value: true
          }
        },
        city: {
          required: {
            message: 'Não esqueça a cidade',
            value: true
          }
        },
        neighborhood: {
          required: {
            message: 'Não esqueça o bairro',
            value: true
          }
        },
        street: {
          required: {
            message: 'Não esqueça o endereço',
            value: true
          }
        },
        zipcode: {
          required: {
            message: 'Não esqueça o CEP',
            value: true
          }
        },
        phone_number: {
          required: {
            message: 'Não esqueça o telefone',
            value: true
          }
        },
        state: {
          required: {
            message: 'Não esqueça o estado',
            value: true
          }
        }
      },
      initialValues: {
        nome: companyData?.name || '',
        razaoSocial: companyData?.corporate_name || '',
        cnpj: validateCNPJ(companyData?.cnpj || ''),
        conta: companyData?.account || '',
        contaDigito: companyData?.account_digit || '',
        agencia: companyData?.agency || '',
        agenciaDigito: companyData?.agency_digit || '',
        tipoConta: companyData?.account_kind || 'checking',
        mainColor: companyData?.brand_color || '#000000',
        proposalValue: companyData?.brand_proposal || '',
        landingCompanyName: companyData?.landing_name || '',
        genreArticle: companyData?.name_article || '',
        buildingNumber: companyData?.address.building_number || '',
        city: companyData?.address.city || '',
        complement: companyData?.address.complement || '',
        neighborhood: companyData?.address.neighborhood || '',
        state: companyData?.address.state || '',
        street: companyData?.address.street || '',
        zipcode: companyData?.address.zipcode || '',
        phone_number: phoneMask(companyData?.phone_number || '')
      },

      onSubmit: async () => {
        try {
          setBankError('')
          if (tagsCategories.length <= 0) {
            validationCategories(true)
            return
          }

          if ((isPaymentRequired && !bankSelected) || !avatar) {
            return
          }

          setLoading(true)

          const categoriesId = tagsCategories.map((eachCategory) => ({
            id: Number(eachCategory.id)
          }))

          const faqCategoriesIds = faqCategoriesTags?.map((category) =>
            String(category.id)
          )

          const reqBody: UpdateCompanyReqBody = {
            name: data.nome,
            corporate_name: data.razaoSocial,
            cnpj: data.cnpj,
            is_active: isActive,
            image_trademark: avatar || '',
            categories: categoriesId,
            bank: bankSelected?.id,
            agency: data.agencia,
            agency_digit: data.agenciaDigito,
            account: data.conta,
            account_digit: data.contaDigito,
            account_kind: data.tipoConta,
            company_disbursement: isPaymentRequired,
            name_article: data.genreArticle,
            brand_color: data.mainColor,
            brand_proposal: data.proposalValue,
            landing_name: data.landingCompanyName,
            faq_category_ids: faqCategoriesIds || [],
            phone_number: unmask(data.phone_number),
            building_number: data.buildingNumber,
            city: data.city,
            complement: data.complement,
            neighborhood: data.neighborhood,
            state: data.state,
            street: data.street,
            zipcode: unmask(data.zipcode)
          }

          if (edit && companyData) {
            const hasLPImages = !Object.values(landingPageImages).every(
              (value) => value === null
            )

            if (hasLPImages) {
              const formData = new FormData()

              if (landingPageImages.logo) {
                formData.append(
                  'logo',
                  landingPageImages.logo as unknown as Blob
                )
              }

              if (landingPageImages.primary) {
                formData.append(
                  'banner',
                  landingPageImages.primary as unknown as Blob
                )
              }

              if (landingPageImages.secondary) {
                formData.append(
                  'partner',
                  landingPageImages.secondary as unknown as Blob
                )
              }

              await LandingPageService.uploadLandingPageImages(
                companyData?.uuid || '',
                formData
              )
            }

            await handleEditSubmit(companyData?.uuid, reqBody)
          } else {
            await handleCreateSubmit(reqBody)
          }

          await updateFirstAccessData()

          showToast({
            title: edit ? 'Empresa salva' : 'Empresa criada',
            message: edit
              ? 'Sua empresa foi alterada com sucesso'
              : 'Sua nova empresa foi criada com sucesso',
            type: 'success'
          })
          history.goBack()
        } catch (err: any) {
          showToast({
            title: 'Oops! Algo deu errado',
            message:
              err?.response?.data?.error?.messages_client[0] || err.message,
            type: 'error'
          })
        } finally {
          setLoading(false)
        }
      }
    })

  const handleFillAddress = async () => {
    try {
      setCepLoading(true)

      if (companyData && firstLoad) {
        updateFormData({
          ...data,
          street: companyData.address.street,
          neighborhood: companyData.address.neighborhood,
          city: companyData.address.city,
          state: companyData.address.state
        })
        setStateOption(companyData.address.state || '')
        setFirstLoad(false)
        setCepLoading(false)
        return
      }

      const unmaskedZipCode = unmask(data.zipcode)

      const response = await CEPService.getAddressInfoByCep(unmaskedZipCode)

      const { city, district, state, street } = response.data.data

      const stateResult =
        companyData && firstLoad && state === null
          ? states.find(
              (stateOpts) =>
                stateOpts.value.localeCompare(companyData.address.state) === 0
            )
          : states.find(
              (stateOpts) => stateOpts.value.localeCompare(state) === 0
            )

      setStateSelected(stateResult)
      setStateOption(stateResult?.value || '')
      updateFormData({
        ...data,
        street: street || '',
        neighborhood: district || '',
        city: city || '',
        state: state || '',
        buildingNumber: '',
        complement: street ? data.complement : ''
      })
      setCepDisableFields({
        street: false,
        complement: false,
        buildingNumber: false,
        neighborhood: false,
        state: !!stateResult?.value,
        city: !!city
      })
    } catch (err: any) {
      updateFormData({
        ...data,
        street: '',
        neighborhood: '',
        city: '',
        state: '',
        buildingNumber: '',
        complement: ''
      })
      setCepDisableFields({
        street: false,
        complement: false,
        buildingNumber: false,
        neighborhood: false,
        city: false,
        state: false
      })
      setStateSelected({ id: '', value: '' })
      setStateOption('')
    } finally {
      setCepLoading(false)
    }
  }

  useEffect(() => {
    if (unmask(data.zipcode).length === 8) {
      handleFillAddress()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.zipcode, data.zipcode.length])

  useEffect(() => {
    if (!colorPickerRef.current) return

    const ref = colorPickerRef.current

    const callbackFn = () => {
      const obj = {
        persist: () => null,
        target: {
          value: colorPickerRef.current?.value
        }
      }

      handleChange('mainColor')(
        obj as unknown as ChangeEvent<HTMLInputElement & HTMLSelectElement>
      )
    }

    ref.addEventListener('change', callbackFn)
    return () => ref.removeEventListener('change', callbackFn)
  }, [colorPickerRef, handleChange])

  useEffect(() => {
    const existErrorsPersonalization =
      errors?.genreArticle ||
      errors?.landingCompanyName ||
      errors?.proposalValue

    const existErrorsAbout =
      errors?.agencia || errors?.cnpj || errors?.conta || errors?.nome

    if (edit && existErrorsPersonalization) {
      setSelectedTab('personalization')
    }

    if (edit && existErrorsAbout) {
      setSelectedTab('about')
    }
  }, [edit, errors])

  return (
    <Form
      _flex={{
        direction: 'column',
        gap: '1rem'
      }}
      _spacing={{
        marginTop: '38px'
      }}
      onSubmit={(e) => {
        handleSubmit(e)

        if (!avatar) {
          setAvatarError('O logo da empresa é obrigatório')
        }

        if (isPaymentRequired && !bankSelected) {
          setBankError('Selecione um banco')
          return
        }

        if (!avatar) return

        setBankError('')
        setAvatarError('')
      }}
    >
      <S.Box>
        {edit ? (
          <S.TabWrapper>
            <S.ContentTab
              isActive={selectedTab === 'about'}
              onClick={() => setSelectedTab('about')}
            >
              <Text type="subheading-1" color="galaxy">
                Sobre a empresa
              </Text>
            </S.ContentTab>

            <S.ContentTab
              isActive={selectedTab === 'personalization'}
              onClick={() => setSelectedTab('personalization')}
            >
              <Text type="subheading-1" color="galaxy">
                Personalização
              </Text>
            </S.ContentTab>
          </S.TabWrapper>
        ) : (
          <Text type="subheading-1" color="galaxy">
            Sobre a empresa
          </Text>
        )}

        {selectedTab === 'about' ? (
          <>
            <S.InputGroup>
              <Input
                name="nome"
                animatedLabel="nome empresa"
                isRequired
                value={data.nome || ''}
                onChange={handleChange('nome')}
                message={errors.nome}
              />

              <section
                style={{ marginBottom: 5 }}
                {...(errors.nome ? { style: { marginBottom: '20px' } } : null)}
              >
                <Input
                  name="razaoSocial"
                  animatedLabel="razão social"
                  isRequired
                  value={data.razaoSocial || ''}
                  onChange={handleChange('razaoSocial')}
                  message={errors.razaoSocial}
                />

                <S.SwitchGroup>
                  <label htmlFor="">ativa</label>
                  <ToggleSwitcher
                    isActive={isActive}
                    toggleActive={(value) => setIsActive(value)}
                  />
                </S.SwitchGroup>
              </section>
              <Input
                name="cnpj"
                animatedLabel="CNPJ"
                isRequired
                value={data.cnpj || ''}
                onChange={handleChange('cnpj', validateCNPJ)}
                message={errors.cnpj}
              />

              <Input
                name="phone_number"
                animatedLabel="telefone contato *"
                message={errors.phone_number}
                value={data.phone_number}
                onChange={handleChange('phone_number', phoneMask)}
              />
            </S.InputGroup>

            <Text
              type="subheading-1"
              color="galaxy"
              _spacing={{ marginTop: '10px' }}
            >
              Endereço do estabelecimento
            </Text>

            <S.InputGroupRow customGridTemplate="1fr 1fr 1fr 0.8fr">
              <Input
                name="zipcode"
                animatedLabel="CEP *"
                message={errors.zipcode}
                value={data.zipcode}
                onChange={handleChange('zipcode', cepMask)}
                loading={cepLoading}
              />

              <Input
                name="street"
                animatedLabel="Rua/Av. *"
                disabled={cepDisableFields.street}
                message={errors.street}
                value={data.street}
                onChange={handleChange('street')}
              />

              <Input
                name="buildingNumber"
                animatedLabel="número *"
                disabled={cepDisableFields.buildingNumber}
                message={errors.buildingNumber}
                value={data.buildingNumber}
                onChange={handleChange('buildingNumber')}
              />

              <Input
                name="complement"
                animatedLabel="complemento"
                disabled={cepDisableFields.complement}
                value={data.complement}
                onChange={handleChange('complement')}
              />
            </S.InputGroupRow>

            <S.InputGroupRow customGridTemplate="1fr 1fr 1fr 0.8fr">
              <Input
                name="neighborhood"
                animatedLabel="bairro *"
                disabled={cepDisableFields.neighborhood}
                message={errors.neighborhood}
                value={data.neighborhood}
                onChange={handleChange('neighborhood')}
              />

              <Input
                name="city"
                animatedLabel="cidade *"
                message={errors.city}
                value={data.city}
                onChange={handleChange('city')}
                disabled={cepDisableFields.city}
              />

              <AutoComplete
                disabled={cepDisableFields.state}
                options={states}
                selectedOption={stateSelected}
                inputValue={stateOption}
                errorMessage={errors.state}
                onInputChange={(value: string) => setStateOption(value)}
                onChange={(value) => {
                  setStateSelected(value ? value : { id: '', value: '' })
                  setStateOption(String(value?.value))
                  updateFormData({
                    ...data,
                    state: String(value?.value)
                  })
                }}
                renderInput={(params) => (
                  <Input
                    name="state"
                    placeholder="selecione uma opção"
                    animatedLabel="estado *"
                    data-lpignore
                    {...params}
                  />
                )}
              />
            </S.InputGroupRow>

            <S.InputGroupRow customGridTemplate="1fr 1fr">
              <AutoComplete
                options={disbursementOption}
                selectedOption={payoutMethod}
                inputValue={disbursmentOption}
                onInputChange={(value: string) => setDisbursmentOption(value)}
                onChange={(value) =>
                  updatePayoutMethod(value ? value : { id: '', value: '' })
                }
                renderInput={(params) => (
                  <Input
                    _spacing={{ marginTop: '0.8rem' }}
                    name="desembolso"
                    placeholder="selecione uma opção"
                    animatedLabel="desembolso *"
                    data-lpignore
                    {...params}
                  />
                )}
              />

              {isPaymentRequired && (
                <S.BankDetailGroup paddingLeft="1rem" gap="3rem">
                  <InputRadio
                    label="Conta corrente"
                    labelFor="corrente"
                    id="corrente"
                    name="nome"
                    value="checking"
                    onChange={handleChange('tipoConta')}
                    defaultChecked
                  />
                  <InputRadio
                    label="Conta poupança"
                    labelFor="poupanca"
                    id="poupanca"
                    name="nome"
                    value="savings"
                    onChange={handleChange('tipoConta')}
                  />
                </S.BankDetailGroup>
              )}
            </S.InputGroupRow>

            {isPaymentRequired && (
              <>
                <Text type="subheading-1" color="galaxy">
                  Conta bancária para desembolso
                </Text>

                <S.InputGroup bankTemplate="1fr 1fr">
                  <AutoComplete
                    async
                    errorMessage={bankError}
                    options={banksList}
                    selectedOption={bankSelected || null}
                    isLoading={banksList.length === 0}
                    inputValue={bankTerm}
                    onInputChange={setBankTerm}
                    onChange={(value) => setBankSelected(value)}
                    renderInput={(params) => (
                      <Input
                        name="banco"
                        placeholder="escolha um banco..."
                        animatedLabel="Banco *"
                        {...params}
                      />
                    )}
                  />
                </S.InputGroup>
                <S.InputGroup bankTemplate="1fr 1fr">
                  <S.InputGroup bankTemplate="1fr 0.5fr">
                    <Input
                      name="agencia"
                      animatedLabel="Agência"
                      isRequired
                      value={data.agencia || ''}
                      onChange={handleChange('agencia', onlyNumbers)}
                      message={errors.agencia}
                    />

                    <Input
                      name="digito-agência"
                      animatedLabel="Dígito"
                      value={data.agenciaDigito || ''}
                      onChange={handleChange('agenciaDigito', onlyNumbers)}
                    />
                  </S.InputGroup>
                  <S.InputGroup bankTemplate="0.5fr 0.5fr" paddingLeft="1rem">
                    <Input
                      message={errors.conta}
                      name="conta"
                      animatedLabel="Conta"
                      isRequired
                      value={data.conta || ''}
                      onChange={handleChange('conta', onlyNumbers)}
                    />

                    <Input
                      name="digito-conta"
                      animatedLabel="Dígito"
                      isRequired
                      value={data.contaDigito || ''}
                      onChange={handleChange('contaDigito', onlyNumbers)}
                      message={errors.contaDigito}
                      _spacing={{ margin: '0 0 0 15px' }}
                    />
                  </S.InputGroup>
                </S.InputGroup>
              </>
            )}

            <S.LabelTitle>Logo da empresa</S.LabelTitle>
            <DropzoneUpload
              onChange={(files) => setAvatar(files[0]?.base64)}
              initialValue={companyData?.image_trademark}
              errorMessage={avatarError}
            />
            <Text
              type="subheading-1"
              color="galaxy"
              _spacing={{
                margin: '1rem 0'
              }}
            >
              Categoria de produto ofertadas
            </Text>

            <AutoComplete
              multiple
              tagOptions={{
                color: 'blue400',
                backgroundColor: 'blue10'
              }}
              options={
                categoriesList?.map((eachCategory) => ({
                  value: eachCategory.name,
                  id: String(eachCategory.id)
                })) || []
              }
              inputValue={faqCategoriesTerm}
              tags={tagsCategories}
              onAddTag={addCategoryTag}
              onRemoveTag={removeCategoryTag}
              onInputChange={setFaqCategoriesTerm}
              errorMessage={
                categoriesRequired && tagsCategories.length <= 0
                  ? 'selecione uma categoria'
                  : ''
              }
              renderInput={(params) => (
                <Input
                  name="categorias"
                  placeholder="escolher categorias de produto..."
                  animatedLabel="categorias de produto *"
                  {...params}
                />
              )}
            />
          </>
        ) : (
          <>
            <S.FormContainer>
              <S.FormColumn isLarger>
                <Text type="subheading-1" color="galaxy">
                  Parametrização das comunicações
                </Text>
                <S.InputGroup>
                  <Input
                    name="genreArticle"
                    animatedLabel="artigo do nome"
                    value={data.genreArticle || ''}
                    onChange={handleChange('genreArticle')}
                    message={errors.genreArticle}
                  />

                  <Input
                    message={errors.landingCompanyName}
                    name="landingCompanyName"
                    animatedLabel="nome da empresa"
                    value={data.landingCompanyName || ''}
                    onChange={handleChange('landingCompanyName')}
                  />
                </S.InputGroup>
              </S.FormColumn>

              <S.FormColumn>
                <Text type="subheading-1" color="galaxy">
                  Cor principal
                </Text>
                <S.InputGroup>
                  <Input
                    name="mainColor"
                    animatedLabel="hash da cor principal"
                    value={data.mainColor || ''}
                    onChange={handleChange('mainColor')}
                    message={errors.mainColor}
                    disabled
                  />

                  <input
                    type="color"
                    ref={colorPickerRef}
                    defaultValue={data.mainColor}
                  />
                </S.InputGroup>
              </S.FormColumn>

              <S.FormColumn>
                <Input
                  name="proposalValue"
                  animatedLabel="proposta de valor de parceria"
                  value={data.proposalValue || ''}
                  onChange={handleChange('proposalValue')}
                  message={errors.proposalValue}
                  {...(errors.proposalValue
                    ? { _spacing: { marginBottom: '2rem' } }
                    : {})}
                />

                <S.ProposalTextPreview>
                  &quot;A parceria que vai te ajudar a{' '}
                  <span>{data.proposalValue || '{proposta de valor}'}</span>
                  &quot; <br />
                  &quot;Realize o sonho de{' '}
                  <span>{data.proposalValue || '{proposta de valor}'}</span>
                  &quot;
                  <br />
                  &quot;A parceria nasceu para realizar o sonho de quem deseja{' '}
                  <span>{data.proposalValue || '{proposta de valor}'}</span>
                  &quot;
                </S.ProposalTextPreview>
              </S.FormColumn>
            </S.FormContainer>

            <S.UploadsWrapper>
              <Text
                type="subheading-1"
                color="galaxy"
                _spacing={{
                  margin: '1rem 0',
                  marginBottom: '1.5rem'
                }}
              >
                Logo da empresa para Landing Page - versão cor ou preto
              </Text>

              <DropzoneUpload
                maxWidth="360px"
                onChange={(files) => {
                  handleCompanyCustomizationImages('logo', files[0])
                }}
                initialValue={companyData?.images?.logo?.url || ''}
              />

              <Text
                type="subheading-1"
                color="galaxy"
                _spacing={{
                  margin: '1rem 0',
                  marginBottom: '1.5rem'
                }}
              >
                Imagem da campanha (para Landing Page e Email boas-vindas)
              </Text>

              <DropzoneUpload
                showTip={false}
                recommendedResolution="1233px x 530px"
                onChange={(files) =>
                  handleCompanyCustomizationImages('primary', files[0])
                }
                acceptAllImageTypes
                initialValue={companyData?.images?.banner?.url || ''}
              />

              <Text
                type="subheading-1"
                color="galaxy"
                _spacing={{
                  margin: '1.5rem 0'
                }}
              >
                Imagem secundária (para Landing Page)
              </Text>

              <DropzoneUpload
                showTip={false}
                acceptAllImageTypes
                recommendedResolution="525px x 578px"
                onChange={(files) =>
                  handleCompanyCustomizationImages('secondary', files[0])
                }
                initialValue={companyData?.images?.partner?.url || ''}
              />
            </S.UploadsWrapper>

            <Text
              type="subheading-1"
              color="galaxy"
              _spacing={{
                margin: '1rem 0'
              }}
            >
              Categoria de FAQ existentes
            </Text>

            <AutoComplete
              multiple
              tagOptions={{
                color: 'blue400',
                backgroundColor: 'blue10'
              }}
              options={faqCategoriesList || []}
              inputValue={faqCategoriesTerm}
              tags={faqCategoriesTags}
              onAddTag={addFaqCategoriesTag}
              onRemoveTag={removeFaqCategoryTag}
              onInputChange={setFaqCategoriesTerm}
              renderInput={(params) => (
                <Input
                  name="faq_categorias"
                  placeholder="escolher categorias de FAQ para Landing Page"
                  animatedLabel="categorias de FAQ *"
                  {...params}
                />
              )}
            />
          </>
        )}
      </S.Box>
      <S.ButtonGroup>
        {!hideUpdate && (
          <Button
            _sizing={{
              width: '12rem'
            }}
          >
            {edit ? 'atualizar' : 'salvar'}
          </Button>
        )}
        {edit && companyData && hideDelete && (
          <Button
            type="button"
            onClick={() => handleDelete(companyData.uuid)}
            color="white"
            backgroundColor="error"
            _sizing={{
              width: '12rem'
            }}
          >
            excluir
          </Button>
        )}
      </S.ButtonGroup>
    </Form>
  )
}
