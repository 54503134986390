/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useCallback, useState } from 'react'

import { useRouter } from 'hooks/useRouter'
import { useLoading } from 'hooks/useLoading'
import { useAppData } from 'hooks/useAppData'

import { FormUnit } from 'components/FormUnit'
import { HeaderActions } from 'components/HeaderActions'
import { UnityDTO } from 'services/remote/v1/Unit/UnitServiceTypes'

import BankService from 'services/remote/v1/Bank/BankService'

import * as S from './Unit.styles'
import { ROUTES_NAME } from 'routes'
import { PermissionsType } from 'pages/Profile/types'
import { getResourcePermissions } from 'utils/getResourcePermissions'

export interface UnitEditRouteProps {
  editData: UnityDTO
  currentUnit: string
}

export const UnitEdit = () => {
  const [banksList, setBanksList] = useState<any[]>([])

  const { history } = useRouter()
  const { location } = useRouter<UnitEditRouteProps>()
  const { setLoading } = useLoading()
  const { selectedUnit, data: appData } = useAppData()
  const [permissions, setPermissions] = useState<PermissionsType>(
    {} as PermissionsType
  )
  const init = useCallback(async () => {
    try {
      setLoading(true)

      const banksResponse = await BankService.getBanksList()

      const banks = banksResponse.data.data.banks.map((eachBank) => ({
        id: eachBank.code,
        value: `${eachBank.code} - ${eachBank.name}`
      }))

      setBanksList(banks)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }, [setLoading])

  useEffect(() => {
    init()
  }, [init])

  useEffect(() => {
    if (!location?.state?.currentUnit) {
      history.replace(ROUTES_NAME.UNITS)
      return
    }

    if (location.state.currentUnit !== selectedUnit.unitUuid) {
      history.replace(ROUTES_NAME.UNITS)
    }
  }, [location, selectedUnit, history])

  useEffect(() => {
    const _permissions = getResourcePermissions(appData, 'CAD', 'CAD_UNI')

    if (_permissions) setPermissions(_permissions)
  }, [appData])

  return (
    <S.Container>
      <HeaderActions
        title="editar unidade"
        hasReturnButton={true}
        returnButtonAction={() => history.goBack()}
        renderCustomRightCorner={<></>}
      />
      <FormUnit
        edit
        editData={location.state?.editData}
        banksList={banksList}
        hideDelete={!permissions.exclude}
        hideUpdate={!permissions.write}
      />
    </S.Container>
  )
}
