/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useCallback } from 'react'

import { useLoading } from 'hooks/useLoading'
import { useRouter } from 'hooks/useRouter'
import { useAppData } from 'hooks/useAppData'
import { useToast } from 'hooks/useToast'

import { FormCompany } from 'components/FormCompany'
import { HeaderActions } from 'components/HeaderActions'
import { CategoryDTO } from 'services/remote/v1/Category/CategoryServiceTypes'
import CategoryService from 'services/remote/v1/Category/CategoryService'
import BankService from 'services/remote/v1/Bank/BankService'
import { ROUTES_NAME } from 'routes'

interface RouteStateType {
  currentUnit: string
}

export const CompanyCreate = () => {
  const [categoriesList, setCategoriesList] = useState<CategoryDTO[]>([])
  const [banksList, setBanksList] = useState<any[]>([])

  const { setLoading } = useLoading()
  const { history, location } = useRouter<RouteStateType>()
  const { selectedUnit } = useAppData()
  const { showToast } = useToast()

  const init = useCallback(async () => {
    try {
      setLoading(true)

      const [categoriesResponse, banksResponse] = await Promise.all([
        CategoryService.getAllCategories(),
        BankService.getBanksList()
      ])

      const banks = banksResponse.data.data.banks.map((eachBank) => ({
        id: eachBank.code,
        value: `${eachBank.code} - ${eachBank.name}`
      }))

      setBanksList(banks)
      setCategoriesList(categoriesResponse.data.data)
    } catch (err: any) {
      showToast({
        title: 'Oops! Algo deu errado',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })

      history.goBack()
    } finally {
      setLoading(false)
    }
  }, [setLoading, showToast, history])

  useEffect(() => {
    init()
  }, [init])

  useEffect(() => {
    if (!location?.state?.currentUnit) {
      history.push(ROUTES_NAME.COMPANY)
      return
    }

    if (location.state.currentUnit !== selectedUnit.unitUuid) {
      history.push(ROUTES_NAME.COMPANY)
    }
  }, [location, selectedUnit, history])

  return (
    <>
      <HeaderActions
        title="nova empresa"
        hasReturnButton
        returnButtonAction={() => history.goBack()}
        renderCustomRightCorner={<></>}
      />
      <FormCompany
        edit={false}
        categoriesList={categoriesList}
        banksList={banksList}
      />
    </>
  )
}
