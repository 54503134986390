import Assets from 'assets'
import { isEmpty } from 'utils/is-empty'
import * as S from './ExportButton.styles'

type ExportButtonProps = {
  headers?:
    | {
        label: string
        key: string
      }[]
    | string[]

  data: any[]
  filename: string
  marginTop?: number | string
  useButton?: boolean
}
export const ExportButton = ({
  filename,
  headers,
  data,
  marginTop,
  useButton
}: ExportButtonProps) => {
  return !useButton ? (
    <S.ExportCSVIcon
      filename={filename}
      headers={headers}
      data={data}
      marginTop={marginTop}
      hide={isEmpty(data)}
    >
      <Assets
        alt="download"
        assetProps={{ key: 'downloadIcon', type: 'icon' }}
      />
    </S.ExportCSVIcon>
  ) : (
    <S.ExportCSVButton filename={filename} headers={headers} data={data}>
      Exportar
    </S.ExportCSVButton>
  )
}
