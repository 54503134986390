import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'
import { GetAllOccupationsResponse } from './OccupationServiceTypes'

class OccupationService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Get all occupations
   * return GetAllOccupationsResponse
   */
  public getAllOccupations = () => {
    return this.instance.get<GetAllOccupationsResponse>('/occupations')
  }
}

export default new OccupationService()
