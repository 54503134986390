import { useEffect, useState, MouseEvent, memo } from 'react'

import { useAppData } from 'hooks/useAppData'
import { useRouter } from 'hooks/useRouter'
import Assets from 'assets'

import * as S from './ModuleList.styles'
import { ModuleDTO, ResourceDTO } from 'models'

const mappedModuleIcons = {
  CAD: 'lineEdit' as const,
  ATN: 'editPencil' as const,
  DASH: 'homeIcon' as const,
  FER: 'manage' as const
}

const mappedResourcesIcons = {
  CAD_EMPR: 'companyIcon' as const,
  CAD_EQUIP: 'userIcon' as const,
  CAD_UNI: 'unitsIcon' as const,
  CAD_PRD: 'productIcon' as const,
  CAD_PER: 'profileIcon' as const,
  CAD_MOT: 'reasonIcon' as const,
  ATEN_NV: 'newAttendanceIcon' as const,
  ATEN_HIS: 'attendanceHistoryIcon' as const,
  DASH: 'dashboardIcon' as const,
  ATEN_PROG: 'followUpIcon' as const,
  FEC: 'reportsIcon' as const,
  NEG_IMP: 'fileUploadBlank' as const,
  BNPL_OFRS: 'newAttendanceIcon' as const
}

export type ModuleListProps = {
  isCollapsed?: boolean
  handleCollapseButton: () => void
}

const ModuleListMemo = ({
  isCollapsed,
  handleCollapseButton
}: ModuleListProps) => {
  const [selectedModule, setSelectedModule] = useState<ModuleDTO | null>(null)
  const { history, location } = useRouter()
  const { data } = useAppData()

  const handleModuleOnClick = (module: ModuleDTO) => {
    if (module === selectedModule) {
      setSelectedModule(null)
      return
    }

    setSelectedModule(module)
  }

  const handleResourceOnClick = (
    event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>,
    resource: ResourceDTO
  ) => {
    event.stopPropagation()
    history.push(resource.link)
  }

  useEffect(() => {
    setSelectedModule(data?.modules[0] || null)
  }, [data])

  return (
    <S.Container isCollapsed={isCollapsed}>
      {data?.modules &&
        data.modules.map((eachModule) => {
          const iconKey = (
            eachModule?.abbreviation ? eachModule.abbreviation : 'CAD'
          ) as keyof typeof mappedModuleIcons

          return isCollapsed === true ? (
            <S.CollapsedIcons
              key={eachModule.abbreviation}
              onClick={() => {
                handleModuleOnClick(eachModule)
                handleCollapseButton()
              }}
            >
              <Assets
                alt=""
                assetProps={{
                  key: mappedModuleIcons[iconKey],
                  type: 'icon'
                }}
              />
            </S.CollapsedIcons>
          ) : (
            <S.ModuleContainer
              key={eachModule.abbreviation}
              resourcesLength={eachModule.resources.length}
              isSelected={
                selectedModule?.abbreviation === eachModule.abbreviation
              }
              onClick={() => handleModuleOnClick(eachModule)}
            >
              <S.ModuleHeader>
                <Assets
                  alt=""
                  assetProps={{
                    key: mappedModuleIcons[iconKey],
                    type: 'icon'
                  }}
                />
                {eachModule.name}
              </S.ModuleHeader>
              <S.ModuleResourcesWrapper>
                {eachModule.resources.map((eachResource) => {
                  const resourceIconKey = (
                    eachResource?.abbreviation
                      ? eachResource.abbreviation
                      : 'DASH'
                  ) as keyof typeof mappedResourcesIcons

                  return (
                    <S.ResourceItem
                      isSelected={
                        eachResource.link.localeCompare(location.pathname) === 0
                      }
                      key={eachResource.name}
                      onClick={(event) =>
                        handleResourceOnClick(event, eachResource)
                      }
                    >
                      <Assets
                        alt=""
                        assetProps={{
                          key: mappedResourcesIcons[resourceIconKey],
                          type: 'icon'
                        }}
                      />
                      {eachResource.name}
                    </S.ResourceItem>
                  )
                })}
              </S.ModuleResourcesWrapper>
            </S.ModuleContainer>
          )
        })}
    </S.Container>
  )
}

export const ModuleList = memo(ModuleListMemo)
