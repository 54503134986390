/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { renderRestTypes } from '../typings/utils/renderRestTypes'
import styled, { css, DefaultTheme } from 'styled-components'
import { TextProps } from '.'

type modifiersProps = {
  theme: DefaultTheme
} & TextProps

const modifiers = {
  type: {
    headline: ({ theme }: modifiersProps) => css`
      font-size: 3.5rem;
      font-weight: ${theme.font.weight['weight-400']};
    `,
    title: ({ theme }: modifiersProps) => css`
      font-size: 1.25rem;
      font-weight: ${theme.font.weight['weight-400']};
    `,
    'subheading-1': ({ theme }: modifiersProps) => css`
      font-size: 1rem;
      font-weight: ${theme.font.weight['weight-400']};
    `,
    'subheading-2': ({ theme }: modifiersProps) => css`
      font-size: 1rem;
      font-weight: ${theme.font.weight['weight-700']};
    `,
    'body-1': ({ theme }: modifiersProps) => css`
      font-size: 0.875rem;
      font-weight: ${theme.font.weight['weight-400']};
    `,
    'body-2': ({ theme }: modifiersProps) => css`
      font-size: 0.875rem;
      font-weight: ${theme.font.weight['weight-600']};
    `,
    'body-3': ({ theme }: modifiersProps) => css`
      font-size: 0.875rem;
      font-weight: ${theme.font.weight['weight-700']};
    `,
    'caption-1': ({ theme }: modifiersProps) => css`
      font-size: 0.75rem;
      font-weight: ${theme.font.weight['weight-400']};
    `,
    'caption-2': ({ theme }: modifiersProps) => css`
      font-size: 0.75rem;
      font-weight: ${theme.font.weight['weight-600']};
    `,
    'caption-3': ({ theme }: modifiersProps) => css`
      font-size: 0.75rem;
      font-weight: ${theme.font.weight['weight-700']};
    `
  }
}

export const Text = styled('p').attrs<TextProps>(({ tag = 'p' }) => ({
  as: tag
}))`
  ${({
    theme,
    size,
    type,
    color,
    custom,
    _flex,
    _layout,
    weight = 'weight-300',
    _spacing,
    _sizing,
    _positioning
  }: modifiersProps) => css`
    ${!type && `font-size: ${size}`};
    ${!type && `font-weight: ${theme.font.weight[weight]}`};
    ${type && modifiers.type[type]};
    ${!!custom?.marginBottom && `margin-bottom: ${custom?.marginBottom}`};
    ${!!custom?.marginTop && `margin-top: ${custom?.marginTop}`};
    ${!!color && `color: ${theme.colors[color!]}`};

    ${renderRestTypes({
      _flex: _flex,
      _layout: _layout,
      _positioning: _positioning,
      _sizing: _sizing,
      _spacing: _spacing
    })}
  `}
`
