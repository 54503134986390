import { renderRestTypes } from 'components/typings/utils/renderRestTypes'
import styled, { css } from 'styled-components'
import { FormProps } from './Form'

export const FormContainer = styled.form<FormProps>`
  display: flex;
  ${({ _layout, _positioning, _sizing, _spacing, _flex }) => css`
    ${renderRestTypes({ _spacing, _sizing, _layout, _positioning, _flex })}
  `}
`
