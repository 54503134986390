import { ExportButton } from 'components/ExportButton'
import { Text } from 'components/Text'
import { WrapperExport } from 'pages/Dashboard/Dashboard.styles'
import Skeleton from 'react-loading-skeleton'
import * as S from './ReasonFrequentRefusal.styles'
interface ProposalAveragesData {
  name: string
  total: number
}

interface ReasonFrequentRefusalProps {
  dataset?: ProposalAveragesData[]
  headers: any[]
  hasPermissionExport: boolean
}

export const ReasonFrequentRefusal = ({
  dataset,
  headers = [],
  hasPermissionExport = false
}: ReasonFrequentRefusalProps) => {
  const maxValue = dataset?.reduce(
    (prev, curr) => {
      if (prev.total > curr.total) {
        return prev
      } else {
        return curr
      }
    },
    { name: '', total: 0 }
  ).total

  return (
    <S.Wrapper>
      {hasPermissionExport && (
        <WrapperExport hide={hasPermissionExport}>
          <ExportButton
            data={dataset || []}
            filename="motivosDeRecusa.csv"
            headers={headers}
            marginTop="1rem"
          />
        </WrapperExport>
      )}

      <Text
        type="subheading-2"
        _spacing={{
          marginBottom: '1rem'
        }}
      >
        motivos de recusa frequentes
      </Text>
      {maxValue ? (
        dataset?.map((data) => (
          <S.Item key={data.name}>
            <S.NameItem>{data.name}</S.NameItem>
            <S.BarContainer>
              <S.BarContent percentage={(data.total / maxValue) * 100} />
              <S.BarValue>{data.total}</S.BarValue>
            </S.BarContainer>
          </S.Item>
        ))
      ) : (
        <S.EmptyReasonFrequentRefusal>
          <S.WrapperSkeleton>
            <Skeleton width={175} height={27} />
            <Skeleton width={100} height={27} />
            <Skeleton width={50} height={27} />
          </S.WrapperSkeleton>
          <Text color="text" weight="weight-600">
            assim que propostas forem recusadas, os motivos aparecerão aqui
          </Text>
        </S.EmptyReasonFrequentRefusal>
      )}
    </S.Wrapper>
  )
}
