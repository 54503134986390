import Assets from 'assets'
import * as S from './FillFormButton.styles'

interface FillFormButtonProps {
  onClick: () => void
}

export const FillFormButton = ({ onClick }: FillFormButtonProps) => {
  return (
    <S.Container onClick={onClick}>
      <Assets alt="edit" assetProps={{ type: 'icon', key: 'editPencil' }} />
    </S.Container>
  )
}
