/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react'

import { useRouter } from 'hooks/useRouter'
import useForm from 'hooks/useForm'
import { useAppData } from 'providers/AppDataProvider'
import { useModal } from 'providers/ModalProvider'
import { useLoading } from 'hooks/useLoading'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Form } from 'components/Form'
import { StepsWithIconReturn } from 'templates/StepsWithIconReturn'
import { CommonModal } from 'templates/Modals/CommonModal'

import AuthenticationService from 'services/remote/v1/Authorizer/AuthenticationService'
import { ROUTES_NAME } from 'routes'

type RouteStateType = {
  uuidLogin: string
  email: string
}

type FormDataType = {
  password: string
}

export const AccessWithPassword = () => {
  const { history, location } = useRouter<RouteStateType>()

  const { dispatch } = useModal()
  const { setLoading } = useLoading()
  const { data: appData, updateAuthToken } = useAppData()
  const { errors, handleChange, handleSubmit, setIsSubmitting, data } =
    useForm<FormDataType>({
      onSubmit: () => handleOnSubmit(data),
      validations: {
        password: {
          required: {
            message: 'não esqueça sua senha',
            value: true
          }
        }
      }
    })

  useEffect(() => {
    if (!location.state?.uuidLogin) {
      history.replace(ROUTES_NAME.LOGIN)
    }
  }, [history, location.state])

  useEffect(() => {
    if (!appData) return
    history.push(ROUTES_NAME.PROFILE)
  }, [appData, history])

  const handleGoBack = () => {
    history.goBack()
  }

  const handleForgetPassword = async () => {
    const { uuidLogin, email } = location.state

    try {
      setLoading(true)
      const { data } = await AuthenticationService.generatePin(uuidLogin)

      history.push({
        pathname: ROUTES_NAME.RESET_PASSWORD,
        state: {
          pinUuid: data?.data?.uuid_pin,
          uuidLogin: uuidLogin,
          flowType: 'pinSent',
          email
        }
      })
    } catch (err: any) {
      if (
        err?.response.status === 401 &&
        err?.response?.data?.data?.expiration_time
      ) {
        history.push({
          pathname: ROUTES_NAME.FIRST_ACCESS,
          state: {
            pinUuid: '',
            uuidLogin: uuidLogin,
            flowType: 'pinBlocked',
            email
          }
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const handleOnSubmit = async ({ password }: FormDataType) => {
    try {
      setIsSubmitting(true)
      setLoading(true)

      const tokenResponse = await AuthenticationService.authenticateUser(
        location.state.uuidLogin,
        password
      )

      if (!tokenResponse.data.data) {
        throw new Error(tokenResponse.data.error?.messages_client[0])
      }

      updateAuthToken(tokenResponse.data.data.access_token)
    } catch (err: any) {
      const statusCode = err.response.status

      const renderTitle = () => {
        switch (statusCode) {
          case 404:
            return 'usuário desabilitado'
          case 401:
            return 'erro no login'
          default:
            return 'erro no login'
        }
      }

      const renderDescription = () => (
        <p>{err?.response?.data?.error?.messages_client[0] || err.message}</p>
      )
      dispatch({
        action: 'OPEN_MODAL',
        component: (
          <CommonModal
            maxWidth={[statusCode].includes(404)}
            title={renderTitle()}
            description={renderDescription()}
            primaryButtonTitle="voltar"
            primaryButtonProps={{
              fontSize: '1rem',
              backgroundColor: 'noverde'
            }}
            primaryButtonAction={() => dispatch({ action: 'CLOSE_MODAL' })}
          />
        )
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <StepsWithIconReturn
      notStep={false}
      title={
        <>
          que bom <strong>te</strong> <br />
          <strong>ver de novo</strong>
        </>
      }
      stepTitle={
        <>
          <strong>insira sua</strong> senha
        </>
      }
      onClickReturnIcon={handleGoBack}
    >
      <Form
        onSubmit={handleSubmit}
        _flex={{
          alignItems: 'center',
          direction: 'column',
          justifyContent: 'center'
        }}
      >
        <Input
          message={errors.password}
          onChange={handleChange('password')}
          name="senha"
          animatedLabel="senha"
          placeholder=" "
          isRequired-
          isSecuryText
          _spacing={{
            marginTop: '3rem',
            marginBottom: '5rem'
          }}
        />
        <Button
          type="submit"
          _spacing={{
            marginBottom: '2rem'
          }}
          backgroundColor="dotzOrange"
        >
          Fazer login
        </Button>
        <Button
          type="button"
          color="dotzOrange"
          variant="textPrimary"
          onClick={handleForgetPassword}
        >
          esqueci minha senha
        </Button>
      </Form>
    </StepsWithIconReturn>
  )
}
