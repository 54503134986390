import { renderRestTypes } from 'components/typings/utils/renderRestTypes'
import styled, { css } from 'styled-components'

import { CustomerInformationProps } from '.'

export const Root = styled.div<Partial<CustomerInformationProps>>`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: fit-content;
  width: 100%;

  ${({ _flex, _layout, _spacing, _sizing, _positioning }) => css`
    ${renderRestTypes({
      _flex: _flex,
      _layout: _layout,
      _positioning: _positioning,
      _sizing: _sizing,
      _spacing: _spacing
    })}
  `}
`

export const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  row-gap: 1.5rem;
  height: fit-content;
  width: 100%;
`

export const BoxItem = styled.div`
  display: grid;
  height: fit-content;
  width: fit-content;
`
