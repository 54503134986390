import { useRef } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import pt from 'date-fns/locale/pt-BR'
import { format } from 'date-fns'

import { DatePickerInput } from './DatePickerInput'

registerLocale('pt-BR', pt)

interface CustomDatePickerProps {
  dateRange: (null | Date)[]
  setDateRange: (selectedRange: (null | Date)[]) => void
}

export const CustomDatePicker = ({
  dateRange,
  setDateRange
}: CustomDatePickerProps) => {
  const [startDate, endDate] = dateRange

  const datePickerRef = useRef<DatePicker>(null)

  const handleFocus = () => {
    datePickerRef?.current?.setOpen(true)
  }

  const parsedInputDate = () => {
    if (!startDate || !endDate) return ''
    const parsedStartDate = format(startDate, 'dd/MM/yyyy')
    const parsedEndDate = format(endDate, 'dd/MM/yyyy')

    return `${parsedStartDate} - ${parsedEndDate}`
  }

  return (
    <DatePicker
      ref={datePickerRef}
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      dateFormat="dd/MM/yyyy"
      locale="pt-BR"
      customInput={
        <DatePickerInput
          name="datePicker"
          handleFocus={handleFocus}
          value={parsedInputDate()}
        />
      }
      onChange={(update) => {
        if (Array.isArray(update)) setDateRange(update)
      }}
    />
  )
}
