import { appearFromRight } from 'layout/animations'
import styled, { css } from 'styled-components'

interface ContentTabProps {
  isActive?: boolean
}

interface InputGroupProps {
  isBankTemplate?: boolean
  bankTemplate?: string
  paddingLeft?: string
}

interface FormColumnProps {
  isLarger?: boolean
}

interface BankDetailGroupProps {
  gap?: string
  justifyContent?: string
  paddingLeft?: string
}

export const Box = styled.div`
  ${({ theme }) => css`
    animation: ${appearFromRight} 0.5s;
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.greyborders};
    border-radius: 8px;
    display: grid;
    height: fit-content;
    padding: 1rem 1.5rem;
    row-gap: 1rem;
    width: 100%;
  `}
`

export const InputGroup = styled.div<InputGroupProps>`
  display: grid;
  gap: 0.75rem;
  grid-template-columns: '1fr';
  grid-template-columns: ${({ isBankTemplate }) =>
    isBankTemplate ? '1fr 0.25fr 0.75fr' : '1fr 1fr'};

  ${({ bankTemplate }) =>
    bankTemplate &&
    css`
      grid-template-columns: ${bankTemplate};
    `}
  ${({ paddingLeft }) =>
    paddingLeft &&
    css`
      padding-left: ${paddingLeft};
    `}

  section {
    align-items: center;
    justify-items: center;
    display: grid;
    gap: 0.75rem;
    grid-template-columns: 1fr auto;
    grid-auto-flow: row;

    div {
      align-items: center;
      display: grid;
      grid-auto-flow: row;
    }
  }
`

export const ButtonGroup = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  flex-direction: row-reverse;
  margin-top: 1rem;
  width: 100%;
`

export const SwitchGroup = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: -10px;
  gap: 5px;

  label {
    color: #646e7d;
  }
`

export const LabelTitle = styled.span`
  display: block;
  margin-bottom: 10px;
  margin-top: 1.5rem;
`

export const TabWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 26px;

  p + p {
    height: 100%;
    margin-left: 60px;
  }

  div {
    height: 100%;
    padding: 26px;
    padding-top: 16px;
    cursor: pointer;
  }
`

export const ContentTab = styled.div<ContentTabProps>`
  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 5px solid #151b41;
    `}
`

export const FormContainer = styled.div`
  display: flex;
  gap: 46px;
  flex-wrap: wrap;
`

export const FormColumn = styled.div<FormColumnProps>`
  display: flex;
  flex-direction: column;
  flex: ${({ isLarger }) => (isLarger ? 1.5 : 1)};
  min-width: 40%;
  max-width: 55%;

  p {
    margin-bottom: 20px;
  }

  input[type='color'] {
    -webkit-appearance: none;
    border: none;
    width: 52px;
    height: 52px;
  }
  input[type='color']::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type='color']::-webkit-color-swatch {
    border: none;
    border-radius: 8px;
  }
`

export const ProposalTextPreview = styled.p`
  color: #646e7d;
  line-height: 25px;

  span {
    font-weight: 600;
  }
`

export const UploadsWrapper = styled.div`
  margin-top: 24px;
`

export const BankDetailGroup = styled.div<BankDetailGroupProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  gap: ${(props) => (props.gap ? props.gap : '')};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '')};
`

interface InputGroupRowProps {
  customGridTemplate?: string
}

export const InputGroupRow = styled.div<InputGroupRowProps>`
  display: grid;
  grid-template-columns: ${({ customGridTemplate }) =>
    customGridTemplate ? customGridTemplate : '1fr 1fr 1fr'};
  gap: 15px;
`
