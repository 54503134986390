import styled, { css } from 'styled-components'

import { devices } from 'layout/devices'

interface SliderItemProps {
  bgImage: string
}

export const SliderContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    height: 100%;
    overflow: hidden;

    ul {
      &.slick-dots {
        bottom: 20px;

        @media ${devices.small} {
          bottom: 50px;
        }
      }

      li {
        width: 10px;
        height: 10px;
        margin: 0 8px;

        @media ${devices.small} {
          width: 16px;
          height: 16px;
        }
        button {
          width: 100%;
          height: 100%;
          background: ${theme.colors.black};
          border-radius: 16px;
          padding: 0;
          &:before {
            content: '';
          }
        }

        &.slick-active {
          transition: all 0.3s;
          button {
            transition: all 0.3s;
            background: ${theme.colors.white};
          }
        }
      }
    }
  `}
`

export const SliderItem = styled.div<SliderItemProps>`
  ${({ bgImage }) => css`
    position: relative;

    background-image: url(${bgImage});
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
  `}
`

export const SliderHolder = styled.div`
  display: flex;
  align-items: flex-end;
  height: calc(100vh - 190px);
  background: linear-gradient(
    0.85deg,
    rgba(0, 0, 0, 0.69) 39.36%,
    rgba(0, 0, 0, 0.0905992) 47.46%,
    rgba(0, 0, 0, 0.00881356) 85.31%,
    rgba(0, 0, 0, 0.54) 92.16%
  );

  @media ${devices.small} {
    height: 100vh;
  }
`

export const ContentWrapper = styled.div`
  padding: 0 15px 50px;

  display: flex;
  flex-direction: column;

  @media ${devices.small} {
    padding: 0 15px 110px;
  }

  @media ${devices.xlarge} {
    padding: 0 30px 110px;
  }
`

export const IconContent = styled.div`
  display: flex;
  align-items: center;
  padding: 6% 0 5px 0;
  @media ${devices.xlarge} {
    padding: 6% 0 20px 0;
  }
`

export const SliderIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`

export const TextStyled = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: 18px;
    @media ${devices.large} {
      font-size: 24px;
    }
  `}
`

export const TitleStyled = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: 30px;
    line-height: 38px;
    @media ${devices.large} {
      font-size: 54px;
      line-height: 64px;
    }
  `}
`

export const TopLogo = styled.div`
  position: absolute;
  top: 0;
  margin: 15px auto;
  width: 100%;
  text-align: center;

  img {
    width: 150px;
  }
`
