export const PartnersTheme = {
  colors: {
    acqua: '#00ffb2',
    backgrounds: '#F9FAFD',
    black: '#000000',
    blue10: ' #EFF8FF',
    blue400: '#1890FF',
    blue500: '#2F80ED',
    blue700: '#001E65',
    blue800: '#151B41',
    blue900: '#080a18',
    error: '#FF6464',
    galaxy: '#0c0f25',
    greyborders: '#E2E2E2',
    green10: '#F3FFF9',
    green400: '#27AE60',
    noverde: '#0cd15b',
    orange10: '#FFFBF5',
    placeholders: '#AAABB6',
    red10: '#F7D6D2',
    sunset: '#ff9900',
    text: '#646e7d',
    white: '#ffffff',
    preview: 'rgba(0, 30, 101, 1)',
    purple: '#9B51E0',
    orange200: '#F3C56A',
    dotzOrange: '#FF4F0E',
    dotzLightOrange: '#FF910E'
  },
  font: {
    family: {
      default: 'Open Sans'
    },
    weight: {
      'weight-100': 100,
      'weight-200': 200,
      'weight-300': 300,
      'weight-400': 400,
      'weight-500': 500,
      'weight-600': 600,
      'weight-700': 700,
      'weight-800': 800,
      'weight-900': 900
    }
  },
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  }
}

export type Colors = keyof typeof PartnersTheme.colors
