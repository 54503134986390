import { HeaderActions } from 'components/HeaderActions'
import { FormProduct } from 'components/FormProduct/FormProduct'
import { useRouter } from 'hooks/useRouter'

import * as S from './Product.styles'

export const ProductCreate = () => {
  const { history } = useRouter()
  return (
    <S.Container>
      <HeaderActions
        title="novo produto"
        returnButtonAction={() => {
          history.goBack()
        }}
        hasReturnButton={true}
        renderCustomRightCorner={<></>}
      />
      <FormProduct edit={false} />
    </S.Container>
  )
}
