const baseUrlAuthorizer = process.env.REACT_APP_API_AUTHORIZER
const baseUrlPortal = process.env.REACT_APP_API_PORTAL

export const endpoints = {
  remote: {
    v1: {
      authorizer: baseUrlAuthorizer,
      portal: baseUrlPortal
    },
    v2: {
      Zendesk: `https://noverde.zendesk.com/api/v2/help_center`
    }
  }
}
