import { useCallback, useMemo, useState } from 'react'

export function useCollapsedSidebar() {
  const [isCollapsed, setIsCollapsed] = useState<boolean>()

  const handleCollapseButton = useCallback(() => {
    setIsCollapsed(!isCollapsed)
  }, [isCollapsed])

  return useMemo(() => {
    return {
      isCollapsed,
      handleCollapseButton
    }
  }, [handleCollapseButton, isCollapsed])
}
