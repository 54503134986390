import { ChangeEvent } from 'react'
import * as S from './Checkbox.styles'

import { RestTypes } from 'components/typings/utils/restTypes'

type CheckboxProps = {
  label?: string
  isChecked: boolean
  labelId?: string
  toggleChecked: (value: boolean) => void
  isDisabled?: boolean
} & RestTypes

export const Checkbox = ({
  label,
  isChecked,
  toggleChecked,
  labelId,
  isDisabled = false,
  ...rest
}: CheckboxProps) => {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    toggleChecked(event.target.checked)
  }

  const checkbox = (
    <S.CheckboxContainer>
      <S.HiddenCheckbox
        id={labelId || undefined}
        checked={isChecked}
        onChange={handleOnChange}
        disabled={isDisabled}
      />
      <S.StyledCheckbox isChecked={isChecked} isDisabled={isDisabled} />
    </S.CheckboxContainer>
  )

  if (labelId) return checkbox

  return (
    <S.Label {...rest} isDisabled={isDisabled}>
      <span>{label}</span>
      {checkbox}
    </S.Label>
  )
}
