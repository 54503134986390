import { Route, Redirect, RouteProps } from 'react-router-dom'

interface RouteGatewayProps extends RouteProps {
  children: React.ReactNode
  permissions: string[]
}

export const RouteGateway = ({
  children,
  permissions,
  path,
  ...rest
}: RouteGatewayProps) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return permissions.some((permission) =>
          permission.includes(path as string)
        ) ? (
          children
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }}
    />
  )
}
