import { useState, useEffect, useMemo } from 'react'

let timeout: NodeJS.Timeout

export const useCountdown = () => {
  const [time, setTime] = useState(0 * 60)
  const [isActiveTime, setIsActiveTime] = useState(false)

  const minutes = Math.floor(time / 60)
  const seconds = time % 60

  const [minutesLeft, minutesRight] = String(minutes).padStart(2, '0').split('')
  const [secondsLeft, secondsRight] = String(seconds).padStart(2, '0').split('')

  useEffect(() => {
    if (isActiveTime && time > 0) {
      timeout = setTimeout(() => {
        setTime(time - 1)
      }, 1000)
    }
    if (time === 0) setIsActiveTime(false)
    return () => clearTimeout(timeout)
  }, [isActiveTime, time])

  const timeValue = `${minutesLeft + minutesRight}:${
    secondsLeft + secondsRight
  }`

  return useMemo(() => {
    return { timeValue, isActiveTime, setTime, setIsActiveTime }
  }, [isActiveTime, timeValue])
}
