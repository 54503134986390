import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 1.5rem 2.5rem;
  width: 100%;
`

export const Icon = styled.div`
  cursor: pointer;
  margin-left: auto;
`
