import * as S from './CommonModal.styles'

import { Button, ButtonProps } from 'components/Button'
import { ReactNode } from 'react'

interface CommonModalProps {
  title?: string
  description?: string | ReactNode
  primaryButtonProps?: ButtonProps
  primaryButtonTitle: string
  primaryButtonAction?: () => void
  secondaryButtonProps?: ButtonProps
  secondaryButtonTitle?: string
  secondaryButtonAction?: () => void
  maxWidth?: boolean
}

export const CommonModal = ({
  title,
  description,
  primaryButtonProps,
  primaryButtonTitle,
  primaryButtonAction,
  secondaryButtonProps,
  secondaryButtonAction,
  secondaryButtonTitle,
  maxWidth = false
}: CommonModalProps) => {
  return (
    <S.Container maxWidth={maxWidth}>
      <S.Title>{title}</S.Title>
      <S.Description maxWidth={maxWidth}>
        {typeof description === 'string' ? <p>{description}</p> : description}
      </S.Description>
      <S.ButtonsWrapper
        hasOnlyPrimaryButton={!secondaryButtonTitle}
        maxWidth={maxWidth}
      >
        {secondaryButtonTitle && (
          <Button onClick={secondaryButtonAction} {...secondaryButtonProps}>
            {secondaryButtonTitle}
          </Button>
        )}
        <Button {...primaryButtonProps} onClick={primaryButtonAction}>
          {primaryButtonTitle}
        </Button>
      </S.ButtonsWrapper>
    </S.Container>
  )
}
