import { RestTypes } from 'components/typings/utils/restTypes'
import React, { useRef } from 'react'

import * as S from './Pin.styles'

export type PinProps = {
  pin: Array<number | undefined>
  pinLength: number
  updatePinValues?: (values: Array<number>) => void
  onPinChanged: (pinEntry: number | undefined, index: number) => void
} & RestTypes

const PIN_MIN_VALUE = 0
const PIN_MAX_VALUE = 9
const BACKSPACE_KEY = 'Backspace'

export const Pin = ({
  pinLength,
  pin,
  onPinChanged,
  updatePinValues,
  ...props
}: PinProps) => {
  const inputRefs = useRef<HTMLInputElement[]>([])

  const removeValuesFromArray = (valuesArray: string[], value: string) => {
    const valueIndex = valuesArray.findIndex((entry) => entry === value)
    if (valueIndex === -1) return
    valuesArray.splice(valueIndex, 1)
  }

  const changePinFocus = (pinIndex: number) => {
    const ref = inputRefs.current[pinIndex]
    if (ref) ref.focus()
  }

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const previousValue = event.target.defaultValue
    const valuesArray = event.target.value.split('')
    removeValuesFromArray(valuesArray, previousValue)
    const value = valuesArray.pop()
    if (!value) {
      return
    }
    const pinNumber = Number(value.trim())
    if (isNaN(pinNumber) || value.length === 0) {
      return
    }

    if (pinNumber >= PIN_MIN_VALUE && pinNumber <= PIN_MAX_VALUE) {
      onPinChanged(pinNumber, index)
      if (index < pinLength - 1) {
        changePinFocus(index + 1)
      }
    }
  }

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const keyboardKeyCode = event.nativeEvent.code
    if (keyboardKeyCode !== BACKSPACE_KEY) {
      return
    }

    if (pin[index] === undefined) {
      changePinFocus(index - 1)
    } else {
      onPinChanged(undefined, index)
    }
  }

  const onPaste = (event: React.ClipboardEvent) => {
    event.stopPropagation()
    event.preventDefault()

    const rgx = new RegExp(/\D/g, '')
    const value = String(event.clipboardData.getData('Text'))

    if (!rgx.test(value)) {
      const tmp = value.split('').slice(0, 4)
      if (updatePinValues) updatePinValues(tmp.map((val) => Number(val)))
      inputRefs.current[0].value = tmp[0]
      inputRefs.current[1].value = tmp[1]
      inputRefs.current[2].value = tmp[2]
      inputRefs.current[3].value = tmp[3]
    }
  }

  return (
    <S.Box {...props}>
      <S.BoxTitle>confirme o código</S.BoxTitle>
      <S.BoxesPins>
        {Array.from({ length: 4 }, (_, index) => (
          <S.BoxPin
            onPaste={onPaste}
            onKeyDown={(event) => onKeyDown(event, index)}
            key={index}
            ref={(el) => {
              if (el) inputRefs.current[index] = el
            }}
            onChange={(event) => onChange(event, index)}
            value={pin[index]?.toString() || ''}
            required
          />
        ))}
      </S.BoxesPins>
    </S.Box>
  )
}
