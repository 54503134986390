import Assets from 'assets'
import { useCallback, useEffect, useRef, useState } from 'react'
import * as S from './Tooltipstyles'

interface TooltipProps {
  text: string | string[]
  direction?: 'up' | 'down'
}

export const Tooltip = ({ text, direction = 'down' }: TooltipProps) => {
  const SpanRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [positionX, setPositionX] = useState(0)
  const [positionY, setPositionY] = useState(0)

  const onMouseEnterHandler = useCallback(() => {
    const element = SpanRef?.current
    setPositionX((element?.offsetLeft || 0) - (width > 150 ? width / 5 : 0))
    setPositionY(element?.offsetTop || 0)
  }, [width])

  useEffect(() => {
    setWidth(SpanRef?.current?.offsetWidth || 0)
    setHeight(SpanRef?.current?.offsetHeight || 0)
  }, [SpanRef])

  return (
    <>
      <S.Container onMouseEnter={onMouseEnterHandler}>
        <S.Content>
          <span ref={SpanRef}>
            <div>
              <Assets
                alt=""
                assetProps={{
                  key: 'helpIcon',
                  type: 'icon'
                }}
              />
            </div>
          </span>
        </S.Content>
        <S.TextBox
          direction={direction}
          width={width}
          height={height}
          posX={positionX}
          posY={positionY}
        >
          {Array.isArray(text) ? (
            text.map((label) => <p key={label}>{label}</p>)
          ) : (
            <p>{text}</p>
          )}
        </S.TextBox>
      </S.Container>
    </>
  )
}
