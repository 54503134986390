import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'

import { GetLeadResponse, LeadDTO } from './LeadServiceTypes'

class LeadService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Get lead's data
   */
  public getLeadByDocument = (
    uuidCompany: string,
    uuidUnit: string,
    document: string
  ) => {
    return this.instance.get<GetLeadResponse>(
      `/companies/${uuidCompany}/units/${uuidUnit}/lead?document=${document}`
    )
  }

  /**
   * Create a new lead
   */
  public createLeadToCompany = (
    uuidCompany: string,
    uuidUnit: string,
    leadsData: LeadDTO
  ) => {
    return this.instance.post<GetLeadResponse>(
      `/companies/${uuidCompany}/units/${uuidUnit}/lead`,
      leadsData
    )
  }

  /**
   * Create a new lead
   */
  public updateLead = (
    uuidCompany: string,
    uuidUnit: string,
    leadUuid: string,
    leadsData: Omit<LeadDTO, 'document' | 'uuid'>
  ) => {
    return this.instance.patch<GetLeadResponse>(
      `/companies/${uuidCompany}/units/${uuidUnit}/lead/${leadUuid}`,
      leadsData
    )
  }
}

export default new LeadService()
