import { appearFromRight } from 'layout/animations'
import styled, { css } from 'styled-components'

interface InputGroupProps {
  isBankTemplate?: boolean
  bankTemplate?: string
  paddingLeft?: string
}

interface BankDetailGroupProps {
  gap?: string
  justifyContent?: string
  paddingLeft?: string
}

export const Box = styled.div`
  ${({ theme }) => css`
    animation: ${appearFromRight} 0.5s;
    background: ${theme.colors.white};
    border-radius: 12px;
    border: 1px solid ${theme.colors.greyborders};
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin-top: 2rem;
    padding: 1.5rem 2rem;
    width: 100%;
  `}
`

export const InputGroup = styled.div<InputGroupProps>`
  display: grid;
  gap: 1rem;
  margin-bottom: 1.5rem;
  grid-template-columns: ${({ isBankTemplate }) =>
    isBankTemplate ? '1fr 0.25fr 0.75fr' : '1fr auto'};

  ${({ bankTemplate }) =>
    bankTemplate &&
    css`
      grid-template-columns: ${bankTemplate};
    `}

  ${({ paddingLeft }) =>
    paddingLeft &&
    css`
      padding-left: ${paddingLeft};
    `}
`

export const SwitchGroup = styled.div`
  align-items: center;
  display: flex;
  gap: 0.25rem;
  justify-content: center;
  flex-direction: column;
  margin-top: -14px;
`

export const ButtonGroup = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  flex-direction: row-reverse;
  margin-top: 1rem;
  width: 100%;
`

export const BankDetailGroup = styled.div<BankDetailGroupProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  gap: ${(props) => (props.gap ? props.gap : '3rem')};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '')};
`

interface InputGroupRowProps {
  customGridTemplate?: string
}

export const InputGroupRow = styled.div<InputGroupRowProps>`
  display: grid;
  grid-template-columns: ${({ customGridTemplate }) =>
    customGridTemplate ? customGridTemplate : '1fr 1fr 1fr'};
  gap: 15px;
  margin-bottom: 20px;
`
