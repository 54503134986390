import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'
import { GetAllResourcesResponse } from './ResourceProfileServiceTypes'

class ResourceProfileService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Get all resources
   * @returns GetAllResourcesResponse
   */
  public getAllResources() {
    return this.instance.get<GetAllResourcesResponse>('/resources')
  }

  public getCompanyResources(uuid: string) {
    return this.instance.get<GetAllResourcesResponse>(
      `companies/${uuid}/resources`
    )
  }
}

export default new ResourceProfileService()
