/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import Assets from 'assets'

import { HeaderActions } from 'components/HeaderActions'
import { ListTable } from 'components/ListTable'
import { ToggleSwitcher } from 'components/ToggleSwitcher'
import { useRouter } from 'hooks/useRouter'
import { useLoading } from 'providers/LoadingProvider'
import { useAppData } from 'hooks/useAppData'
import { useToast } from 'hooks/useToast'
import { ROUTES_NAME } from 'routes'
import CompanyService from 'services/remote/v1/Company/CompanyService'
import { UnityDTO } from 'services/remote/v1/Unit/UnitServiceTypes'

import * as S from './Unit.styles'
import { format } from 'date-fns'
import { formatZonedTimeToUtc } from 'utils/formatters/fomatZonedTimeToUtc'
import { PermissionsType } from 'pages/Profile/types'
import { getResourcePermissions } from 'utils/getResourcePermissions'
import { customSort } from 'utils/customSort'
import { TooltipText } from 'components/TooltipText'

interface RenderItemProps {
  item: UnityDTO
  canEdit?: boolean
}

export const Unit = () => {
  const { isLoading, setLoading } = useLoading()
  const { history } = useRouter()
  const { selectedUnit, data: appData } = useAppData()
  const [permissions, setPermissions] = useState<PermissionsType>(
    {} as PermissionsType
  )
  const { showToast } = useToast()

  const [tableListData, setTableListData] = useState<UnityDTO[]>([])

  const handleEditButton = useCallback(
    (unit: UnityDTO) => {
      history.push({
        pathname: ROUTES_NAME.UNITS_EDIT,
        state: {
          editData: { ...unit },
          currentUnit: selectedUnit.unitUuid
        }
      })
    },
    [history, selectedUnit]
  )

  const handleCreateNewUnit = () => {
    history.push({
      pathname: ROUTES_NAME.UNITS_CREATE,
      state: {
        currentUnit: selectedUnit.unitUuid
      }
    })
  }

  const getAllUnitys = useCallback(async () => {
    if (!selectedUnit.companyUuid) return
    try {
      setLoading(true)
      const { data } = await CompanyService.getAllUnitysInCompanyByUuid(
        selectedUnit.companyUuid,
        true
      )

      if (data.data.length > 0) {
        setTableListData(
          data.data?.sort((a, b) =>
            customSort(a.name.toLowerCase(), b.name.toLowerCase())
          )
        )
      }
    } catch (err: any) {
      showToast({
        title: 'Oops! Algo deu errado',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }, [setLoading, selectedUnit, showToast])

  useEffect(() => {
    getAllUnitys()
  }, [getAllUnitys])

  const renderItem = useCallback(
    ({ item, canEdit }: RenderItemProps) => {
      const formattedDate = item?.created_at
        ? format(
            new Date(formatZonedTimeToUtc(item?.created_at) || new Date()),
            'dd/MM/yyyy'
          )
        : ''
      return (
        <>
          <span>
            <TooltipText text={item?.name || ''}>
              {item?.name || <Skeleton width={200} />}
            </TooltipText>
          </span>
          <span>
            <TooltipText text={formattedDate}>
              {item?.created_at ? formattedDate : <Skeleton width={100} />}{' '}
            </TooltipText>
          </span>
          <span>
            <TooltipText text={item?.created_by || ''}>
              {item?.created_by ? item.created_by : <Skeleton width={100} />}{' '}
            </TooltipText>
          </span>
          <ToggleSwitcher
            isActive={isLoading ? false : (item?.is_active as boolean)}
            toggleActive={() => null}
            disabled
          />
          {canEdit ? (
            <S.Icon
              onClick={() => {
                handleEditButton(item)
              }}
            >
              <Assets
                assetProps={{ key: 'lineEditV2', type: 'icon' }}
                alt="editar"
              />
            </S.Icon>
          ) : (
            <></>
          )}
        </>
      )
    },
    [handleEditButton, isLoading]
  )

  useEffect(() => {
    const _permissions = getResourcePermissions(appData, 'CAD', 'CAD_UNI')

    if (_permissions) setPermissions(_permissions)
  }, [appData])

  return (
    <S.Container>
      <HeaderActions
        title="unidades"
        buttonTitle="+ nova unidade"
        buttonAction={handleCreateNewUnit}
        hasReturnButton={false}
        renderCustomRightCorner={!permissions.write ? <></> : null}
      />

      <ListTable<UnityDTO>
        headerTitle="Todas as unidades"
        tableStructure={{
          header:
            '1fr minmax(200px, 200px) 1fr minmax(60px, 60px) minmax(60px, 60px)',
          body: '1fr minmax(200px, 200px) 1fr minmax(60px, 60px) minmax(60px, 60px)',
          columns: ['nome', 'criado em', 'criado por', 'ativa', 'editar']
        }}
        data={isLoading ? Array(9).fill({}) : tableListData}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        canEdit={permissions.write || permissions.exclude}
      />
    </S.Container>
  )
}
