import { Form } from 'components/Form'
import styled, { css } from 'styled-components'

import { Text } from 'components/Text'

export const FormArea = styled(Form)`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 0.5fr 1.5fr 1fr;
  margin-top: 2rem;
  width: 100%;
`

export const Header = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`
export const Title = styled(Text)`
  margin-top: 2rem;
`
export const WrapperEmpty = styled.div`
  margin-top: -8rem;
`
