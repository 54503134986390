import { FormClosingReport } from 'components/FormClosingReport'
import { HeaderActions } from 'components/HeaderActions'

import * as S from './ClosingReport.styles'

export const ClosingReport = () => {
  return (
    <S.Container>
      <HeaderActions
        title="fechamento"
        hasReturnButton={false}
        renderCustomRightCorner={<></>}
      />
      <FormClosingReport />
    </S.Container>
  )
}
