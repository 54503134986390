import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'
import { GetFileTypes } from './ImportsTypes'

class ImportsService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Get all file types
   * return getFileTypes
   */
  public getFileTypes = () => {
    return this.instance.get<GetFileTypes>(`/imports/file-types`)
  }
}

export default new ImportsService()
