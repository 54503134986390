import styled from 'styled-components'

import { devices } from 'layout/devices'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 50px;
  padding: 27px 20px;

  button {
    max-width: 50%;
  }

  @supports (-webkit-touch-callout: none) {
    margin-bottom: 100px;
  }

  @media ${devices.large} {
    padding: 27px 50px;

    button {
      max-width: 300px;
    }
  }
`

export const FooterButtonsWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  gap: 15px;

  @supports (-webkit-touch-callout: none) {
    button:first-child {
      margin-left: 15px;
    }
  }
`

export const EditButton = styled.div`
  margin-left: auto;
  cursor: pointer;
  margin-right: 5px;
`

export const TitleInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 26px;

  @media ${devices.large} {
    padding: 0 46px;
  }
`

export const InputRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 36px;
  margin-top: 26px;

  @supports (-webkit-touch-callout: none) {
    div:first-child {
      margin-right: 20px;
    }
  }
`
export const SwitchGroup = styled.div`
  align-items: center;
  display: flex;
  gap: 0.25rem;
  justify-content: center;
  flex-direction: column;
  margin-top: -14px;
`
export const WrapperTooltip = styled.div`
  display: flex;
  gap: 10px;
`
export const WrapperSelect = styled.div`
  width: 100%;
`
