import { Form } from 'components/Form'
import { appearFromRight } from 'layout/animations'
import styled, { css } from 'styled-components'

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 1.5rem 2.5rem;
  width: 100%;
`

export const Content = styled.div`
  ${({ theme }) => css`
    animation: ${appearFromRight} 0.3s ease-in;
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.greyborders};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: fit-content;
    margin-top: 2rem;
    padding: 2rem 3rem;
    width: 100%;
  `}
`

export const InputSelectContainer = styled.div`
  width: 50%;
`

interface ButtonGroupProps {
  center?: boolean
}

export const ButtonGroup = styled.div<ButtonGroupProps>`
  display: flex;
  justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
`
