/* eslint-disable react/no-unescaped-entities */
import { Text } from 'components/Text'
import * as S from './Terms.styles'

export const Terms = () => {
  return (
    <S.Content>
      <Text>
        Noverde.com.br pertence à Noverde Tecnologia e Pagamentos S/A - CNPJ nº
        23.698.063/0001-69("Noverde"). A Noverde não é uma instituição
        financeira e não realiza operações de crédito diretamente, mas como
        correspondente bancário da (i) Lecca - Crédito, Financiamento e
        Investimento S.A. inscrita no CNPJ sob o nº 07.652.226/0001-16 ("Lecca")
        ou da (ii) Money Plus Sociedade de Crédito ao Microempreendedor e à
        Empresa de Pequeno Porte, inscrita no CNPJ sob o n° 11.581.339/0001-45
        ("BMP" nos termos do artigo 2º, da Resolução CMN nº 3.954, de 24 de
        fevereiro de 2011.
      </Text>
      <Text>
        Os correspondentes bancários são empresas contratadas por instituições
        financeiras e demais instituições autorizadas pelo Banco Central do
        Brasil para a prestação de serviços de atendimento aos clientes e
        usuários dessas instituições. Entre os correspondentes mais conhecidos
        encontram-se as lotéricas e o banco postal.
      </Text>
      <Text>
        A Noverde não cobra nenhum valor antecipado para aprovar ou depositar
        seu crédito. Caso você seja contatado por alguém, se passando pela
        Noverde, e cobrando qualquer valor antecipado, encerre o contato
        imediatamente e entre em contato com a gente. Você pode estar sendo
        vítima de uma tentativa de fraude.
      </Text>
      <Text>
        A análise de crédito e cálculo das taxas de juros dependem de diversas
        informações disponibilizadas por você, tais como dados pessoais, valor
        solicitado e número de parcelas. Toda avaliação será realizada conforme
        a política de crédito da Lecca. Antes da contratação de qualquer serviço
        através da Noverde, você receberá todas as condições e informações
        relativas ao empréstimo de forma completa e transparente, incluindo
        impostos incidentes (IOF) e o custo efetivo total (CET) da operação. O
        atraso ou não pagamento de prestações do contrato de empréstimo pessoal
        pode ter consequências legais, tais como a inclusão de nome nos
        cadastros dos órgãos de proteção ao crédito, o protesto de títulos e o
        ajuizamento de ações de cobrança.
      </Text>
      <Text>
        razo de Pagamento: O período mínimo é de 3 meses e o máximo 12 meses.
        Taxa de juros: A taxa varia entre 7,9% e 18,9% ao mês (149% a 698% ao
        ano). Exemplo de um empréstimo: R$ 1.500,00, para pagar em 12 meses e
        taxa de juros de 10,15% ao mês (219% ao ano). Total de 12 parcelas de R$
        221,77. Valor total a pagar igual a R$ 2.661,18.
      </Text>
      <Text>Copyright 2021. Noverde App. Todos os direitos reservados.</Text>
    </S.Content>
  )
}
