import * as S from './Loader.styles'

import { useLoading } from 'hooks/useLoading'

export const Loader = () => {
  const { isLoading } = useLoading()

  if (!isLoading) return <></>

  return (
    <S.Container>
      <S.Spinner />
    </S.Container>
  )
}
