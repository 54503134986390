import { appearFromRight } from 'layout/animations'
import styled, { css } from 'styled-components'

export const ContainerInviteData = styled.div`
  ${({ theme }) => css`
    animation: ${appearFromRight} 0.5s;
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.greyborders};
    border-radius: 8px;
    display: grid;
    height: fit-content;
    padding: 2rem 2.5rem;
    row-gap: 1.2rem;
    width: 100%;
  `}
`

export const ContainerInviteInputs = styled.div`
  display: grid;
  gap: 0.75rem;
  grid-template-columns: 1fr 1fr;

  section {
    align-items: center;
    justify-items: center;
    display: grid;
    gap: 0.75rem;
    grid-template-columns: 1fr auto;
    grid-auto-flow: row;

    div {
      align-items: center;
      display: grid;
      grid-auto-flow: row;
    }
  }
`
export const ButtonGroup = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`

export const SwitcherWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: -10px;
  label {
    color: #646e7d;
  }
`
