import { createGlobalStyle } from 'styled-components'
import { PartnersTheme } from 'layout/theme'
import 'react-toastify/dist/ReactToastify.css'

const ResetCSS = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
    font-family: '${PartnersTheme.font.family.default}', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif
  }
  html, body {
    height: 100%;
    overflow: hidden;
  }
  body {
    font-size: 1rem;
    line-height: 1.5;
    position: relative;
    max-height: 100vh;

    @supports (-webkit-touch-callout: none) {
      max-height: -webkit-fill-available;
    }
  }

  .Toastify__toast-container {
    .Toastify__toast--success {
      background-color: ${PartnersTheme.colors.noverde};
    }

    .Toastify__toast--error {
      background-color: ${PartnersTheme.colors.error};
    }
  }
`

export default ResetCSS
