export function validateDate(value: string) {
  const splitedValue = value.split('/')
  const day = Number(splitedValue[0])
  const month = Number(splitedValue[1])
  const year = Number(splitedValue[2])
  const age = new Date().getFullYear() - year

  if (day <= 0 || day > 31) return false
  if (month < 1 || month > 12) return false
  if (age < 18) return false

  return true
}
