import { appearFromRight } from 'layout/animations'
import styled, { css } from 'styled-components'

export const Box = styled.div`
  ${({ theme }) => css`
    animation: ${appearFromRight} 0.5s;
    background: ${theme.colors.white};
    border-radius: 12px;
    border: 1px solid ${theme.colors.greyborders};
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin-top: 2rem;
    padding: 1.5rem 2rem;
    width: 100%;
  `}
`

export const InputGroup = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 0.2fr;
`
export const SwitchGroup = styled.div`
  align-items: center;
  display: flex;
  gap: 0.25rem;
  justify-content: center;
  flex-direction: column;
  margin-top: -14px;
`

export const ButtonGroup = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  flex-direction: row-reverse;
  margin-top: 1rem;
  width: 100%;
`
