import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import {
  STORAGE_KEYS,
  getStorageValue,
  clearLocalStorage
} from 'hooks/useLocalStorage'
import log from 'utils/log'

export abstract class HttpClient {
  protected readonly instance: AxiosInstance

  public constructor(baseURL: string) {
    this.instance = axios.create({ baseURL })
    this.initializeRequestInterceptor()
    this.initializeResponseInterceptor()
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest, this.handleError)
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this.handleResponse,
      this.handleError
    )
  }

  private handleRequest = (request: AxiosRequestConfig) => {
    const accessToken = getStorageValue<string>(STORAGE_KEYS.ACCESS_TOKEN)
    if (accessToken) request.headers.Authorization = accessToken

    if (!request.baseURL?.includes('zendesk')) {
      request.headers['x-api-key'] = process.env.REACT_APP_X_API_KEY
    }

    return request
  }

  private handleResponse = (response: AxiosResponse) => {
    return response
  }

  protected handleError = async (err: AxiosError) => {
    const { response } = err

    if (
      response?.status === 401 &&
      response?.request?.responseURL.includes('/access')
    ) {
      setTimeout(() => {
        clearLocalStorage()
        window.location.href = '/'
      }, 2000)
    }

    log.error(`R equest error `, err.message)
    return Promise.reject(err)
  }
}
