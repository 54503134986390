export enum ROUTES_NAME {
  ROOT = '/',
  LOGIN = '/login',
  LOGIN_PASSWORD = '/login/password',
  RESET_PASSWORD = '/reset-password',
  RESET_PASSWORD_STEP_2 = '/reset-password/step-2',

  FIRST_ACCESS = '/first-access',
  FIRST_ACCESS_STEP_2 = '/first-access/step-2',

  PROFILE = '/profiles',
  PROFILE_CREATE = '/profiles/create',
  PROFILE_EDIT = '/profiles/edit',

  USERS = '/users',
  USERS_INVITE_USER = '/users/invite-user',
  USERS_EDIT_USER = '/users/edit-user',

  COMPANY = '/companies',
  COMPANY_CREATE = '/companies/create',
  COMPANY_EDIT = '/companies/edit',

  UNITS = '/units',
  UNITS_CREATE = '/units/create',
  UNITS_EDIT = '/units/edit',

  USER_MY_ACCOUNT = '/user/my-account',
  USER_UPDATE_PASSWORD = '/user/update-password',

  PRODUCT = '/products',
  PRODUCT_CREATE = '/products/create',
  PRODUCT_EDIT = '/products/edit',

  BNPL_OFFERS = '/bnpl-offers',

  ATTENDANCE = '/attendance',
  ATTENDANCE_CUSTOMER_INFORMATION = '/attendance/customer-information',
  ATTENDANCE_HISTORY = '/attendance/history',
  ATTENDANCE_PRE_ANALISYS = '/attendance/pre-analisys',
  ATTENDANCE_SIMULATION = '/attendance/simulation',
  ATTENDANCE_SIMULATION_APPROVED = '/attendance/simulation/approved',
  ATTENDANCE_SIMULATION_DENIED = '/attendance/simulation/denied',
  ATTENDANCE_PROPOSAL_FOLLOW_UP = '/attendance/proposal-follow-up',

  REASON = '/reason',
  REASON_CREATE = '/reason/create',
  REASON_EDIT = '/reason/edit',
  DASHBOARD = '/dashboard',
  REPORTS = '/reports',
  UPLOAD = '/negotiation_import'
}
