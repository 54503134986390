import { css } from 'styled-components'
import { RestTypes } from './restTypes'

export const renderRestTypes = ({
  _flex,
  _layout,
  _positioning,
  _spacing,
  _sizing
}: Partial<RestTypes>) => {
  return css`
    ${!!_flex?.alignContent && `align-content: ${_flex?.alignContent}`};
    ${!!_flex?.alignItems && `align-items: ${_flex?.alignItems}`};
    ${!!_flex?.direction && `flex-direction: ${_flex?.direction}`};
    ${!!_flex?.justifyContent && `justify-content: ${_flex?.justifyContent}`};
    ${!!_flex?.gap && `gap: ${_flex?.gap}`};
    ${!!_flex?.columnGap && `column-gap: ${_flex?.columnGap}`};
    ${!!_flex?.rowGap && `row-gap: ${_flex?.rowGap}`};
    ${!!_flex?.wrap && `wrap: ${_flex?.wrap}`};

    ${!!_layout?.alignSelf && `align-self: ${_layout?.alignSelf}`};
    ${!!_layout?.flex && `flex: ${_layout?.flex}`};
    ${!!_layout?.flexGrow && `flex-grow: ${_layout?.flexGrow}`};
    ${!!_layout?.flexShrink && `flex-shrink: ${_layout?.flexShrink}`};
    ${!!_layout?.flexBasics && `flex-basics: ${_layout?.flexBasics}`};
    ${!!_layout?.justifySelf && `justify-self: ${_layout?.justifySelf}`};

    ${!!_positioning?.bottom && `bottom: ${_positioning?.bottom}`};
    ${!!_positioning?.end && `end: ${_positioning?.end}`};
    ${!!_positioning?.left && `left: ${_positioning?.left}`};
    ${!!_positioning?.position && `position: ${_positioning?.position}`};
    ${!!_positioning?.right && `right: ${_positioning?.right}`}
    ${!!_positioning?.start && `start: ${_positioning?.start}`}
    ${!!_positioning?.top && `top: ${_positioning.top}`}
    ${!!_positioning?.zIndex && `z-index: ${_positioning.zIndex}`};

    ${!!_spacing?.margin && `margin: ${_spacing.margin}`};
    ${!!_spacing?.marginBottom && `margin-bottom: ${_spacing.marginBottom}`};
    ${!!_spacing?.marginTop && `margin-top: ${_spacing.marginTop}`};
    ${!!_spacing?.marginStart && `margin-start: ${_spacing.marginStart}`};
    ${!!_spacing?.marginEnd && `margin-end: ${_spacing.marginEnd}`};
    ${!!_spacing?.padding && `padding: ${_spacing.padding}`};
    ${!!_spacing?.paddingBottom &&
    `padding-bottom: ${_spacing?.paddingBottom}`};
    ${!!_spacing?.paddingTop && `padding-top: ${_spacing?.paddingTop}`};
    ${!!_spacing?.paddingLeft && `padding-left: ${_spacing?.paddingLeft}`};
    ${!!_spacing?.paddingRight && `padding-right: ${_spacing?.paddingRight}`};

    ${!!_sizing?.width && `width: ${_sizing.width}`};
    ${!!_sizing?.minWidth && `min-width: ${_sizing.minWidth}`};
    ${!!_sizing?.maxWidth && `max-width: ${_sizing.maxWidth}`};
    ${!!_sizing?.height && `height: ${_sizing.height}`};
    ${!!_sizing?.minHeight && `min-height: ${_sizing.minHeight}`};
    ${!!_sizing?.maxHeight && `max-height: ${_sizing.maxHeight}`};
  `
}
