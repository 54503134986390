import { useState, ChangeEvent, useMemo, MouseEvent } from 'react'
import Assets from 'assets'
import { Input } from 'components/Input'

import * as S from './Dropdown.styles'
import { CompanyDTO, UnitDTO } from 'models'
import { SelectedUnitDTO } from 'providers/AppDataProvider'

export interface SelectedOptionProps {
  company: CompanyDTO
  subgroup?: UnitDTO
}

interface NestedDropdownProps {
  data: CompanyDTO[]
  onChange: (value: CompanyDTO, subgroupId?: string) => void
  selectedUnit?: SelectedUnitDTO
}

type SubMenuOpenedType = {
  state: boolean
  companyTitle: string | null
}

export const NestedDropdown = ({
  data,
  onChange,
  selectedUnit
}: NestedDropdownProps) => {
  const [isOpened, setIsOpened] = useState(false)
  const [isSubMenuOpened, setIsSubMenuOpened] = useState<SubMenuOpenedType>({
    state: false,
    companyTitle: null
  })
  const [hoveredOption, setHoveredOption] = useState<CompanyDTO | null>(null)

  const [filter, setFilter] = useState('')
  const [secondaryFilter, setSecondaryFilter] = useState('')
  const isSingleCompany = data.length === 1 && data[0].units.length === 1

  const handleFilterText = (e: ChangeEvent<HTMLInputElement>) => {
    setSecondaryFilter('')
    setFilter(e.target.value)
  }

  const handleSecondaryFilterText = (e: ChangeEvent<HTMLInputElement>) => {
    setSecondaryFilter(e.target.value)
  }

  const filteredList = useMemo(() => {
    return data.filter(
      ({ name }) => name.toLowerCase().indexOf(filter.toLowerCase()) > -1
    )
  }, [filter, data])

  const filteredSecondaryList = useMemo(() => {
    if (!hoveredOption) return []

    return hoveredOption.units?.filter(
      ({ name }) =>
        name.toLowerCase().indexOf(secondaryFilter.toLowerCase()) > -1
    )
  }, [secondaryFilter, hoveredOption])

  const handleSelectedOption = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    option: CompanyDTO,
    secondaryOption?: UnitDTO
  ) => {
    event.stopPropagation()
    setFilter('')
    setSecondaryFilter('')
    setIsSubMenuOpened({
      state: false,
      companyTitle: null
    })

    if (!option.units.length) {
      onChange(option)
      handleToggleDropdown()
      return
    }

    onChange(option, secondaryOption?.uuid)
    handleToggleDropdown()
  }

  type SubMenuToggleType = {
    state: boolean
    hoveredItem?: CompanyDTO
    companyTitle?: string | null
  }

  const handleSubMenuToggle = ({
    state,
    companyTitle,
    hoveredItem
  }: SubMenuToggleType) => {
    setIsSubMenuOpened({
      state,
      companyTitle: companyTitle || null
    })
    if (state && hoveredItem) {
      setHoveredOption(hoveredItem)
    }
  }

  const handleToggleDropdown = () => {
    setIsOpened((currState) => !currState)
  }

  const selectedCompanyTitle = useMemo(() => {
    if (!selectedUnit) {
      return null
    }

    const selectedCompany = data.find(
      (eachCompany) => eachCompany.uuid === selectedUnit.companyUuid
    )

    const unit = selectedCompany?.units.find(
      (eachUnit) => eachUnit.uuid === selectedUnit.unitUuid
    )

    if (selectedCompany) {
      return `${selectedCompany?.name}${unit?.name ? ` - ${unit?.name}` : ''}`
    }

    return ''
  }, [selectedUnit, data])

  if (!selectedUnit) {
    return <></>
  }

  return (
    <S.Container
      onMouseLeave={() => {
        if (isOpened) {
          handleToggleDropdown()
        }
      }}
    >
      <S.DropdownButton
        onClick={() => (isSingleCompany ? null : handleToggleDropdown())}
      >
        {isSingleCompany ? (
          <></>
        ) : (
          <S.ArrowContainer isOpened={isOpened}>
            <Assets
              assetProps={{ key: 'dropdownArrow', type: 'icon' }}
              alt="botão para expandir"
            />
          </S.ArrowContainer>
        )}

        <span>{selectedCompanyTitle}</span>
      </S.DropdownButton>

      {isOpened && (
        <S.DropdownContainer>
          <Input
            autoComplete="off"
            onChange={handleFilterText}
            value={filter}
            _spacing={{
              margin: '0 14px'
            }}
            _sizing={{
              width: 'calc(100% - 28px)',
              height: '56px'
            }}
            placeholder="pesquisar empresa"
            name="input-filter"
          />

          {filteredList.map((eachItem) => (
            <S.ListOption
              key={eachItem.uuid}
              isSelected={selectedUnit.companyUuid === eachItem.uuid}
              onClick={(e) => handleSelectedOption(e, eachItem)}
              onMouseEnter={(e) => {
                handleSubMenuToggle({
                  state: true,
                  companyTitle: e.currentTarget.firstChild?.textContent || null,
                  hoveredItem: eachItem
                })
              }}
              onMouseLeave={() =>
                handleSubMenuToggle({
                  state: false
                })
              }
            >
              <span>
                {eachItem.units.length === 1
                  ? `${eachItem.name} - ${eachItem.units[0].name}`
                  : eachItem.name}
              </span>

              {eachItem.units.length > 1 && (
                <>
                  <Assets
                    assetProps={{
                      key: 'filledArrowPolygonRight',
                      type: 'icon'
                    }}
                    alt="botão para expandir"
                  />
                  {isSubMenuOpened.state &&
                    isSubMenuOpened.companyTitle === eachItem.name && (
                      <S.DropdownContainer secondaryDropdown>
                        <Input
                          onChange={handleSecondaryFilterText}
                          value={secondaryFilter}
                          autoComplete="off"
                          _spacing={{
                            margin: '0 14px'
                          }}
                          _sizing={{
                            width: 'calc(100% - 28px)',
                            height: '56px'
                          }}
                          placeholder="pesquisar filial"
                          name="input-secondary-filter"
                        />

                        {filteredSecondaryList.map((eachSubGroup) => (
                          <S.ListOption
                            key={eachSubGroup.uuid}
                            onClick={(e) =>
                              handleSelectedOption(e, eachItem, eachSubGroup)
                            }
                            isSelected={
                              selectedUnit.unitUuid === eachSubGroup.uuid
                            }
                          >
                            <span>{eachSubGroup.name}</span>
                          </S.ListOption>
                        ))}

                        {!filteredSecondaryList.length && (
                          <S.OptionNotFound>
                            Sem resultados correspondentes
                          </S.OptionNotFound>
                        )}
                      </S.DropdownContainer>
                    )}
                </>
              )}
            </S.ListOption>
          ))}

          {!filteredList.length && (
            <S.OptionNotFound>Sem resultados correspondentes</S.OptionNotFound>
          )}
        </S.DropdownContainer>
      )}
    </S.Container>
  )
}
