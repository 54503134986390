import { PartnersTheme } from 'layout/theme'

interface CloseTagIconProps {
  color: keyof typeof PartnersTheme.colors
}

export const CloseTagIcon = ({ color }: CloseTagIconProps) => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.55648 4.49872L8.77837 1.28433C8.91946 1.14324 8.99872 0.951875 8.99872 0.752342C8.99872 0.552808 8.91946 0.361447 8.77837 0.220356C8.63727 0.0792644 8.44591 0 8.24638 0C8.04685 0 7.85549 0.0792644 7.71439 0.220356L4.5 3.44224L1.28561 0.220356C1.14451 0.0792644 0.953154 -1.48664e-09 0.753621 0C0.554087 1.48664e-09 0.362726 0.0792644 0.221635 0.220356C0.0805431 0.361447 0.00127872 0.552808 0.00127871 0.752342C0.00127871 0.951875 0.0805431 1.14324 0.221635 1.28433L3.44352 4.49872L0.221635 7.71312C0.151406 7.78277 0.0956644 7.86564 0.0576247 7.95695C0.0195849 8.04825 0 8.14619 0 8.2451C0 8.34401 0.0195849 8.44195 0.0576247 8.53325C0.0956644 8.62456 0.151406 8.70743 0.221635 8.77709C0.291289 8.84731 0.37416 8.90306 0.465466 8.9411C0.556773 8.97914 0.654707 8.99872 0.753621 8.99872C0.852534 8.99872 0.950468 8.97914 1.04177 8.9411C1.13308 8.90306 1.21595 8.84731 1.28561 8.77709L4.5 5.5552L7.71439 8.77709C7.78405 8.84731 7.86692 8.90306 7.95823 8.9411C8.04953 8.97914 8.14747 8.99872 8.24638 8.99872C8.34529 8.99872 8.44323 8.97914 8.53453 8.9411C8.62584 8.90306 8.70871 8.84731 8.77837 8.77709C8.84859 8.70743 8.90434 8.62456 8.94238 8.53325C8.98042 8.44195 9 8.34401 9 8.2451C9 8.14619 8.98042 8.04825 8.94238 7.95695C8.90434 7.86564 8.84859 7.78277 8.77837 7.71312L5.55648 4.49872Z"
        fill={PartnersTheme.colors[color]}
      />
    </svg>
  )
}
