import { useCallback, useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'

import { useAppData } from 'hooks/useAppData'
import { useToast } from 'hooks/useToast'
import { EmptyState } from 'components/EmptyState'

import { Button } from 'components/Button'
import { CustomDatePicker } from 'components/CustomDatePicker'
import { InputSelect, SelectItemType } from 'components/InputSelect'
import { ListTable } from 'components/ListTable'
import { useLoading } from 'providers/LoadingProvider'

import * as S from './FormClosingReport.styles'
import UnitService from 'services/remote/v1/Unit/UnitService'

import { formatDate } from 'utils/formatters/format-date'

import { TooltipText } from 'components/TooltipText'
import { Text } from 'components/Text'
import {
  ClosingReportDTO,
  DocumentDTO,
  ProductDTO
} from 'models/ClosingReportDTO'
import { toBrl } from 'utils/formatters/to-brl'
import { ExportButton } from 'components/ExportButton'
import { AutoComplete } from 'components/Autocomplete'
import { Input } from 'components/Input'

const documentHeaders = [
  { label: 'CPF', key: 'document' },
  { label: 'nome', key: 'name' },
  { label: 'data de aceite', key: 'date' },
  { label: 'data de desembolso', key: 'disbursed_at' },
  { label: 'montante', key: 'amount' },
  { label: 'comissão Noverde', key: 'commission' }
]

const productHeaders = [
  { label: 'Produto', key: 'product' },
  { label: 'montante', key: 'amount' },
  { label: 'comissão Noverde', key: 'commission' }
]

export const FormClosingReport = () => {
  const { isLoading, setLoading } = useLoading()
  const { showToast } = useToast()
  const { selectedUnit: currentSelectedUnit, data: appData } = useAppData()
  const [hasPermissionExport, setHasPermissionExport] = useState(false)

  const [dateRange, setDateRange] = useState<(null | Date)[]>([
    new Date(),
    new Date()
  ])
  const [unitsList, setUnitsList] = useState<SelectItemType[]>([])
  const [reportData, setReportData] = useState<ClosingReportDTO>()

  const [selectedUnit, setSelectedUnit] = useState<SelectItemType>({
    id: '',
    value: ''
  })
  const [unitTerm, setUnitTerm] = useState('')

  const listAllCompanyUnits = () => {
    const currentCompany = appData?.companies.find(
      (eachCompany) => eachCompany.uuid === currentSelectedUnit.companyUuid
    )

    let units: SelectItemType[] = []

    if (currentCompany) {
      units = currentCompany?.units.map((eachUnit) => ({
        id: String(eachUnit.uuid),
        value: String(eachUnit.name)
      }))
    }

    setUnitsList(units)

    if (units.length > 0) {
      setSelectedUnit(units[0])
    }
  }

  const getReports = useCallback(async () => {
    if (!currentSelectedUnit?.unitUuid) return

    try {
      setLoading(true)

      const beginDate = dateRange[0]
        ? formatDate(dateRange[0].toISOString(), 'AAAA-MM-DD')
        : ''

      const endDate = dateRange[1]
        ? formatDate(dateRange[1].toISOString(), 'AAAA-MM-DD')
        : ''

      const params = {
        unitUuid: selectedUnit?.id || currentSelectedUnit.unitUuid,
        beginDate: beginDate ? `${beginDate}T00:00:00.000Z` : '',
        endDate: endDate ? `${endDate}T23:59:59.999Z` : ''
      }

      const { data } = await UnitService.getClosingReports(
        params?.unitUuid,
        params?.beginDate,
        params?.endDate
      )
      setReportData(data?.data)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      showToast({
        title: '',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnit, dateRange, currentSelectedUnit, setSelectedUnit])

  const getDocumentExport = () => {
    if (reportData && reportData.documents.length > 0) {
      const exportData = reportData.documents.map((document) => ({
        document: document.document,
        name: document.name,
        date: document.date ? formatDate(document.date, 'DD/ MM/ AAAA') : '-',
        disbursed_at: document.disbursed_at
          ? formatDate(document.disbursed_at, 'DD/ MM/ AAAA')
          : '',
        amount: document.amount ? toBrl(document?.amount) : '',
        commission: document.commission ? toBrl(document?.commission) : ''
      }))

      return exportData
    }
  }

  const getProductExport = () => {
    if (reportData && reportData.products.length > 0) {
      const exportData = reportData.products.map((product) => ({
        product: product.product,
        amount: product.amount ? toBrl(product.amount) : '',
        commission: product.commission ? toBrl(product.commission) : ''
      }))

      return exportData
    }
  }

  const checkPermission = () => {
    if (appData) {
      const currentModule = appData.modules.find(
        (_module) => _module.abbreviation === 'DASH'
      )

      if (currentModule) {
        const report = currentModule.resources.find(
          (resource) => resource.abbreviation === 'FEC'
        )

        setHasPermissionExport(!!report?.permissions?.export)
      }
    }
  }

  useEffect(() => {
    checkPermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData])

  useEffect(() => {
    getReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedUnit, setLoading, setSelectedUnit])

  useEffect(() => {
    if (currentSelectedUnit.unitUuid) {
      listAllCompanyUnits()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedUnit, setLoading])

  interface RenderItemProps {
    item: DocumentDTO
    canEdit?: boolean
  }

  const renderItemDocument = useCallback(
    ({ item }: RenderItemProps) => {
      return (
        <>
          {isLoading ? (
            <>
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={200} />
            </>
          ) : (
            <>
              <span>
                <TooltipText text={item?.document || '-'}>
                  {item?.document || '-'}
                </TooltipText>
              </span>
              <span>
                <TooltipText text={item?.name || '-'}>
                  {item?.name || '-'}
                </TooltipText>
              </span>
              <span>
                <TooltipText text={item?.date}>
                  {item?.date ? formatDate(item.date, 'DD/ MM/ AAAA') : '-'}
                </TooltipText>
              </span>

              <span>
                <TooltipText text={item?.disbursed_at}>
                  {item?.disbursed_at
                    ? formatDate(item.disbursed_at, 'DD/ MM/ AAAA')
                    : '-'}
                </TooltipText>
              </span>
              <span>
                <TooltipText text={String(item?.amount) || '-'}>
                  {item?.amount ? (
                    <>
                      R$ &nbsp;
                      {toBrl(item?.amount, {
                        withoutPrefix: true
                      })}
                    </>
                  ) : (
                    '-'
                  )}
                </TooltipText>
              </span>
              <span>
                <TooltipText text={String(item?.commission) || '-'}>
                  {item?.commission ? (
                    <>
                      R$ &nbsp;
                      {toBrl(item?.commission, {
                        withoutPrefix: true
                      })}
                    </>
                  ) : (
                    '-'
                  )}
                </TooltipText>
              </span>
            </>
          )}
        </>
      )
    },
    [isLoading]
  )

  interface RenderItemPropsProduct {
    item: ProductDTO
    canEdit?: boolean
  }

  const renderItemProduct = useCallback(
    ({ item }: RenderItemPropsProduct) => {
      return (
        <>
          {isLoading ? (
            <>
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={200} />
            </>
          ) : (
            <>
              <span>
                <TooltipText text={item?.product || '-'}>
                  {item?.product || '-'}
                </TooltipText>
              </span>
              <span>
                <TooltipText text={String(item?.amount) || '-'}>
                  {item?.amount ? (
                    <>
                      R$ &nbsp;
                      {toBrl(item?.amount, {
                        withoutPrefix: true
                      })}
                    </>
                  ) : (
                    '-'
                  )}
                </TooltipText>
              </span>
              <span>
                <TooltipText text={String(item?.commission)}>
                  {item?.commission ? (
                    <>
                      R$ &nbsp;
                      {toBrl(item?.commission, {
                        withoutPrefix: true
                      })}
                    </>
                  ) : (
                    '-'
                  )}
                </TooltipText>
              </span>
            </>
          )}
        </>
      )
    },
    [isLoading]
  )

  return (
    <>
      <S.FormArea>
        <div>
          <CustomDatePicker dateRange={dateRange} setDateRange={setDateRange} />
        </div>
        <AutoComplete
          options={unitsList}
          selectedOption={selectedUnit}
          inputValue={unitTerm}
          onInputChange={(value: string) => setUnitTerm(value)}
          onChange={(value) =>
            setSelectedUnit(value ? value : { id: '', value: '' })
          }
          renderInput={(params) => (
            <Input
              name="unidade"
              placeholder="selecione uma unidade..."
              animatedLabel="unidade *"
              data-lpignore
              {...params}
            />
          )}
        />
        <Button type="button" variant="primaryBlue" onClick={getReports}>
          consultar
        </Button>
      </S.FormArea>

      <Text
        weight="weight-600"
        _spacing={{
          marginTop: '2rem',
          marginBottom: '0.5rem'
        }}
        color="galaxy"
        size="2rem"
      >
        consolidado
      </Text>

      <S.Header>
        <S.Box>
          <Text weight="weight-600" color="galaxy" size="1rem">
            contratos desembolsados
          </Text>

          <Text weight="weight-600" color="galaxy" size="1rem">
            {reportData?.funded.accepted_contracts || '-'}
          </Text>
        </S.Box>

        <S.Box>
          <Text weight="weight-600" color="galaxy" size="1rem">
            montante originado
          </Text>

          <Text weight="weight-600" color="galaxy" size="1rem">
            {reportData?.funded.amount ? (
              <>
                R$ &nbsp;
                {toBrl(reportData?.funded.amount, {
                  withoutPrefix: true
                })}
              </>
            ) : (
              '-'
            )}
          </Text>
        </S.Box>

        <S.Box>
          <Text weight="weight-600" color="galaxy" size="1rem">
            comissão Noverde
          </Text>

          <Text weight="weight-600" color="galaxy" size="1rem">
            {reportData?.funded.commission ? (
              <>
                R$ &nbsp;
                {toBrl(reportData?.funded.commission, {
                  withoutPrefix: true
                })}
              </>
            ) : (
              '-'
            )}
          </Text>
        </S.Box>
      </S.Header>

      <Text
        weight="weight-600"
        _spacing={{
          marginTop: '2rem',
          marginBottom: '-1rem'
        }}
        color="galaxy"
        size="2rem"
      >
        analítico por cpf
      </Text>

      {reportData?.documents.length === 0 ? (
        <S.WrapperEmpty>
          <EmptyState
            description="Não foi retornado nenhum dado com esta filtragem"
            onClick={() => null}
            showCreateButton={true}
          />
        </S.WrapperEmpty>
      ) : (
        <>
          <ListTable<DocumentDTO>
            tableStructure={{
              header: '1fr 1fr 1fr 1.5fr 1fr 1fr',
              body: '1fr 1fr 1fr 1.5fr 1fr 1fr',
              columns: documentHeaders.map((header) => header.label)
            }}
            data={isLoading ? Array(5).fill({}) : reportData?.documents || []}
            renderItem={renderItemDocument}
            keyExtractor={(item) => item.amount}
          />

          {hasPermissionExport &&
            !!reportData &&
            reportData.documents.length > 0 && (
              <ExportButton
                filename={'AnaliticoPorCPF.csv'}
                headers={documentHeaders}
                data={getDocumentExport() || []}
                useButton
              />
            )}
        </>
      )}

      <Text
        weight="weight-600"
        _spacing={{
          marginTop: '2rem',
          marginBottom: '-1rem'
        }}
        color="galaxy"
        size="2rem"
      >
        analítico por produto
      </Text>

      {reportData?.products.length === 0 ? (
        <S.WrapperEmpty>
          <EmptyState
            description="Não foi retornado nenhum dado com esta filtragem"
            onClick={() => null}
            showCreateButton={true}
          />
        </S.WrapperEmpty>
      ) : (
        <>
          <ListTable<ProductDTO>
            tableStructure={{
              header: '1fr 0.8fr 0.5fr',
              body: '1fr 0.8fr 0.5fr',
              columns: productHeaders.map((header) => header.label)
            }}
            data={isLoading ? Array(5).fill({}) : reportData?.products || []}
            renderItem={renderItemProduct}
            keyExtractor={(item) => item.amount}
          />

          {hasPermissionExport &&
            !!reportData &&
            reportData.products.length > 0 && (
              <ExportButton
                filename={'AnaliticoPorProduto.csv'}
                headers={productHeaders}
                data={getProductExport() || []}
                useButton
              />
            )}
        </>
      )}
    </>
  )
}
