import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ReturnButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 32px;
  cursor: pointer;
`

export const LeftCorner = styled.div`
  display: flex;
  align-items: center;

  span {
    display: block;

    font-size: 40px;
    font-weight: 600;
    line-height: 32px;
  }
`
