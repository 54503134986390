export const formatNameLastName = (fullname: string) => {
  const allNames = fullname.split(' ')

  const namesCount = allNames.length

  if (namesCount >= 2) {
    return `${allNames[0]} ${allNames[namesCount - 1]}`
  }

  return allNames[0]
}
