/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useCallback } from 'react'

import useForm from 'hooks/useForm'
import { useRouter } from 'hooks/useRouter'
import { useTags } from 'hooks/useTags'
import { useAppData } from 'hooks/useAppData'
import { useInterval } from 'hooks/useInterval'
import { useModal } from 'providers/ModalProvider'

import CompanyService from 'services/remote/v1/Company/CompanyService'
import SimulationService from 'services/remote/v1/Simulation/SimulationService'
import {
  CreateNewSimulationReqBody,
  CreditAnalysisDTO,
  GetCreditAnalysisResponse
} from 'services/remote/v1/Simulation/SimulationTypes'

import { formatMoney } from 'utils/formatters/format-money'
import { onlyNumbers } from 'utils/formatters/only-numbers'
import { toBrl } from 'utils/formatters/to-brl'
import { monetaryUnmask } from 'utils/formatters/unmask'
import { toFloat } from 'utils/formatters/to-float'
import { Button } from 'components/Button'
import { AutoComplete } from 'components/Autocomplete'
import {
  CustomerInformation,
  CustomerInformationData
} from 'components/CustomerInformation'
import { HeaderActions } from 'components/HeaderActions'
import { Input } from 'components/Input'
import { InputSelect, SelectItemType } from 'components/InputSelect'
import { Text } from 'components/Text'
import { CommonModal } from 'templates/Modals/CommonModal'

import { ROUTES_NAME } from 'routes'

import * as S from './AttendancePreAnalisys.styles'
import { AttendanceSimulationRouteProps } from 'pages/AttendanceSimulation'
import { useLoading } from 'providers/LoadingProvider'
import { useActionModal } from 'hooks/useActionModal'
import { useToast } from 'hooks/useToast'
import { useLocation } from 'react-router'
import { validatePaymentDay } from 'utils/validators/validatePaymentDay'

export interface AttendancePreAnalisysFormValues {
  valorCompra: string
  valorFinanciado: string
  diaPagamento: string
  parcelas: string
  proximaParcela: string
  prazoExecucao: number
}

export interface AttendancePreAnalisysRouteProps {
  preAnalisysData: {
    name: string
    birthday: string
    document: string
    unityUuid: string
    attendanceUuid: string
    leadUuid: string
  }
  simulationData?: Partial<Pick<CreditAnalysisDTO, 'simulation'>>
}

interface SimulationSettingsProps {
  id: string
  simulation_settings?: {
    grace_period: number
    max_period: number
    max_value: number
    min_period: number
    min_value: number
  }
}

export const AttendancePreAnalisys = () => {
  const { history } = useRouter<AttendancePreAnalisysRouteProps>()
  const { selectedUnit, data: appData } = useAppData()
  const { setLoading } = useLoading()
  const { showToast } = useToast()
  const { dispatch } = useModal()
  const { state } = useLocation<AttendancePreAnalisysRouteProps>()

  const simulationData = state?.simulationData

  const [period, setPeriod] = useState({
    maxPeriod: 0,
    minPeriod: 0
  })
  const [simulationSettingsList, setSimulationSettingsList] = useState<
    SimulationSettingsProps[]
  >([])
  const [simulationUuid, setSimulationUuid] = useState<string>('')
  const [products, setProducts] = useState<SelectItemType[]>([])
  const [productsTerm, setProductsTerm] = useState('')
  const [categories, setCategories] = useState<SelectItemType[]>([])
  const [creditAnalysisData, setCreditAnalysisData] =
    useState<GetCreditAnalysisResponse>({
      data: { simulation: { reason: { slug: 'created' } } }
    } as GetCreditAnalysisResponse)
  const [pollingError, setPollingError] = useState(false)
  const [pollingAttempts, setPollingAttempts] = useState(0)
  const [customErrors, setCustomErrors] = useState({
    categories: false,
    products: false,
    period: false,
    installment: false
  })
  const [selectedCategory, setSelectedCategory] =
    useState<SelectItemType | null>(
      (!!simulationData?.simulation && {
        id: String(simulationData?.simulation?.category?.id),
        value: simulationData?.simulation?.category?.uuid
      }) ||
        null
    )
  const [selectedInstallment, setSelectedInstallment] =
    useState<SelectItemType | null>(
      (!!simulationData?.simulation && {
        id: String(simulationData?.simulation?.period) || '',
        value: String(simulationData?.simulation?.period) || ''
      }) ||
        null
    )
  const [installmentsData, setIntallmentsData] = useState<SelectItemType[]>([])
  const { actionModal } = useActionModal()
  const MAX_POLLING_ATTEMPTS = 8

  const dispatchPolling =
    !!simulationUuid &&
    ['created'].includes(creditAnalysisData?.data?.simulation?.reason.slug) &&
    !pollingError &&
    pollingAttempts < MAX_POLLING_ATTEMPTS

  useInterval(
    async () => {
      await getCreditAnalysis()
      setPollingAttempts((currState) => (currState += 1))
    },
    dispatchPolling ? 5000 : null
  )

  const { tags, addTag, removeTag, removeAllTags, setMostTags } = useTags({
    tagsProps: products
  })

  const customerInformationData: CustomerInformationData = {
    nomeCompleto: {
      rotule: state?.preAnalisysData?.name || ''
    },
    dataNascimento: {
      rotule:
        (!!state?.preAnalisysData?.birthday &&
          state?.preAnalisysData?.birthday) ||
        ''
    },
    cpf: {
      rotule: state?.preAnalisysData?.document || ''
    }
  }

  const getCategories = useCallback(() => {
    if (!selectedUnit.companyUuid) return
    setLoading(true)

    const currentCompany = appData?.companies?.find(
      (company) => company.uuid === selectedUnit.companyUuid
    )

    if (currentCompany) {
      const categories = currentCompany?.categories?.map((category) => {
        return {
          ...category,
          id: String(category.id),
          value: category.name
        }
      })

      setSimulationSettingsList(categories)

      setCategories(categories)
    }

    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnit.companyUuid])

  const getAllProductsByUuidAndCategoryId = useCallback(async () => {
    try {
      const productsResponse =
        await CompanyService.getAllProductsInCompanyByUuid(
          selectedUnit?.companyUuid || '',
          selectedCategory?.id
        )
      setProducts(
        productsResponse.data?.data?.map((eachProduct) => ({
          id: String(eachProduct.uuid),
          value: eachProduct.name
        }))
      )
    } catch (err: any) {
      showToast({
        title: '',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })
    }
  }, [selectedCategory?.id, selectedUnit.companyUuid, showToast])

  const getCreditAnalysis = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await SimulationService.getCreditAnalysis(simulationUuid)
      setCreditAnalysisData(data)
    } catch (err: any) {
      setPollingError(true)
      showToast({
        title: '',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })
      setLoading(false)
    }
  }, [setLoading, showToast, simulationUuid])

  const handleSubmitSimulation = async () => {
    try {
      if (!selectedCategory || !selectedInstallment || !tags.length) {
        return
      }

      setLoading(true)
      const reqBody: CreateNewSimulationReqBody = {
        category: Number(selectedCategory.id),
        lead: state?.preAnalisysData?.leadUuid || '',
        payday: Number(data.diaPagamento),
        period: Number(selectedInstallment?.value) || 1,
        products: tags?.map((eachProduct) => eachProduct.id),
        purchase_amount: monetaryUnmask(data.valorCompra),
        requested_amount: monetaryUnmask(data.valorFinanciado),
        deadline: Number(data.prazoExecucao)
      }

      const response = await SimulationService.createNewSimulation(
        state?.preAnalisysData?.unityUuid || '',
        state?.preAnalisysData?.attendanceUuid || '',
        reqBody
      )

      setSimulationUuid(response.data.data.uuid)
    } catch (err: any) {
      setLoading(false)
      if (err?.response?.data?.error?.category === 'validation') {
        showToast({
          message:
            err?.response?.data?.error?.messages_client[0] || err.message,
          title: 'Atenção!',
          type: 'info'
        })
      } else {
        showToast({
          message:
            err?.response?.data?.error?.messages_client[0] || err.message,
          title: 'Oops! Algo deu errado.',
          type: 'error'
        })
      }
    }
  }

  function handleChangeCategoryFinance(value: SelectItemType) {
    if (selectedCategory?.id === value.id) return

    const tmp = simulationSettingsList?.find(
      (item) => Number(item.id) === Number(value.id)
    )
    setPeriod({
      maxPeriod: Number(tmp?.simulation_settings?.max_period),
      minPeriod: Number(tmp?.simulation_settings?.min_period)
    })
    setIntallmentsData([])
    setSelectedCategory(value)
    removeAllTags()
  }

  function handleChangeInstallments(value: SelectItemType) {
    setSelectedInstallment(value)
  }

  const goToSimulation = useCallback(
    (isTimeout = false) => {
      try {
        const newState: AttendanceSimulationRouteProps = {
          simulationData: {
            lead: {
              ...creditAnalysisData.data?.lead,
              leadUuid: state?.preAnalisysData?.leadUuid || '',
              unityUuid: state?.preAnalisysData?.unityUuid || '',
              attendanceUuid: state?.preAnalisysData?.attendanceUuid || ''
            },
            proposal: { ...creditAnalysisData.data.proposal },
            simulation: {
              ...creditAnalysisData.data?.simulation,
              reason: {
                ...creditAnalysisData.data?.simulation.reason,
                slug: isTimeout
                  ? 'denied'
                  : creditAnalysisData.data?.simulation.reason.slug,
                name: isTimeout
                  ? 'Simulação cancelada pelo usuário (timeout)'
                  : creditAnalysisData.data?.simulation.reason.name
              }
            }
          }
        }

        history.push({
          pathname: ROUTES_NAME.ATTENDANCE_SIMULATION,
          state: newState
        })
      } catch (err) {
        console.log(err)
      }
    },
    [
      creditAnalysisData.data?.lead,
      creditAnalysisData.data.proposal,
      creditAnalysisData.data?.simulation,
      history,
      state?.preAnalisysData?.attendanceUuid,
      state?.preAnalisysData?.leadUuid,
      state?.preAnalisysData?.unityUuid
    ]
  )

  const handleReturn = () => {
    actionModal({
      title: 'Finalizar atendimento',
      description:
        'Tem certeza que deseja sair? Seu progresso atual será perdido.',
      submitButtonTitle: 'Finalizar atendimento',
      submitAction: () => {
        void SimulationService.finishAttendance(
          selectedUnit.unitUuid,
          state?.preAnalisysData?.attendanceUuid,
          {
            cancelled: true
          }
        )
        history.replace(ROUTES_NAME.ATTENDANCE)
      }
    })
  }

  const { handleSubmit, handleChange, data, errors } =
    useForm<AttendancePreAnalisysFormValues>({
      initialValues: {
        ...(simulationData?.simulation?.payday && {
          diaPagamento: String(simulationData?.simulation?.payday) || ''
        }),
        valorCompra:
          toBrl(String(simulationData?.simulation?.purchase_amount)) || '',
        valorFinanciado:
          toBrl(String(simulationData?.simulation?.requested_amount)) || '',
        prazoExecucao: simulationData?.simulation?.deadline || 0
      },
      validations: {
        valorCompra: {
          required: {
            message: 'Informe um valor',
            value: true
          },
          custom: {
            message: 'O valor deve ser maior que zero',
            isValid: (currValue) => {
              return ![0].includes(Number(toFloat(currValue)))
            }
          }
        },
        valorFinanciado: {
          required: {
            message: 'Informe um valor',
            value: true
          },
          custom: {
            message: 'O valor deve ser maior que zero',
            isValid: (currValue) => Number(toFloat(currValue)) > 0
          }
        },
        diaPagamento: {
          required: {
            message: 'Informe um dia para o pagamento',
            value: true
          },
          custom: {
            message: 'Informe uma data entre 1 e 31',
            isValid: (value) => validatePaymentDay(value)
          }
        },
        prazoExecucao: {
          custom: {
            message: 'O valor deve ser no mínimo 0 e no máximo 100',
            isValid: (currValue) => Number(currValue) <= 100
          }
        }
      },
      onSubmit: () => handleSubmitSimulation()
    })

  useEffect(() => {
    if (simulationUuid) {
      if (
        ['approved', 'denied', 'active_loans'].includes(
          creditAnalysisData.data?.simulation?.reason.slug
        )
      ) {
        setLoading(false)
        goToSimulation()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditAnalysisData?.data?.simulation?.status, simulationUuid])

  useEffect(() => {
    if (selectedCategory?.id && selectedUnit?.companyUuid) {
      getAllProductsByUuidAndCategoryId()
    }
  }, [
    getAllProductsByUuidAndCategoryId,
    selectedCategory?.id,
    selectedUnit?.companyUuid
  ])

  useEffect(() => {
    if (simulationData?.simulation?.category?.id) {
      const tmp = simulationSettingsList?.find(
        (item) =>
          Number(item.id) === Number(simulationData?.simulation?.category?.id)
      )
      setPeriod({
        maxPeriod: Number(tmp?.simulation_settings?.max_period),
        minPeriod: Number(tmp?.simulation_settings?.min_period)
      })
    }
  }, [simulationSettingsList, simulationData?.simulation?.category?.id])

  useEffect(() => {
    if (period.maxPeriod || period.minPeriod) {
      for (let i = period.minPeriod; i < period.maxPeriod + 1; i++) {
        setIntallmentsData((prev) => [
          ...prev,
          {
            id: String(i),
            value: String(i)
          }
        ])
      }
    }
  }, [period.maxPeriod, period.minPeriod])

  useEffect(() => {
    if (pollingAttempts === MAX_POLLING_ATTEMPTS || pollingError) {
      setLoading(false)
      setPollingError(false)

      dispatch({
        action: 'OPEN_MODAL',
        canClose: false,
        component: (
          <CommonModal
            title="Solicitação em andamento"
            description="Não recebemos o retorno da sua simulação. Ela ainda está sendo processada. "
            primaryButtonTitle="cancelar simulação"
            primaryButtonProps={{
              fontSize: '1rem',
              backgroundColor: 'galaxy'
            }}
            primaryButtonAction={async () => {
              try {
                setLoading(true)
                await SimulationService.updateSimulationStatus(
                  simulationUuid || '',
                  {
                    cancelled: true,
                    timeout: true
                  }
                )
                setSimulationUuid('')
                dispatch({ action: 'CLOSE_MODAL' })
                goToSimulation(true)
              } catch (err) {
                console.log(err)
              } finally {
                setLoading(false)
              }
            }}
            secondaryButtonTitle="tentar novamente"
            secondaryButtonProps={{
              fontSize: '1rem',
              backgroundColor: 'noverde'
            }}
            secondaryButtonAction={() => {
              setPollingAttempts(0)
              setLoading(true)
              dispatch({ action: 'CLOSE_MODAL' })
            }}
          />
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollingAttempts, dispatch, setLoading, pollingError])

  useEffect(() => {
    if (simulationData?.simulation?.products) {
      setMostTags(
        simulationData?.simulation?.products.map((product) => ({
          id: String(product.uuid),
          value: String(product.name)
        }))
      )
    }
  }, [setMostTags, simulationData?.simulation?.products])

  useEffect(() => {
    getCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnit])

  return (
    <S.Container
      onSubmit={(e) => {
        const error = customErrors
        error.categories = !selectedCategory
        error.period = !selectedInstallment
        error.products = !tags.length
        error.installment = !selectedInstallment

        setCustomErrors(error)
        handleSubmit(e)
      }}
    >
      <HeaderActions
        title="novo atendimento"
        renderCustomRightCorner={<></>}
        hasReturnButton={true}
        returnButtonAction={() => handleReturn()}
      />

      <S.Content>
        <Text
          tag="span"
          color="black"
          size="28px"
          weight="weight-600"
          _spacing={{
            marginTop: '2rem'
          }}
        >
          Simulador
        </Text>

        <CustomerInformation
          heading="Cliente"
          customerInformationData={customerInformationData}
          _spacing={{
            marginBottom: '3rem',
            marginTop: '2rem'
          }}
        />

        <Text tag="span" color="galaxy" type="subheading-1">
          Financiamento
        </Text>
        <S.FormGroup>
          <S.InputGroup gridColumns="0.4fr 0.4fr 0.3fr">
            <div>
              <InputSelect
                name="categoriaFinanciamento"
                label="categoria do financiamento *"
                data={categories}
                loading={categories?.length === 0}
                selectedItem={selectedCategory}
                message={customErrors.categories ? 'Informe uma categoria' : ''}
                onChange={handleChangeCategoryFinance}
              />
            </div>

            <AutoComplete
              multiple
              options={products}
              onAddTag={addTag}
              onRemoveTag={removeTag}
              tags={tags}
              isLoading={products?.length === 0 && selectedCategory !== null}
              errorMessage={
                !customErrors.products ? '' : 'Selecione pelo menos um produto'
              }
              renderInput={(params) => (
                <Input
                  name="produtoServicos"
                  placeholder="selecionar produtos/serviços..."
                  animatedLabel="produtos/serviços *"
                  {...params}
                />
              )}
              inputValue={productsTerm}
              onInputChange={setProductsTerm}
            />

            <div>
              <Input
                name="prazoExecucao"
                animatedLabel="prazo de execução (meses)"
                value={data.prazoExecucao}
                message={errors.prazoExecucao}
                onChange={handleChange('prazoExecucao', onlyNumbers)}
              />
            </div>
          </S.InputGroup>

          <S.InputGroup>
            <Input
              isRequired
              name="valorCompra"
              animatedLabel="valor da compra"
              value={data.valorCompra || toBrl(0)}
              message={errors.valorCompra}
              onChange={handleChange('valorCompra', formatMoney)}
            />
            <Input
              isRequired
              name="valorFinanciado"
              animatedLabel="valor a ser financiado"
              value={data.valorFinanciado || toBrl(0)}
              message={errors.valorFinanciado}
              onChange={handleChange('valorFinanciado', formatMoney)}
            />
          </S.InputGroup>

          <S.InputGroup>
            <Input
              isRequired
              name="diaPagamento"
              animatedLabel="dia para pagamento"
              placeholder="dia para pagamento"
              maxLength={2}
              value={data.diaPagamento}
              message={errors.diaPagamento}
              onChange={handleChange('diaPagamento', onlyNumbers)}
            />

            {period.maxPeriod || simulationData?.simulation?.period ? (
              <InputSelect
                name="parcelas"
                label="parcelas *"
                data={installmentsData}
                selectedItem={selectedInstallment}
                message={
                  customErrors.installment ? 'Selecione uma parcela' : ''
                }
                onChange={handleChangeInstallments}
              />
            ) : null}
          </S.InputGroup>
        </S.FormGroup>
      </S.Content>
      <Button
        _layout={{
          alignSelf: 'center'
        }}
        _spacing={{
          marginTop: '3rem',
          marginBottom: '4rem'
        }}
        _sizing={{
          width: '15rem'
        }}
      >
        simulação
      </Button>
    </S.Container>
  )
}
