/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import Assets from 'assets'
import { CustomDatePicker } from 'components/CustomDatePicker'
import { SelectItemType } from 'components/InputSelect'
import { PieChart } from 'components/PieChart'
import { ProposalAverages } from 'components/ProposalAverages'
import { ReasonFrequentRefusal } from 'components/ReasonFrequentRefusal'
import { ReportByProduct } from 'components/ReportByProduct'
import {
  defaultStatusSimulations,
  SheetDashboard
} from 'components/SheetDashboard'
import { Text } from 'components/Text'

import * as S from './Dashboard.styles'
import { useLoading } from 'hooks/useLoading'
import { useAppData } from 'hooks/useAppData'

import DashboardService from 'services/remote/v1/Dashboard/DashboardService'
import { DashboardDataType } from 'services/remote/v1/Dashboard/DashboardServiceTypes'
import { useToast } from 'hooks/useToast'
import { Button } from 'components/Button'

import { formatDate } from 'utils/formatters/format-date'

import { PartnersTheme } from 'layout/theme'
import { ExportButton } from 'components/ExportButton'
import { toBrl } from 'utils/formatters/to-brl'
import { AutoComplete } from 'components/Autocomplete'
import { Input } from 'components/Input'
import { isEmpty } from 'utils/is-empty'

type DataType = {
  id: string | number
  icon?: 'up' | 'down'
  name: string
  total: string | number
  total_accepted: string | number
  conversion_rate: string | number
}
interface RenderItemProps {
  item: DataType
}

interface donutTypes {
  labels: string[]
  datasets: [
    {
      label: string
      data: number[]
      backgroundColor: string[]
    }
  ]
  totalRequests: number
}

const productsHeaders = [
  { label: 'produto', key: 'name' },
  { label: 'propostas', key: 'total' },
  { label: 'aceitas', key: 'total_accepted' },
  { label: 'taxa de conversão', key: 'conversion_rate' }
]

const pieChartHeaders = [
  { label: 'status', key: 'name' },
  { label: 'quantidade', key: 'amount' }
]

const proposalHeaders = [
  { label: 'valor aprovado', key: 'approved_value' },
  { label: 'valor recusado', key: 'refused_value' },
  { label: 'valor aceito', key: 'accepted_value' },
  { label: 'valor desembolsado', key: 'lent_value' },
  { label: 'prazo medio (meses)', key: 'average_period' },
  { label: 'ticket medio', key: 'average_ticket' },
  { label: 'taxa media', key: 'average_interest_rate' }
]

const reasontHeaders = [
  { label: 'motivos de recusa frequentes', key: 'name' },
  { label: 'quantidade', key: 'total' }
]

export const Dashboard = () => {
  const [dateRange, setDateRange] = useState<(null | Date)[]>([
    new Date(),
    new Date()
  ])
  const { isLoading, setLoading } = useLoading()
  const { selectedUnit, data: appData } = useAppData()
  const [unitsListData, setUnitsListData] = useState<SelectItemType[]>([])
  const { showToast } = useToast()
  const [currentSelectedUnit, setCurrentSelectedUnit] =
    useState<SelectItemType | null>({} as SelectItemType)
  const [dashboardData, setDashboardData] = useState<DashboardDataType>()
  const [donutData, setDonutData] = useState<donutTypes>()
  const [hasPermissionExport, setHasPermissionExport] = useState(false)
  const [unitTerm, setUnitTerm] = useState('')

  const getDataPieChart = () => {
    setLoading(true)

    if (dashboardData?.solicitations) {
      const newDonutData: donutTypes = {
        labels: dashboardData?.solicitations?.solicitations?.map(
          (solicitation) => solicitation.label
        ),
        datasets: [
          {
            label: '',
            data: dashboardData?.solicitations?.solicitations?.map(
              (solicitation) => solicitation.total_simulations
            ),
            backgroundColor: dashboardData?.solicitations?.solicitations?.map(
              (solicitation) => solicitation.color
            )
          }
        ],
        totalRequests: dashboardData?.solicitations?.total
      }

      if (dashboardData?.solicitations?.total) {
        setDonutData(newDonutData)
      } else {
        const emptyDonutData: donutTypes = {
          labels: defaultStatusSimulations.solicitations.map(
            (solicitation) => solicitation.label
          ),
          datasets: [
            {
              label: '',
              data: [25, 25, 25, 25, 25],
              backgroundColor: [
                PartnersTheme.colors.backgrounds,
                PartnersTheme.colors.backgrounds,
                PartnersTheme.colors.backgrounds,
                PartnersTheme.colors.backgrounds
              ]
            }
          ],
          totalRequests: 0
        }
        setDonutData(emptyDonutData)
      }
    }
    setLoading(false)
  }

  const getDashboardData = useCallback(async () => {
    if (!selectedUnit.companyUuid) return

    try {
      setLoading(true)

      const beginDate = dateRange[0]
        ? formatDate(dateRange[0].toISOString(), 'AAAA-MM-DD')
        : ''

      const endDate = dateRange[1]
        ? formatDate(dateRange[1].toISOString(), 'AAAA-MM-DD')
        : ''

      const params = {
        unitUuid: currentSelectedUnit?.id || '',
        beginDate: beginDate ? `${beginDate}T00:00:00.000Z` : '',
        endDate: endDate ? `${endDate}T23:59:59.999Z` : ''
      }

      const { data } = await DashboardService.getDashboardData(
        selectedUnit?.companyUuid,
        params?.unitUuid,
        params?.beginDate,
        params?.endDate
      )

      setDashboardData(data?.data)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      showToast({
        title: '',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnit, dateRange, unitsListData, currentSelectedUnit])

  const listAllCompanyUnits = () => {
    if (!selectedUnit.companyUuid) return

    const currentCompany = appData?.companies.find(
      (eachCompany) => eachCompany.uuid === selectedUnit.companyUuid
    )

    let units: SelectItemType[] = []

    if (currentCompany) {
      units = currentCompany?.units.map((eachUnit) => ({
        id: String(eachUnit.uuid),
        value: String(eachUnit.name)
      }))
    }

    const defaultUnit = { id: '', value: 'Todas' }

    units.unshift(defaultUnit)

    setCurrentSelectedUnit(defaultUnit)

    setUnitsListData(units)
  }

  const formatConversationTime = (time: string) => {
    const newTime = time.split(':')
    const hours = Number(newTime[0]) > 9 ? newTime[0] : `0${newTime[0]}`

    return `${hours}:${newTime[1]}`
  }

  const getProductExport = () => {
    if (dashboardData && dashboardData.products.length > 0) {
      const exportData = dashboardData?.products.map((product) => ({
        name: product.name,
        total: product.total,
        total_accepted: product.total_accepted,
        conversion_rate: product.conversion_rate
          ? Number(product.conversion_rate).toFixed(2)
          : ''
      }))

      return exportData
    }
  }

  const getProposalExport = () => {
    if (dashboardData && !!dashboardData.proposals) {
      const proposal = {
        approved_value: dashboardData.proposals.approved_value
          ? toBrl(dashboardData.proposals.approved_value)
          : '',
        refused_value: dashboardData.proposals.refused_value
          ? toBrl(dashboardData.proposals.refused_value)
          : '',
        accepted_value: dashboardData.proposals.accepted_value
          ? toBrl(dashboardData.proposals.approved_value)
          : '',
        lent_value: dashboardData.proposals.lent_value
          ? toBrl(dashboardData.proposals.lent_value)
          : '',
        average_period: dashboardData.proposals.average_period,
        average_ticket: dashboardData.proposals.average_ticket
          ? toBrl(dashboardData.proposals.average_ticket)
          : '',
        average_interest_rate: dashboardData.proposals.average_interest_rate
          ? `${dashboardData.proposals.average_interest_rate}%`
          : ''
      } as any

      const checkProposalData = () =>
        Object.keys(proposal).filter((value) => proposal[value])

      return !isEmpty(checkProposalData()) ? [proposal] : []
    }
  }

  const getExportDataPieChart = () => {
    if (
      dashboardData &&
      Array.isArray(dashboardData?.solicitations?.solicitations) &&
      dashboardData?.solicitations?.solicitations.length > 0
    ) {
      const exportData = dashboardData?.solicitations?.solicitations.map(
        (solicitation) => ({
          name: solicitation.label,
          amount: solicitation.total_simulations
        })
      )

      if (exportData.length > 0) {
        exportData.push({
          name: 'total de solicitações',
          amount: dashboardData.solicitations.total
        })
      }
      return exportData
    }
  }

  const checkPermission = () => {
    if (appData) {
      const currentModule = appData.modules.find(
        (_module) => _module.abbreviation === 'DASH'
      )

      if (currentModule) {
        const report = currentModule.resources.find(
          (resource) => resource.abbreviation === 'DASH'
        )

        setHasPermissionExport(!!report?.permissions?.export)
      }
    }
  }

  useEffect(() => {
    checkPermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData])

  useEffect(() => {
    getDataPieChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData])

  useEffect(() => {
    getDashboardData()

    listAllCompanyUnits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnit])

  const renderItem = ({ item }: RenderItemProps) => {
    return (
      <>
        {isLoading ? (
          <>
            <Skeleton width={50} />
            <Skeleton width={50} />
            <Skeleton width={50} />
            <Skeleton width={50} />
          </>
        ) : (
          <>
            <span title={item.name}>{item?.name || '-'}</span>
            <span>{item?.total || '-'}</span>
            <span>{item?.total_accepted || '-'}</span>
            <span>
              {item?.conversion_rate
                ? Number(item.conversion_rate).toFixed(2)
                : '-'}
            </span>
          </>
        )}
      </>
    )
  }

  return (
    <S.Container>
      <Text color="black" tag="span" size="2rem" weight="weight-600">
        Visão geral
      </Text>
      <S.ContainerSelects>
        <div>
          <CustomDatePicker dateRange={dateRange} setDateRange={setDateRange} />
        </div>
        <S.AutoCompleteContainer>
          <AutoComplete
            options={unitsListData}
            selectedOption={currentSelectedUnit}
            inputValue={unitTerm}
            onInputChange={(value: string) => setUnitTerm(value)}
            onChange={(value) =>
              setCurrentSelectedUnit(value ? value : { id: '', value: '' })
            }
            renderInput={(params) => (
              <Input
                name="unidade"
                placeholder="selecione uma unidade..."
                animatedLabel="unidade *"
                data-lpignore
                {...params}
              />
            )}
          />
        </S.AutoCompleteContainer>

        <Button
          variant="primaryBlue"
          _sizing={{ maxWidth: '200px' }}
          onClick={getDashboardData}
        >
          consultar
        </Button>
      </S.ContainerSelects>

      <S.ContainerCharts>
        <S.ColumnLeft>
          {hasPermissionExport && (
            <S.WrapperExport hide={hasPermissionExport}>
              <ExportButton
                data={getExportDataPieChart() || []}
                filename="pieChart.csv"
                headers={pieChartHeaders}
                marginTop="1rem"
              />
            </S.WrapperExport>
          )}

          {donutData && (
            <PieChart
              title={String(donutData?.totalRequests || '')}
              subtitle={donutData?.totalRequests ? 'solicitações' : '-'}
              labels={donutData?.labels}
              datasets={donutData?.datasets}
            />
          )}

          <S.ConversionTime>
            <Assets
              alt=""
              assetProps={{
                key: 'clock',
                type: 'icon'
              }}
            />
            <Text>{`tempo de conversão: ${
              dashboardData?.solicitations?.conversion_time
                ? formatConversationTime(
                    dashboardData?.solicitations?.conversion_time
                  )
                : '-'
            } `}</Text>
          </S.ConversionTime>

          <S.SheetsList>
            <SheetDashboard
              solicitations={dashboardData?.solicitations?.solicitations || []}
            />
          </S.SheetsList>
        </S.ColumnLeft>

        <S.RowsRight>
          <ProposalAverages
            proposalAveragesData={dashboardData?.proposals}
            data={getProposalExport() || []}
            headers={proposalHeaders}
            hasPermissionExport={hasPermissionExport}
          />
          <div>
            <ReasonFrequentRefusal
              dataset={dashboardData?.refusal_reasons?.sort(
                (a, b) => b.total - a.total
              )}
              headers={reasontHeaders}
              hasPermissionExport={hasPermissionExport}
            />
          </div>
        </S.RowsRight>
      </S.ContainerCharts>

      <ReportByProduct
        data={isLoading ? Array(5).fill({}) : dashboardData?.products || []}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        hasPermissionExport={hasPermissionExport}
        exportData={getProductExport() || []}
        exportHeaders={productsHeaders}
      />
    </S.Container>
  )
}
