import React, { HTMLAttributes } from 'react'

import { Image, ImageProps } from 'components/Image'

import brushSVG from './svgs/brush.svg'
import closeIconSVG from './svgs/close-icon.svg'
import eyeIconSVG from './svgs/eye.svg'
import filledArrowPolygonBottomSVG from './svgs/filled-arrow-polygon-bottom.svg'
import filledArrowPolygonRightSVG from './svgs/filled-arrow-polygon-right.svg'
import houseSVG from './svgs/house.svg'
import lineCircleAlertSVG from './svgs/line-circle-alert.svg'
import lineCircleDisclaimerSVG from './svgs/line-circle-disclaimer.svg'
import lineCircleErroSVG from './svgs/line-circle-erro.svg'
import lineCircleInformativeSVG from './svgs/line-circle-informative.svg'
import lineCircleSuccessSVG from './svgs/line-circle-success.svg'
import lineEditSVG from './svgs/line-edit.svg'
import lineEditV2SVG from './svgs/line-edit-v2.svg'
import lineExitSVG from './svgs/line-exit.svg'
import leftArrowSVG from './svgs/left-arrow-icon.svg'
import leftArrowV2SVG from './svgs/arrow-left-v2.svg'
import noverdeLogoSVG from './svgs/noverde-business-logo.svg'
import storeSVG from './svgs/store.svg'
import closePreviewSVG from './svgs/close-preview-icon.svg'
import pictureIconSVG from './svgs/picture-icon.svg'
import editPencilSVG from './svgs/edit-pencil.svg'
import successSVG from './svgs/success.svg'
import deniedSVG from './svgs/denied.svg'
import arrowDownSVG from './svgs/arrow-down.svg'
import editProposalSVG from './svgs/edit-proposal.svg'
import attendanceAceiteSVG from './svgs/attendance-aceite.svg'
import attendanceEmailSVG from './svgs/attendance-email.svg'
import attendanceMoneySVG from './svgs/attendance-money.svg'
import attendanceProfileSVG from './svgs/attendance-profile.svg'
import attendanceSiteSVG from './svgs/attendance-site.svg'
import filterSVG from './svgs/filter.svg'
import clockSVG from './svgs/clock.svg'
import calendarSVG from './svgs/calendar.svg'
import datePickerSVG from './svgs/date-picker.svg'
import homeIconSVG from './svgs/home-icon.svg'
import userIconSVG from './svgs/user-icon.svg'
import unitsIconSVG from './svgs/units-icon.svg'
import profileIconSVG from './svgs/profile-icon.svg'
import companyIconSVG from './svgs/company-icon.svg'
import productIconSVG from './svgs/product-icon.svg'
import reasonIconSVG from './svgs/reason-icon.svg'
import newAttendanceIconSVG from './svgs/new-attendance-icon.svg'
import attendanceHistoryIconSVG from './svgs/attendance-history-icon.svg'
import dashboardIconSVG from './svgs/dashboard-icon.svg'
import reportsIconSVG from './svgs/reports.svg'
import dropdownArrowSVG from './svgs/dropdown-arrow.svg'
import followUpSVG from './svgs/follow-up.svg'
import EyeOpenSVG from './svgs/eye-open.svg'
import NoverdeSymbol from './svgs/noverde-symbol.svg'
import DotzLogo from './svgs/dotz-logo.svg'
import WebIcon from './svgs/web-icon.svg'
import OpenWebIcon from './svgs/open-web-icon.svg'
import HelpSvg from './svgs/help.svg'
import DownloadIcon from './svgs/download-icon.svg'
import CopyTextIcon from './svgs/copy-text-icon.svg'
import DeleteIcon from './svgs/delete.svg'
import FileUpload from './svgs/file-upload.svg'
import RefreshIcon from './svgs/refresh.svg'
import Download from './svgs/download.svg'
import FileUploadBlank from './svgs/file-upload-blank.svg'
import Manage from './svgs/manage.svg'

import cookingPNG from './images/cooking.png'
import happyCouplePNG from './images/happy-couple.png'
import paintingPNG from './images/painting.png'

export type assetKey =
  | ({ type: 'icon' } & { key: keyof typeof icons })
  | ({ type: 'image' } & { key: keyof typeof images })

export type AssetsProps = {
  assetProps: assetKey
} & Omit<ImageProps, 'src'> &
  HTMLAttributes<HTMLDivElement>

export const icons = {
  arrowLeft: leftArrowSVG,
  arrowLeftV2: leftArrowV2SVG,
  brush: brushSVG,
  closeIcon: closeIconSVG,
  eyeIcon: eyeIconSVG,
  filledArrowPolygonBottom: filledArrowPolygonBottomSVG,
  filledArrowPolygonRight: filledArrowPolygonRightSVG,
  house: houseSVG,
  lineCircleAlert: lineCircleAlertSVG,
  lineCircleDisclaimer: lineCircleDisclaimerSVG,
  lineCircleErro: lineCircleErroSVG,
  lineCircleInformative: lineCircleInformativeSVG,
  lineCircleSuccess: lineCircleSuccessSVG,
  lineEdit: lineEditSVG,
  lineEditV2: lineEditV2SVG,
  lineExit: lineExitSVG,
  store: storeSVG,
  closePreview: closePreviewSVG,
  pictureIcon: pictureIconSVG,
  editPencil: editPencilSVG,
  success: successSVG,
  denied: deniedSVG,
  arrowDown: arrowDownSVG,
  editProposal: editProposalSVG,
  attendanceAceite: attendanceAceiteSVG,
  attendanceEmail: attendanceEmailSVG,
  attendanceMoney: attendanceMoneySVG,
  attendanceProfile: attendanceProfileSVG,
  attendanceSite: attendanceSiteSVG,
  filter: filterSVG,
  clock: clockSVG,
  calendar: calendarSVG,
  datePicker: datePickerSVG,
  homeIcon: homeIconSVG,
  userIcon: userIconSVG,
  unitsIcon: unitsIconSVG,
  profileIcon: profileIconSVG,
  companyIcon: companyIconSVG,
  productIcon: productIconSVG,
  reasonIcon: reasonIconSVG,
  newAttendanceIcon: newAttendanceIconSVG,
  attendanceHistoryIcon: attendanceHistoryIconSVG,
  dashboardIcon: dashboardIconSVG,
  dropdownArrow: dropdownArrowSVG,
  followUpIcon: followUpSVG,
  eyeOpenIcon: EyeOpenSVG,
  noverdeSymbol: NoverdeSymbol,
  dotzLogo: DotzLogo,
  webIcon: WebIcon,
  openWebIcon: OpenWebIcon,
  reportsIcon: reportsIconSVG,
  helpIcon: HelpSvg,
  downloadIcon: DownloadIcon,
  copyTextIcon: CopyTextIcon,
  deleteIcon: DeleteIcon,
  fileUpload: FileUpload,
  refresh: RefreshIcon,
  download: Download,
  fileUploadBlank: FileUploadBlank,
  manage: Manage
}

const images = {
  noverdeLogo: noverdeLogoSVG,
  dotzLogo: DotzLogo,
  cooking: cookingPNG,
  happyCouple: happyCouplePNG,
  painting: paintingPNG
}

const Assets = ({ assetProps, ...rest }: AssetsProps) => {
  return (
    <Image
      {...rest}
      src={
        assetProps.type === 'icon'
          ? icons[assetProps.key]
          : images[assetProps.key]
      }
    />
  )
}

export const assetsPaths = {
  images,
  icons
}

export default Assets
