import { useEffect } from 'react'

import { useRouter } from 'hooks/useRouter'

import { HeaderActions } from 'components/HeaderActions'
import { unmask } from 'utils/formatters/unmask'
import {
  CustomerInformation,
  CustomerInformationData
} from 'components/CustomerInformation'
import { AttendanceAccordion } from 'components/AttendanceAccordion'
import { Button } from 'components/Button'

import * as S from './AttendanceHistory.styles'
import { formatZonedTimeToUtc } from 'utils/formatters/fomatZonedTimeToUtc'
import { ROUTES_NAME } from 'routes'
import { AttendanceCustomerInfoRouteProps } from 'pages/AttendanceCustomerInfo'
import { formatDate } from 'utils/formatters/format-date'

export const AttendanceHistory = () => {
  const { history, location } = useRouter<AttendanceCustomerInfoRouteProps>()

  useEffect(() => {
    if (!location?.state?.leadData) {
      history.push(ROUTES_NAME.ATTENDANCE)
    }
  }, [history, location])

  const customerInformationData: CustomerInformationData = {
    nomeCompleto: {
      rotule: location?.state?.leadData?.data?.client?.name || ''
    },
    dataNascimento: {
      rotule: location?.state?.leadData?.data?.client?.birthdate || ''
    },
    cpf: {
      rotule: location?.state?.leadData?.data?.client?.cpf || ''
    }
  }

  if (!location?.state?.leadData) return <></>

  return (
    <S.Container>
      <HeaderActions
        title="histórico de atendimentos"
        hasReturnButton={false}
        renderCustomRightCorner={<></>}
      />
      <S.ContentBoxContainer>
        <CustomerInformation
          heading="Cliente"
          customerInformationData={customerInformationData}
          _spacing={{
            marginBottom: '2rem',
            marginTop: '2rem'
          }}
        />

        {location.state.leadData?.data.attendances.map((eachAttendance) => (
          <S.AccordionWrapper key={eachAttendance.uuid}>
            <AttendanceAccordion
              accordionData={{
                attendant: eachAttendance.user.name,
                attendanceDate: eachAttendance?.created_at
                  ? formatDate(
                      String(
                        new Date(
                          formatZonedTimeToUtc(
                            eachAttendance.created_at,
                            true
                          ) || ''
                        )
                      ),
                      'DD/MM/AAAA - HH:MM'
                    )
                  : '-',
                status: eachAttendance?.reason?.name || '',
                unit: eachAttendance.unit.name,
                validity: '20/05/2021'
              }}
              simulations={eachAttendance.simulations}
              attendanceOverviewData={{
                taxaJuros: 24.9,
                totalParcelas: 24.9,
                valorPago: 24.9,
                valorParcelas: 24.9,
                valorSolicitado: 24.9
              }}
            />
          </S.AccordionWrapper>
        ))}
      </S.ContentBoxContainer>

      <Button
        onClick={() => {
          history.push({
            pathname: ROUTES_NAME.ATTENDANCE_CUSTOMER_INFORMATION,
            state: {
              customerData: location?.state?.customerData,
              attendanceUuid: location?.state?.attendanceUuid,
              document: unmask(location?.state?.customerData?.document || '')
            }
          })
        }}
        type="button"
        _layout={{
          alignSelf: 'center'
        }}
        _spacing={{
          marginTop: '3rem',
          marginBottom: '4rem'
        }}
        _sizing={{
          width: '40%'
        }}
      >
        nova análise
      </Button>
    </S.Container>
  )
}
