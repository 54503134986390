import Assets from 'assets'
import * as S from './Callout.styles'
import { Text } from 'components/Text'
import { RestTypes } from 'components/typings/utils/restTypes'

export type CalloutProps = {
  type?: 'success' | 'erro' | 'alert' | 'informative' | 'disclaimer'
  title?: string
  children?: React.ReactNode | string
} & RestTypes

export function Callout({
  type = 'success',
  children,
  title = '',
  ...props
}: CalloutProps) {
  const Icons = {
    success: (
      <Assets
        alt="sucesso"
        assetProps={{
          key: 'lineCircleSuccess',
          type: 'icon'
        }}
      />
    ),
    erro: (
      <Assets
        alt="erro"
        assetProps={{
          key: 'lineCircleErro',
          type: 'icon'
        }}
      />
    ),
    alert: (
      <Assets
        alt="alert"
        assetProps={{
          key: 'lineCircleAlert',
          type: 'icon'
        }}
      />
    ),
    informative: (
      <Assets
        alt="informative"
        assetProps={{
          key: 'lineCircleInformative',
          type: 'icon'
        }}
      />
    ),
    disclaimer: (
      <Assets
        alt="disclaimer"
        assetProps={{
          key: 'lineCircleDisclaimer',
          type: 'icon'
        }}
      />
    )
  }

  return (
    <S.Box type={type} title={title} {...props}>
      <S.Header>
        {Icons[type]}
        <Text type="body-2">{title}</Text>
      </S.Header>
      {children && (
        <Text
          color="text"
          type="caption-1"
          _spacing={{
            marginTop: '0.5rem'
          }}
        >
          {children}
        </Text>
      )}
    </S.Box>
  )
}
