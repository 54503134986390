import { Form } from 'components/Form'
import { appearFromRight } from 'layout/animations'
import styled, { css } from 'styled-components'

interface InputGroupProps {
  gridColumns?: string
}

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 1.5rem 2.5rem;
  width: 100%;
`

export const Content = styled.div`
  ${({ theme }) => css`
    animation: ${appearFromRight} 0.3s ease-in;
    background: ${theme.colors.white};
    border-radius: 12px;
    border: 1px solid ${theme.colors.greyborders};
    height: fit-content;
    margin-top: 2rem;
    padding: 2rem 3rem;
    width: 100%;
  `}
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 2rem;
`

export const InputGroup = styled.div<InputGroupProps>`
  display: grid;
  grid-auto-flow: column;
  gap: 0.75rem;
  grid-template-columns: ${({ gridColumns }) =>
    gridColumns ? gridColumns : 'repeat(auto-fill, minmax(50%, 50%))'};
  width: 100%;
`
export const ButtonGroup = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
`
