import React from 'react'
import { ThemeProvider } from 'styled-components'
import { PartnersTheme } from 'layout/theme'

interface PartnersThemeProviderProps {
  children?: React.ReactNode
}

export const PartnersThemeProvider = ({
  children
}: PartnersThemeProviderProps) => {
  return <ThemeProvider theme={PartnersTheme}>{children}</ThemeProvider>
}
