import styled, { css } from 'styled-components'

import { devices } from 'layout/devices'

interface ItemListProps {
  isActive?: boolean
  isLogoutButton?: boolean
}

interface SidebarProps {
  isOpen?: boolean
  isCollapsed?: boolean
}

interface HeaderProps {
  isCollapsed?: boolean
}

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  max-height: 100%;
  position: relative;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
    max-height: -webkit-fill-available;
  }
`
export const Sidebar = styled.div<SidebarProps>`
  ${({ isCollapsed, isOpen }) => css`
    display: flex;
    flex-direction: column;
    width: ${isCollapsed ? '5.5rem' : '32vw'};
    max-width: 24rem;
    ${!isCollapsed ? 'min-width: 300px' : 'min-width: 0'};
    height: 100%;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
    background-color: ${({ theme }) => theme.colors.white};
    justify-content: space-between;

    position: absolute;
    z-index: 999;
    transform: ${isOpen ? 'translateX(0)' : 'translateX(-120%)'};

    @media ${devices.large} {
      position: initial;
      transform: translateX(0);
    }

    transition: all 0.3s ease-in;
  `}
`

export const Header = styled.div<HeaderProps>`
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;

  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      width: 0;
      opacity: 0;
    `}

  transition: all 0.3s ease-in;

  img {
    width: auto;
    max-width: 150px;
  }
`

export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ItemList = styled.li<ItemListProps>`
  ${({ theme, isActive }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.white};
    padding-left: 65px;
    height: 65px;
    background-color: ${isActive ? theme.colors.blue800 : theme.colors.blue900};
    cursor: pointer;

    img {
      margin-right: 20px;
    }

    span {
      display: block;
      font-size: 1rem;
      line-height: 20px;
    }

    &:hover {
      filter: brightness(1.2);
    }
  `}

  ${({ isLogoutButton }) =>
    isLogoutButton &&
    css`
      background-color: transparent;
      margin-top: 48px;
    `}
`

export const Footer = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
  width: fit-content;
  margin: 0 auto;
  padding: 2rem 0;

  img {
    width: 114px;
  }

  span {
    align-self: flex-start;
    text-align: left;
    display: block;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 10px;
    font-size: 0.7rem;
  }
`

export const MainContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    background: ${theme.colors.backgrounds};
  `}
`

export const MainContainerHeader = styled.div`
  min-height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid #d9d9d9;

  @media ${devices.large} {
    padding: 0 40px;
  }
`

export const AnimatedBurger = styled.button<SidebarProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.6rem;
  height: 1.6rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 9999;

  &:focus {
    outline: none;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      position: absolute;
      transform: translateY(-10px);
    `}

  div {
    width: 1.6rem;
    height: 0.25rem;
    background: ${({ isOpen, theme }) =>
      isOpen ? theme.colors.white : theme.colors.dotzOrange};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
      transform: ${({ isOpen }) =>
        isOpen ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }

  @media ${devices.large} {
    display: none;
  }
`

export const ModalLayer = styled.div<SidebarProps>`
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;

  transition: all 0.2s ease-in;

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      z-index: 200;
    `}
`
export const SkeletonContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CurrentEnvBar = styled.div`
  position: absolute;
  width: 70px;
  height: 15px;
  top: 10px;
  right: -20px;
  background-color: red;
  transform: rotate(45deg);
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #ffffff;
    font-size: 11px;
  }
`

export const ModuleContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`
