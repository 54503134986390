import { useCallback, useState, useEffect } from 'react'
import { format } from 'date-fns'
import Skeleton from 'react-loading-skeleton'

import { useLoading } from 'hooks/useLoading'
import { useRouter } from 'hooks/useRouter'
import { useAppData } from 'hooks/useAppData'

import { HeaderActions } from 'components/HeaderActions'
import Assets from 'assets'
import { ListTable } from 'components/ListTable'
import { ToggleSwitcher } from 'components/ToggleSwitcher'
import { EmptyState } from 'components/EmptyState'
import { ROUTES_NAME } from 'routes'

import { ProductEditRouteProps } from './ProductEdit'
import CompanyService from 'services/remote/v1/Company/CompanyService'
import { ProductDTO } from 'services/remote/v1/Product/ProductServiceTypes'
import { formatZonedTimeToUtc } from 'utils/formatters/fomatZonedTimeToUtc'

import * as S from './Product.styles'
import { PermissionsType } from 'pages/Profile/types'
import { getResourcePermissions } from 'utils/getResourcePermissions'
import { TooltipText } from 'components/TooltipText'

interface RenderItemProps {
  item: ProductDTO
  canEdit?: boolean
}

export const Product = () => {
  const { selectedUnit, data: appData } = useAppData()
  const { isLoading, setLoading } = useLoading()
  const [permissions, setPermissions] = useState<PermissionsType>(
    {} as PermissionsType
  )
  const [tableListData, setTableListData] = useState<ProductDTO[]>([])
  const { history } = useRouter()
  const [isEmptyState, setIsEmptyState] = useState(false)

  useEffect(() => {
    setLoading(true)
  }, [setLoading])

  const getAllProducts = useCallback(async () => {
    if (!selectedUnit.companyUuid) return
    try {
      const { data, status } =
        await CompanyService.getAllProductsInCompanyByUuid(
          selectedUnit.companyUuid,
          '',
          true
        )

      setIsEmptyState(status === 204)

      setTableListData(data?.data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [setLoading, selectedUnit])

  const handleCreateNewProduct = () => {
    history.push(ROUTES_NAME.PRODUCT_CREATE)
  }

  const handleEditProductButton = useCallback(
    (product: ProductDTO) => {
      const state: ProductEditRouteProps = {
        routeItems: { ...product }
      }
      history.push({
        pathname: ROUTES_NAME.PRODUCT_EDIT,
        state
      })
    },
    [history]
  )

  const renderItem = useCallback(
    ({ item, canEdit }: RenderItemProps) => {
      const formattedDate = format(
        new Date(formatZonedTimeToUtc(item.created_at) || new Date()),
        'dd/MM/yyyy'
      )

      return (
        <>
          <span>
            <TooltipText text={item?.name}>
              {item?.name || <Skeleton width={200} />}
            </TooltipText>
          </span>
          <span>
            <TooltipText text={item?.category?.name || ''}>
              {item?.category?.name || <Skeleton width={200} />}
            </TooltipText>
          </span>
          <span>
            <TooltipText text={formattedDate || ''}>
              {item?.created_at ? formattedDate : <Skeleton width={100} />}{' '}
            </TooltipText>
          </span>
          <span>
            <TooltipText text={item?.created_by?.name}>
              {item?.created_by ? (
                item.created_by.name
              ) : (
                <Skeleton width={100} />
              )}{' '}
            </TooltipText>
          </span>

          <ToggleSwitcher
            disabled
            isActive={isLoading ? false : item?.is_active}
            toggleActive={() => null}
          />
          {canEdit ? (
            <S.Icon
              onClick={() => {
                handleEditProductButton(item)
              }}
            >
              <Assets
                assetProps={{ key: 'lineEditV2', type: 'icon' }}
                alt="editar"
              />
            </S.Icon>
          ) : (
            <></>
          )}
        </>
      )
    },
    [isLoading, handleEditProductButton]
  )

  useEffect(() => {
    getAllProducts()
  }, [getAllProducts])

  useEffect(() => {
    const _permissions = getResourcePermissions(appData, 'CAD', 'CAD_PRD')

    if (_permissions) setPermissions(_permissions)
  }, [appData])

  return (
    <S.Container>
      <HeaderActions
        title="produtos"
        buttonTitle={'+ novo produto'}
        buttonAction={handleCreateNewProduct}
        hasReturnButton={false}
        renderCustomRightCorner={!permissions.write ? <></> : null}
      />

      {isEmptyState ? (
        <EmptyState
          type="produtos"
          onClick={handleCreateNewProduct}
          showCreateButton={!permissions.write}
        />
      ) : (
        <ListTable<ProductDTO>
          headerTitle="Todos os produtos"
          tableStructure={{
            header:
              '1fr 1fr minmax(200px, 200px) 1fr minmax(60px, 60px) minmax(60px, 60px)',
            body: '1fr 1fr minmax(200px, 200px) 1fr minmax(60px, 60px) minmax(60px, 60px)',
            columns: [
              'nome',
              'categoria',
              'criado em',
              'criado por',
              'ativa',
              'editar'
            ]
          }}
          data={isLoading ? Array(5).fill({}) : tableListData || []}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          canEdit={permissions.write || permissions.exclude}
        />
      )}
    </S.Container>
  )
}
