/* eslint-disable @typescript-eslint/no-explicit-any */
import * as S from './SheetDashboard.styles'

export interface SheetDashboardProps {
  solicitations: {
    label: string
    color: string
    total_simulations: number | string
  }[]
}

export const statusColors = {
  aceitas: 'noverde' as any,
  'pré-aprovada': 'sunset' as any,
  reprovadas: 'error' as any,
  desembolsadas: 'blue400' as any,
  recusadas: 'purple' as any
}

export const defaultStatusSimulations: SheetDashboardProps = {
  solicitations: [
    {
      label: 'reprovadas',
      total_simulations: '',
      color: '#FF6464'
    },
    {
      label: 'pré-aprovadas',
      total_simulations: '',
      color: '#ff9900'
    },
    {
      label: 'recusadas',
      total_simulations: '',
      color: '#9B51E0'
    },
    {
      label: 'aceitas',
      total_simulations: '',
      color: '#0cd15b'
    },
    {
      label: 'desembolsadas',
      total_simulations: '',
      color: '#1890FF'
    }
  ]
}

export const SheetDashboard = ({ solicitations }: SheetDashboardProps) => {
  solicitations =
    solicitations.length > 0
      ? solicitations
      : defaultStatusSimulations.solicitations

  return (
    <>
      {solicitations.map((legend, index) => (
        <S.Box
          color={legend.color as any}
          key={legend.label}
          customColumn={
            solicitations.length - 1 === index && solicitations.length % 2 !== 0
          }
        >
          <S.Head color={legend.color as any}>
            <S.Text color={legend.color as any} size="0.875rem">
              {legend?.label || '-'}
            </S.Text>
          </S.Head>
          <S.Body>
            <S.Text color={legend.color as any} size="1.5rem">
              {legend?.total_simulations || '-'}
            </S.Text>
          </S.Body>
        </S.Box>
      ))}
    </>
  )
}
