import { renderRestTypes } from 'components/typings/utils/renderRestTypes'
import { RestTypes } from 'components/typings/utils/restTypes'
import styled, { css } from 'styled-components'
import { InputProps } from './Input'
import { spin } from 'layout/animations'

export type ContentProps = {
  marginBottom?: string
  width?: string
} & RestTypes

export interface InputWrapperProps {
  isActive?: boolean
}

export const Wrapper = styled.div`
  position: relative;
  height: fit-content;
  width: 100%;
`

export const Content = styled.div<ContentProps>`
  ${({
    marginBottom,
    width,
    _flex,
    _layout,
    _positioning,
    _sizing,
    _spacing
  }) => css`
    margin-bottom: ${marginBottom ?? '4px'};
    position: relative;
    transition: 0.5s;
    width: ${width ?? '100%'};

    ${renderRestTypes({
      _flex: _flex,
      _layout: _layout,
      _positioning: _positioning,
      _sizing: _sizing,
      _spacing: _spacing
    })}
  `}
`

export const InputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ isActive }) =>
    isActive &&
    css`
      #animated-label {
        top: -6px;
        left: 10px;

        font-size: 12px;
        padding: 0 15px 0 5px;
      }
    `}
`

export const Input = styled.input<InputProps>`
  ${({ width, message, iconLeft, iconRight, height, theme, darkMode }) => css`
    border: 1px solid ${theme.colors.greyborders};
    border-radius: 8px;
    font-size: 0.875rem;
    height: ${height ?? '3.25rem'};
    outline: none;
    padding: 0.7rem;
    position: relative;
    inherits: inherit;
    width: ${width ?? '100%'};
    &:disabled {
      opacity: 0.7;
      filter: brightness(0.95);
    }

    background-color: ${darkMode ? theme.colors.galaxy : '#ffffff'} !important;
    color: ${darkMode ? '#ffffff' : 'currentColor'};

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px
        ${darkMode ? theme.colors.galaxy : '#ffffff'} inset;
      -webkit-text-fill-color: ${darkMode
        ? '#ffffff'
        : 'currentColor'} !important;
    }

    &:disabled {
      border: 1px solid rgba(0, 0, 0, 0.2);
      cursor: not-allowed;
    }
    &:focus:invalid {
      border: 1px solid ${theme.colors.error};
    }
    &:focus:valid {
      border: 1px solid #73dcb1;
    }
    &::placeholder {
      color: ${darkMode
        ? 'rgba(255, 255, 255, 0.6)'
        : theme.colors.placeholders};
    }
    ${!!iconLeft &&
    css`
      padding-left: 2.5rem !important;
    `}
    ${!!iconRight &&
    css`
      padding-right: 2rem !important;
    `}
    ${!!message &&
    css`
      border: 1px solid ${theme.colors.error};
      &:focus:valid {
        border: 1px solid ${theme.colors.error} !important;
      }
    `};
  `}
`

export type InputLabelProps = {
  marginBottom?: string
}
export const Label = styled.label<InputLabelProps>`
  color: #646e7d;
  font-size: 0.75rem;
  display: inline-block;
  margin-bottom: 8px;
  position: relative;
  transition: 0.5s;
`

export type MessageProps = {
  bottom?: string
  marginTop?: string
} & Pick<InputProps, 'message'>

export const Message = styled.span<MessageProps>`
  ${({ theme }) => css`
    top: 100%;
    color: ${theme.colors.error};
    font-size: 0.75rem;
    left: 0;
    margin-bottom: -20px;
    position: absolute;
  `}
`

export const Icon = styled.div<
  Pick<InputProps, 'iconLeft' | 'iconRight' | 'disabledIcon'>
>`
  ${({ theme, iconLeft, iconRight, disabledIcon }) => css`
    color: ${theme.colors.greyborders};
    display: flex;
    position: relative;
    width: 3rem;
    height: calc(100%);
    justify-content: center;
    align-items: center;
    cursor: ${disabledIcon ? 'not-allowed' : 'pointer'};
    & > svg {
      width: 100%;
      &:hover {
        cursor: ${disabledIcon ? 'not-allowed' : 'pointer'};
      }
    }

    ${!!iconLeft &&
    css`
      padding-left: 1rem;
      position: absolute;
      z-index: 1;
      left: 0;
    `}
    ${!!iconRight &&
    css`
      padding-right: 1rem;
      position: absolute;
      right: 0;
      z-index: 1;
    `}
  `}
`
export type AnimatedLabelProps = {
  background?: string
  color?: string
  disabled?: boolean
}

export const AnimatedLabel = styled.label<AnimatedLabelProps>`
  ${({ background, color, disabled }) => css`
    align-self: start;

    color: ${color ?? '#212121'};
    background: ${background ?? '#fff'};

    position: absolute;
    top: 1.1rem;
    left: 15px;

    transition: 0.1s all;
    line-height: 16px;

    cursor: text;

    ${disabled && `background: transparent !important`};
  `}
`

export const Spinner = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 10px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-left-color: ${theme.colors.noverde};
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: ${spin} 1s linear infinite;
    top: calc(50% - 12.5px);
  `}
`
