import { AttendanceBlockInformation } from 'components/AttendanceBlockInformation'
import { Button } from 'components/Button'
import { HeaderActions } from 'components/HeaderActions'
import { Text } from 'components/Text'
import { useRouter } from 'hooks/useRouter'
import { ROUTES_NAME } from 'routes'
import * as S from './AttendanceSimulationApproved.styles'

export const AttendanceSimulationApproved = () => {
  const { history } = useRouter()

  function handleGoBack() {
    history.goBack()
  }
  return (
    <S.Container>
      <HeaderActions
        title="finalizando"
        returnButtonAction={handleGoBack}
        renderCustomRightCorner={<></>}
      />
      <S.Content>
        <AttendanceBlockInformation
          assetAlt="acesse seu email"
          assetProps={{
            key: 'attendanceEmail',
            type: 'icon'
          }}
          title="Acesse seu email"
          description={
            <Text>
              No <span>e-mail</span> que enviamos acesse o <span>link</span>
            </Text>
          }
        />
        <AttendanceBlockInformation
          assetAlt="acesse sua conta"
          assetProps={{
            key: 'attendanceProfile',
            type: 'icon'
          }}
          title="Acesse sua conta"
          description={
            <Text>
              Insira seu <span>CPF</span> e crie sua <span>senha</span> no site
              ou aplicativo Noverde.
            </Text>
          }
        />
      </S.Content>

      <S.Content>
        <AttendanceBlockInformation
          assetAlt="aceite nossos termos e condições"
          assetProps={{
            key: 'attendanceAceite',
            type: 'icon'
          }}
          title="Aceite nossos Termos e Condições"
          description={
            <Text>
              Precisamos que você aceite os <span>Termos de Uso</span>, Política
              de Privacidade e Contrato do financiamento da entrada.
            </Text>
          }
        />
        <AttendanceBlockInformation
          assetAlt="valor de desembolso"
          assetProps={{
            key: 'attendanceMoney',
            type: 'icon'
          }}
          title="Valor de desembolso"
          description={
            <Text>
              Em até 1 dia útil o valor será depositado diretamente na conta
              empresa.
            </Text>
          }
        />
        <AttendanceBlockInformation
          assetAlt="acesse o site ou app noverde"
          assetProps={{
            key: 'attendanceSite',
            type: 'icon'
          }}
          title="Acesse o site ou app Noverde"
          description={
            <Text>
              Lá você visualiza e paga as suas parcelas e acompanha o seu
              financiamento.
            </Text>
          }
        />
      </S.Content>
      <Button
        onClick={() => history.push(ROUTES_NAME.ATTENDANCE)}
        _layout={{
          alignSelf: 'center'
        }}
        _spacing={{
          marginTop: '2rem'
        }}
        _sizing={{
          width: '20rem'
        }}
      >
        finalizar atendimento
      </Button>
    </S.Container>
  )
}
