import { Form } from 'components/Form'
import styled, { css } from 'styled-components'

interface ColorSpan {
  customColor: string
}

export const FormArea = styled(Form)`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: auto 1fr 1fr 1fr;
  margin-top: 2rem;
  width: 100%;
`
export const ColorSpan = styled.span<ColorSpan>`
  color: ${({ customColor }) => customColor};
  font-weight: 600;
`
export const WrapperTable = styled.div`
  position: relative;
  margin-top: 2rem;
`
