import { Key } from 'react'
import * as S from './ReportByProduct.styles'
import { Text } from 'components/Text'
import { ExportButton } from 'components/ExportButton'

type RenderItemProps<T> = {
  item: T
  index: number
}

type TableStructureType = {
  header: string
  body: string
  columns: string[]
}

type TableProps<T> = {
  headerTitle?: string
  data: T[]
  keyExtractor: (data: T) => Key | null | undefined
  renderItem: (data: RenderItemProps<T>) => JSX.Element
  listHeaderComponent?: () => JSX.Element
  listFooterComponent?: () => JSX.Element
  hasPermissionExport?: boolean
  exportData?: any[]
  exportHeaders?: any[]
}

export const ReportByProduct: <T>(props: TableProps<T>) => React.ReactElement =
  ({
    renderItem,
    keyExtractor,
    data,
    listHeaderComponent,
    listFooterComponent,
    hasPermissionExport,
    exportData,
    exportHeaders
  }) => {
    const tableStructure: TableStructureType = {
      header: '1fr 1fr 1fr 1fr 0.2fr',
      body: '1fr 1fr 1fr 1fr 0.2fr',
      columns: ['produto', 'propostas', 'aceitas', 'taxa de conversão']
    }

    const renderGridHeader = tableStructure.columns.map((eachColumn) => (
      <S.GridHeader key={eachColumn} isEditColumn={eachColumn === 'editar'}>
        {eachColumn}
      </S.GridHeader>
    ))

    {
      hasPermissionExport &&
        renderGridHeader.push(
          <ExportButton
            data={exportData || []}
            filename="produtos.csv"
            headers={exportHeaders || []}
          />
        )
    }
    const renderGridRow = data.map((eachLine, index) => (
      <S.TableRow
        key={keyExtractor(eachLine)}
        gridTemplate={tableStructure.body}
      >
        {renderItem && renderItem({ item: eachLine, index })}
      </S.TableRow>
    ))

    return (
      <S.Container>
        {listHeaderComponent && listHeaderComponent()}

        <S.TableWrapper>
          <S.TableHeader gridTemplate={tableStructure.header}>
            {renderGridHeader}
          </S.TableHeader>

          {renderGridRow.length > 0 ? (
            renderGridRow
          ) : (
            <S.EmptyProducts>
              <Text color="text" weight="weight-600">
                comece a gerar propostas agora mesmo para ver as métricas de
                seus produtos
              </Text>
            </S.EmptyProducts>
          )}
        </S.TableWrapper>

        {listFooterComponent && listFooterComponent()}
      </S.Container>
    )
  }
