import Assets, { assetKey } from 'assets'
import { Text } from 'components/Text'
import { ReactNode } from 'react'
import * as S from './AttendanceBlockInformation.styles'

export interface AttendanceBlockInformationProps {
  assetAlt: string
  assetProps: assetKey
  description: string | ReactNode
  title: string
}

export const AttendanceBlockInformation = ({
  assetAlt,
  assetProps,
  description,
  title
}: AttendanceBlockInformationProps) => (
  <S.Box>
    <Assets alt={assetAlt} assetProps={assetProps} />
    <S.BoxTitle color="galaxy" weight="weight-800" size="20px">
      {title}
    </S.BoxTitle>
    <S.BoxDescription>
      <Text>{description}</Text>
    </S.BoxDescription>
  </S.Box>
)
