/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useRef } from 'react'

import useForm from 'hooks/useForm'
import { useLoading } from 'hooks/useLoading'
import { useModal } from 'providers/ModalProvider'

import { CommonModal } from 'templates/Modals/CommonModal'

import Assets from 'assets'
import { Button } from 'components/Button'
import { Form } from 'components/Form/Form'
import { Input } from 'components/Input'
import { Text } from 'components/Text'

import UserService from 'services/remote/v1/User/UserService'

import * as S from './FormUserUpdatePassword.styles'

interface FormValues {
  currentPassord: string
  newPassword: string
  confirmNewPassword: string
}

export const FormUserUpdatePassword = () => {
  const [eyeCurrentPassword, setEyeCurrentPassword] = useState(false)
  const [eyeNewPassword, setEyeNewPassword] = useState(false)
  const [eyeConfirmNewPassword, setEyeConfirmNewPassword] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)
  const { setLoading } = useLoading()
  const { dispatch } = useModal()

  const handleUpdatePassword = async (
    currentPassword: string,
    newPassword: string
  ) => {
    try {
      setLoading(true)
      await UserService.updateUserPassword(currentPassword, newPassword)

      dispatch({
        action: 'OPEN_MODAL',
        component: (
          <CommonModal
            title="Senha alterada"
            description="Sua senha foi alterada com sucesso."
            primaryButtonTitle="voltar"
            primaryButtonProps={{
              fontSize: '1rem',
              backgroundColor: 'noverde'
            }}
            primaryButtonAction={() => {
              history.back()
              dispatch({ action: 'CLOSE_MODAL' })
            }}
          />
        )
      })
    } catch (err: any) {
      dispatch({
        action: 'OPEN_MODAL',
        component: (
          <CommonModal
            title="Oops! Algo deu errado"
            description={
              err?.response?.data?.error?.messages_client[0] || err.message
            }
            primaryButtonTitle="voltar"
            primaryButtonProps={{
              fontSize: '1rem',
              backgroundColor: 'noverde'
            }}
            primaryButtonAction={() => {
              dispatch({ action: 'CLOSE_MODAL' })
            }}
          />
        )
      })
    } finally {
      setLoading(false)
    }
  }

  const { data, errors, handleChange, handleSubmit } = useForm<FormValues>({
    onSubmit: async () =>
      await handleUpdatePassword(data.currentPassord, data.newPassword),
    validations: {
      newPassword: {
        required: {
          value: true,
          message: 'Informe a nova senha'
        }
      },
      confirmNewPassword: {
        required: {
          value: true,
          message: 'Confirme a nova senha'
        },
        custom: {
          message: 'As senhas informadas precisam ser iguais',
          isValid: (currValue) => inputRef.current?.value === currValue
        }
      },
      currentPassord: {
        required: {
          value: true,
          message: 'Informe a senha atual'
        }
      }
    }
  })

  return (
    <Form
      _flex={{
        direction: 'column',
        gap: '1rem'
      }}
      onSubmit={handleSubmit}
    >
      <S.Box>
        <Text
          type="subheading-1"
          _spacing={{
            marginBottom: '1.5rem'
          }}
        >
          Defina sua senha
        </Text>

        <Input
          type={eyeCurrentPassword ? 'text' : 'password'}
          name="currentPassord"
          animatedLabel="senha atual"
          isRequired
          message={errors.currentPassord}
          onChange={handleChange('currentPassord')}
          value={data.currentPassord || ''}
          iconRight={
            <Assets
              alt=""
              assetProps={{
                key: 'eyeIcon',
                type: 'icon'
              }}
              onClick={() => setEyeCurrentPassword(!eyeCurrentPassword)}
            />
          }
          _spacing={{
            marginBottom: '3rem'
          }}
          _sizing={{
            width: '32rem'
          }}
        />
        <Input
          ref={inputRef}
          type={eyeNewPassword ? 'text' : 'password'}
          name="newPassword"
          animatedLabel="nova senha"
          isRequired
          message={errors.newPassword}
          onChange={handleChange('newPassword')}
          value={data.newPassword || ''}
          iconRight={
            <Assets
              alt=""
              assetProps={{
                key: 'eyeIcon',
                type: 'icon'
              }}
              onClick={() => setEyeNewPassword(!eyeNewPassword)}
            />
          }
          _spacing={{
            marginBottom: '2rem'
          }}
          _sizing={{
            width: '32rem'
          }}
        />
        <Input
          type={eyeConfirmNewPassword ? 'text' : 'password'}
          name="confirmNewPassword"
          animatedLabel="repetir nova senha"
          isRequired
          message={errors.confirmNewPassword}
          onChange={handleChange('confirmNewPassword')}
          value={data.confirmNewPassword || ''}
          iconRight={
            <Assets
              alt=""
              assetProps={{
                key: 'eyeIcon',
                type: 'icon'
              }}
              onClick={() => setEyeConfirmNewPassword(!eyeConfirmNewPassword)}
            />
          }
          _sizing={{
            width: '32rem'
          }}
        />
      </S.Box>
      <S.ButtonGroup>
        <Button
          _sizing={{
            width: '12rem'
          }}
        >
          Salvar
        </Button>
      </S.ButtonGroup>
    </Form>
  )
}
