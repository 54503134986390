import { ExportButton } from 'components/ExportButton'
import { Text } from 'components/Text'
import { WrapperExport } from 'pages/Dashboard/Dashboard.styles'
import { toBrl } from 'utils/formatters/to-brl'
import * as S from './ProposalAverages.styles'

export interface ProposalAveragesData {
  accepted_value: number | string
  approved_value: number | string
  average_interest_rate: number | string
  average_period: number | string
  average_ticket: number | string
  lent_value: number | string
  refused_value: number | string
}

interface ProposalAveragesProps {
  proposalAveragesData?: ProposalAveragesData
  data: any[]
  headers: any[]
  hasPermissionExport: boolean
}

export const ProposalAverages = ({
  proposalAveragesData = {} as ProposalAveragesData,
  data = [],
  headers = [],
  hasPermissionExport = false
}: ProposalAveragesProps) => (
  <S.Box>
    {hasPermissionExport && (
      <WrapperExport hide={hasPermissionExport}>
        <ExportButton
          data={data}
          filename="propostas.csv"
          headers={headers}
          marginTop="1rem"
        />
      </WrapperExport>
    )}

    <S.BoxMoreInformations>
      <S.BoxInformation>
        <Text tag="span" color="text" type="subheading-1">
          valor aprovado
        </Text>
        <Text color="blue700" size="24px" weight="weight-400">
          {proposalAveragesData?.approved_value ? (
            <>
              <Text color="blue700" tag="span" size="1rem" weight="weight-400">
                R$
              </Text>
              &nbsp;
              {toBrl(proposalAveragesData.approved_value, {
                withoutPrefix: true
              })}
            </>
          ) : (
            '-'
          )}
        </Text>
      </S.BoxInformation>
      <S.BoxInformation>
        <Text tag="span" color="text" type="subheading-1">
          valor recusado
        </Text>
        <Text color="sunset" size="24px" weight="weight-400">
          {proposalAveragesData?.refused_value ? (
            <>
              <Text color="sunset" tag="span" size="1rem" weight="weight-400">
                R$
              </Text>
              &nbsp;
              {toBrl(proposalAveragesData.refused_value, {
                withoutPrefix: true
              })}
            </>
          ) : (
            '-'
          )}
        </Text>
      </S.BoxInformation>
      <S.BoxInformation>
        <Text tag="span" color="text" type="subheading-1">
          valor aceito
        </Text>
        <Text color="noverde" size="24px" weight="weight-400">
          {proposalAveragesData?.accepted_value ? (
            <>
              <Text color="noverde" tag="span" size="1rem" weight="weight-400">
                R$
              </Text>
              &nbsp;
              {toBrl(proposalAveragesData.accepted_value, {
                withoutPrefix: true
              })}
            </>
          ) : (
            '-'
          )}
        </Text>
      </S.BoxInformation>
      <S.BoxInformation>
        <Text tag="span" color="text" type="subheading-1">
          valor desembolsado
        </Text>
        <Text color="noverde" size="24px" weight="weight-400">
          {proposalAveragesData?.lent_value ? (
            <>
              <Text color="black" tag="span" size="1rem" weight="weight-400">
                R$
              </Text>
              &nbsp;
              {toBrl(proposalAveragesData.lent_value, {
                withoutPrefix: true
              })}
            </>
          ) : (
            '-'
          )}
        </Text>
      </S.BoxInformation>
    </S.BoxMoreInformations>

    <S.BoxMoreInformations>
      <S.BoxInformation>
        <S.RotuleInformation size="1rem" weight="weight-400">
          prazo médio (meses){' '}
        </S.RotuleInformation>
        <S.ValueInformation size="2rem" weight="weight-400">
          {proposalAveragesData.average_period || '-'}
        </S.ValueInformation>
      </S.BoxInformation>

      <S.BoxInformation>
        <S.RotuleInformation size="1rem" weight="weight-400">
          ticket médio
        </S.RotuleInformation>
        <S.ValueInformation size="2rem" weight="weight-400">
          {proposalAveragesData?.average_ticket ? (
            <>
              <Text color="black" tag="span" size="1rem" weight="weight-400">
                R$
              </Text>
              &nbsp;
              {toBrl(proposalAveragesData.average_ticket, {
                withoutPrefix: true
              })}
            </>
          ) : (
            '-'
          )}
        </S.ValueInformation>
      </S.BoxInformation>

      <S.BoxInformation>
        <S.RotuleInformation size="1rem" weight="weight-400">
          taxa média
        </S.RotuleInformation>
        <S.ValueInformation size="2rem" weight="weight-400">
          {proposalAveragesData?.average_interest_rate
            ? `${proposalAveragesData?.average_interest_rate}%`
            : '-'}
        </S.ValueInformation>
      </S.BoxInformation>
    </S.BoxMoreInformations>
  </S.Box>
)
