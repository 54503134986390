import { BrowserRouter } from 'react-router-dom'

import { useAppData } from 'hooks/useAppData'

import { AuthRoutes } from './AuthRoutes'
import { PrivateRoutes } from './PrivateRoutes'

export const Routes = () => {
  const { authToken } = useAppData()

  return (
    <BrowserRouter>
      {!authToken ? <AuthRoutes /> : <PrivateRoutes />}
    </BrowserRouter>
  )
}
