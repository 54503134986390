import { FormUsers } from 'components/FormUsers'
import { HeaderActions } from 'components/HeaderActions'
import { useRouter } from 'hooks/useRouter'

import * as S from './Users.styles'

export const UsersCreate = () => {
  const { handleGoBack } = useRouter()

  return (
    <S.Container>
      <HeaderActions
        title="convidar"
        hasReturnButton={true}
        returnButtonAction={handleGoBack}
        renderCustomRightCorner={<></>}
      />
      <FormUsers />
    </S.Container>
  )
}
