import { FormReason } from 'components/FormReason'
import { HeaderActions } from 'components/HeaderActions'
import { useRouter } from 'hooks/useRouter'

import * as S from './Reason.styles'

export const ReasonCreate = () => {
  const { history } = useRouter()

  return (
    <S.Container>
      <HeaderActions
        title="novo motivo"
        hasReturnButton={true}
        returnButtonAction={() => history.goBack()}
        renderCustomRightCorner={<></>}
      />
      <FormReason />
    </S.Container>
  )
}
