import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 8rem;
`

export const Description = styled.p`
  color: #646e7d;
  font-size: 20px;
  margin-top: 27px;
`

export const StartToRegisterButton = styled.p`
  cursor: pointer;
  text-decoration: underline;
  color: #0c0f25;
  font-size: 16px;
  font-weight: 700;
  margin-top: 24px;
`
