import { WrapperExport } from 'pages/Dashboard/Dashboard.styles'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: flex-start;
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.greyborders};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    min-height: 300px;

    &:hover ${WrapperExport} {
      display: flex;
    }
  `}
`

export const Item = styled.div`
  align-items: center;
  display: flex;
  gap: 2rem;
  width: 100%;

  &:not(:first-child) {
    margin-top: 1rem;
  }
`

export const NameItem = styled.span`
  width: 50%;
`

export const BarContainer = styled.span`
  height: 1.25rem;
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
`

interface BarContentProps {
  percentage: number
}

export const BarContent = styled.div<BarContentProps>`
  background-color: #86bced;
  border: 1px solid #0c78db;
  display: block;
  height: 100%;
  position: relative;
  width: ${(props) => props.percentage}%;
`

export const BarValue = styled.p`
  color: #646e7d;
`

export const WrapperSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const EmptyReasonFrequentRefusal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
  margin-top: 3rem;
`
