import { Chart, DoughnutController, Tooltip, ArcElement } from 'chart.js'
import { useCallback, useMemo } from 'react'
import { RefObject } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { Wrapper } from './PieChart.styles'
Chart.register(DoughnutController, Tooltip, ArcElement)

export interface DoughnutChartDataset {
  label: string
  data: number[]
  backgroundColor: string[]
}

interface DoughnutChartProps {
  title?: string
  subtitle?: string
  labels: string[]
  datasets: DoughnutChartDataset[]
}

export function PieChart({
  title = '',
  subtitle = '',
  labels,
  datasets
}: DoughnutChartProps) {
  const ref: RefObject<HTMLCanvasElement> = useRef(null)

  const data = useMemo(() => {
    return {
      labels: labels,
      datasets: datasets as DoughnutChartDataset[]
    }
  }, [labels, datasets])

  const draw = useCallback(() => {
    return new Chart(ref.current as HTMLCanvasElement, {
      type: 'doughnut',
      data: data,
      plugins: [
        {
          id: 'text',
          beforeDraw: (chart) => {
            const width = chart.width
            const height = chart.height
            const ctx = chart.ctx
            ctx.restore()
            const fontSize = (height / 114).toFixed(4)
            ctx.font = fontSize + 'rem sans-serif'
            ctx.textBaseline = 'bottom'
            const titleMeasures = ctx.measureText(title)
            const titleX = Math.round((width - titleMeasures.width) / 2)
            const fontHeightTitle =
              titleMeasures.actualBoundingBoxAscent -
              titleMeasures.actualBoundingBoxDescent
            const titleY = height / 2 - fontHeightTitle + 40

            ctx.fillText(title, titleX, titleY)

            const fontSize2 = (height / 180).toFixed(4)
            ctx.font = fontSize2 + 'rem sans-serif'
            ctx.textBaseline = 'top'

            const subtitleMeasures = ctx.measureText(subtitle)
            const subtitleX = Math.round((width - subtitleMeasures.width) / 2)
            const fontHeightSubtitle =
              subtitleMeasures.actualBoundingBoxAscent -
              subtitleMeasures.actualBoundingBoxDescent
            const subtitleY = height / 2 - fontHeightSubtitle
            ctx.fillText(subtitle, subtitleX, subtitleY)
            ctx.save()
          }
        }
      ],
      options: {
        responsive: true,
        cutout: '70%'
      }
    })
  }, [data, subtitle, title])

  useEffect(() => {
    const chart = draw()
    return () => {
      chart.destroy()
    }
  }, [draw])

  return (
    <Wrapper>
      <canvas ref={ref} />
    </Wrapper>
  )
}
