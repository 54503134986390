import { devices } from 'layout/devices'
import styled, { css } from 'styled-components'

type StatusTextProps = {
  status: string
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 50px;
  padding: 27px 20px;
  width: 100%;

  button {
    max-width: 50%;
  }

  @supports (-webkit-touch-callout: none) {
    margin-bottom: 100px;
  }

  @media ${devices.large} {
    padding: 27px 50px;

    button {
      max-width: 300px;
    }
  }
`

export const NameWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 15px;
`

export const StatusText = styled.span<StatusTextProps>`
  ${({ theme, status }) => css`
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.16px;
    color: ${status === 'validated'
      ? theme.colors.noverde
      : status === 'pending'
      ? theme.colors.sunset
      : theme.colors.error};
  `}
`

export const EditButton = styled.div`
  cursor: pointer;
  margin-left: auto;
  margin-right: 5px;
`
