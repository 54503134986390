import React, { useEffect, useCallback } from 'react'

import { useModal } from 'providers/ModalProvider'

import Assets from 'assets'

import * as S from './Modal.styles'

export const Modal = () => {
  const { component, dispatch, isOpen, canCloseModal } = useModal()

  const handleCloseModal = useCallback(() => {
    dispatch({ action: 'CLOSE_MODAL' })
  }, [dispatch])

  const handleKeyUp = useCallback(
    (event) => {
      switch (event.key) {
        case 'Escape':
          handleCloseModal()
          break

        default:
          break
      }
    },
    [handleCloseModal]
  )

  const handleClickPropagation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()
  }

  useEffect(() => {
    if (canCloseModal) {
      window.addEventListener('keyup', handleKeyUp)
      return () => window.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyUp, canCloseModal])

  return (
    <>
      {isOpen && (
        <S.Layer
          onClick={() => {
            if (canCloseModal) handleCloseModal()
          }}
        >
          <S.Dialog onClick={handleClickPropagation}>
            <S.Body>
              {canCloseModal && (
                <S.CloseButton onClick={handleCloseModal}>
                  <Assets
                    assetProps={{ type: 'icon', key: 'closeIcon' }}
                    alt="Close"
                  />
                </S.CloseButton>
              )}
              {component}
            </S.Body>
          </S.Dialog>
        </S.Layer>
      )}
    </>
  )
}
