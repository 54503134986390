import * as S from './SimulationSheet.styles'
import { Text } from '../Text'
import { memo, ReactNode } from 'react'

export interface SimulationSheetProps {
  title: string | ReactNode
  value: string | number | ReactNode
  description?: string | ReactNode
  fullHeight?: boolean
  status?: 'approved' | 'denied' | 'active_loans'
}

const SimulationSheetMemo = ({
  title = '',
  description = '',
  value = '0',
  fullHeight = false,
  status = 'approved'
}: SimulationSheetProps) => (
  <S.Box fullHeight={fullHeight} status={status}>
    <S.Head status={status}>
      <Text
        color={status === 'approved' ? 'noverde' : 'sunset'}
        size="0.875rem"
        weight="weight-700"
      >
        {title}
      </Text>
    </S.Head>
    <S.Body>
      <Text color="black" size="1.5rem" weight="weight-700">
        {value}
      </Text>
      {fullHeight ? (
        <Text
          tag="span"
          color="text"
          size="0.75rem"
          weight="weight-400"
          _spacing={{
            marginTop: description ? '2rem' : '4rem'
          }}
        >
          {description}
        </Text>
      ) : (
        ''
      )}
    </S.Body>
  </S.Box>
)

export const SimulationSheet = memo(SimulationSheetMemo)
