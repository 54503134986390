import { CompanyProvider } from './Company.context'

import * as S from './Company.styles'
import { CompanyEdit } from './CompanyEdit'

export const CompanyEditWrapper = () => {
  return (
    <CompanyProvider>
      <S.Container>
        <CompanyEdit />
      </S.Container>
    </CompanyProvider>
  )
}
