import styled from 'styled-components'

export const Container = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #0c0f25;
  position: fixed;
  bottom: 25px;
  right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
