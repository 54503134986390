import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'

import { GetAddressDataResponse } from './CepServiceTypes'

class CepService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Get lead's address data
   */
  public getAddressInfoByCep = (cep: string) => {
    return this.instance.get<GetAddressDataResponse>(`/geo-location/${cep}`)
  }
}

export default new CepService()
