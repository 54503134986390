/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react'

import { useRouter } from 'hooks/useRouter'
import { useLoading } from 'hooks/useLoading'
import { useModal } from 'providers/ModalProvider'

import { StepsWithIconReturn } from 'templates/StepsWithIconReturn'
import { CommonModal } from 'templates/Modals/CommonModal'

import { Button } from 'components/Button'
import { Pin } from 'components/Pin'
import { Text } from 'components/Text'
import { Form } from 'components/Form'

import AuthenticationService from 'services/remote/v1/Authorizer/AuthenticationService'
import { ROUTES_NAME } from 'routes'
import { useCountdown } from 'hooks/useCountdown'
import { formatZonedTimeToUtc } from 'utils/formatters/fomatZonedTimeToUtc'
import { Callout } from 'components/Callout'

const PIN_LENGTH = 4

type RouteStateType = {
  email: string
  uuidLogin: string
  pinUuid: string
  flowType: 'firstAccess' | 'forgetPassword' | 'pinBlocked'
}

export const getModalStatusError = (status: number) =>
  ({
    404: {
      title: 'Código incorreto',
      body: 'Confira se digitou o código PIN corretamente ou aguarde para "reeviar código" '
    },
    400: {
      title: 'Código expirado',
      body: 'O código pin que você digitou expirou, clique em "reeviar código" '
    }
  }[status])

export const FirstAccessStep1 = () => {
  const { history, location } = useRouter<RouteStateType>()
  const { setLoading } = useLoading()
  const { dispatch } = useModal()
  const { setTime, setIsActiveTime, timeValue, isActiveTime } = useCountdown()

  const [pinValue, setPinValue] = useState<Array<number | undefined>>(
    new Array(PIN_LENGTH)
  )
  const [pinUuid, setPinUuid] = useState(location.state?.pinUuid || '')

  const [showCallout, setShowCallout] = useState(false)

  useEffect(() => {
    setShowCallout(location?.state?.flowType === 'pinBlocked')
  }, [])

  useEffect(() => {
    if (!location.state) {
      history.replace(ROUTES_NAME.LOGIN)
    }
  }, [location, history])

  const onPinChanged = (pinEntry: number | undefined, index: number) => {
    const newPin = [...pinValue]
    newPin[index] = pinEntry
    setPinValue(newPin)
  }

  const handleSubmitPin = async () => {
    const { uuidLogin, flowType } = location.state
    const pin = pinValue.join('')

    try {
      setLoading(true)

      await AuthenticationService.validatePin(uuidLogin, pinUuid, pin)

      history.push({
        pathname: '/first-access/step-2',
        state: {
          uuidLogin,
          pinUuid,
          flowType
        }
      })
    } catch (err: any) {
      const errorMessage = getModalStatusError(err?.response.status)

      if (
        err?.response.status === 401 &&
        err?.response?.data?.data?.expiration_time
      ) {
        setShowCallout(true)
      } else {
        dispatch({
          action: 'OPEN_MODAL',
          component: (
            <CommonModal
              maxWidth
              title={errorMessage?.title || 'Ops, algo deu errado'}
              description={
                errorMessage?.body ||
                err?.response?.data?.error?.messages_client[0] ||
                err.message
              }
              primaryButtonTitle="voltar"
              primaryButtonProps={{
                fontSize: '1rem',
                backgroundColor: 'noverde'
              }}
              primaryButtonAction={() => {
                dispatch({ action: 'CLOSE_MODAL' })
              }}
            />
          )
        })
      }
    } finally {
      setLoading(false)
    }
  }

  async function handleResendCode() {
    try {
      setLoading(true)
      const { data } = await AuthenticationService.generatePin(
        location.state.uuidLogin
      )
      setPinUuid(data.data?.uuid_pin || '')
      setTime(60)
      setIsActiveTime(true)
    } catch (err: any) {
      setLoading(false)
      dispatch({
        action: 'OPEN_MODAL',
        component: (
          <CommonModal
            title="Oops! Algo deu errado"
            description={
              err?.response?.data?.error?.messages_client[0] || err.message
            }
            primaryButtonTitle="voltar"
            primaryButtonProps={{
              fontSize: '1rem',
              backgroundColor: 'noverde'
            }}
            primaryButtonAction={() => {
              dispatch({ action: 'CLOSE_MODAL' })
            }}
          />
        )
      })
    } finally {
      setLoading(false)
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  useEffect(() => {
    if (location.state?.pinUuid) {
      setIsActiveTime(true)
      setTime(60)
    }
  }, [setIsActiveTime, setTime, location.state?.pinUuid])

  return (
    <StepsWithIconReturn
      stepLines={1}
      stepLinesBehind={1}
      stepTitle={
        <>
          <strong>enviamos um código</strong> <br />
          para seu email
        </>
      }
      subtitle="muito prazer"
      title={
        <>
          estamos <br />
          <strong>
            felizes em <br /> te conhecer
          </strong>
        </>
      }
      onClickReturnIcon={handleGoBack}
    >
      <Text type="body-1">{location.state?.email}</Text>
      <Form
        _flex={{
          alignItems: 'center',
          direction: 'column',
          justifyContent: 'center'
        }}
        _spacing={{
          marginTop: '2.5rem'
        }}
      >
        <Pin
          onPinChanged={onPinChanged}
          pin={pinValue}
          pinLength={PIN_LENGTH}
          _spacing={{
            marginBottom: '2rem'
          }}
        />

        {isActiveTime && !showCallout ? (
          <Text
            _spacing={{
              marginBottom: '1.2rem'
            }}
            weight="weight-600"
            color="placeholders"
          >
            {`reenviar código (${timeValue})`}
          </Text>
        ) : (
          <Button
            type="button"
            variant="textPrimary"
            _spacing={{
              marginBottom: '2rem'
            }}
            disabled={showCallout}
            onClick={handleResendCode}
          >
            reenviar código
          </Button>
        )}
        <Button
          type="button"
          onClick={handleSubmitPin}
          disabled={
            !location.state ||
            pinValue.filter((pin) => pin !== undefined).length !== PIN_LENGTH ||
            showCallout
          }
        >
          confirmar
        </Button>

        {showCallout && (
          <Callout
            type="alert"
            title="Excesso de tentativas falhas"
            _spacing={{
              marginTop: '1rem'
            }}
          >
            Você excedeu o número de tentativas. Tente novamente mais tarde.
          </Callout>
        )}
      </Form>
    </StepsWithIconReturn>
  )
}
