import Lottie from 'react-lottie'

import * as S from './EmptyState.styles'
import emptyStateAnimation from 'assets/lottie/empy-state.json'

interface EmptyStateProps {
  type?:
    | 'produtos'
    | 'empresas'
    | 'usuários'
    | 'unidades'
    | 'perfis'
    | 'motivos'
  description?: string
  onClick: () => void
  showCreateButton?: boolean
}

export const EmptyState = ({
  type,
  description,
  onClick,
  showCreateButton = true
}: EmptyStateProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyStateAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <S.Container>
      <Lottie options={defaultOptions} width="100px" height="100px" />
      <S.Description>
        {description ? description : `Ainda não há ${type} cadastrados`}
      </S.Description>
      {!showCreateButton && (
        <S.StartToRegisterButton onClick={onClick}>
          comece a cadastrar agora mesmo
        </S.StartToRegisterButton>
      )}
    </S.Container>
  )
}
