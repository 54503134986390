/* eslint-disable @typescript-eslint/no-non-null-assertion */
import styled, { css } from 'styled-components'
import { renderRestTypes } from 'components/typings/utils/renderRestTypes'
import { ButtonProps } from '.'

export type ContentProps = ButtonProps

const modifiers = {
  variants: {
    primary: () => css`
      color: #fff;
      background-color: #0cd15b;

      &:hover {
        filter: brightness(1.2);
      }
      &:focus {
        filter: brightness(1.2);
      }
    `,
    secondary: () => css`
      color: #0cd15b;
      background: transparent;

      &:hover {
        background-color: #f6fff9;
      }
      &:focus {
        background-color: #e0ffe8;
      }
    `,
    text: () => css`
      background-color: transparent;
      font-size: 1rem;
      color: #fff;
      text-decoration: underline;
      width: fit-content;
      height: fit-content;
      margin: auto;
      padding: 0;
      font-weight: 700;
      line-height: 24px;

      :hover:not(:active) {
        filter: brightness(0.8);
      }
    `,

    textPrimary: ({ color }: ButtonProps) => css`
      ${({ theme }) => css`
        background-color: transparent;
        color: ${theme.colors[color!] ?? '#0cd15b'};
        font-size: 1rem;
        text-decoration: underline;
        width: fit-content;
        height: fit-content;
        padding: 0;
        font-weight: 700;
        line-height: 24px;

        :hover:not(:active) {
          filter: brightness(0.8);
        }
      `}
    `,

    primaryBlue: () => css`
      background-color: #001e65;
      color: #fff;
      font-size: 1.2rem;
    `
  }
}

export const Content = styled.button<ContentProps>`
  ${({
    variant,
    height,
    marginBottom,
    theme,
    width,
    leftIcon,
    rightIcon,
    color,
    backgroundColor,
    fontSize,
    _flex,
    _layout,
    _spacing,
    _sizing,
    _positioning,
    withoutUnderline
  }) => css`
    align-items: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    font-size: 1.2rem;
    font-weight: ${theme.font.weight['weight-600']};
    grid-auto-flow: column;
    justify-content: center;
    height: ${height ?? '3.25rem'};
    text-decoration: none;
    transition: 0.25s;
    padding: 1rem 1.5rem;
    width: ${width ?? '100%'};

    &:disabled {
      filter: brightness(0.8);
      color: #fff;
      cursor: not-allowed;
      &:hover {
        filter: brightness(0.8);
      }
    }

    ${renderRestTypes({
      _flex: _flex,
      _layout: _layout,
      _positioning: _positioning,
      _sizing: _sizing,
      _spacing: _spacing
    })}

    ${!!variant && modifiers.variants[variant!]};
    ${!!fontSize && `font-size: ${fontSize}`};
    ${!!color && `color: ${theme.colors[color!]}`};
    ${!!backgroundColor && `background: ${theme.colors[backgroundColor!]}`};
    ${!!marginBottom && `margin-bottom: ${marginBottom}`};
    ${!!withoutUnderline &&
    css`
      text-decoration: none;
      :hover:not(:active) {
        filter: opacity(0.6);
      }
    `};
    ${!!rightIcon &&
    css`
      & > svg {
        margin-left: 8px;
      }
    `};
    ${!!leftIcon &&
    css`
      & > svg {
        margin-right: 8px;
      }
    `};
  `}
`
