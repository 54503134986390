import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'
import {
  DeleteUnityByUuidResponse,
  GetReportsTypes,
  UpdateUnityByUuidResponse,
  UpdateUnitReqBody
} from './UnitServiceTypes'

class UnityService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Update unity by uuid
   * @param uuid string
   * @param unity Omit<UnityDTO, 'id' | 'uuid'>
   * @returns UpdateUnityByUuidResponse
   */
  public updateUnitByUuid(uuid: string, unit: UpdateUnitReqBody) {
    return this.instance.put<UpdateUnityByUuidResponse>(`/units/${uuid}`, {
      ...unit
    })
  }

  /**
   * Delete unity by uuid
   * @param uuid string
   * @returns DeleteUnityByUuidResponse
   */
  public deleteUnitByUuid(uuid: string) {
    return this.instance.delete<DeleteUnityByUuidResponse>(`/units/${uuid}`)
  }

  public getClosingReports = (
    unitUuid: string,
    beginDate?: string,
    endDate?: string
  ) => {
    return this.instance.get<GetReportsTypes>(
      `/units/${unitUuid}/reports?begin_date=${beginDate}&end_date=${endDate}`
    )
  }
}

export default new UnityService()
