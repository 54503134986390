import { renderRestTypes } from 'components/typings/utils/renderRestTypes'
import styled, { css } from 'styled-components'
import { StepsLineProps } from './StepsLine'

export const StepContainer = styled.div<Partial<StepsLineProps>>`
  ${({ _flex, _layout, _positioning, _sizing, _spacing }) => css`
    display: grid;
    grid-auto-flow: column;
    gap: 8px;
    position: relative;
    width: 100%;

    ${renderRestTypes({
      _flex: _flex,
      _layout: _layout,
      _positioning: _positioning,
      _sizing: _sizing,
      _spacing: _spacing
    })}
  `}
`

export const StepLine = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.noverde};
    border-radius: 8px;
    height: 4px;
    max-width: 25rem;
    width: 100%;
  `}
`

export const StepLineBehind = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.greyborders};
    border-radius: 8px;
    height: 4px;
    max-width: 25rem;
    width: 100%;
  `}
`
