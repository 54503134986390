import { onlyNumbers } from 'utils/formatters/only-numbers'

const ddds = [
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '21',
  '22',
  '24',
  '27',
  '28',
  '31',
  '32',
  '33',
  '34',
  '35',
  '37',
  '38',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '51',
  '53',
  '54',
  '55',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '71',
  '73',
  '74',
  '75',
  '77',
  '79',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '89',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '99'
]

const unpermitteds = [
  '0000',
  '1111',
  '2222',
  '3333',
  '4444',
  '5555',
  '6666',
  '7777',
  '8888',
  '9999'
]

export function validatePhoneNumber(value: string) {
  const formatedValue = String(onlyNumbers(value))

  const ddd = formatedValue.slice(0, 2)
  const firstNumbers = formatedValue.slice(3, 7)
  const restNumbers = formatedValue.slice(7)

  if (!ddds.includes(ddd)) return false
  if (unpermitteds.includes(firstNumbers)) return false
  if (unpermitteds.includes(restNumbers)) return false

  return true
}
