import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-datepicker/dist/react-datepicker.css'

import { AppProvider } from './providers/AppProvider'
import { Modal } from 'components/Modal'
import { Loader } from 'components/Loader'
import { ToastContainer } from 'react-toastify'

import ResetCSS from 'layout/reset'
import DatePickerStyle from 'layout/date-picker'
import { Routes } from './routes'

const App = () => {
  return (
    <AppProvider>
      <DatePickerStyle />
      <ResetCSS />
      <Routes />
      <Modal />
      <Loader />
      <ToastContainer />
    </AppProvider>
  )
}

export default App
