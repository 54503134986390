import { useCallback, useMemo, useState } from 'react'

export interface TagType {
  id: string
  value: string
}

interface useTagsProps {
  tagsProps: TagType[]
}

export const useTags = ({ tagsProps }: useTagsProps) => {
  const [tags, setTags] = useState(tagsProps || [])

  const addTag = useCallback(
    (tag: TagType) => {
      const index = tags.findIndex(
        (eachCategory) => tag.value.toLowerCase() === eachCategory.value
      )
      if (index >= 0) return
      setTags([...tags, { value: tag.value.toLowerCase(), id: tag.id }])
    },
    [tags]
  )

  const removeTag = useCallback(
    (tag: TagType) => {
      const updatedTags = tags.filter((tmpTag) => tmpTag.value !== tag.value)
      setTags(updatedTags)
    },
    [tags]
  )

  const setMostTags = useCallback((tags: TagType[]) => {
    setTags(tags)
  }, [])

  const removeAllTags = useCallback(() => {
    setTags([])
  }, [])

  return useMemo(() => {
    return {
      tags,
      addTag,
      removeTag,
      removeAllTags,
      setMostTags
    }
  }, [tags, addTag, removeTag, removeAllTags, setMostTags])
}
