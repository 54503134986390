/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react'
import { format, addYears } from 'date-fns'
import faker from 'faker-br'

import { useRouter } from 'hooks/useRouter'
import { useAppData } from 'hooks/useAppData'
import useForm from 'hooks/useForm'
import { useLoading } from 'hooks/useLoading'
import { useActionModal } from 'hooks/useActionModal'

import ProfessionService from 'services/remote/v1/Profession/ProfessionService'
import AttendanceService from 'services/remote/v1/Attendance/AttendanceService'
import SimulationService from 'services/remote/v1/Simulation/SimulationService'
import CEPService from 'services/remote/v1/Cep/CepService'
import LeadService from 'services/remote/v1/Lead/LeadService'
import { LeadDTO } from 'services/remote/v1/Lead/LeadServiceTypes'
import { ProfessionDTO } from 'services/remote/v1/Profession/ProfessionTypes'

import { HeaderActions } from 'components/HeaderActions'
import { Input } from 'components/Input'
import { Form } from 'components/Form'
import { Button } from 'components/Button'
import { InputSelect, SelectItemType } from 'components/InputSelect'
import { FillFormButton } from 'components/FillFormButton'

import {
  cpfMask,
  dateMask,
  phoneMask,
  monetaryMask,
  cepMask
} from 'utils/formatters/masks'
import * as S from './AttendanceCustomerInfo.styles'
import { ROUTES_NAME } from 'routes'
import useDebounce from 'hooks/useDebounce'
import { unmask, monetaryUnmask } from 'utils/formatters/unmask'
import { formatZonedTimeToUtc } from 'utils/formatters/fomatZonedTimeToUtc'
import { validateEmail } from 'utils/validators/validateEmail'
import { validateFullName } from 'utils/validators/validateFullName'
import { onlyText } from 'utils/formatters/only-text'
import { validateDate } from 'utils/validators/validateDate'
import { onlyNumbers } from 'utils/formatters/only-numbers'
import { validatePhoneNumber } from 'utils/validators/validatePhoneNumber'
import { useToast } from 'hooks/useToast'
import OccupationService from 'services/remote/v1/Occupation/OccupationService'
import { OccupationDTO } from 'services/remote/v1/Occupation/OccupationServiceTypes'
import { toFloat } from 'utils/formatters/to-float'
import { AttendanceHistoryResponse } from 'services/remote/v1/Attendance/AttendanceTypes'
import { sleep } from 'utils/sleep'
import { AutoComplete } from 'components/Autocomplete'
import { states } from 'utils/enums/states'

interface FormValues {
  cpf: string
  fullName: string
  birthdate: string
  rg: string
  motherName: string
  email: string
  phone: string
  profession: string
  monthlyIncome: string
  cep: string
  buildingNumber: string
  complement: string
  neighborhood: string
  city: string
  state: string
  street: string
  occupation_type: string
}

type Genders = {
  id: string
  value: string
}

const gendersData = [
  {
    id: 'm',
    value: 'Masculino'
  },
  {
    id: 'f',
    value: 'Feminino'
  }
]

export interface AttendanceCustomerInfoRouteProps {
  leadData?: AttendanceHistoryResponse
  customerData?: {
    document: string
    name: string
    identity_document: string
    birthdate: string
    mothers_name: string
    gender: string
    email: string
    mobile_number: string
    proper_credit?: boolean
    profession: string
    cbo: string
    monthly_income: number
    zipcode: string
    street_name: string
    building_number: string
    complement: string
    neighborhood: string
    city: string
    state: string
    uuid?: string
    occupation_type?: {
      loans_value?: string
      name?: string
      required_profession?: boolean
    }
  }
  document?: string
  attendanceUuid: string
}

interface CepValidator {
  street: boolean
  state: boolean
  neighborhood: boolean
  city: boolean
  complement: boolean
  buildingNumber: boolean
}

export const AttendanceCustomerInfo = () => {
  const { history, location, handleGoBack } =
    useRouter<AttendanceCustomerInfoRouteProps>()
  const { customerData, attendanceUuid } = location.state
  const { actionModal } = useActionModal()

  const [isRequiredProfession, setIsRequiredProfession] = useState(true)
  const [checkCep, setCheckCep] = useState(!!customerData?.zipcode.length)
  const [termText, setTermText] = useState('')
  const [occupationTerm, setOccupationTerm] = useState('')
  const [occupationList, setOccupationList] = useState<OccupationDTO[]>([])
  const [gendersList] = useState<Genders[]>(gendersData)
  const [professionsList, setProfessionsList] = useState<ProfessionDTO[]>([])
  const [selectedProfession, setSelectedProfession] =
    useState<SelectItemType | null>(
      (!!customerData?.profession && {
        id: customerData?.cbo || '',
        value: customerData?.profession || ''
      }) ||
        null
    )
  const [selectedGender, setSelectedGender] = useState<SelectItemType>(
    {
      id: customerData?.gender.toUpperCase() || '',
      value:
        gendersData
          .filter(
            (gender) => gender.id === customerData?.gender.toLocaleLowerCase()
          )
          .map((genderName) => genderName.value)
          .toString() || ''
    } || ({} as SelectItemType)
  )

  const [selectedOccupation, setSelectedOccupation] = useState<SelectItemType>(
    {
      id: customerData?.occupation_type?.loans_value || '',
      value: customerData?.occupation_type?.name || ''
    } || ({} as SelectItemType)
  )
  const [loadingProfessions, setLoadingProfessions] = useState(false)

  const [stateOption, setStateOption] = useState('')
  const [cepDisableFields, setCepDisableFields] = useState<CepValidator>({
    street: !customerData,
    state: true,
    neighborhood: !customerData,
    city: true,
    complement: !customerData,
    buildingNumber: !customerData
  })
  const [stateSelected, setStateSelected] = useState<
    { id: string; value: string } | undefined
  >(
    customerData?.state
      ? states.find(
          (stateOpts) => stateOpts.value.localeCompare(customerData.state) === 0
        )
      : { id: '', value: '' }
  )
  const { selectedUnit } = useAppData()
  const { setLoading } = useLoading()
  const debouncedSearchTerm = useDebounce(termText, 500)
  const { showToast } = useToast()

  const [cepLoading, setCepLoading] = useState(false)
  // const [isOwnCredit, setIsOwnCredit] = useState(!!customerData?.proper_credit)

  const [customErrors, setCustomErrors] = useState({
    professions: false,
    gender: false,
    occupation: false
  })

  useEffect(() => {
    const unsub = history.listen((newLocation, action) => {
      if (action === 'POP') history.go(1)
    })

    return () => unsub()
  }, [history])

  // const toggleIsOwnCredit = () => {
  //   setIsOwnCredit((currState) => !currState)
  // }

  const handleUpdateCustomerData = async () => {
    try {
      setLoading(true)

      const parsedBirthdate = customerData?.birthdate
        ? customerData.birthdate
        : data.birthdate.split('/').reverse().join().replaceAll(',', '-')

      let leadUuid = customerData?.uuid || ''

      const reqBody: LeadDTO = {
        city: data.city,
        building_number: data.buildingNumber,
        document: unmask(data.cpf),
        identity_document: data.rg,
        name: data.fullName,
        gender: selectedGender?.id?.toUpperCase() || '',
        birthdate: parsedBirthdate,
        complement: data.complement,
        email: data.email,
        mobile_number: unmask(data.phone),
        monthly_income: monetaryUnmask(data.monthlyIncome),
        mothers_name: data.motherName,
        state: data.state,
        neighborhood: data.neighborhood,
        zipcode: data.cep,
        street_name: data.street,
        profession: selectedProfession?.value || '',
        cbo: selectedProfession?.id || '',
        proper_credit: true,
        occupation_type: selectedOccupation.id
      }

      if (leadUuid) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { document: _, ...restInformations } = reqBody

        await LeadService.updateLead(
          selectedUnit.companyUuid,
          selectedUnit.unitUuid,
          leadUuid,
          restInformations
        )
      } else {
        const response = await LeadService.createLeadToCompany(
          selectedUnit.companyUuid,
          selectedUnit.unitUuid,
          reqBody
        )

        leadUuid = response.data.data?.uuid || ''
      }

      await AttendanceService.associateLeadToAttendance(
        selectedUnit.companyUuid,
        selectedUnit.unitUuid,
        attendanceUuid,
        leadUuid
      )

      history.replace({
        pathname: ROUTES_NAME.ATTENDANCE_PRE_ANALISYS,
        state: {
          preAnalisysData: {
            name: reqBody.name,
            birthday: reqBody.birthdate,
            document: reqBody.document,
            unityUuid: selectedUnit.unitUuid,
            attendanceUuid,
            leadUuid
          }
        }
      })
    } catch (err: any) {
      showToast({
        title: '',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const initialBirthdate = customerData?.birthdate
    ? format(
        formatZonedTimeToUtc(customerData.birthdate) || new Date(),
        'dd/MM/yyyy'
      )
    : ''

  const verifyIsRequiredProfession = (id: string) => {
    const occupation = occupationList.filter((item) => {
      return item.loans_value === id
    })
    if (!occupation[0]?.required_profession) {
      setCustomErrors((prev) => ({
        ...prev,
        professions: false
      }))
    }
    setIsRequiredProfession(occupation[0]?.required_profession)
  }

  useEffect(() => {
    if (customerData?.occupation_type) {
      setIsRequiredProfession(
        !!customerData?.occupation_type?.required_profession
      )
    }
  }, [customerData?.occupation_type])

  const handleFillAddress = async () => {
    if (!checkCep) {
      try {
        setCepLoading(true)

        const unmaskedZipCode = unmask(data.cep)

        const response = await CEPService.getAddressInfoByCep(unmaskedZipCode)

        const { city, district, state, street } = response.data.data

        const stateResult = states.find(
          (stateOpts) => stateOpts.value.localeCompare(state) === 0
        )
        setStateSelected(stateResult)
        setStateOption(stateResult?.value || '')

        updateFormData({
          ...data,
          street,
          neighborhood: district,
          city,
          state,
          buildingNumber: '',
          complement: ''
        })

        if (!city) {
          updateFormData({
            ...data
          })
        }
        setCepDisableFields({
          street: false,
          complement: false,
          buildingNumber: false,
          neighborhood: false,
          state: !!stateResult?.value,
          city: !!city
        })
      } catch (err: any) {
        updateFormData({
          ...data,
          street: '',
          neighborhood: '',
          city: '',
          state: '',
          buildingNumber: '',
          complement: ''
        })
        setCepDisableFields({
          street: false,
          complement: false,
          buildingNumber: false,
          neighborhood: false,
          city: false,
          state: false
        })
        setStateSelected({ id: '', value: '' })
        setStateOption('')
      } finally {
        setCepLoading(false)
      }
    } else setCheckCep(false)
  }

  const loadOccupations = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await OccupationService.getAllOccupations()
      setOccupationList(data.data)
    } catch (err: any) {
      handleGoBack()
      showToast({
        title: '',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }, [handleGoBack, setLoading, showToast])

  const { data, errors, handleSubmit, handleChange, updateFormData } =
    useForm<FormValues>({
      initialValues: {
        cpf:
          cpfMask(customerData?.document || '') ||
          cpfMask(location.state?.document || ''),
        fullName: customerData?.name || '',
        birthdate: initialBirthdate,
        rg: customerData?.identity_document || '',
        motherName: customerData?.mothers_name || '',
        email: customerData?.email || '',
        phone: phoneMask(customerData?.mobile_number || ''),
        monthlyIncome: monetaryMask(
          String((customerData?.monthly_income || 0) * 100)
        ),
        cep: cepMask(customerData?.zipcode || ''),
        buildingNumber: customerData?.building_number || '',
        complement: customerData?.complement || '',
        profession: customerData?.profession || '',
        street: customerData?.street_name || '',
        state: customerData?.state || '',
        city: customerData?.city || '',
        neighborhood: customerData?.neighborhood || '',
        occupation_type: 'employee'
      },
      validations: {
        cpf: {
          required: {
            message: 'Este campo é obrigatório',
            value: true
          }
        },
        fullName: {
          required: {
            message: 'Este campo é obrigatório',
            value: true
          },
          custom: {
            message: 'Informe seu nome completo',
            isValid: (value: string) => validateFullName(value)
          }
        },
        birthdate: {
          required: {
            message: 'Este campo é obrigatório',
            value: true
          },
          custom: {
            message: 'Data incorreta',
            isValid: (value: string) => validateDate(value)
          }
        },
        buildingNumber: {
          required: {
            message: 'Este campo é obrigatório',
            value: true
          }
        },
        cep: {
          required: {
            message: 'Este campo é obrigatório',
            value: true
          }
        },
        email: {
          required: {
            message: 'Este campo é obrigatório',
            value: true
          },
          custom: {
            message: 'Formato inválido',
            isValid: (value: string) => validateEmail(value)
          }
        },
        phone: {
          required: {
            message: 'Este campo é obrigatório',
            value: true
          },
          custom: {
            message: 'Telefone inválido',
            isValid: (value: string) => validatePhoneNumber(value)
          }
        },
        neighborhood: {
          required: {
            message: 'Este campo é obrigatório',
            value: true
          }
        },
        state: {
          required: {
            message: 'Este campo é obrigatório',
            value: true
          }
        },
        city: {
          required: {
            message: 'Este campo é obrigatório',
            value: true
          }
        },
        monthlyIncome: {
          required: {
            message: 'Este campo é obrigatório',
            value: true
          },
          custom: {
            message: 'Infome uma renda',
            isValid: (value) => {
              const decimal = toFloat(value)
              return parseInt(String(decimal)) > 0
            }
          }
        },
        motherName: {
          required: {
            message: 'Este campo é obrigatório',
            value: true
          },
          custom: {
            message: 'Informe seu nome completo',
            isValid: (value: string) => validateFullName(value)
          }
        },
        rg: {
          required: {
            message: 'Este campo é obrigatório',
            value: true
          }
        },
        street: {
          required: {
            message: 'Este campo é obrigatório',
            value: true
          }
        }
      },
      onSubmit: () => {
        if (
          !selectedGender.value ||
          !selectedOccupation.value ||
          (isRequiredProfession && !selectedProfession)
        ) {
          return
        }

        handleUpdateCustomerData()
      }
    })

  useEffect(() => {
    loadOccupations()
  }, [loadOccupations])

  useEffect(() => {
    const loadProfessions = async () => {
      try {
        setLoadingProfessions(true)
        const response = await ProfessionService.listProfessionsByTerm(
          debouncedSearchTerm
        )

        setProfessionsList(response.data.data || [])
      } catch (err: any) {
        showToast({
          title: '',
          message:
            err?.response?.data?.error?.messages_client[0] || err.message,
          type: 'error'
        })
      } finally {
        setLoadingProfessions(false)
      }
    }

    if (debouncedSearchTerm.length >= 3) {
      void loadProfessions()
    }
  }, [debouncedSearchTerm, showToast])

  useEffect(() => {
    if (unmask(data.cep).length === 8) {
      handleFillAddress()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.cep, data.cep.length])

  const handleReturn = () => {
    actionModal({
      title: 'Finalizar atendimento',
      description:
        'Tem certeza que deseja sair? Seu progresso atual será perdido.',
      submitButtonTitle: 'Finalizar atendimento',
      submitAction: () => {
        void SimulationService.finishAttendance(
          selectedUnit.unitUuid,
          attendanceUuid,
          {
            cancelled: true
          }
        )
        history.replace(ROUTES_NAME.ATTENDANCE)
      }
    })
  }

  const fillWithMockedData = async () => {
    const mockedFullname = faker.name.findName()
    const mockedMothersName = faker.name.findName('Maria')
    const mockedRg = faker.br.rg()
    const mockedCep = '96075160'
    const mockedBirthDate = faker.date.past()
    const mockedAdressNumber = faker.random.number({
      max: 1200,
      min: 100
    })

    updateFormData({
      fullName: mockedFullname,
      motherName: mockedMothersName,
      rg: mockedRg,
      cep: cepMask(mockedCep),
      buildingNumber: String(mockedAdressNumber),
      birthdate: format(addYears(mockedBirthDate, -25), 'dd/MM/yyyy'),
      monthlyIncome: monetaryMask(String(15000 * 100))
    } as FormValues)

    setSelectedGender(gendersData[0])

    setSelectedOccupation({
      id: 'employee',
      value: 'Empregado'
    })

    setTermText('desenvolvedor de ti')

    await sleep(2000)

    const mockedProfession = {
      cbo: '212405',
      description: 'Desenvolvedor de ti'
    }
    setSelectedProfession({
      id: mockedProfession.cbo,
      value: mockedProfession.description
    })
  }

  return (
    <S.Container>
      <HeaderActions
        title="novo atendimento"
        hasReturnButton={true}
        returnButtonAction={() => handleReturn()}
        renderCustomRightCorner={<></>}
      />
      <Form
        _flex={{ direction: 'column' }}
        onSubmit={(e) => {
          const error = customErrors
          error.gender = !selectedGender.value
          error.occupation = !selectedOccupation.value
          error.professions = isRequiredProfession && !selectedProfession

          setCustomErrors(error)
          handleSubmit(e)
        }}
        autoComplete="off"
      >
        <S.ContentBoxContainer>
          <S.FormTitle>Sobre o cliente</S.FormTitle>

          <S.InputGroupRow>
            <Input
              name="cpf"
              animatedLabel="cpf"
              message={errors.cpf}
              value={data.cpf}
              onChange={handleChange('cpf', cpfMask)}
              disabled
            />

            <Input
              name="fullName"
              animatedLabel="nome completo *"
              message={errors.fullName}
              value={data.fullName}
              onChange={handleChange('fullName', onlyText)}
            />

            <Input
              name="birthdate"
              animatedLabel="data de nascimento *"
              message={errors.birthdate}
              value={data.birthdate}
              onChange={handleChange('birthdate', dateMask)}
            />
          </S.InputGroupRow>

          <S.InputGroupRow>
            <Input
              name="rg"
              animatedLabel="rg *"
              message={errors.rg}
              value={data.rg}
              onChange={handleChange('rg', onlyNumbers)}
            />

            <Input
              name="motherName"
              animatedLabel="nome da mãe *"
              message={errors.motherName}
              value={data.motherName}
              onChange={handleChange('motherName', onlyText)}
            />

            <InputSelect
              name="gender"
              label="gênero *"
              data={gendersList}
              selectedItem={selectedGender}
              onChange={(value) => {
                setCustomErrors((prev) => ({ ...prev, gender: false }))
                setSelectedGender(value)
              }}
              message={customErrors.gender ? 'Informe um gênero' : ''}
            />
          </S.InputGroupRow>

          <S.InputGroupRow>
            <Input
              name="email"
              animatedLabel="email *"
              message={errors.email}
              value={data.email}
              onChange={handleChange('email')}
            />

            <Input
              name="phone"
              animatedLabel="celular *"
              message={errors.phone}
              value={data.phone}
              onChange={handleChange('phone', phoneMask)}
            />
            {/* 
            <Checkbox
              _flex={{ alignItems: 'start' }}
              _spacing={{ margin: '0 0 0 10px' }}
              isChecked={isOwnCredit}
              toggleChecked={toggleIsOwnCredit}
              label="crédito próprio? (pagador)"
            /> */}
          </S.InputGroupRow>

          <S.InputGroupRow customGridTemplate="2fr 2fr 1fr">
            <AutoComplete
              options={occupationList.map((eachOccupation) => ({
                id: eachOccupation.loans_value,
                value: eachOccupation.name
              }))}
              selectedOption={selectedOccupation}
              inputValue={occupationTerm}
              onInputChange={setOccupationTerm}
              onChange={(value) => {
                if (value) {
                  setCustomErrors((prev) => ({ ...prev, occupation: false }))
                  verifyIsRequiredProfession(value.id)
                  setSelectedOccupation(value)
                } else {
                  setSelectedOccupation({} as SelectItemType)
                }
              }}
              errorMessage={
                customErrors.occupation ? 'Informe uma ocupação' : ''
              }
              renderInput={(params) => (
                <Input
                  name="occupation"
                  placeholder="escolher ocupação..."
                  animatedLabel="ocupação *"
                  {...params}
                />
              )}
            />

            <AutoComplete
              async
              options={professionsList.map((eachProfession) => ({
                id: eachProfession.cbo,
                value: eachProfession.description
              }))}
              selectedOption={selectedProfession || null}
              isLoading={loadingProfessions}
              inputValue={termText}
              onInputChange={(value: string) => setTermText(value)}
              onChange={(value) => {
                setTermText('')
                setSelectedProfession(value)
              }}
              errorMessage={
                customErrors.professions
                  ? 'Você precisa informar uma profissão'
                  : ''
              }
              renderInput={(params) => (
                <Input
                  name="profession"
                  placeholder="escolher profissão..."
                  animatedLabel={`profissão ${isRequiredProfession ? '*' : ''}`}
                  {...params}
                />
              )}
            />

            <Input
              name="monthlyIncome"
              animatedLabel="renda mensal"
              message={errors.monthlyIncome}
              value={data.monthlyIncome}
              onChange={handleChange('monthlyIncome', monetaryMask)}
            />
          </S.InputGroupRow>

          <S.FormTitle mt="30">Endereço residencial</S.FormTitle>

          <S.InputGroupRow customGridTemplate="1fr 1fr 1fr 0.8fr">
            <Input
              name="cep"
              animatedLabel="CEP *"
              message={errors.cep}
              value={data.cep}
              onChange={handleChange('cep', cepMask)}
              loading={cepLoading}
            />

            <Input
              name="street"
              animatedLabel="Rua/Av. *"
              disabled={cepDisableFields.street}
              message={errors.street}
              value={data.street}
              onChange={handleChange('street')}
            />

            <Input
              name="buildingNumber"
              animatedLabel="número *"
              disabled={cepDisableFields.buildingNumber}
              message={errors.buildingNumber}
              value={data.buildingNumber}
              onChange={handleChange('buildingNumber')}
            />

            <Input
              name="complement"
              animatedLabel="complemento"
              disabled={cepDisableFields.complement}
              value={data.complement}
              onChange={handleChange('complement')}
            />
          </S.InputGroupRow>

          <S.InputGroupRow customGridTemplate="1fr 1fr 1fr 0.8fr">
            <Input
              name="neighborhood"
              animatedLabel="bairro *"
              disabled={cepDisableFields.neighborhood}
              message={errors.neighborhood}
              value={data.neighborhood}
              onChange={handleChange('neighborhood')}
            />

            <Input
              name="city"
              animatedLabel="cidade *"
              message={errors.city}
              value={data.city}
              onChange={handleChange('city')}
              disabled={cepDisableFields.city}
            />

            <AutoComplete
              disabled={cepDisableFields.state}
              options={states}
              selectedOption={stateSelected}
              inputValue={stateOption}
              errorMessage={errors.state}
              onInputChange={(value: string) => setStateOption(value)}
              onChange={(value) => {
                setStateSelected(value ? value : { id: '', value: '' })
                setStateOption(String(value?.value))
                updateFormData({
                  ...data,
                  state: String(value?.value || '')
                })
              }}
              renderInput={(params) => (
                <Input
                  name="state"
                  placeholder="selecione uma opção"
                  animatedLabel="estado *"
                  data-lpignore
                  {...params}
                />
              )}
            />
          </S.InputGroupRow>
        </S.ContentBoxContainer>

        <S.ButtonGroup>
          <Button
            color="white"
            _sizing={{
              width: '16rem'
            }}
            _spacing={{
              paddingLeft: 'auto'
            }}
          >
            salvar e simular
          </Button>
        </S.ButtonGroup>
      </Form>
      {process.env.REACT_APP_CURRENT_ENV !== 'PROD' && (
        <FillFormButton onClick={fillWithMockedData} />
      )}
    </S.Container>
  )
}
