type DateFormats =
  | 'DD/ MM/ AAAA'
  | 'DD-MM-AAAA'
  | 'DD de MM de AAAA'
  | 'AAAA-MM-DD'
  | 'DD/MM/AAAA - HH:MM'

export function formatDate(date: string, format: DateFormats) {
  if (format === 'DD-MM-AAAA') {
    date = date.replaceAll('-', '/')
  }

  const newDate = new Date(date)

  const day = newDate.getDate()
  const month = newDate.getMonth() + 1
  const year = newDate.getFullYear()
  const hours = newDate.getHours()
  const minutes = newDate.getMinutes()

  const updatedMonth = month < 10 ? `0${month}` : month
  const updateDay = day < 10 ? `0${day}` : day
  const updateMinutes = minutes < 10 ? `0${minutes}` : minutes

  const withBar = `${updateDay}/${updatedMonth}/${year}`
  const withHyphen = `${updateDay}-${updatedMonth}-${year}`
  const withHyphenReverse = `${year}-${updatedMonth}-${updateDay}`
  const withPronoun = `${day} de ${updatedMonth} de ${year}`
  const dateWithHoursMinutes = `${withBar} - ${hours}:${updateMinutes} `

  switch (format) {
    case 'DD de MM de AAAA':
      return withPronoun
    case 'DD-MM-AAAA':
      return withHyphen
    case 'AAAA-MM-DD':
      return withHyphenReverse
    case 'DD/MM/AAAA - HH:MM':
      return dateWithHoursMinutes
    default:
      return withBar
  }
}
