import Assets from 'assets'
import * as S from './CollapsedButton.styles'

export type CollapsedButtonProps = {
  isCollapsed?: boolean
  onClick?: () => void
}

export const CollapsedButton = ({
  isCollapsed = false,
  onClick
}: CollapsedButtonProps) => (
  <S.Container onClick={onClick} isCollapsed={isCollapsed}>
    <Assets
      alt=""
      assetProps={{
        key: 'arrowLeft',
        type: 'icon'
      }}
    />
  </S.Container>
)
