import styled, { css } from 'styled-components'

import { spin } from 'layout/animations'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
`

export const Spinner = styled.div`
  ${({ theme }) => css`
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: ${theme.colors.noverde};
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: ${spin} 1s linear infinite;
  `}
`
