import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  margin-top: 2rem;
`

export const WrapperSelect = styled.div`
  width: 700px;
`
export const Box = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.greyborders};
    border-radius: 8px;
    display: grid;
    height: fit-content;
    padding: 1rem 1.5rem;
    row-gap: 1rem;
    width: 100%;
    margin-top: 1rem;
  `}
`

interface InputGroupProps {
  isBankTemplate?: boolean
  bankTemplate?: string
  paddingLeft?: string
}

export const InputGroup = styled.div<InputGroupProps>`
  display: grid;
  gap: 1rem;
  margin-bottom: 1.5rem;
  grid-template-columns: ${({ isBankTemplate }) =>
    isBankTemplate ? '1fr 0.25fr 0.75fr' : '1fr auto'};

  ${({ bankTemplate }) =>
    bankTemplate &&
    css`
      grid-template-columns: ${bankTemplate};
    `}

  ${({ paddingLeft }) =>
    paddingLeft &&
    css`
      padding-left: ${paddingLeft};
    `}
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 38px;
  width: 100%;
`
export const ContainerLastProcessing = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

interface InputGroupRowProps {
  customGridTemplate?: string
}

export const InputGroupRow = styled.div<InputGroupRowProps>`
  display: grid;
  grid-template-columns: ${({ customGridTemplate }) =>
    customGridTemplate ? customGridTemplate : '1fr 1fr 1fr'};
  gap: 15px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

export const Divider = styled.div`
  ${({ theme }) => css`
    height: 100%;
    width: 2px;
    border: 1px solid ${theme.colors.galaxy};
    margin: 0 auto;
  `}
`
