import * as S from './HeaderActions.styles'

import Assets from 'assets'
import { Button } from 'components/Button'
import { ButtonVariants } from 'components/Button/Button.types'

interface HeaderActionsProps {
  title: string
  hasReturnButton?: boolean
  renderCustomRightCorner?: React.ReactNode
  buttonTitle?: string
  buttonVariant?: ButtonVariants
  returnButtonAction?: () => void
  buttonAction?: () => void
}

export const HeaderActions = ({
  title,
  hasReturnButton = true,
  renderCustomRightCorner,
  buttonTitle,
  buttonVariant = 'primaryBlue',
  returnButtonAction,
  buttonAction
}: HeaderActionsProps) => {
  return (
    <S.Container>
      <S.LeftCorner>
        {hasReturnButton && (
          <S.ReturnButton onClick={returnButtonAction}>
            <Assets
              assetProps={{ type: 'icon', key: 'arrowLeftV2' }}
              alt="Voltar"
              _sizing={{ width: '14px', height: '24px' }}
            />
          </S.ReturnButton>
        )}

        <span>{title}</span>
      </S.LeftCorner>

      {renderCustomRightCorner || (
        <Button
          variant={buttonVariant}
          _sizing={{ maxWidth: '300px' }}
          onClick={buttonAction}
        >
          {buttonTitle}
        </Button>
      )}
    </S.Container>
  )
}
