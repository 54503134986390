import { ReactNode, useEffect, useState, useMemo } from 'react'

import { useAppData } from 'providers/AppDataProvider'
import { useResetApp } from 'hooks/useResetApp'
import { useLoading } from 'hooks/useLoading'
import { useRouter } from 'hooks/useRouter'

import * as S from './WithFixedSidedbar.styles'

import Assets from 'assets'
import { Avatar } from 'components/Avatar'
import { NestedDropdown } from 'components/NestedDropdown'
import { ModuleList } from 'components/ModuleList'

import { ROUTES_NAME } from 'routes'
import { CompanyDTO } from 'models'
import Skeleton from 'react-loading-skeleton'
import { PartnersTheme } from 'layout/theme'
import { useCollapsedSidebar } from 'hooks/useCollapsedSidebar'
import { CollapsedButton } from 'components/CollapsedButton'

interface WithFixedSidebarProps {
  children?: ReactNode
}

export const WithFixedSidebar = ({ children }: WithFixedSidebarProps) => {
  const [isMenuOpen, setIsOpenMenu] = useState(false)
  const [companyList, setCompanyList] = useState<CompanyDTO[]>([])
  const { resetStorageAndContexts } = useResetApp()
  const { setLoading } = useLoading()
  const { history } = useRouter()
  const { isCollapsed, handleCollapseButton } = useCollapsedSidebar()

  const {
    data,
    updateSelectedCompanyUuid,
    authToken,
    selectedCompanyUuid,
    updateSelectedUnit,
    selectedUnit
  } = useAppData()

  useEffect(() => {
    if (!authToken) {
      history.push(ROUTES_NAME.LOGIN)
      return
    }

    if (data) {
      const companiesWithSelectValue = data.companies.map(
        (eachCompany, index) => {
          const unitsClone = eachCompany.units.map((eachUnity, index) => ({
            ...eachUnity,
            isSelected: index === 0
          }))

          return {
            ...eachCompany,
            units: unitsClone,
            isSelected: index === 0
          }
        }
      )

      updateSelectedCompanyUuid(
        selectedCompanyUuid ||
          (companiesWithSelectValue.length > 0 &&
            companiesWithSelectValue[0].uuid) ||
          ''
      )

      setCompanyList([...companiesWithSelectValue])
    }
  }, [data, authToken, history, updateSelectedCompanyUuid, selectedCompanyUuid])

  useEffect(() => {
    if (!data) return

    const companySelected =
      selectedCompanyUuid ||
      (data.companies.length > 0 && data.companies[0].uuid) ||
      ''

    let selectedUnitIndex = data.companies.findIndex(
      (eachCompany) => eachCompany.uuid === companySelected
    )

    if (selectedUnitIndex < 0) {
      selectedUnitIndex = 0
    }

    updateSelectedUnit(
      companySelected,
      (data.companies.length > 0 &&
        data.companies[selectedUnitIndex].units[0].uuid) ||
        ''
    )
  }, [selectedCompanyUuid, data, updateSelectedUnit])

  const toggleMenu = () => {
    setIsOpenMenu((currState) => !currState)
  }

  const handleLogout = () => {
    try {
      setLoading(true)
      updateSelectedCompanyUuid('')
      resetStorageAndContexts()
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const handleNestedDropdownChange = (
    company: CompanyDTO,
    subgroupId?: string
  ) => {
    setCompanyList((currState) => {
      // Reset all isActive state to false
      const clonedState = currState.map((eachItem) => {
        if (!eachItem.units.length) {
          return {
            ...eachItem,
            isSelected: false
          }
        }

        const resetedSubGroups = eachItem.units.map((eachSubgroup) => ({
          ...eachSubgroup,
          isSelected: false
        }))

        return {
          ...eachItem,
          units: resetedSubGroups,
          isSelected: false
        }
      })

      const companyIndex = currState.findIndex(
        (eachCompany) => eachCompany.id === company.id
      )

      clonedState[companyIndex].isSelected = true

      if (subgroupId) {
        const subGroupIndex = clonedState[companyIndex].units.findIndex(
          (eachSub) => eachSub.uuid === subgroupId
        )
        clonedState[companyIndex].units[subGroupIndex].isSelected = true
      }

      updateSelectedCompanyUuid(clonedState[companyIndex].uuid)
      updateSelectedUnit(
        company.uuid,
        subgroupId ? subgroupId : company.units[0].uuid
      )

      return [...clonedState]
    })
  }

  const companyLogo = useMemo(() => {
    if (data && selectedCompanyUuid) {
      const companyIndex = data.companies.findIndex(
        (eachCompany) => eachCompany.uuid === selectedCompanyUuid
      )

      if (companyIndex >= 0) {
        return data.companies[companyIndex].image_trademark
      }
    }

    return null
  }, [data, selectedCompanyUuid])

  return (
    <S.Container>
      <S.Sidebar isCollapsed={isCollapsed} isOpen={isMenuOpen}>
        <CollapsedButton
          isCollapsed={isCollapsed}
          onClick={handleCollapseButton}
        />
        <S.Header isCollapsed={isCollapsed}>
          {companyLogo ? (
            <img src={companyLogo} />
          ) : (
            <Skeleton
              width={150}
              height={81}
              style={{ background: PartnersTheme.colors.blue900 }}
            />
          )}
        </S.Header>
        <S.ModuleContent>
          {companyLogo ? (
            <ModuleList
              isCollapsed={isCollapsed}
              handleCollapseButton={handleCollapseButton}
            />
          ) : (
            <Skeleton
              width={190}
              height={30}
              style={{ background: PartnersTheme.colors.blue900 }}
              count={5}
              wrapper={S.SkeletonContent}
            />
          )}
        </S.ModuleContent>

        <S.Footer>
          {isCollapsed ? (
            <Assets
              alt=""
              assetProps={{
                key: 'dotzLogo',
                type: 'icon'
              }}
              _sizing={{
                width: '5rem !important'
              }}
            />
          ) : (
            <>
              <span>powered by</span>
              <Assets
                assetProps={{ key: 'dotzLogo', type: 'image' }}
                alt="Dotz logo"
              />
            </>
          )}
        </S.Footer>
      </S.Sidebar>

      <S.MainContainer>
        <S.MainContainerHeader>
          <S.AnimatedBurger isOpen={isMenuOpen} onClick={toggleMenu}>
            <div />
            <div />
            <div />
          </S.AnimatedBurger>

          {companyList.length ? (
            <NestedDropdown
              data={companyList}
              onChange={handleNestedDropdownChange}
              selectedUnit={selectedUnit}
            />
          ) : (
            <Skeleton
              width={190}
              height={30}
              style={{ background: PartnersTheme.colors.blue900 }}
            />
          )}

          {data?.name ? (
            <Avatar
              title={data?.name || ''}
              enableDropdown
              handleLogout={handleLogout}
            />
          ) : (
            <Skeleton
              width={40}
              height={40}
              circle
              style={{ background: PartnersTheme.colors.blue900 }}
            />
          )}
        </S.MainContainerHeader>
        {companyLogo && children}
      </S.MainContainer>
      <S.ModalLayer isOpen={isMenuOpen} onClick={toggleMenu} />
      {process.env.REACT_APP_CURRENT_ENV !== 'PROD' && (
        <S.CurrentEnvBar>
          <span>{process.env.REACT_APP_CURRENT_ENV}</span>
        </S.CurrentEnvBar>
      )}
    </S.Container>
  )
}
