import styled, { css } from 'styled-components'

export const Content = styled.div`
  ${({ theme }) => css`
    display: grid;
    height: fit-content;
    padding: 2rem;
    row-gap: 1rem;
    width: 100%;
    & > p {
      color: ${theme.colors.text};
      font-size: 0.75rem;
      font-weight: ${theme.font.weight['weight-400']};
      text-align: justify;
    }
  `}
`
