/* eslint-disable @typescript-eslint/no-explicit-any */
import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'
import { GetAllProductsResponse } from '../Product/ProductServiceTypes'
import { ProfileDTO } from '../Profile/ProfileServiceTypes'
import { UpdateUnitReqBody } from '../Unit/UnitServiceTypes'
import {
  CreateCompanyResponse,
  GetAllCompaniesResponse,
  GetCompanyByIdResponse,
  DeleteCompanyBydUuidResponse,
  UpdateCompanyReqBody,
  GetAllProfilesInCompanyByUuidResponse,
  CreateProfileToCompanyByUuid,
  CreateUnityToCompanyByUuid,
  GetAllUnitysInCompanyByUuid,
  GetAllCategoriesInCompanyByUuid,
  CreateUserReqBody,
  CreateReasonToCompanyByUuid,
  GetAllReasonsInCompanyByUuidResponse,
  CreateReasonToCompanyByUuidResponse
} from './CompanyServiceTypes'

class CompanyService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Create company
   * @param company CompanyDTO
   */
  public createCompany(company: UpdateCompanyReqBody) {
    return this.instance.post<CreateCompanyResponse>('/companies', {
      ...company
    })
  }

  /**
   * Get company by uuid
   * @returns GetCompanyByIdResponse
   */
  public getCompanyByUuid(uuid: string) {
    return this.instance.get<GetCompanyByIdResponse>(`/companies/${uuid}`)
  }

  /**
   * Get all companies
   * @returns GetAllCompaniesResponse
   */
  public getAllCompanies(listAll?: boolean) {
    return this.instance.get<GetAllCompaniesResponse>(
      `/companies${listAll ? '?listAll=true' : ''}`
    )
  }

  /**
   * Delete company by UUID
   * @returns DeleteCompanyBydUuidResponse
   */
  public deleteCompanyBydUuid(uuid: string) {
    return this.instance.delete<DeleteCompanyBydUuidResponse>(
      `/companies/${uuid}`
    )
  }

  /**
   * update company by UUID
   * @returns DeleteCompanyBydUuidResponse
   */
  public updateCompanyByUuid(uuid: string, company: UpdateCompanyReqBody) {
    return this.instance.put<DeleteCompanyBydUuidResponse>(
      `/companies/${uuid}`,
      { ...company }
    )
  }

  /**
   * Get all profiles in a company
   * @returns GetAllProfilesInCompanyByUuidResponse
   */
  public getAllProfilesInCompanyByUuid(uuid: string, listAll?: boolean) {
    return this.instance.get<GetAllProfilesInCompanyByUuidResponse>(
      `/companies/${uuid}/profiles${listAll ? '?listAll=true' : ''}`
    )
  }

  /**
   * Create profile to company by uuid
   * @param uuid string
   * @param profile ProfileDTO
   * @returns CreateProfileToCompanyByUuid
   */
  public createProfileToCompanyByUuid(uuid: string, profile: ProfileDTO) {
    const payload: ProfileDTO = {
      is_active: profile.is_active,
      name: profile.name,
      resources: profile.resources,
      parent: profile.parent,
      ...(profile.standard && { standard: profile.standard }),
      ...(profile.uuid && { uuid: profile.uuid }),
      ...(profile.id && { id: profile.id })
    }
    return this.instance.post<CreateProfileToCompanyByUuid>(
      `/companies/${uuid}/profiles`,
      { ...payload }
    )
  }

  /**
   * Create unity to company by uuid
   * @param uuid string
   * @param unity UnityDTO
   * @returns CreateUnityToCompanyByUuid
   */

  public createUnityToCompanyByUuid(uuid: string, unit: UpdateUnitReqBody) {
    return this.instance.post<CreateUnityToCompanyByUuid>(
      `/companies/${uuid}/units`,
      { ...unit }
    )
  }

  /**
   * Get all unitys in a company by uuid
   * @param uuid string
   * @returns GetAllUnitysInCompanyByUuid
   */
  public getAllUnitysInCompanyByUuid(uuid: string, listAll?: boolean) {
    return this.instance.get<GetAllUnitysInCompanyByUuid>(
      `/companies/${uuid}/units${listAll ? '?listAll=true' : ''}`
    )
  }

  /**
   * Get all categories in a company by uuid
   * @param uuid string
   * @returns GetAllCategoriesInCompanyByUuid
   */
  public getAllCategoriesInCompanyByUuid(uuid: string) {
    return this.instance.get<GetAllCategoriesInCompanyByUuid>(
      `/companies/${uuid}/categories`
    )
  }

  /**
   * Get all products
   * @returns GetAllProductsResponse
   */

  /**
   * Get all products
   * @returns GetAllProductsResponse
   */
  public getAllProductsInCompanyByUuid(
    uuid: string,
    categoryId?: string | number,
    listAll?: boolean
  ) {
    return this.instance.get<GetAllProductsResponse>(
      `/companies/${uuid}/products${
        categoryId ? `?category=${categoryId}` : ''
      }${listAll ? `?listAll=true` : ``}`
    )
  }

  /**
   * Create a  new user
   * @returns GetAllProductsResponse
   */
  public createUserToCompanyByUuid(uuid: string, user: CreateUserReqBody) {
    return this.instance.post<any>(`/companies/${uuid}/users`, { ...user })
  }

  /**
   * Get all reasons in company by uuid
   * @returns GetAllReasonsInCompanyByUuidResponse
   */
  public getAllReasonsInCompanyByUuid(uuidCompany: string, listAll?: boolean) {
    return this.instance.get<GetAllReasonsInCompanyByUuidResponse>(
      `/companies/${uuidCompany}/reasons${listAll ? '?listAll=true' : ''}`
    )
  }

  /**
   * Get all refused reasons in company by uuid
   * @returns GetAllReasonsInCompanyByUuidResponse
   */
  public getAllRefusedReasonsInCompanyByUuid(uuidCompany: string) {
    return this.instance.get<GetAllReasonsInCompanyByUuidResponse>(
      `/companies/${uuidCompany}/refused-reasons`
    )
  }

  /**
   * Create a reason to company by uuid
   * @returns CreateReasonToCompanyByUuidResponse
   */
  public createReasonToCompanyByUuid(
    uuidCompany: string,
    payload: CreateReasonToCompanyByUuid
  ) {
    return this.instance.post<CreateReasonToCompanyByUuidResponse>(
      `/companies/${uuidCompany}/reasons`,
      {
        ...payload
      }
    )
  }
}

export default new CompanyService()
