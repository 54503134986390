import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'

import { GetDashboardDataTypes } from './DashboardServiceTypes'

class DashboardService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Get dashboard data
   */
  public getDashboardData = (
    uuidCompany: string,
    unitUuid: string,
    beginDate?: string,
    endDate?: string
  ) => {
    return this.instance.get<GetDashboardDataTypes>(
      `/companies/${uuidCompany}/dashboard?units_uuid=${unitUuid}&begin_date=${beginDate}&end_date=${endDate}`
    )
  }
}

export default new DashboardService()
