import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import * as S from './TooltipText.styles'

interface TooltipProps {
  text: string
  children: ReactNode
  direction?: 'up' | 'down'
  positionRelative?: boolean
}

export const TooltipText = ({
  text,
  children,
  direction = 'up',
  positionRelative = false
}: TooltipProps) => {
  const SpanRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [positionX, setPositionX] = useState(0)
  const [positionY, setPositionY] = useState(0)

  const onMouseEnterHandler = useCallback(() => {
    const element = SpanRef?.current
    setPositionX((element?.offsetLeft || 0) - (width > 150 ? width / 5 : 0))
    setPositionY(element?.offsetTop || 0)
  }, [width])

  useEffect(() => {
    setWidth(SpanRef?.current?.offsetWidth || 0)
    setHeight(SpanRef?.current?.offsetHeight || 0)
  }, [SpanRef])

  return (
    <>
      <S.Container
        width={width}
        positionRelative={positionRelative}
        onMouseEnter={onMouseEnterHandler}
      >
        <S.Content>
          <span ref={SpanRef}>{children}</span>
        </S.Content>
        {text && (
          <S.TextBox
            direction={direction}
            width={width}
            height={height}
            posX={positionX}
            posY={positionY}
          >
            <p>{text}</p>
          </S.TextBox>
        )}
      </S.Container>
    </>
  )
}
