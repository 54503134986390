import { RestTypes } from 'components/typings/utils/restTypes'
import React, { FormEventHandler } from 'react'
import { FormContainer } from './Form.style'

export type FormProps = {
  children?: React.ReactNode
  action?: string
  method?: 'get' | 'post'
  target?: '_blank' | '_self' | '_parent' | '_top'
  className?: string
  autoComplete?: 'on' | 'off'
  onSubmit?: FormEventHandler<HTMLFormElement>
} & RestTypes

export const Form = ({
  children,
  action,
  method,
  target,
  className,
  autoComplete = 'on',
  onSubmit,
  ...props
}: FormProps) => (
  <FormContainer
    autoComplete={autoComplete}
    action={action}
    method={method}
    target={target}
    onSubmit={onSubmit}
    {...(className ? { className: className } : {})}
    {...props}
  >
    {children}
  </FormContainer>
)
