import { unformatNumber } from './unformat-number'

export function formatMoney(value: string) {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  })

  const valueToFormat =
    parseFloat(String(Number(unformatNumber(value)) / 100)).toFixed(2) || 0
  return formatter.format(Number(valueToFormat))
}
