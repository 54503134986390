import styled from 'styled-components'

import { appearFromRight } from 'layout/animations'
import { devices } from 'layout/devices'

interface InputGroupRowProps {
  customGridTemplate?: string
}

interface FormTitleProps {
  mt?: string
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 1.5rem 2.5rem;
  width: 100%;
`

export const ContentBoxContainer = styled.div`
  margin-top: 30px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4.75px;
  padding: 26px;
  width: 100%;

  animation: ${appearFromRight} 0.3s ease-in;

  @media ${devices.large} {
    padding: 32px 46px 46px;
  }
`

export const FormTitle = styled.p<FormTitleProps>`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.galaxy};
  margin-bottom: 30px;
  margin-top: ${({ mt }) => mt || 0}px;
`

export const ButtonGroup = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  flex-direction: row-reverse;
  margin-top: 2rem;
  margin-bottom: 65px;
  width: 100%;
`

export const InputGroupRow = styled.div<InputGroupRowProps>`
  display: grid;
  grid-template-columns: ${({ customGridTemplate }) =>
    customGridTemplate ? customGridTemplate : '1fr 1fr 1fr'};
  gap: 15px;
  margin-bottom: 20px;
`

export const ContentTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`
