import { useMemo, useState } from 'react'
import { Text } from 'components/Text'

import * as S from './Avatar.styles'

import { RestTypes } from 'components/typings/utils/restTypes'
import { useRouter } from 'hooks/useRouter'
import { ROUTES_NAME } from 'routes'

const getNameInitials = (name: string) => {
  const initials = name.split(' ')

  const theInitials = `${initials[0].charAt(0)}${
    initials.length >= 2 ? initials[initials.length - 1].charAt(0) : ''
  }`

  return theInitials || ''
}

export type AvatarProps = {
  enableDropdown?: boolean
  title: string
  avatarUrl?: string
  handleLogout?: () => void
} & RestTypes

export const Avatar = ({
  avatarUrl,
  title,
  enableDropdown = false,
  handleLogout,
  ...rest
}: AvatarProps) => {
  const initials = useMemo(
    () => (title ? getNameInitials(title).toUpperCase() : ''),
    [title]
  )
  const { history } = useRouter()
  const [openMenu, setOpenMenu] = useState(false)

  function routeTo(route: string) {
    history.push(route)
  }

  return (
    <S.Wrapper>
      <S.AvatarContainer
        {...rest}
        onClick={() => setOpenMenu(!openMenu)}
        cursorPointer={enableDropdown}
      >
        {avatarUrl ? (
          <img src={avatarUrl} alt="avatar" />
        ) : (
          <span>{initials}</span>
        )}
      </S.AvatarContainer>
      {enableDropdown && openMenu && (
        <S.MenuPerfil isOpen={openMenu} onMouseLeave={() => setOpenMenu(false)}>
          <S.MenuPerfilItem
            onClick={() => routeTo(ROUTES_NAME.USER_MY_ACCOUNT)}
          >
            <Text>Minha conta</Text>
          </S.MenuPerfilItem>
          <S.MenuPerfilItem
            onClick={() => routeTo(ROUTES_NAME.USER_UPDATE_PASSWORD)}
          >
            <Text>Alterar senha</Text>
          </S.MenuPerfilItem>
          <S.MenuPerfilItem onClick={handleLogout}>
            <Text color="error">Sair</Text>
          </S.MenuPerfilItem>
        </S.MenuPerfil>
      )}
    </S.Wrapper>
  )
}
