/* eslint-disable @typescript-eslint/no-explicit-any */
import useForm from 'hooks/useForm'
import { useAppData } from 'hooks/useAppData'
import { useLoading } from 'hooks/useLoading'
import { useModal } from 'providers/ModalProvider'

import UserService from 'services/remote/v1/User/UserService'

import { CommonModal } from 'templates/Modals/CommonModal'
import { Button } from 'components/Button'
import { Form } from 'components/Form/Form'
import { Input } from 'components/Input'
import { Text } from 'components/Text'

import * as S from './FormMyAccount.styles'

interface FormValues {
  name: string
  email: string
}

export const FormMyAccount = () => {
  const { data: appData, updateFirstAccessData } = useAppData()
  const { setLoading } = useLoading()
  const { dispatch } = useModal()

  const handleUpdateUsername = async (newName: string) => {
    try {
      setLoading(true)
      await UserService.updateUserName(newName)
      await updateFirstAccessData()
    } catch (err: any) {
      dispatch({
        action: 'OPEN_MODAL',
        component: (
          <CommonModal
            title="Oops! Algo deu errado"
            description={
              err?.response?.data?.error?.messages_client[0] || err.message
            }
            primaryButtonTitle="voltar"
            primaryButtonProps={{
              fontSize: '1rem',
              backgroundColor: 'noverde'
            }}
            primaryButtonAction={() => {
              dispatch({ action: 'CLOSE_MODAL' })
            }}
          />
        )
      })
    } finally {
      setLoading(false)
    }
  }

  const { data, errors, handleChange, handleSubmit } = useForm<FormValues>({
    onSubmit: async () => await handleUpdateUsername(data.name),
    initialValues: {
      name: appData?.name,
      email: appData?.email
    },
    validations: {
      name: {
        required: {
          value: true,
          message: 'Não esqueçar informar seu nome'
        }
      }
    }
  })

  return (
    <Form
      _flex={{
        direction: 'column',
        gap: '1rem'
      }}
      onSubmit={handleSubmit}
    >
      <S.Box>
        <Text
          type="subheading-1"
          _spacing={{
            marginBottom: '1.5rem'
          }}
        >
          Dados da conta
        </Text>

        <S.InputGroup>
          <Input
            name="name"
            animatedLabel="nome e sobrenome"
            isRequired
            message={errors.name}
            onChange={handleChange('name')}
            value={data.name || ''}
          />
          <Input
            disabled
            type="email"
            name="email"
            animatedLabel="email"
            isRequired
            animatedLabelProps={{
              disabled: true
            }}
            value={data.email}
          />
        </S.InputGroup>
      </S.Box>
      <S.ButtonGroup>
        <Button
          _sizing={{
            width: '12rem'
          }}
        >
          Salvar
        </Button>
      </S.ButtonGroup>
    </Form>
  )
}
