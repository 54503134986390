import { FormUsers } from 'components/FormUsers'
import { HeaderActions } from 'components/HeaderActions'
import { useAppData } from 'hooks/useAppData'
import { useLocationState } from 'hooks/useLocationState'
import { useRouter } from 'hooks/useRouter'
import { PermissionsType } from 'pages/Profile/types'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { ROUTES_NAME } from 'routes'
import { UserDTO } from 'services/remote/v1/User/UserServiceTypes'
import { getResourcePermissions } from 'utils/getResourcePermissions'
import * as S from './Users.styles'

export interface UsersEditRouteProps {
  editData: UserDTO
}

export const UsersEdit = () => {
  const { handleGoBack } = useRouter()
  const { state } = useLocation<UsersEditRouteProps>()
  const { verifyLocationState } = useLocationState()
  const { data: appData } = useAppData()
  const [permissions, setPermissions] = useState<PermissionsType>(
    {} as PermissionsType
  )

  useEffect(() => {
    verifyLocationState(ROUTES_NAME.USERS)
  }, [verifyLocationState])

  useEffect(() => {
    const _permissions = getResourcePermissions(appData, 'CAD', 'CAD_EQUIP')

    if (_permissions) setPermissions(_permissions)
  }, [appData])

  return (
    <S.Container>
      <HeaderActions
        title="editar convidado"
        hasReturnButton={true}
        returnButtonAction={handleGoBack}
        renderCustomRightCorner={<></>}
      />
      <FormUsers
        edit={true}
        editData={state?.editData}
        hideDelete={!permissions.exclude}
        hideUpdate={!permissions.write}
      />
    </S.Container>
  )
}
