import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'

import {
  CreateNewSimulationReqBody,
  GetCreditAnalysisResponse,
  UpdateSimulationStatusReqbody,
  GetFollowUpDataResponse,
  FollowUpReqParams,
  GetSimulationStatus
} from './SimulationTypes'

class SimulationService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Create a new loan simulation
   */
  public createNewSimulation = (
    unitUuid: string,
    attendanceUuid: string,
    data: CreateNewSimulationReqBody
  ) => {
    return this.instance.post(
      `/units/${unitUuid}/attendances/${attendanceUuid}/simulations`,
      data
    )
  }

  /**
   * Get credity analysis
   * @param simulationUuid string
   * @returns GetCreditAnalysisResponse
   */
  public getCreditAnalysis(simulationUuid: string) {
    return this.instance.get<GetCreditAnalysisResponse>(
      `/simulations/${simulationUuid}`
    )
  }

  public updateSimulationStatus = (
    uuidSimulation: string,
    reqBody: UpdateSimulationStatusReqbody
  ) => {
    return this.instance.patch<GetCreditAnalysisResponse>(
      `/simulations/${uuidSimulation}/status`,
      reqBody
    )
  }

  public finishAttendance = (
    uuidUnit: string,
    uuidAttendance: string,
    reqBody: UpdateSimulationStatusReqbody
  ) => {
    return this.instance.patch(
      `/units/${uuidUnit}/attendances/${uuidAttendance}/finish`,
      reqBody
    )
  }

  public getFollowUpData = ({
    uuidCompany = '',
    unitUuid,
    beginDate = '',
    endDate = '',
    statusUuid = ''
  }: FollowUpReqParams) => {
    const endpoint = `${endpoints.remote.v1.portal}/companies/${uuidCompany}/simulations/follow-up`
    const url = new URL(endpoint)
    const params = url.searchParams
    params.append('beginDate', beginDate)
    params.append('endDate', endDate)
    params.append('uuidUnit', unitUuid)
    params.append('status', statusUuid)

    return this.instance.get<GetFollowUpDataResponse>(
      url.toString().replace(endpoints.remote.v1.portal, '')
    )
  }

  public getSimulationsStatus = () => {
    return this.instance.get<GetSimulationStatus>('/simulations/status ')
  }
}

export default new SimulationService()
