import { RestTypes } from 'components/typings/utils/restTypes'

import * as S from './ToggleSwitcher.styles'

type ToggleSwitcherProps = {
  isActive: boolean
  toggleActive: (value: boolean) => void
  disabled?: boolean
} & RestTypes

export const ToggleSwitcher = ({
  isActive,
  toggleActive,
  disabled,
  ...rest
}: ToggleSwitcherProps) => {
  const handleOnChange = () => {
    toggleActive(!isActive)
  }

  return (
    <S.Container isActive={isActive} disabled={disabled} {...rest}>
      <input type="checkbox" id="checkbox" />

      <label
        role="presentation"
        htmlFor="checkbox"
        onClick={handleOnChange}
        onKeyDown={handleOnChange}
      >
        <S.PaintedBall isActive={isActive} />
      </label>
    </S.Container>
  )
}
