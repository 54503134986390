/* eslint-disable @typescript-eslint/no-explicit-any */
import useForm from 'hooks/useForm'
import { useRouter } from 'hooks/useRouter'
import { useAppData } from 'hooks/useAppData'
import { useLoading } from 'hooks/useLoading'
import faker from 'faker-br'

import LeadService from 'services/remote/v1/Lead/LeadService'
import AttendanceService from 'services/remote/v1/Attendance/AttendanceService'

import { HeaderActions } from 'components/HeaderActions'
import { Input } from 'components/Input'
import { Form } from 'components/Form'
import { FillFormButton } from 'components/FillFormButton'

import * as S from './Attendance.styles'
import { cpfMask } from 'utils/formatters/masks'
import { unmask } from 'utils/formatters/unmask'
import { Button } from 'components/Button'
import { ROUTES_NAME } from 'routes'
import { validateCpf } from 'utils/validators/validateCpf'
import { useToast } from 'hooks/useToast'

interface FormValues {
  cpf: string
}

export const Attendance = () => {
  const { history } = useRouter()
  const { selectedUnit } = useAppData()
  const { setLoading } = useLoading()
  const { showToast } = useToast()

  const handleSearchCustomer = async (data: FormValues) => {
    try {
      setLoading(true)
      const leadResponse = await LeadService.getLeadByDocument(
        selectedUnit.companyUuid,
        selectedUnit.unitUuid,
        unmask(data.cpf)
      )

      const customerData = leadResponse.data.error
        ? null
        : leadResponse.data.data

      const attendanceResponse =
        await AttendanceService.createAttendanceToCompany(
          selectedUnit.companyUuid,
          selectedUnit.unitUuid
        )

      if (customerData) {
        const leadHistoryResponse =
          await AttendanceService.getLeadAttendanceHistory(
            selectedUnit.unitUuid,
            customerData.uuid || ''
          )

        const hasSimulations =
          leadHistoryResponse.data.data.attendances.findIndex(
            (eachAttendance) => eachAttendance.simulations.length >= 1
          )

        if (hasSimulations >= 0) {
          history.push(ROUTES_NAME.ATTENDANCE_HISTORY, {
            leadData: leadHistoryResponse.data,
            attendanceUuid: attendanceResponse.data.data.uuid,
            customerData: customerData
          })

          return
        }
      }

      history.push(ROUTES_NAME.ATTENDANCE_CUSTOMER_INFORMATION, {
        customerData,
        attendanceUuid: attendanceResponse.data.data.uuid,
        document: unmask(data.cpf)
      })
    } catch (err: any) {
      if (err?.response?.status == 403) {
        return showToast({
          message: 'Você não possui permissão para executar a ação',
          title: '',
          type: 'error'
        })
      }
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: '',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const { data, errors, handleSubmit, handleChange, updateFormData } =
    useForm<FormValues>({
      initialValues: {
        cpf: ''
      },
      validations: {
        cpf: {
          required: {
            value: true,
            message: 'Este campo é obrigatório'
          },
          custom: {
            message: 'Informe um CPF válido',
            isValid: (value: string) => validateCpf(value)
          }
        }
      },
      onSubmit: () => handleSearchCustomer(data)
    })

  const fillWithMockedData = () => {
    const mockedCpf = faker.br.cpf()
    updateFormData({
      cpf: cpfMask(mockedCpf)
    })
  }

  return (
    <S.Container>
      <HeaderActions
        title="novo atendimento"
        hasReturnButton={false}
        renderCustomRightCorner={<></>}
      />
      <Form _flex={{ direction: 'column' }} onSubmit={handleSubmit}>
        <S.ContentBoxContainer>
          <S.FormTitle>Sobre o cliente</S.FormTitle>

          <Input
            name="cpf"
            animatedLabel="digite o cpf do cliente"
            placeholder="digite o cpf do cliente"
            message={errors.cpf}
            value={data.cpf}
            onChange={handleChange('cpf', cpfMask)}
          />
        </S.ContentBoxContainer>

        <S.ButtonGroup>
          <Button
            color="white"
            onClick={() => null}
            _sizing={{
              width: '12rem'
            }}
            _spacing={{
              paddingLeft: 'auto'
            }}
          >
            iniciar
          </Button>
        </S.ButtonGroup>
      </Form>
      {process.env.REACT_APP_CURRENT_ENV !== 'PROD' && (
        <FillFormButton onClick={fillWithMockedData} />
      )}
    </S.Container>
  )
}
