import styled, { css } from 'styled-components'

interface ButtonsWrapperProps {
  hasOnlyPrimaryButton?: boolean
}

interface MeasuresProps {
  maxWidth?: boolean
}

export const Container = styled.div<MeasuresProps>`
  min-width: 16.875rem;
  max-width: 37.9375rem;
  width: fit-content;

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      width: 30.875rem;
    `}
`

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.galaxy};
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    text-align: center;
    margin-bottom: 46px;
  `}
`

export const Description = styled.div<MeasuresProps>`
  ${({ theme }) => css`
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: ${theme.colors.text};
    margin-bottom: 34px;
  `}

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      margin: 0 auto;
      margin-bottom: 44px;
      margin-top: 60px;
      line-height: 24px;
      max-width: 366px;
      p {
        margin-bottom: 25px;
      }
      p:last-child {
        margin-bottom: 0px;
      }
    `}
`

export const ButtonsWrapper = styled.div<ButtonsWrapperProps & MeasuresProps>`
  display: grid;
  grid-template-columns: ${({ hasOnlyPrimaryButton }) =>
    hasOnlyPrimaryButton ? '1fr' : 'repeat(2, 1fr)'};
  direction: rtl;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      margin-bottom: 37px;
    `}

  button {
    margin: auto;

    ${({ maxWidth }) =>
      maxWidth &&
      css`
        max-width: 328px;
      `}
  }
`
