import { ReactNode } from 'react'
import * as S from './WithTextAndLogo.styles'

import { Text } from 'components/Text'
import Assets from 'assets'

interface WithTextAndLogoProps {
  subtitle?: string
  title?: string | ReactNode | JSX.Element
  withLogo?: boolean
  children?: ReactNode
}

export const WithTextAndLogo = ({
  subtitle,
  title,
  withLogo = true,
  children
}: WithTextAndLogoProps) => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.ContainerLeft>
          <S.ContentLeft>
            {!!subtitle && (
              <Text type="body-1" color="white">
                {subtitle}
              </Text>
            )}
            {!!title && (
              <Text size="5rem" color="white">
                {title}
              </Text>
            )}
          </S.ContentLeft>
          {withLogo && (
            <Assets
              alt="Logo NoVerde Business"
              assetProps={{
                key: 'dotzLogo',
                type: 'image'
              }}
            />
          )}
        </S.ContainerLeft>
        <S.ContainerRight>{children}</S.ContainerRight>
      </S.Container>
    </S.Wrapper>
  )
}
