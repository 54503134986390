import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'

import { GetAccessResponse } from './PortalService.types'

class PortalService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Get user's data
   */
  public getAccessData = () => {
    return this.instance.get<GetAccessResponse>('/access')
  }
}

export default new PortalService()
