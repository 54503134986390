/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Assets from 'assets'
import * as S from './ProposalState.styles'
import { Text } from '../Text'

export interface ProposalStateProps {
  text: string
  type: 'Negada' | 'Simulação' | 'Pendente' | 'pré-aprovada' | undefined
}

export const ProposalState = ({ text, type }: ProposalStateProps) => {
  const Icon = {
    Negada: (
      <Assets
        alt="denied"
        assetProps={{
          key: 'denied',
          type: 'icon'
        }}
      />
    ),
    ['pré-aprovada']: (
      <Assets
        alt="success"
        assetProps={{
          key: 'success',
          type: 'icon'
        }}
      />
    ),
    Pendente: <></>,
    ['Simulação']: <></>
  }

  return (
    <S.Box>
      {Icon[type!]}
      <Text size="24px" weight="weight-600">
        {text}
      </Text>
    </S.Box>
  )
}
