/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { renderRestTypes } from 'components/typings/utils/renderRestTypes'
import { appearFromTop } from 'layout/animations'
import { PartnersTheme } from 'layout/theme'
import styled, { css } from 'styled-components'
import { CalloutProps } from './Callout'

const { colors } = PartnersTheme

const modifiers = {
  success: () => css`
    border: 1px solid ${colors.noverde};
    background: ${colors.green10};
  `,
  erro: () => css`
    border: 1px solid ${colors.error};
    background: ${colors.red10};
  `,
  alert: () => css`
    border: 1px solid ${colors.sunset};
    background: ${colors.orange10};
  `,
  informative: () => css`
    border: 1px solid ${colors.blue500};
    background: ${colors.blue10};
  `,
  disclaimer: () => css`
    border: 1px solid ${colors.greyborders};
    background: ${colors.backgrounds};
  `
}

export const Box = styled.div<CalloutProps>`
  ${({ type, _flex, _layout, _positioning, _sizing, _spacing }) => css`
    animation: ${appearFromTop} 0.5s;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    width: 100%;

    ${type && modifiers[type!]};

    ${renderRestTypes({
      _flex: _flex,
      _layout: _layout,
      _positioning: _positioning,
      _sizing: _sizing,
      _spacing: _spacing
    })}
  `}
`

export const Header = styled.header`
  ${({ theme }) => css`
    color: ${theme.colors.text};
    display: flex;

    img {
      margin-right: 0.5rem;
    }
  `}
`
