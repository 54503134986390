/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from 'components/Button'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { Text } from 'components/Text'
import useForm from 'hooks/useForm'
import { useRouter } from 'hooks/useRouter'
import { useLoading } from 'providers/LoadingProvider'
import { useModal } from 'providers/ModalProvider'
import { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ROUTES_NAME } from 'routes'
import AuthenticationService from 'services/remote/v1/Authorizer/AuthenticationService'
import { CommonModal } from 'templates/Modals/CommonModal'
import { StepsWithIconReturn } from 'templates/StepsWithIconReturn'

interface FormValues {
  newPassword: string
  confirmationPassword: string
}
export interface ResetPasswordStep2RouteProps {
  uuidLogin: string
  pinUuid: string
}

export const ResetPasswordStep2 = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { history } = useRouter()
  const { state } = useLocation<ResetPasswordStep2RouteProps>()
  const [enabledButton, setEnabledButton] = useState(true)
  const { setLoading } = useLoading()
  const { dispatch } = useModal()
  const { data, errors, handleSubmit, handleChange, setErrors } =
    useForm<FormValues>({
      onSubmit: () => {
        handleResetPassword()
      },
      validations: {
        newPassword: {
          required: {
            value: true,
            message: 'Informe uma senha'
          },
          custom: {
            message: 'Sua senha precisa possuir no mínimo 6 caracteres',
            isValid: (currValue) => currValue.length >= 6
          },
          pattern: {
            message:
              'A senha deve conter deve conter ao menos uma letra maiúscula, símbolo e numero',
            value:
              '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$'
          }
        },
        confirmationPassword: {
          required: {
            value: true,
            message: 'Confirme a senha'
          },
          custom: {
            message: 'As senhas informadas precisam ser iguais',
            isValid: (currValue) => inputRef.current?.value === currValue
          }
        }
      }
    })

  async function handleResetPassword() {
    try {
      setLoading(true)
      await AuthenticationService.updateFirstAccessPassword(
        state?.uuidLogin || '',
        state?.pinUuid || '',
        data.newPassword || ''
      )
      history.push(ROUTES_NAME.LOGIN)
    } catch (err: any) {
      setLoading(false)
      dispatch({
        action: 'OPEN_MODAL',
        component: (
          <CommonModal
            title="Oops! Algo deu errado"
            description={
              err?.response?.data?.error?.messages_client[0] || err.message
            }
            primaryButtonTitle="voltar"
            primaryButtonProps={{
              fontSize: '1rem',
              backgroundColor: 'noverde'
            }}
            primaryButtonAction={() => {
              dispatch({ action: 'CLOSE_MODAL' })
            }}
          />
        )
      })
    } finally {
      setLoading(false)
    }
  }

  function handleChangeConfirmationPassword(value: string) {
    let message = ''
    let flag = false
    if (value !== data.newPassword) {
      message = 'As senhas não coincidem'
      flag = true
    }
    setErrors({
      ...errors,
      confirmationPassword: message
    })
    setEnabledButton(flag)
    return value
  }

  function handleReturn() {
    history.goBack()
  }

  return (
    <StepsWithIconReturn
      title={
        <>
          vamos <br />
          <strong>
            redefinir <br /> sua senha
          </strong>
        </>
      }
      stepLines={2}
      stepLinesBehind={0}
      stepTitle={
        <>
          alterar <strong>senha</strong> <br />
        </>
      }
      onClickReturnIcon={handleReturn}
    >
      <Text
        color="text"
        type="subheading-1"
        _spacing={{
          marginBottom: '2.6rem'
        }}
      >
        defina uma nova senha para acessar o portal
      </Text>
      <Form
        _flex={{
          alignItems: 'center',
          direction: 'column',
          justifyContent: 'center'
        }}
        onSubmit={handleSubmit}
      >
        <Input
          ref={inputRef}
          name="newPassword"
          animatedLabel="senha"
          placeholder="mínimo de 8 caracteres contendo letras e números"
          isRequired
          isSecuryText
          message={errors.newPassword}
          onChange={handleChange('newPassword')}
          _spacing={{
            marginTop: '2rem',
            marginBottom: '2rem'
          }}
        />
        <Input
          name="confirmationPassword"
          animatedLabel="confirmação de senha"
          placeholder="devem ser idênticas"
          isRequired
          isSecuryText
          message={errors.confirmationPassword}
          _spacing={{
            marginBottom: '5rem'
          }}
          onChange={handleChange(
            'confirmationPassword',
            handleChangeConfirmationPassword
          )}
        />

        <Button disabled={enabledButton}>alterar senha</Button>
      </Form>
    </StepsWithIconReturn>
  )
}
