import styled, { css } from 'styled-components'
import { CSVLink } from 'react-csv'

type CsvLinkProps = {
  marginTop?: number | string
  hide?: boolean
}

export const ExportCSVIcon = styled(CSVLink)<CsvLinkProps>`
  ${({ marginTop, hide }) => css`
    margin-top: ${marginTop && '1rem'};
    cursor: pointer;
    display: ${hide ? 'none' : 'flex'};
    justify-content: center;
    transition: 0.25s;
    text-decoration: none;
  `}
`

export const ExportCSVButton = styled(CSVLink)`
  ${({ theme }) => css`
    margin: 1rem auto 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    font-size: 1.2rem;
    font-weight: ${theme.font.weight['weight-600']};
    justify-content: center;
    height: 3.25rem;
    transition: 0.25s;
    padding: 1rem 1.5rem;
    width: 190px;
    background-color: #001e65;
    color: ${theme.colors.white};
    font-size: 1.2rem;
    text-decoration: none;
  `}
`
