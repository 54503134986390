import styled, { css } from 'styled-components'
import { Text } from '../Text'

export const Box = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 15rem;
  position: relative;
  width: 100%;
`

export const BoxTitle = styled(Text)`
  max-width: 12rem;
  position: relative;
  ${({ theme }) => css`
    ::before {
      align-items: center;
      content: '';
      background: ${theme.colors.noverde};
      display: flex;
      height: 20px;
      justify-content: center;
      left: -0.5rem;
      position: absolute;
      top: 0.3rem;
      width: 2px;
    }
  `}
`

export const BoxDescription = styled.div`
  ${({ theme }) => css`
    align-items: flex-start;
    display: flex;
    height: fit-content;
    justify-content: flex-start;
    word-wrap: break-word;
    width: 100%;

    p {
      color: ${theme.colors.black};
      font-size: 18px;
      font-weight: 300;
    }

    span {
      color: ${theme.colors.noverde};
      font-size: 18px;
      font-weight: 600;
    }
  `}
`
