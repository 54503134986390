import styled, { css } from 'styled-components'

import { renderRestTypes } from 'components/typings/utils/renderRestTypes'

import { RestTypes } from 'components/typings/utils/restTypes'

export const Wrapper = styled.div`
  position: relative;
`

type AvatarContainerProps = {
  cursorPointer?: boolean
} & RestTypes

export const AvatarContainer = styled.div<AvatarContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  height: 43px;
  min-width: 20px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.dotzOrange};
  position: relative;
  cursor: ${({ cursorPointer }) => (cursorPointer ? 'pointer' : 'default')};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  span {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    max-width: 100%;
  }

  ${({ _flex, _layout, _spacing, _sizing, _positioning }) => css`
    ${renderRestTypes({
      _flex: _flex,
      _layout: _layout,
      _positioning: _positioning,
      _sizing: _sizing,
      _spacing: _spacing
    })}
  `}
`

type MenuPerfilProps = {
  isOpen?: boolean
}
export const MenuPerfil = styled.div<MenuPerfilProps>`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    box-shadow: 0 0 0.5rem #e8e8e8e8;
    border-radius: 8px;
    color: ${theme.colors.text};
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 1rem;
    position: absolute;
    top: 3.5rem;
    right: 0rem;
    width: 156px;
    z-index: 999;
  `}
`

export const MenuPerfilItem = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  margin: 8px 0;
  position: relative;
  transition: all 0.2s ease-in;

  &:hover {
    cursor: pointer;
    background-color: #f9fafd;
  }
  svg {
    margin-right: 12px;
  }
  span {
    width: fit-content;
  }
`
