import { FormUserUpdatePassword } from 'components/FormUserUpdatePassword'
import { HeaderActions } from 'components/HeaderActions'

import * as S from './UserUpdatePassword.styles'

export const UserUpdatePassword = () => {
  return (
    <S.Container>
      <HeaderActions
        title="alterar senha"
        hasReturnButton={false}
        renderCustomRightCorner={<></>}
      />
      <FormUserUpdatePassword />
    </S.Container>
  )
}
