/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react'

import { useRouter } from 'hooks/useRouter'
import { useModal } from 'providers/ModalProvider'
import useForm from 'hooks/useForm'
import { useToast } from 'hooks/useToast'
import { useLoading } from 'hooks/useLoading'

import { CommonModal } from 'templates/Modals/CommonModal'

import { HeaderActions } from 'components/HeaderActions'
import { Button } from 'components/Button'
import { ListTable } from 'components/ListTable'
import { Checkbox } from 'components/Checkbox'
import { Input } from 'components/Input'
import { Text } from 'components/Text'
import { Form } from 'components/Form'
import { ToggleSwitcher } from 'components/ToggleSwitcher'

import * as S from './Profile.styles'

import { PermissionsType, UpdateListType } from './types'
import { ROUTES_NAME } from 'routes'
import { ProfileDTO } from 'services/remote/v1/Profile/ProfileServiceTypes'
import ProfileService from 'services/remote/v1/Profile/ProfileService'
import { useLocationState } from 'hooks/useLocationState'
import { useAppData } from 'hooks/useAppData'
import { getResourcePermissions } from 'utils/getResourcePermissions'
import { ResourceDTO } from 'models'
import { InputSelect, SelectItemType } from 'components/InputSelect'
import CompanyService from 'services/remote/v1/Company/CompanyService'
import { Tooltip } from 'components/Tooltip'
import ResourceProfileService from 'services/remote/v1/Profile/ResourceProfileService/ResourceProfileService'
import { AutoComplete } from 'components/Autocomplete'

type RouteStateProps = {
  resources: ResourceDTO[]
  selectedProfile: ProfileDTO
  parent: {
    id: string | number
    name: string
  }
}

type FormValues = {
  name: string
}

export const ProfileEdit = () => {
  const [customErrors, setCustomErrors] = useState({
    perfilHierarquia: false
  })
  const { history, location } = useRouter<RouteStateProps>()
  const { showToast } = useToast()
  const [resources, setResources] = useState<ResourceDTO[]>([])
  const [isProfileActive, setIsProfileActive] = useState(true)
  const { data: appData, selectedUnit, selectedCompanyUuid } = useAppData()
  const [permissions, setPermissions] = useState<PermissionsType>(
    {} as PermissionsType
  )

  const [profiles, setProfiles] = useState<SelectItemType[]>([
    {
      id: '',
      value: 'carregando opções...'
    } as SelectItemType
  ])

  const [selectedProfile, setSelectedProfile] =
    useState<SelectItemType | null>(null)

  const { dispatch } = useModal()
  const { setLoading } = useLoading()
  const [profileValue, setProfileValue] = useState('')

  const handleSelectedProfile = useCallback((value: SelectItemType) => {
    setSelectedProfile(value)
  }, [])

  const { verifyLocationState, clearLocationStateAndReturn } =
    useLocationState()

  const getAllUsersProfiles = useCallback(async () => {
    if (!selectedCompanyUuid) return

    const { data } = await CompanyService.getAllProfilesInCompanyByUuid(
      selectedCompanyUuid
    )
    const values = data.data.map((values) => {
      return {
        id: String(values.id),
        value: String(values.name)
      }
    })
    setProfiles(values)
  }, [])

  useEffect(() => {
    getAllUsersProfiles()
  }, [getAllUsersProfiles])

  useEffect(() => {
    verifyLocationState(ROUTES_NAME.PROFILE)
  }, [verifyLocationState])

  const getAllResources = useCallback(async () => {
    if (!selectedCompanyUuid) return

    try {
      setLoading(true)
      const { data } = await ResourceProfileService.getCompanyResources(
        selectedCompanyUuid
      )

      const resourcesList = data.data.map((eachResource) => {
        const resourceIndex =
          location.state.selectedProfile.resources.findIndex(
            (currRes) => currRes.id === eachResource.id
          )

        if (resourceIndex >= 0) {
          return {
            ...eachResource,
            ...location.state.selectedProfile.resources[resourceIndex]
          }
        }

        return eachResource
      })

      setResources(resourcesList)
    } catch (err: any) {
      dispatch({
        action: 'OPEN_MODAL',
        component: (
          <CommonModal
            title="Oops! Algo deu errado"
            description={
              err?.response?.data?.error?.messages_client[0] || err.message
            }
            primaryButtonTitle="voltar"
            primaryButtonProps={{
              fontSize: '1rem',
              backgroundColor: 'noverde'
            }}
            primaryButtonAction={() => {
              dispatch({ action: 'CLOSE_MODAL' })
            }}
          />
        )
      })
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!location.state?.selectedProfile) {
      return
    }

    setSelectedProfile({
      id: String(location.state.parent.id),
      value: String(location.state.parent.name)
    })

    setIsProfileActive(location.state.selectedProfile.is_active)
  }, [location, history, verifyLocationState])

  useEffect(() => {
    getAllResources()
  }, [])

  const handleSubmitEdit = async (name: string) => {
    try {
      if (!location?.state?.selectedProfile?.uuid) return

      setLoading(true)

      const profileReqBody: ProfileDTO = {
        is_active: isProfileActive,
        name,
        resources,
        parent: {
          id: Number(selectedProfile?.id),
          uuid: selectedUnit.unitUuid,
          name: selectedProfile?.value
        }
      }

      const checkAnyResourceIsChecked = resources
        .map((item) => item.permissions.read)
        .some((item) => item === true)

      if (checkAnyResourceIsChecked) {
        await ProfileService.updateProfileByUuid(
          location.state.selectedProfile.uuid,
          profileReqBody
        )
        showToast({
          message: 'Seu perfil foi atualizado com sucesso',
          title: 'Perfil Atualizado',
          type: 'success'
        })

        clearLocationStateAndReturn(ROUTES_NAME.PROFILE)
      } else {
        showToast({
          message: 'Selecione ao menos um dos recursos listados',
          title: '',
          type: 'error'
        })
      }
    } catch (err: any) {
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: '',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }
  const { handleSubmit, errors, data, handleChange } = useForm<FormValues>({
    validations: {
      name: {
        required: {
          message: 'Não esqueça o nome do perfil',
          value: true
        },
        pattern: {
          message: 'Apenas letras no nome do perfil',
          value: '^[A-Za-z ]*$'
        }
      }
    },
    initialValues: {
      name: location.state?.selectedProfile.name
    },
    onSubmit: async () => await handleSubmitEdit(data.name as string)
  })

  const handleGoBack = () => {
    history.goBack()
  }

  const handleDeleteProfile = async () => {
    const profileUuid = location?.state?.selectedProfile?.uuid

    try {
      setLoading(true)

      if (!profileUuid) return

      await ProfileService.deleteProfileByUuid(profileUuid)

      showToast({
        message: 'Seu perfil foi excluído com sucesso',
        title: 'Perfil Excluído',
        type: 'success'
      })

      clearLocationStateAndReturn(ROUTES_NAME.PROFILE)
    } catch (err: any) {
      showToast({
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        title: '',
        type: 'error'
      })
    } finally {
      dispatch({ action: 'CLOSE_MODAL' })
      setLoading(false)
    }
  }

  const handleRemoveProfile = () => {
    dispatch({
      action: 'OPEN_MODAL',
      component: (
        <CommonModal
          title="excluir perfil"
          description="Tem certeza que deseja excluir esse perfil? Uma vez excluído, você não poderá acessá-lo novamente."
          primaryButtonTitle="excluir perfil"
          primaryButtonProps={{ fontSize: '1rem', backgroundColor: 'error' }}
          primaryButtonAction={() => handleDeleteProfile()}
          secondaryButtonTitle="cancelar"
          secondaryButtonProps={{
            variant: 'text',
            color: 'galaxy',
            withoutUnderline: true,
            _spacing: {
              padding: '0'
            }
          }}
          secondaryButtonAction={() => dispatch({ action: 'CLOSE_MODAL' })}
        />
      )
    })
  }

  const handleUpdateEditTable = (props: UpdateListType) => {
    if (props.element === 'checkbox') {
      setResources((currState) => {
        let currStateCopy = [...currState]

        if (
          currStateCopy[props.index].abbreviation === 'CAD_PER' &&
          props.permissionKey === 'write'
        ) {
          if (!props.newValue) {
            currStateCopy = currState.map((resource) => ({
              ...resource,
              permissions: {
                ...resource.permissions,
                allow: false
              }
            }))
          } else {
            dispatch({
              action: 'OPEN_MODAL',
              component: (
                <CommonModal
                  title="Atenção"
                  description="Você sinalizou que o perfil que está criando pode criar outros perfis. Marque na coluna permitir os recursos que este perfil pode dar permissões de acesso"
                  primaryButtonTitle="Fechar"
                  primaryButtonAction={() =>
                    dispatch({ action: 'CLOSE_MODAL' })
                  }
                />
              )
            })
          }
        }

        if (props.permissionKey === 'read' && !props.newValue) {
          currStateCopy[props.index].permissions = {
            exclude: false,
            export: false,
            read: false,
            write: false,
            allow: false
          }

          return [...currStateCopy]
        }

        currStateCopy[props.index].permissions[props.permissionKey] =
          props.newValue

        return [...currStateCopy]
      })

      return
    }

    setIsProfileActive(props.newValue)
  }

  const renderListHeader = () => (
    <S.TitleInputWrapper>
      <Text color="galaxy">Perfil</Text>
      <S.InputRowWrapper>
        <Input
          name="name"
          animatedLabel="Nome do perfil"
          message={errors.name}
          value={data.name || ''}
          onChange={handleChange('name')}
        />

        <AutoComplete
          options={profiles}
          selectedOption={selectedProfile}
          inputValue={profileValue}
          onInputChange={(value: string) => setProfileValue(value)}
          onChange={(value) => {
            setCustomErrors((prev) => ({
              ...prev,
              perfilHierarquia: false
            }))
            handleSelectedProfile(value ? value : { id: '', value: '' })
          }}
          renderInput={(params) => (
            <Input
              name="perfilHierarquia"
              placeholder="selecione um perfil de hierarquia..."
              animatedLabel="perfil hierarquia *"
              message={
                customErrors.perfilHierarquia
                  ? 'Informe um perfil de hierarquia'
                  : ''
              }
              data-lpignore
              {...params}
            />
          )}
        />

        <S.SwitchGroup>
          <label htmlFor="">ativo</label>
          <ToggleSwitcher
            isActive={isProfileActive}
            toggleActive={(currValue) => {
              handleUpdateEditTable({
                element: 'switch',
                newValue: currValue
              })
            }}
          />
        </S.SwitchGroup>
      </S.InputRowWrapper>
    </S.TitleInputWrapper>
  )

  const renderItem = ({
    item,
    index
  }: {
    item: ResourceDTO
    index: number
  }) => {
    return (
      <>
        <span className="recurse-column__title">{item.name}</span>
        <Checkbox
          label="Ver"
          isChecked={item.permissions.read}
          toggleChecked={(currValue) =>
            handleUpdateEditTable({
              element: 'checkbox',
              permissionKey: 'read',
              index,
              newValue: currValue
            })
          }
        />
        {item.can_register ? (
          <>
            <Checkbox
              isDisabled={!item.permissions.read}
              label="Criar"
              isChecked={item.permissions.write}
              toggleChecked={(currValue) =>
                handleUpdateEditTable({
                  element: 'checkbox',
                  permissionKey: 'write',
                  index,
                  newValue: currValue
                })
              }
            />
            <Checkbox
              isDisabled={!item.permissions.read}
              label="Excluir"
              isChecked={item.permissions.exclude}
              toggleChecked={(currValue) =>
                handleUpdateEditTable({
                  element: 'checkbox',
                  permissionKey: 'exclude',
                  index,
                  newValue: currValue
                })
              }
            />
          </>
        ) : (
          <>
            <span>-</span>
            <span>-</span>
          </>
        )}

        {item.can_extract ? (
          <Checkbox
            isDisabled={!item.permissions.read}
            label="Exportar"
            isChecked={item.permissions.export}
            toggleChecked={(currValue) =>
              handleUpdateEditTable({
                element: 'checkbox',
                permissionKey: 'export',
                index,
                newValue: currValue
              })
            }
          />
        ) : (
          <span>-</span>
        )}

        <Checkbox
          label="Permitir"
          isDisabled={
            !resources?.find((resource) => resource.abbreviation === 'CAD_PER')
              ?.permissions?.write && !item.permissions.allow
          }
          isChecked={item.permissions.allow}
          toggleChecked={(currValue) =>
            handleUpdateEditTable({
              element: 'checkbox',
              permissionKey: 'allow',
              index,
              newValue: currValue
            })
          }
        />
      </>
    )
  }

  useEffect(() => {
    const _permissions = getResourcePermissions(appData, 'CAD', 'CAD_PER')

    if (_permissions) setPermissions(_permissions)
  }, [appData])

  return (
    <Form
      onSubmit={(e) => {
        const errors = customErrors
        errors.perfilHierarquia = !selectedProfile

        setCustomErrors(errors)
        handleSubmit(e)
      }}
    >
      <S.Container>
        <HeaderActions
          title="editar perfil"
          hasReturnButton={true}
          returnButtonAction={handleGoBack}
          renderCustomRightCorner={<></>}
        />

        {!!resources.length && (
          <>
            <ListTable<ResourceDTO>
              tableStructure={{
                header: '1fr 2.15fr',
                body: '1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr',
                columns: [
                  'recurso',
                  <S.WrapperTooltip key="tootilp">
                    <p>permissão</p>
                    <Tooltip
                      text={[
                        'Ver - Permite acessar o recurso no menu lateral',
                        'Criar -Permite criar o recurso',
                        'Excluir - Permite excluir o recurso',
                        'Exportar - Permite exportar o recurso',
                        'Permitir - Permite o perfil atribuir o recurso a outro perfil'
                      ]}
                    />
                  </S.WrapperTooltip>
                ]
              }}
              data={resources}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
              listHeaderComponent={renderListHeader}
            />

            <S.FooterButtonsWrapper>
              {permissions.write && (
                <Button _sizing={{ maxWidth: '220px' }}>Atualizar</Button>
              )}
              {permissions.exclude && (
                <Button
                  _sizing={{ maxWidth: '220px' }}
                  {...{ fontSize: '1rem', backgroundColor: 'error' }}
                  onClick={handleRemoveProfile}
                  type="button"
                >
                  Excluir
                </Button>
              )}
            </S.FooterButtonsWrapper>
          </>
        )}
      </S.Container>
    </Form>
  )
}
