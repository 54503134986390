/* eslint-disable react/jsx-key */
import { ReactNode, cloneElement } from 'react'

import { PartnersThemeProvider } from './theme'
import { ModalProvider } from './ModalProvider'
import { AppDataProvider } from './AppDataProvider'
import { LoadingProvider } from './LoadingProvider'

interface AppProviderProps {
  children: ReactNode
}

const contexts = [
  <PartnersThemeProvider />,
  <ModalProvider />,
  <AppDataProvider />,
  <LoadingProvider />
]

export const AppProvider = ({
  children: app
}: AppProviderProps): JSX.Element => (
  <>
    {contexts.reduce(
      (children, parent) => cloneElement(parent, { children }),
      app
    )}
  </>
)
