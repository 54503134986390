/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useCallback } from 'react'
import Skeleton from 'react-loading-skeleton'
import { format } from 'date-fns'

import { useRouter } from 'hooks/useRouter'
import { useLoading } from 'hooks/useLoading'
import { useAppData } from 'hooks/useAppData'
import { useToast } from 'hooks/useToast'

import CompanyService from 'services/remote/v1/Company/CompanyService'
import { CompanyDTO } from 'services/remote/v1/Company/CompanyServiceTypes'

import { ListTable } from 'components/ListTable'
import { HeaderActions } from 'components/HeaderActions'
import { ToggleSwitcher } from 'components/ToggleSwitcher'
import { formatNameLastName } from 'utils/formatters/formatNameLastName'

import Assets from 'assets'
import { ROUTES_NAME } from 'routes'

import * as S from './Company.styles'
import { PermissionsType } from 'pages/Profile/types'
import { getResourcePermissions } from 'utils/getResourcePermissions'
import { TooltipText } from 'components/TooltipText'

type RenderItemProps = {
  item: CompanyDTO
  index: number
  canEdit?: boolean
}

const renderListHeader = () => <S.ListHeader>Todas as empresas</S.ListHeader>

export const Company = () => {
  const [companyData, setCompanyData] = useState<CompanyDTO[]>([])
  const { history } = useRouter()
  const { setLoading, isLoading } = useLoading()
  const { selectedUnit, data: appData } = useAppData()
  const [permissions, setPermissions] = useState<PermissionsType>(
    {} as PermissionsType
  )
  const { showToast } = useToast()

  const init = useCallback(async () => {
    try {
      setLoading(true)
      const response = await CompanyService.getAllCompanies(true)

      setCompanyData(response.data.data)
    } catch (err: any) {
      showToast({
        title: 'Oops! Algo deu errado',
        message: err?.response?.data?.error?.messages_client[0] || err.message,
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }, [setLoading, showToast])

  useEffect(() => {
    init()
  }, [init])

  const handleNewCompanyButton = () => {
    history.push({
      pathname: ROUTES_NAME.COMPANY_CREATE,
      state: {
        currentUnit: selectedUnit.unitUuid
      }
    })
  }

  const handleCompanyEditButton = useCallback(
    (item: CompanyDTO) => {
      history.push({
        pathname: ROUTES_NAME.COMPANY_EDIT,
        state: { selectedCompany: item, currentUnit: selectedUnit.unitUuid }
      })
    },
    [history, selectedUnit]
  )

  const handleOpenPreview = (item: CompanyDTO) => {
    const url = `${process.env.REACT_APP_API_NOVERDE_ORIGINACAO}/credito/${item.uuid}`
    window.open(url, '_blank')
  }

  const renderItem = useCallback(
    ({ item, canEdit }: RenderItemProps) => {
      const formattedDate = item?.created_at
        ? format(new Date(item?.created_at) || new Date(), 'dd/MM/yyyy')
        : ''
      return (
        <>
          <span>
            <TooltipText text={item?.name}>
              {item?.name || <Skeleton width={200} />}
            </TooltipText>
          </span>
          <span>
            <TooltipText text={item?.cnpj}>
              {item?.cnpj || <Skeleton width={200} />}
            </TooltipText>
          </span>
          <span>
            <TooltipText text={formattedDate}>
              {item?.created_at ? formattedDate : <Skeleton width={100} />}{' '}
            </TooltipText>
          </span>
          <span>
            {item?.created_by?.name ? (
              <TooltipText text={formatNameLastName(item?.created_by?.name)}>
                {formatNameLastName(item?.created_by?.name)}
              </TooltipText>
            ) : (
              <Skeleton width={100} />
            )}
          </span>
          <ToggleSwitcher
            isActive={isLoading ? false : item.is_active}
            toggleActive={() => null}
            disabled
          />
          {canEdit ? (
            <S.EditButton onClick={() => handleCompanyEditButton(item)}>
              <Assets
                assetProps={{ key: 'lineEditV2', type: 'icon' }}
                alt="editar"
              />
            </S.EditButton>
          ) : (
            <div></div>
          )}
          {item.landing_name ? (
            <S.EditButton onClick={() => handleOpenPreview(item)}>
              <Assets
                assetProps={{ key: 'openWebIcon', type: 'icon' }}
                alt="editar"
              />
            </S.EditButton>
          ) : (
            <S.EditButton disabled>
              <Assets
                assetProps={{ key: 'webIcon', type: 'icon' }}
                alt="editar"
              />
            </S.EditButton>
          )}
        </>
      )
    },
    [handleCompanyEditButton, isLoading]
  )

  useEffect(() => {
    const _permissions = getResourcePermissions(appData, 'CAD', 'CAD_EMPR')

    if (_permissions) setPermissions(_permissions)
  }, [appData])

  return (
    <S.Container>
      <HeaderActions
        hasReturnButton={false}
        title="empresa"
        buttonTitle="+ nova empresa"
        buttonAction={handleNewCompanyButton}
        renderCustomRightCorner={!permissions.write ? <></> : null}
      />

      <ListTable<CompanyDTO>
        tableStructure={{
          header:
            'minmax(180px, 1fr) minmax(150px, 13rem) minmax(100px, 150px) minmax(140px, 1fr) minmax(60px, 60px) minmax(60px, 60px) minmax(60px, 60px)',
          body: 'minmax(180px, 1fr) minmax(150px, 13rem) minmax(100px, 150px) minmax(140px, 1fr) minmax(60px, 60px) minmax(60px, 60px) minmax(60px, 60px)',
          columns: [
            'nome',
            'cnpj',
            'criado em',
            'criador por',
            'ativa',
            'editar',
            'site'
          ]
        }}
        renderItem={renderItem}
        keyExtractor={(item) => item.uuid || String(Math.random())}
        data={
          isLoading || !companyData.length
            ? Array(appData?.companies.length).fill({})
            : companyData
        }
        listHeaderComponent={renderListHeader}
        canEdit={permissions.write || permissions.exclude}
      />
    </S.Container>
  )
}
