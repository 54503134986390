import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'
import {
  DeleteUserByUuidResponse,
  GetAllUsersProfilesResponse,
  GetAllUsersResponse,
  UpdateUserReqBody
} from './UserServiceTypes'

class UserService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.portal)
  }

  /**
   * Update user by uuid
   * @param uuid string
   * @returns DeleteProfileByUuidResponse
   */
  public updateProfileByUuid(
    uuidCompany: string,
    uuid: string,
    data: UpdateUserReqBody
  ) {
    return this.instance.put<DeleteUserByUuidResponse>(
      `/companies/${uuidCompany}/users/${uuid}`,
      { ...data }
    )
  }

  /**
   * Delete user by uuid
   * @param uuid string
   * @returns DeleteProfileByUuidResponse
   */
  public deleteProfileByUuid(uuidCompany: string, uuid: string) {
    return this.instance.delete<DeleteUserByUuidResponse>(
      `/companies/${uuidCompany}/users/${uuid}`
    )
  }

  /**
   * Update user password
   * @param oldPassword string
   * @param newPassword string
   * @returns void
   */
  public updateUserPassword(oldPassword: string, newPassword: string) {
    const reqBody = {
      new_password: newPassword,
      old_password: oldPassword
    }

    return this.instance.patch('/users/change-password', reqBody)
  }

  /**
   * Update user name
   * @param name string
   * @returns void
   */
  public updateUserName(name: string) {
    const reqBody = {
      name
    }

    return this.instance.patch('/users/update-profile', reqBody)
  }

  /**
   * Get all users
   * @returns GetAllUsersResponse
   */
  public getAllUsers(uuid: string) {
    return this.instance.get<GetAllUsersResponse>(`/units/${uuid}/users`)
  }

  /**
   * Get all users profiles
   * @returns GetAllUsersProfilesResponse
   */
  public getAllUsersProfiles() {
    return this.instance.get<GetAllUsersProfilesResponse>(`/users/profiles`)
  }
}

export default new UserService()
