import { useState } from 'react'

export const useLocalStorage = <T>(
  key: string,
  initialValue: T
): [T, (s: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (err) {
      console.log(err)
      return initialValue
    }
  })

  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (err) {
      console.log(err)
    }
  }

  return [storedValue, setValue]
}

type StorageValueType = keyof {
  [M in keyof typeof STORAGE_KEYS as `${typeof STORAGE_KEYS[M]}`]: M
}

export enum STORAGE_KEYS {
  ACCESS_TOKEN = '@partners/access_token',
  APP_DATA = '@partners/app_data',
  SELECTED_COMPANY_UUID = '@partners/selected_company_uuid',

  MOCKED_OBJ_DATA = '@partners/mocked_obj_data',
  MOCKED_ARR_DATA = '@partners/mocked_arr_data'
}

export const getStorageValue = <T>(key: StorageValueType): T | null => {
  const item = window.localStorage.getItem(key)

  return item ? JSON.parse(item) : null
}

export const clearLocalStorage = () => {
  const deletedKeys = [
    STORAGE_KEYS.APP_DATA,
    STORAGE_KEYS.ACCESS_TOKEN,
    STORAGE_KEYS.SELECTED_COMPANY_UUID
  ]

  deletedKeys.forEach((eachKey) => {
    localStorage.removeItem(eachKey)
  })
}
