import styled from 'styled-components'

import { appearFromTop } from 'layout/animations'

export const Layer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.59);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Dialog = styled.div`
  position: relative;
  z-index: 200;
  background-color: white;
  border-radius: 15px;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${appearFromTop} 0.5s;
  width: fit-content;
`

export const Body = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

export const CloseButton = styled.div`
  position: absolute;
  top: 32px;
  left: 34px;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
  }
`
